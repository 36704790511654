import * as R from 'ramda'
import React, { Component } from 'react'
import Helmet from 'react-helmet'
import { Switch, Route, Redirect } from 'react-router-dom'
import ProductOrderSubContainer from './ProductOrderSubContainer'
import AddOnsSubContainer from './AddOnsSubContainer'
import OverViewSubContainer from './OverviewSubContainer'
const canUseDOM = !!(typeof window !== 'undefined' && window.document)

export class OrderWrapper extends Component {
    constructor(props) {
        super(props)

        this.checkCart = this.checkCart.bind(this)
    }

    checkCart() {
        const { history, cc, lang, cart, productId } = this.props

        const cart_id = R.path(['cart_id'], cart)
        const cart_editable = R.path(['editable'], cart)

        const targetURL = productId ? `/${lang}/${cc}/product/${productId}` : `/${lang}/${cc}`

        if (!canUseDOM || !cart || !cart_id || !cart_editable) {
            history.push(targetURL)
            return false
        }
        return true
    }

    render() {
        const { productId, extras, lang, cc, match, location, history, products, cart, dispatch, order } = this.props

        const url = R.path(['url'], match)

        return productId ? (
            <div>
                <Helmet title="Tilaus" meta={[{ name: 'robots', content: 'noindex' }]} />
                <Switch>
                    <Route
                        exact
                        path={`${url}/add-ons`}
                        render={() =>
                            this.checkCart() ? (
                                <AddOnsSubContainer
                                    productId={productId}
                                    extras={extras}
                                    lang={lang}
                                    cc={cc}
                                    cart={cart}
                                />
                            ) : null
                        }
                    />
                    <Route
                        exact
                        path={`${url}/overview`}
                        render={() => (
                            <OverViewSubContainer
                                match={match}
                                lang={lang}
                                location={location}
                                cc={cc}
                                cart={cart}
                                checkCart={this.checkCart}
                            />
                        )}
                    />
                    <Route
                        path={`${url}/`}
                        render={() => (
                            <ProductOrderSubContainer
                                lang={lang}
                                match={match}
                                location={location}
                                history={history}
                                cc={cc}
                                checkCart={this.checkCart}
                                products={products}
                                dispatch={dispatch}
                                cart={cart}
                                order={order}
                            />
                        )}
                    />
                </Switch>
            </div>
        ) : (
            <Redirect to={`/${lang}/${cc}`} />
        )
    }
}
