import React from 'react'

const CheckmarkIcon = (props) => {
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath="url(#clip0_1_37)">
                <rect width="25" height="25" rx="12.5" fill="#76CA61" />
                <path
                    d="M12.5001 0.000244141C5.6075 0.000244141 0 5.60767 0 12.5002C0 19.3927 5.6075 25.0001 12.5001 25.0001C19.3926 25.0001 25 19.3927 25 12.5002C25 5.60767 19.3926 0.000244141 12.5001 0.000244141ZM12.5001 22.9509C6.73741 22.9509 2.04917 18.2628 2.04917 12.5002C2.04917 6.73758 6.73741 2.04941 12.5001 2.04941C18.2627 2.04941 22.9508 6.73758 22.9508 12.5002C22.9508 18.2628 18.2627 22.9509 12.5001 22.9509Z"
                    fill="white"
                    stroke="#76CA61"
                    strokeWidth="10"
                />
                <path
                    d="M17.9075 7.70239L10.4857 15.1242L7.09248 11.731C6.69232 11.3309 6.04364 11.3309 5.64347 11.731C5.24339 12.1311 5.24339 12.7798 5.64347 13.18L9.76123 17.2977C9.96131 17.4977 10.2235 17.5978 10.4857 17.5978C10.7479 17.5978 11.0102 17.4977 11.2102 17.2977L19.3565 9.15148C19.7566 8.75132 19.7566 8.10264 19.3565 7.70247C18.9563 7.30231 18.3076 7.30231 17.9075 7.70239Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_1_37">
                    <rect width="25" height="25" rx="12.5" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default CheckmarkIcon
