export const ADD_FORM_RECIPIENT_INFO = 'ADD_FORM_RECIPIENT_INFO'
export const ADD_FORM_CARD_TEXT = 'ADD_FORM_CARD_TEXT'
export const ADD_FORM_DELIVERY = 'ADD_FORM_DELIVERY'
export const ADD_FORM_SENDER_INFO = 'ADD_FORM_SENDER_INFO'
export const ADD_FORM_PAYMENT = 'ADD_FORM_PAYMENT'
export const SET_F2 = 'SET_F2'
export const SET_F5 = 'SET_F5'
export const SET_F6 = 'SET_F6'
export const SET_PAYMENT_OK = 'SET_PAYMENT_OK'
export const CLEAR_FUNERAL_DATA = 'CLEAR_FUNERAL_DATA'
export const CLEAR_COMPANY_AND_RECIPIENTNOTCONTACTED_DATA = 'CLEAR_COMPANY_AND_RECIPIENTNOTCONTACTED_DATA'
export const CLEAR_FORMS = 'CLEAR_FORMS'
export const SAVE_GIFTCARD_PRICE = 'SAVE_GIFTCARD_PRICE'
export const SAVE_GIFTCARD_AMOUNT = 'SAVE_GIFTCARD_AMOUNT'
export const ADD_RIBBON_CONTENT = 'ADD_RIBBON_CONTENT'
export const ADD_CONDOLENCE_CONTENT = 'ADD_CONDOLENCE_CONTENT'

export const setF2 = (f2) => ({
    type: SET_F2,
    f2: f2,
})

export const setF5 = (f5) => ({
    type: SET_F5,
    f5: f5,
})

export const setF6 = (f6) => ({
    type: SET_F6,
    f6: f6,
})

export const setPaymentOK = (pOK) => ({
    type: SET_PAYMENT_OK,
    pOK: pOK,
})

export const saveGiftCardPrice = (giftcardPrice) => ({
    type: SAVE_GIFTCARD_PRICE,
    giftcardPrice: giftcardPrice,
})

export const saveGiftCardAmount = (giftcardAmount) => ({
    type: SAVE_GIFTCARD_AMOUNT,
    giftcardAmount: giftcardAmount,
})

export const addRibbonContent = (ribbonWords, ribbonNames) => ({
    type: ADD_RIBBON_CONTENT,
    ribbonWords: ribbonWords,
    ribbonNames: ribbonNames,
})

export const addCondolencesContent = (condolenceWords, condolenceMortName, condolenceSenderNames) => ({
    type: ADD_CONDOLENCE_CONTENT,
    condolenceWords: condolenceWords,
    condolenceMortName: condolenceMortName,
    condolenceSenderNames: condolenceSenderNames,
})

export const addFormRecipientInfo = (
    recipientName,
    recipientAddress,
    recipientPhone,
    recipientDate,
    recipientCompany,
    recipientZip,
    recipientCity,
    mortName,
    churchName,
    churchTime,
) => ({
    type: ADD_FORM_RECIPIENT_INFO,
    recipientName: recipientName,
    recipientAddress: recipientAddress,
    recipientPhone: recipientPhone,
    recipientDate: recipientDate,
    recipientCompany: recipientCompany,
    recipientZip: recipientZip,
    recipientCity: recipientCity,
    mortName: mortName,
    churchName: churchName,
    churchTime: churchTime,
})

export const clearFuneralData = (
    mortName,
    churchName,
    churchTime,
    ribbonWords,
    ribbonNames,
    condolenceWords,
    condolenceMortName,
    condolenceSenderNames,
) => ({
    type: CLEAR_FUNERAL_DATA,
    mortName: mortName,
    churchName: churchName,
    churchTime: churchTime,
    ribbonWords: ribbonWords,
    ribbonNames: ribbonNames,
    condolenceWords: condolenceWords,
    condolenceMortName: condolenceMortName,
    condolenceSenderNames: condolenceSenderNames,
})

export const clearCompanyAndRecipientNotContactedData = (
    recipientCompany,
    recipientAdvanceNotice,
    recipientNotContacted,
) => ({
    type: CLEAR_COMPANY_AND_RECIPIENTNOTCONTACTED_DATA,
    recipientCompany: recipientCompany,
    recipientAdvanceNotice: recipientAdvanceNotice,
    recipientNotContacted: recipientNotContacted,
})

export const addFormCardText = (attachedMessage) => ({
    type: ADD_FORM_CARD_TEXT,
    attachedMessage: attachedMessage,
})

export const addFormDelivery = (attachedMessageToCourier, recipientNotContacted, recipientAdvanceNotice) => ({
    type: ADD_FORM_DELIVERY,
    attachedMessageToCourier: attachedMessageToCourier,
    recipientNotContacted: recipientNotContacted,
    recipientAdvanceNotice: recipientAdvanceNotice,
})

export const addFormSenderInfo = (senderPhone, senderEmail, senderName, createAccount, senderCompany) => ({
    type: ADD_FORM_SENDER_INFO,
    senderPhone: senderPhone,
    senderEmail: senderEmail,
    senderName: senderName,
    senderCompany: senderCompany,
    createAccount: createAccount,
})

export const addFormPayment = (deliveryTerms) => ({
    type: ADD_FORM_PAYMENT,
    deliveryTerms: deliveryTerms,
})

export const clearForms = () => ({
    type: CLEAR_FORMS,
})
