import * as R from 'ramda'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { removeCartProduct, removeAllCartAdditionalProducts } from '../../actions/cartActions'
import { unselectRibbon, unselectCondolences, emptyCartAdditionalProduct } from '../../actions/orderActions'

import { getLanguage } from '../../utils/translation'
import config from '../../config'
import { isGiftcardByProductId } from '../../utils/ProductTypes'

const assets = R.path(['ASSETS'], config)

const pageTranslation = {
    fi: {
        page: {
            amount: 'kpl',
        },
    },
    en: {
        page: {
            amount: 'pcs',
        },
    },
}

class OverviewItem extends Component {
    constructor(props) {
        super(props)

        this.removeProductFromCart = this.removeProductFromCart.bind(this)
    }

    removeProductFromCart(event) {
        event.preventDefault()

        const {
            product,
            removeCartProduct,
            removeAllCartAdditionalProducts,
            unselectRibbon,
            unselectCondolences,
            emptyCartAdditionalProduct,
        } = this.props

        const id = R.path(['id'], product)

        // Redux + backend
        removeCartProduct(id)
        removeAllCartAdditionalProducts()
        unselectRibbon()
        unselectCondolences()
        emptyCartAdditionalProduct()
    }

    render() {
        const { product, index, productAmount, giftcardAmount, giftcardPrice, lang, cc } = this.props

        const selectedTrans = R.path([getLanguage()], pageTranslation)
        const trans = R.path(['page'], selectedTrans)

        const id = R.path(['id'], product)
        const name = R.path(['name'], product)
        const description = R.path(['description'], product)
        const productPrice = R.path(['price'], product)

        const isGiftcard = isGiftcardByProductId(id)

        const price = isGiftcard ? giftcardPrice : productPrice

        const amount = isGiftcard ? giftcardAmount : productAmount

        const image =
            cc === 'fi'
                ? `${assets}/thumb_${R.path(['image'], product)}`
                : R.path(['image'], product) + '?width=300&height=300'

        return (
            <div className="pure-g flex-nowrap">
                <div className="pure-u-1 pure-u-sm-1-24 pure-hidden-smallphone"></div>
                <div className="pure-u-1 flex-center">
                    <div className="pure-g overview__itemWrapper" key={index}>
                        <div className="pure-u-2-5 pure-u-sm-1-5">
                            <Link to={`/${lang}/${cc}/product/${id}`}>
                                <img className="pure-img" src={image} alt="" />
                            </Link>
                        </div>
                        <div className="pure-u-2-5 pure-u-sm-3-5 price__name">
                            <h2>{name}</h2>
                            <h3>
                                {price}
                                <span className="small-font">€</span>
                            </h3>
                            <h3>
                                <span className="small-font">
                                    {amount} {R.path(['amount'], trans)}
                                </span>
                            </h3>
                            <p className="product-description">{description}</p>
                        </div>
                        <div className="pure-u-1-5 pure-u-sm-1-5 remove__item">
                            <button className="close-button" onClick={this.removeProductFromCart}>
                                <i className="fa fa-close fa-2x" />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="pure-u-1 pure-u-sm-1-24 pure-hidden-smallphone"></div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    removeCartProduct: (id) => dispatch(removeCartProduct(id)),
    removeAllCartAdditionalProducts: () => dispatch(removeAllCartAdditionalProducts()),
    unselectRibbon: () => dispatch(unselectRibbon()),
    unselectCondolences: () => dispatch(unselectCondolences()),
    emptyCartAdditionalProduct: () => dispatch(emptyCartAdditionalProduct()),
})

export default connect(undefined, mapDispatchToProps)(OverviewItem)
