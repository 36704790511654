export const SHOW_MODAL = 'SHOW_MODAL'
export const HIDE_MODAL = 'HIDE_MODAL'

export const showModal = (show, label, modaltype, modalclass = 'modal__content', headline, content) => ({
    type: SHOW_MODAL,
    show: show,
    label: label,
    modaltype: modaltype,
    modalclass: modalclass,
    headline: headline,
    content: content,
})

export const hideModal = () => ({
    type: HIDE_MODAL,
    show: false,
})
