import * as R from 'ramda'
const canUseDOM = !!(typeof window !== 'undefined' && window.document)
import trackingUtils from './trackingUtils'
import { getTaxAmount } from '../utils/CartProducts'
import { DELIVERY_COST_DOMESTIC, DELIVERY_COST_FOREIGN, DELIVERY_COST_GIFTCARD, GIFTCARD_ID } from '../utils/constants'

const dataLayer = typeof window !== 'undefined' ? R.path(['dataLayer'], window) : undefined

export const trackPageView = (location) => {
    if (canUseDOM && dataLayer && dataLayer.push) {
        dataLayer.push({
            event: 'virtualPageView',
            location,
        })
    }
}

// Fires when user enters home page
export const trackHomePageView = (storeState) => {
    if (canUseDOM && dataLayer && dataLayer.push) {
        dataLayer.push({
            event: 'home',
            adwords_params: {
                ecomm_pagetype: 'home',
            },
        })
    }
}

// Fires when user enters category page
export const trackCategoryPageView = (storeState) => {
    if (canUseDOM && dataLayer && dataLayer.push) {
        dataLayer.push({
            event: 'category',
            adwords_params: {
                ecomm_pagetype: 'category',
            },
        })
    }
}

// Fires when user enters content page
export const trackContentPageView = (storeState) => {
    if (canUseDOM && dataLayer && dataLayer.push) {
        dataLayer.push({
            event: 'content',
            adwords_params: {
                ecomm_pagetype: 'other',
            },
        })
    }
}

// Fires when user enters on the product page
export const trackProductDetailView = (storeState, productId) => {
    let products = R.path(['products', 'allProducts'], storeState)

    if (!products || R.length(products) === 0) return

    let selectedProductDetails = R.find(R.propEq('id', Number(productId)))(products)

    let productPrice = 0

    // Calculate revenue depending on product type
    if (productId !== `${GIFTCARD_ID}`) {
        productPrice += R.path(['price'], selectedProductDetails)
    } else {
        productPrice +=
            Number(R.path(['form', 'giftcardPrice'], storeState)) *
            Number(R.path(['form', 'giftcardAmount'], storeState))
    }

    if (canUseDOM && dataLayer && dataLayer.push) {
        dataLayer.push({
            event: 'detail',
            adwords_params: {
                ecomm_prodid: productId,
                ecomm_pagetype: 'product',
                ecomm_totalvalue: Number(productPrice),
                ecomm_category: 'flower',
            },
            fbpixel_params: {
                fbp_prodid: [String(productId)],
                fbp_totalvalue: Number(productPrice),
                fbp_category: 'flower',
            },
            adform_params: {
                adform_prodid: String(productId),
                adform_category: 'flower',
            },
            ecommerce: {
                detail: {
                    products: [
                        {
                            name: R.path(['name'], selectedProductDetails),
                            id: productId,
                            price: String(productPrice),
                            category: 'flower',
                            quantity: 1,
                        },
                    ],
                },
            },
        })
    }
}

// Fires when user adds the product to cart by clicking order button
export const trackProductAddToCart = (storeState) => {
    let productId = R.path(['order', 'selectedProductId'], storeState)
    let selectedProductDetails = R.find(R.propEq('id', Number(productId)))(
        R.path(['products', 'allProducts'], storeState),
    )

    let productPrice = 0

    // Calculate revenue depending on product type
    if (productId !== `${GIFTCARD_ID}`) {
        productPrice += R.path(['price'], selectedProductDetails)
    } else {
        productPrice +=
            Number(R.path(['form', 'giftcardPrice'], storeState)) *
            Number(R.path(['form', 'giftcardAmount'], storeState))
    }

    if (canUseDOM && dataLayer && dataLayer.push) {
        dataLayer.push({
            event: 'addToCart',
            fbpixel_params: {
                fbp_prodid: [String(productId)],
                fbp_totalvalue: Number(productPrice),
                fbp_category: 'flower',
            },
            ecommerce: {
                currencyCode: 'EUR',
                add: {
                    products: [
                        {
                            name: R.path(['name'], selectedProductDetails),
                            id: productId,
                            price: String(productPrice),
                            category: 'flower',
                            quantity: 1,
                        },
                    ],
                },
            },
        })
    }
}

// Fires when user adds the addOn products to cart
export const trackAddOnProductsAddToCart = (storeState) => {
    let addonitems = R.path(['order', 'selectedAddOnProducts'], storeState)

    if (R.length(addonitems) === 0) return

    let listOfAdditionalProductsWithDetails = []
    const addOnItemsDetails = R.path(['products', 'allExtras'], storeState)

    // Push additional items if available
    addonitems.forEach(function (element) {
        const addonitem_value = R.path(['price'], R.find(R.propEq('id', element))(addOnItemsDetails))
        const addonitem_id = String(R.path(['id'], R.find(R.propEq('id', element))(addOnItemsDetails)))

        let obj = {
            name: R.path(['name'], R.find(R.propEq('id', element))(addOnItemsDetails)),
            id: addonitem_id,
            price: String(addonitem_value),
            category: 'add-on',
            quantity: '1',
        }

        listOfAdditionalProductsWithDetails.push(obj)
    })

    if (canUseDOM && dataLayer && dataLayer.push) {
        dataLayer.push({
            event: 'addToCart',
            ecommerce: {
                currencyCode: 'EUR',
                add: {
                    products: listOfAdditionalProductsWithDetails,
                },
            },
        })
    }
}

export const trackProductRemoveAndAddToCart = (storeState) => {
    // Remove previous product from cart and add the current one

    let productIdGTM = R.path(['order', 'GTMProductId'], storeState)
    let productNameGTM = R.path(['order', 'GTMProductName'], storeState)
    let productPriceGTM = R.path(['order', 'GTMProductPrice'], storeState)

    let productId = R.path(['order', 'selectedProductId'], storeState)
    let selectedProductDetails = R.find(R.propEq('id', Number(productId)))(
        R.path(['products', 'allProducts'], storeState),
    )

    let productPrice = 0

    // Calculate revenue depending on product type
    if (productId !== `${GIFTCARD_ID}`) {
        productPrice += R.path(['price'], selectedProductDetails)
    } else {
        productPrice +=
            Number(R.path(['form', 'giftcardPrice'], storeState)) *
            Number(R.path(['form', 'giftcardAmount'], storeState))
    }

    if (canUseDOM && dataLayer && dataLayer.push) {
        dataLayer.push({
            event: 'removeFromCart',
            ecommerce: {
                remove: {
                    products: [
                        {
                            name: productNameGTM,
                            id: productIdGTM,
                            price: String(productPriceGTM),
                            category: 'flower',
                            quantity: 1,
                        },
                    ],
                },
            },
        })
        dataLayer.push({
            event: 'addToCart',
            fbpixel_params: {
                fbp_prodid: [String(productId)],
                fbp_totalvalue: Number(productPrice),
                fbp_category: 'flower',
            },
            ecommerce: {
                currencyCode: 'EUR',
                add: {
                    products: [
                        {
                            name: R.path(['name'], selectedProductDetails),
                            id: productId,
                            price: String(productPrice),
                            category: 'flower',
                            quantity: 1,
                        },
                    ],
                },
            },
        })
    }
}

// For additional products only!
export const trackProductRemoveFromCart = (storeState) => {
    let addonitems = R.path(['order', 'selectedAddOnProducts'], storeState)
    let listOfAdditionalProductsWithDetails = []

    const addOnItemsDetails = R.path(['products', 'allExtras'], storeState)

    // Push additional items if available
    addonitems.forEach(function (element) {
        let obj = {
            name: R.path(['name'], R.find(R.propEq('id', element))(addOnItemsDetails)),
            id: String(R.path(['id'], R.find(R.propEq('id', element))(addOnItemsDetails))),
            price: String(R.path(['price'], R.find(R.propEq('id', element))(addOnItemsDetails))),
            category: 'add-on',
            quantity: '1',
        }

        listOfAdditionalProductsWithDetails.push(obj)
    })

    if (canUseDOM && dataLayer && dataLayer.push) {
        dataLayer.push({
            event: 'removeFromCart',
            ecommerce: {
                remove: {
                    products: listOfAdditionalProductsWithDetails,
                },
            },
        })
    }
}

// Fires on checkout steps
export const trackCheckoutProcess = (storeState, step, label) => {
    let listOfProductsWithDetails = []

    let cartId = R.path(['cart', 'items', 'cart_id'], storeState)

    // With multiple products: Push products from cart
    const flowerProducts = R.path(['cart', 'items', 'products'], storeState)
    const countFlowerAmountById = R.countBy(R.prop('id'))(flowerProducts)
    const removeDuplicateFlowers = R.uniq(flowerProducts)
    const giftcardPrice = R.path(['cart', 'items', 'giftcard_price'], storeState)
    const giftcardAmount = R.path(['cart', 'items', 'giftcard_amount'], storeState)

    removeDuplicateFlowers.forEach((element) => {
        let flowerId = R.path(['id'], element)
        let price = flowerId === GIFTCARD_ID ? giftcardPrice : R.path(['price'], element)
        let quantity = flowerId === GIFTCARD_ID ? giftcardAmount : R.prop(flowerId)(countFlowerAmountById)

        let obj = {
            name: R.path(['name'], element),
            id: String(flowerId),
            price: String(price),
            category: 'flower',
            quantity: String(quantity),
        }

        listOfProductsWithDetails.push(obj)
    })

    // Push additional items if available
    const addonProducts = R.path(['cart', 'items', 'extras'], storeState)

    addonProducts.forEach((element) => {
        let obj = {
            name: R.path(['name'], element),
            id: String(R.path(['id'], element)),
            price: String(R.path(['price'], element)),
            category: 'add-on',
            quantity: '1',
        }

        listOfProductsWithDetails.push(obj)
    })

    // NOTE: adwords_params not refactored to support multiple products
    if (canUseDOM && dataLayer && dataLayer.push) {
        dataLayer.push({
            event: 'checkout',
            adwords_params: {
                ecomm_prodid: listOfProductsWithDetails[0].id,
                ecomm_pagetype: 'cart',
                ecomm_totalvalue: Number(listOfProductsWithDetails[0].price),
                ecomm_category: 'flower',
            },
            ecommerce: {
                checkout: {
                    actionField: {
                        step: step,
                        id: cartId,
                    },
                    products: listOfProductsWithDetails,
                },
            },
        })
    }
}

// Fires on purchase
export const trackPurchase = (storeState) => {
    let productsWithDetailsForGA = [] // Formatted for Google Analytics 4
    let revenue = 0
    let revenueWithoutTaxAndShipping = 0
    let taxWithoutShipping = 0

    let productId = R.path(['order', 'GTMProductId'], storeState)
    let selectedProductDetails = R.find(R.propEq('id', Number(productId)))(
        R.path(['products', 'allProducts'], storeState),
    )

    let cartId = R.path(['cart', 'items', 'cart_id'], storeState)

    // With multiple products: Push products from cart
    const flowerProducts = R.path(['cart', 'items', 'products'], storeState)
    const countFlowerAmountById = R.countBy(R.prop('id'))(flowerProducts)
    const uniqueFlowerProducts = R.uniq(flowerProducts)
    const giftcardPrice = R.path(['cart', 'items', 'giftcard_price'], storeState)
    const giftcardAmount = R.path(['cart', 'items', 'giftcard_amount'], storeState)

    uniqueFlowerProducts.forEach((element) => {
        let flowerId = R.path(['id'], element)
        let price = flowerId === GIFTCARD_ID ? giftcardPrice : R.path(['price'], element)
        let quantity = flowerId === GIFTCARD_ID ? giftcardAmount : R.prop(flowerId)(countFlowerAmountById)
        let tax = getTaxAmount(price, R.path(['vat'], element)) * quantity

        let objForGA = {
            item_id: String(flowerId),
            item_name: R.path(['name'], element),
            item_category: 'flower',
            price: String(price),
            quantity: String(quantity),
        }

        revenue += price * quantity
        taxWithoutShipping += tax
        revenueWithoutTaxAndShipping += price * quantity - tax

        productsWithDetailsForGA.push(objForGA)
    })

    // Push additional items if available
    const addOnProducts = R.path(['cart', 'items', 'extras'], storeState)
    const countAddOnAmountById = R.countBy(R.prop('id'))(addOnProducts)
    const uniqueAddOnProducts = R.uniq(addOnProducts)

    uniqueAddOnProducts.forEach((element) => {
        let addOnId = R.path(['id'], element)
        let quantity = R.prop(addOnId)(countAddOnAmountById)
        let price = R.path(['price'], element)
        let tax = getTaxAmount(price, R.path(['vat'], element)) * quantity

        let objForGA = {
            item_id: String(R.path(['id'], element)),
            item_name: R.path(['name'], element),
            item_category: 'add-on',
            price: String(price),
            quantity: String(quantity),
        }

        revenue += price * quantity
        taxWithoutShipping += tax
        revenueWithoutTaxAndShipping += price * quantity - tax

        productsWithDetailsForGA.push(objForGA)
    })

    let shipping = 0

    if (productId === `${GIFTCARD_ID}` && R.path(['countrycode'], selectedProductDetails) === 'FI') {
        shipping = DELIVERY_COST_GIFTCARD
    } else if (R.path(['countrycode'], selectedProductDetails) === 'FI') {
        shipping = DELIVERY_COST_DOMESTIC
    } else {
        shipping = DELIVERY_COST_FOREIGN
    }

    revenue += shipping
    const senderEmail = R.path(['form', 'senderEmail'], storeState)

    // NOTE: adwords_params, fbpixel_params and adform_params not refactored to support multiple products
    if (canUseDOM && dataLayer && dataLayer.push) {
        //Clearing the ecommerce object will prevent multiple ecommerce events on a page from affecting each other.
        dataLayer.push({ ecommerce: null })

        dataLayer.push({
            event: 'purchase',
            transactionId: cartId,
            transactionTotal: revenueWithoutTaxAndShipping,
            transactionPromoCode: '',
            md5: trackingUtils.md5(senderEmail),
            adwords_params: {
                ecomm_prodid: String(productsWithDetailsForGA[0].item_id),
                ecomm_pagetype: 'purchase',
                ecomm_totalvalue: Number(revenue),
                ecomm_category: 'flower',
                ecomm_cartid: cartId,
                ecomm_currencycode: 'EUR',
                ecomm_revenue: revenue,
            },
            fbpixel_params: {
                fbp_prodid: [String(productsWithDetailsForGA[0].item_id)],
                fbp_totalvalue: Number(revenue),
                fbp_category: 'flower',
            },
            adform_params: {
                adform_prodid: String(productsWithDetailsForGA[0].item_id),
                adform_category: 'flower',
                adform_orderid: cartId,
                adform_sales: Number(revenue),
            },
            ecommerce: {
                email: String(senderEmail),
                transaction_id: cartId,
                value: revenueWithoutTaxAndShipping,
                tax: taxWithoutShipping,
                shipping: shipping,
                currency: 'EUR',
                items: productsWithDetailsForGA,
            },
        })
    }
}
