import * as R from 'ramda'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { selectPaymentMethod } from '../../actions/payformActions'
import PayformIcon from './PayformIcon'

import config from '../../config'
const assets = R.path(['ASSETS'], config)

class PayformIcons extends Component {
    render() {
        const { payment_methods, selected_page, onClick } = this.props

        const payment_methods_without_nordea_b2b = payment_methods
            .map((method) => {
                if (method.img === 'https://www.vismapay.com/e-payments/method_logos/nordeab2b.png') {
                    return null
                }
                return method
            })
            .filter((item) => item)

        const icons = R.map((method) => {
            const group = R.path(['group'], method)
            return group === selected_page ? (
                <PayformIcon
                    key={R.path(['name'], method)}
                    group={group}
                    imgSrc={
                        group === 'creditcards' || group === 'wallets'
                            ? `${assets}/${group}-${R.path(['name'], method)}.svg`
                            : method.img === 'https://www.vismapay.com/e-payments/method_logos/danskebankb2b.png'
                            ? method.img // TODO: Create a svg for the Danske Bank B2B logo
                            : `${assets}/${group}-${R.path(['selected_value'], method)}.svg`
                    }
                    onClick={onClick.bind(this, method)}
                    name={R.path(['name'], method)}
                />
            ) : null
        })(payment_methods_without_nordea_b2b)

        return <div className="payform__icons">{icons}</div>
    }
}

const mapStateToProps = (state, ownProps) => ({
    payment_methods: R.path(['payform', 'payment_methods'], state),
    selected_page: R.path(['payform', 'selected_page'], state),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    onClick: (name) => dispatch(selectPaymentMethod(name)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PayformIcons)
