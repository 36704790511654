import React, { Component } from 'react'

import { required, passwordsEqual } from '../../utils/ValidationRules.jsx'
import Form from 'react-validation/build/form'
import Input from 'react-validation/build/input'
import Button from 'react-validation/build/button'

import { getLanguage } from '../../utils/translation'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import * as R from 'ramda'

import { resetPasswordWithToken, resetPassword } from '../../actions/loginActions'

const pageTranslation = {
    fi: {
        page: {
            password: 'Salasana',
            passwordConfirm: 'Vahvista salasana',
            yourPassword: 'Salasanasi',
            resetInfo: 'Vaihda salasanasi',
            resetButton: 'Lähetä',
            backToLogin: 'Palaa kirjautumissivulle',
            resetError: 'Salasanan vaihto epäonnistui',
            passwordChanged: 'Salasanasi on vaihdettu',
        },
    },
    en: {
        page: {
            password: 'Password',
            passwordConfirm: 'Confirm password',
            yourPassword: 'Your password',
            resetInfo: 'Change password',
            resetButton: 'Send',
            backToLogin: 'Back to login page',
            resetError: 'Password change failed',
            passwordChanged: 'Password change was successful',
        },
    },
}

class ResetPassword extends Component {
    constructor(props) {
        super(props)

        this.state = {
            resetPassword: '',
            resetPasswordConfirm: '',
            resetPasswordFields: true,
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    handle_resetPassword_change(event) {
        this.setState({ resetPassword: event.target.value })
    }

    handle_resetPasswordConfirm_change(event) {
        this.setState({ resetPasswordConfirm: event.target.value })
    }

    submitForm(event) {
        event.preventDefault()
        const { match, token, resetPasswordWithResetToken, resetPasswordWithJWT } = this.props
        const { resetPassword, resetPasswordConfirm } = this.state
        const reset_token = R.path(['params', 'reset_token'], match)

        if (reset_token) {
            resetPasswordWithResetToken(reset_token, resetPassword, resetPasswordConfirm)
        } else if (token) {
            resetPasswordWithJWT(resetPassword, resetPasswordConfirm)
        }

        this.setState({
            resetPassword: '',
            resetPasswordConfirm: '',
            resetPasswordFields: false,
        })

        this.form.hideError('resetPassword')
        this.form.hideError('resetPasswordConfirm')
    }

    render() {
        const { lang, cc, message, match } = this.props
        const { resetPasswordFields } = this.state

        const reset_token = R.path(['params', 'reset_token'], match)

        const selectedTrans = R.path([getLanguage()], pageTranslation)
        const trans = R.path(['page'], selectedTrans)

        return (
            <div className="productOrder">
                <div className="productOrder__contentHolder">
                    <Form
                        ref={(c) => (this.form = c)}
                        className="pure-form pure-form-stacked productOrder__form login__form"
                    >
                        <fieldset>
                            <h2 className="login__headline">{R.path(['resetInfo'], trans)}</h2>

                            {(resetPasswordFields || message === 'Reset error') && (
                                <div className="input-group form__field">
                                    <label htmlFor="resetPassword">
                                        {R.path(['password'], trans)} <sup>*</sup>
                                    </label>
                                    <Input
                                        onChange={this.handle_resetPassword_change.bind(this)}
                                        placeholder={R.path(['yourPassword'], trans)}
                                        id="resetPassword"
                                        errorclassname="is-invalid-input"
                                        type="password"
                                        containerclassname=""
                                        value={this.state.resetPassword}
                                        name="resetPassword"
                                        validations={[required, passwordsEqual]}
                                        lang={lang}
                                    />
                                </div>
                            )}

                            {(resetPasswordFields || message === 'Reset error') && (
                                <div className="input-group form__field">
                                    <label htmlFor="resetPasswordConfirm">
                                        {R.path(['passwordConfirm'], trans)} <sup>*</sup>
                                    </label>
                                    <Input
                                        onChange={this.handle_resetPasswordConfirm_change.bind(this)}
                                        placeholder={R.path(['yourPassword'], trans)}
                                        id="resetPasswordConfirm"
                                        errorclassname="is-invalid-input"
                                        type="password"
                                        containerclassname=""
                                        value={this.state.resetPasswordConfirm}
                                        name="resetPasswordConfirm"
                                        validations={[required, passwordsEqual]}
                                        lang={lang}
                                    />
                                </div>
                            )}

                            {message === 'Reset error' && message && (
                                <p className="login__message">{R.path(['resetError'], trans)}</p>
                            )}

                            {message !== 'Reset error' && message && (
                                <p className="login__message--success">{R.path(['passwordChanged'], trans)}</p>
                            )}

                            {(resetPasswordFields || message === 'Reset error') && (
                                <div className="productOrder__buttonContainer">
                                    <Button
                                        onClick={this.submitForm.bind(this)}
                                        className="pure-button pure-button-primary productOrder__button"
                                    >
                                        {R.path(['resetButton'], trans)}
                                    </Button>
                                </div>
                            )}

                            <br />
                            <Link className="login__link" to={`/${lang}/${cc}/login`}>
                                {R.path(['backToLogin'], trans)}
                            </Link>
                        </fieldset>
                    </Form>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    lang: R.path(['lang', 'lang'], state),
    cc: R.path(['lang', 'db'], state),
    message: R.path(['login', 'resetMessage'], state),
    token: R.path(['login', 'token'], state),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    dispatch: dispatch,
    resetPasswordWithResetToken: (reset_token, password, confirm_password) =>
        dispatch(resetPasswordWithToken(reset_token, password, confirm_password)),
})

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { token } = stateProps
    const { dispatch } = dispatchProps
    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        resetPasswordWithJWT: (password, confirm_password) =>
            dispatch(resetPassword(password, confirm_password, token)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(ResetPassword))
