import * as R from 'ramda'
import { combineReducers } from 'redux'
import { GET_TOKEN, SET_TOKEN, GET_USER_INFO, RESET_TOKEN, RESET_PASSWORD } from '../actions/loginActions'

const token = (state = null, action) => {
    switch (action.type) {
        case GET_TOKEN + '_SUCCESS':
            return action.token
        case SET_TOKEN:
            return action.token
        case RESET_TOKEN:
            return null
        default:
            return state
    }
}

const loginMessage = (state = null, action) => {
    switch (action.type) {
        case GET_TOKEN + '_SUCCESS':
            return null
        case GET_TOKEN + '_FAILED':
            return action.message
        default:
            return state
    }
}

const user = (state = {}, { type, user }) => {
    switch (type) {
        case GET_USER_INFO + '_SUCCESS':
            return user
        default:
            return state
    }
}

const prevPage = (state = [{ pathname: '/' }], action) => {
    switch (action.type) {
        case '@@router/LOCATION_CHANGE':
            const currentState = !R.isNil(state) ? [...state] : [{ pathname: '/' }]
            const nextState = [...currentState, R.path(['payload'], action)]
            return R.takeLast(2, nextState)
        default:
            return state
    }
}

const resetMessage = (state = '', action) => {
    switch (action.type) {
        case RESET_PASSWORD + '_SUCCESS':
            return action.message
        case RESET_PASSWORD + '_FAILED':
            return action.message
        default:
            return state
    }
}

export default combineReducers({
    token,
    user,
    loginMessage,
    prevPage,
    resetMessage,
})
