export const SET_LAST_VIEWED_PRODUCTID = 'SET_LAST_VIEWED_PRODUCTID'
export const SAVE_PRODUCTID = 'SAVE_PRODUCTID'
export const SAVE_GTM_PRODUCTID = 'SAVE_GTM_PRODUCTID'
export const ADD_TO_CART_ADDITIONAL_PRODUCT = 'ADD_TO_CART_ADDITIONAL_PRODUCT'
export const REMOVE_FROM_CART_ADDITIONAL_PRODUCT = 'REMOVE_FROM_CART_ADDITIONAL_PRODUCT'
export const REMOVE_FROM_CART_ADDITIONAL_PRODUCT_INSTANCES = 'REMOVE_FROM_CART_ADDITIONAL_PRODUCT_INSTANCES'
export const EMPTY_CART_ADDITIONAL_PRODUCT = 'EMPTY_CART_ADDITIONAL_PRODUCT'
export const SELECT_RIBBON = 'SELECT_RIBBON'
export const UNSELECT_RIBBON = 'UNSELECT_RIBBON'
export const SELECT_CONDOLENCES = 'SELECT_CONDOLENCES'
export const UNSELECT_CONDOLENCES = 'UNSELECT_CONDOLENCES'
export const CLEAR_ORDER = 'CLEAR_ORDER'

export const setLastViewedProductId = (productId) => ({
    type: SET_LAST_VIEWED_PRODUCTID,
    productId,
})

export const saveProductId = (selectedProductId) => ({
    type: SAVE_PRODUCTID,
    selectedProductId: selectedProductId,
})

export const addProductToGTMcart = (GTMProductId, GTMProductName, GTMProductPrice) => ({
    type: SAVE_GTM_PRODUCTID,
    GTMProductId: GTMProductId,
    GTMProductName: GTMProductName,
    GTMProductPrice: GTMProductPrice,
})

export const addAdditionalProduct = (additionalProductId) => ({
    type: ADD_TO_CART_ADDITIONAL_PRODUCT,
    selectedAdditionalProductId: additionalProductId,
})

export const removeAdditionalProduct = (additionalProductId) => ({
    type: REMOVE_FROM_CART_ADDITIONAL_PRODUCT,
    selectedAdditionalProductId: additionalProductId,
})

export const removeAllAdditionalProductInstances = (additionalProductId) => ({
    type: REMOVE_FROM_CART_ADDITIONAL_PRODUCT_INSTANCES,
    selectedAdditionalProductId: additionalProductId,
})

export const emptyCartAdditionalProduct = () => ({
    type: EMPTY_CART_ADDITIONAL_PRODUCT,
})

export const selectRibbon = () => ({
    type: SELECT_RIBBON,
})

export const unselectRibbon = () => ({
    type: UNSELECT_RIBBON,
})

export const selectCondolences = () => ({
    type: SELECT_CONDOLENCES,
})

export const unselectCondolences = () => ({
    type: UNSELECT_CONDOLENCES,
})

export const clearOrder = () => ({
    type: CLEAR_ORDER,
})
