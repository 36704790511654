import { combineReducers } from 'redux'
import {
    REQUEST_ZIPCODE,
    SET_ZIPCODE,
    SET_ZIPCODE_CALENDAR,
    SET_ZIPCODE_CUTOFF,
    CLEAR_ZIPCODE,
    CLEAR_ZIPCODE_ERROR,
} from '../actions/zipcodeActions'

const delivery_zipcode = (state = null, { type, zipcode }) => {
    switch (type) {
        case REQUEST_ZIPCODE:
            return null
        case CLEAR_ZIPCODE:
            return null
        case SET_ZIPCODE:
            return zipcode
        case REQUEST_ZIPCODE + '_SUCCESS':
            return zipcode
        case REQUEST_ZIPCODE + '_FAILED':
            return null
        default:
            return state
    }
}

const calendar = (state = [], { type, calendar }) => {
    switch (type) {
        case REQUEST_ZIPCODE:
            return []
        case CLEAR_ZIPCODE:
            return []
        case SET_ZIPCODE_CALENDAR:
            return calendar
        case REQUEST_ZIPCODE + '_SUCCESS':
            return calendar
        case REQUEST_ZIPCODE + '_FAILED':
            return []
        default:
            return state
    }
}

const cutoff = (state = null, { type, cutoff }) => {
    switch (type) {
        case REQUEST_ZIPCODE:
            return null
        case CLEAR_ZIPCODE:
            return null
        case SET_ZIPCODE_CUTOFF:
            return cutoff
        case REQUEST_ZIPCODE + '_SUCCESS':
            return cutoff
        case REQUEST_ZIPCODE + '_FAILED':
            return null
        default:
            return state
    }
}

const error = (state = null, { type, error }) => {
    switch (type) {
        case CLEAR_ZIPCODE_ERROR:
            return null
        case REQUEST_ZIPCODE:
            return null
        case CLEAR_ZIPCODE:
            return null
        case SET_ZIPCODE:
            return null
        case REQUEST_ZIPCODE + '_SUCCESS':
            return null
        case REQUEST_ZIPCODE + '_FAILED':
            return error
        default:
            return state
    }
}

export default combineReducers({
    delivery_zipcode,
    calendar,
    cutoff,
    error,
})
