import * as R from 'ramda'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { updateSelectedCards } from '../../actions/deliveryCardActions'

import { getLanguage } from '../../utils/translation'
import { required, emoji, has7LineBreaks } from '../../utils/ValidationRules.jsx'
import { isCardsSelected, modifyObjectAtIndex } from '../../utils/deliveryCards'
import Form from 'react-validation/build/form'
import Textarea from 'react-validation/build/textarea'
import CardTypeSelector from '../cardIcons/CardTypeSelector'

const pageTranslation = {
    fi: {
        page: {
            addCardText: 'Korttiteksti',
            chooseCardOption: 'Valitse lähetyksen mukaan kortti',
            selectedCcardInformation:
                'Tässä on tietoa valitusta korttituotteesta esim. hinta jotain muuta? Tämä kenttä päivittyy valitun tuotteen mukaan',
            yourMessage:
                'Viestisi korttiin. Huom! Maksimissaan 8 riviä. Suurempi rivimäärä ei mahdu toimitettavaan korttiin.',
            disclaimer1: 'Vastaanottajalle ei toimiteta mitään muita tietoja lähettäjästä, kuin kortissa oleva viesti.',
        },
    },
    en: {
        page: {
            addCardText: 'Card message',
            chooseCardOption: 'Choose a card for your flower delivery',
            selectedCcardInformation:
                'Here you will find some information on the selected card type. This field will update with selected product description',
            yourMessage: 'Your message. Please note, only 8 lines are accepted.',
            disclaimer1:
                "The recipient doesn't see any other information about the sender than what is written on the card.",
        },
    },
}

const DeliveryCard = (props) => {
    const selectedTrans = R.path([getLanguage()], pageTranslation)
    const trans = R.path(['page'], selectedTrans)

    const { card, cardIndex, lang, selectedCards, updateSelectedCards } = props

    const [cardText, setCardText] = useState(R.path(['cardText'], card))

    useEffect(() => {
        const newSelectedCards = modifyObjectAtIndex(selectedCards, cardIndex, { cardText: cardText })
        updateSelectedCards(newSelectedCards)
    }, [cardText])

    useEffect(() => {
        setCardText(R.path(['cardText'], card))
    }, [card])

    const handleCardTextChange = (event) => {
        setCardText(event.target.value)
    }

    const cardsSelected = isCardsSelected(selectedCards)

    return (
        <Form className="pure-form pure-form-stacked">
            <fieldset>
                <CardTypeSelector cardObject={card} cardIndex={cardIndex} />
                {cardsSelected && (
                    <React.Fragment>
                        <div className="input-group form__field">
                            <label htmlFor="attachedMessage">{R.path(['addCardText'], trans)}</label>
                            <div className="productOrder__attachedMessage__container">
                                <Textarea
                                    id={`card-${cardIndex}-text-Input`}
                                    onChange={handleCardTextChange}
                                    className="productOrder__attachedMessage"
                                    placeholder={R.path(['yourMessage'], trans)}
                                    errorclassname="is-invalid-input"
                                    type="textarea"
                                    autoComplete=""
                                    value={cardText}
                                    name="cardText"
                                    validations={[required, has7LineBreaks, emoji]}
                                    lang={lang}
                                    maxLength="255"
                                />
                                <span className="productOrder__attachedMessage__counter">{cardText.length}/255</span>
                            </div>
                        </div>
                    </React.Fragment>
                )}
            </fieldset>
        </Form>
    )
}

const mapStateToProps = (state, ownProps) => ({
    storeState: state,
    lang: R.path(['lang', 'lang'], state),
    formData: state.form,
    cartProducts: R.pathOr([], ['cart', 'items', 'products'], state),
    cartLoading: R.path(['cart', 'loading'], state),
    selectedCards: R.path(['cards', 'selectedCards'], state),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    updateSelectedCards: (selectedCards) => dispatch(updateSelectedCards(selectedCards)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryCard)
