import {
    SAVE_GIFTCARD_PRICE,
    SAVE_GIFTCARD_AMOUNT,
    ADD_RIBBON_CONTENT,
    ADD_CONDOLENCE_CONTENT,
    ADD_FORM_RECIPIENT_INFO,
    ADD_FORM_CARD_TEXT,
    ADD_FORM_DELIVERY,
    ADD_FORM_SENDER_INFO,
    ADD_FORM_PAYMENT,
    SET_F2,
    SET_F5,
    SET_F6,
    SET_PAYMENT_OK,
    CLEAR_FUNERAL_DATA,
    CLEAR_COMPANY_AND_RECIPIENTNOTCONTACTED_DATA,
    CLEAR_FORMS,
} from '../actions/formActions'

import { CLEAR_ZIPCODE } from '../actions/zipcodeActions'

import { CREATE_CART } from '../actions/cartActions'

const initialState = {
    giftcardPrice: 10,
    giftcardAmount: 1,
    ribbonNames: '',
    ribbonWords: '',
    condolenceWords: '',
    condolenceMortName: '',
    condolenceSenderNames: '',
    recipientName: '',
    recipientAddress: '',
    recipientPhone: '',
    recipientDate: null,
    recipientAdvanceNotice: false,
    recipientCompany: '',
    recipientZip: '',
    recipientCity: '',
    mortName: '',
    churchName: '',
    churchTime: '',
    attachedMessage: '',
    attachedMessageToCourier: '',
    recipientNotContacted: '',
    senderPhone: '',
    senderEmail: '',
    senderName: '',
    senderCompany: '',
    createAccount: false,
    deliveryTerms: false,
    f2: false,
    f5: false,
    f6: false,
    pOK: false,
}

export default function formReducer(state = initialState, action) {
    switch (action.type) {
        case SET_F2:
            return {
                ...state,
                f2: action.f2,
            }
        case SET_F5:
            return {
                ...state,
                f5: action.f5,
            }
        case SET_F6:
            return {
                ...state,
                f6: action.f6,
            }
        case SET_PAYMENT_OK:
            return {
                ...state,
                pOK: action.pOK,
            }
        case CLEAR_FUNERAL_DATA:
            return {
                ...state,
                mortName: action.mortName,
                churchName: action.churchName,
                churchTime: action.churchTime,
                ribbonNames: action.ribbonNames,
                ribbonWords: action.ribbonWords,
                condolenceWords: action.condolenceWords,
                condolenceMortName: action.condolenceMortName,
                condolenceSenderNames: action.condolenceSenderNames,
            }
        case CLEAR_COMPANY_AND_RECIPIENTNOTCONTACTED_DATA:
            return {
                ...state,
                recipientCompany: action.recipientCompany,
                recipientAdvanceNotice: action.recipientAdvanceNotice,
                recipientNotContacted: action.recipientNotContacted,
            }
        case SAVE_GIFTCARD_PRICE:
            return {
                ...state,
                giftcardPrice: action.giftcardPrice,
            }
        case SAVE_GIFTCARD_AMOUNT:
            return {
                ...state,
                giftcardAmount: action.giftcardAmount,
            }
        case ADD_RIBBON_CONTENT:
            return {
                ...state,
                ribbonWords: action.ribbonWords,
                ribbonNames: action.ribbonNames,
            }
        case ADD_CONDOLENCE_CONTENT:
            return {
                ...state,
                condolenceWords: action.condolenceWords,
                condolenceMortName: action.condolenceMortName,
                condolenceSenderNames: action.condolenceSenderNames,
            }
        case ADD_FORM_RECIPIENT_INFO:
            return {
                ...state,
                recipientName: action.recipientName,
                recipientAddress: action.recipientAddress,
                recipientPhone: action.recipientPhone,
                recipientDate: action.recipientDate,
                recipientCompany: action.recipientCompany,
                recipientZip: action.recipientZip,
                recipientCity: action.recipientCity,
                mortName: action.mortName,
                churchName: action.churchName,
                churchTime: action.churchTime,
            }
        case ADD_FORM_CARD_TEXT:
            return {
                ...state,
                attachedMessage: action.attachedMessage,
            }
        case ADD_FORM_DELIVERY:
            return {
                ...state,
                attachedMessageToCourier: action.attachedMessageToCourier,
                recipientNotContacted: action.recipientNotContacted,
                recipientAdvanceNotice: action.recipientAdvanceNotice,
            }
        case ADD_FORM_SENDER_INFO:
            return {
                ...state,
                senderPhone: action.senderPhone,
                senderEmail: action.senderEmail,
                senderName: action.senderName,
                senderCompany: action.senderCompany,
                createAccount: action.createAccount,
            }
        case ADD_FORM_PAYMENT:
            return {
                ...state,
                deliveryTerms: action.deliveryTerms,
            }
        case CLEAR_ZIPCODE:
            return {
                ...state,
                recipientZip: '',
            }
        case CREATE_CART:
            return {
                ...state,
                deliveryTerms: false,
            }
        case CLEAR_FORMS:
            return initialState
        default:
            return state
    }
}
