import * as R from 'ramda'
import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { addFormPayment } from '../../actions/formActions'
import {
    clearPayform,
    clearPayformRedirectUrl,
    payformGiftcardPurchase,
    payformPurchase,
    saveStateToSessionStorage,
    selectPaymentMethodType,
} from '../../actions/payformActions'
import { invoiceGiftcardPurchase, invoicePurchase } from '../../actions/invoiceActions'
import { setLoadingIndicatorVisibility } from '../../actions/loadingIndicatorActions'
import { applyDiscountToCart, clearDiscountError, saveDeliverytermsAccepted } from '../../actions/cartActions'
import { showModal } from '../../actions/modalActions'
import { postJoinMailinglist } from '../../api/backend'
import LoadingIndicator from './LoadingIndicator'
import classNames from 'classnames'
import Form from 'react-validation/build/form'
import PayformIcons from '../payformicons/PayformIcons'
import PaymentTypeIcon from '../select-payment-type/PaymentTypeIcon'
import Checkbox from '../checkbox/Checkbox'
import { getLanguage } from '../../utils/translation'
import { trackCheckoutProcess } from '../../tracking/tracking'
import { getProductById, getProductsCountryName } from '../../utils/ProductTypes'

const canUseDOM = !!(typeof window !== 'undefined' && window.document)

const pageTranslation = {
    fi: {
        page: {
            choosePaymentType: 'Maksu',
            choosePaymentOption: 'Valitse maksutapa',
            paymentCreditCard: 'Korttimaksu',
            chooseBank: 'Valitse pankki',
            supportedCards: 'Tuetut maksukortit',
            supportedWallets: 'Valitse digitaalinen lompakkosi',
            continueToPayform: 'Painamalla siirryt Visma Pay maksuvälityspalveluun.',
            doYouHaveCampaingCode: 'Onko käytössäsi kampanjakoodi?',
            CREDITCARD: 'Maksukortti',
            EBANK: 'Verkkopankki',
            WALLET: 'Lompakko',
            INVOICE: 'Lasku',
            invoiceNote: 'Huom! Loppusummaan lisätään laskutuslisä 4€',
            accept: 'Hyväksyn',
            termsOfDelivery: 'toimitusehdot',
            continueWithPaymentPayform: 'Siirry maksuun',
            continueWithPaymentInvoice: 'Tilaa kukat',
            back: 'Takaisin',
            close: 'Sulje',
            invoiceRefNumber: 'Viite',
            invoiceRefDefaultText: 'Anna laskulla näkyvä viite',
            discountCode: 'Kampanjakoodi',
            discountButton: 'Aktivoi koodi',
            mailinglistOrder: 'Haluan tilata',
            mailinglistTerms: 'uutiskirjeen',
        },
    },
    en: {
        page: {
            choosePaymentType: 'Payment',
            choosePaymentOption: 'Choose payment type',
            paymentCreditCard: 'Credit card',
            chooseBank: 'Choose bank',
            supportedCards: 'Supported cards',
            supportedWallets: 'Choose your digital wallet',
            doYouHaveCampaingCode: 'Do you have a discount code?',
            continueToPayform: 'By clicking you will proceed to Visma Pay payment service',
            CREDITCARD: 'Credit card',
            EBANK: 'Bank payment',
            WALLET: 'Wallet',
            INVOICE: 'Invoice',
            invoiceNote: 'Additional 4€ will be added to the fee',
            accept: 'I accept',
            termsOfDelivery: 'the terms of delivery',
            continueWithPaymentPayform: 'Proceed with payment',
            continueWithPaymentInvoice: 'Place order',
            back: 'Back',
            close: 'Close',
            invoiceRefNumber: 'Reference',
            invoiceRefDefaultText: 'Add text reference for the invoice',
            discountCode: 'Discount code',
            discountButton: 'Check code',
            mailinglistOrder: 'Subscribe to',
            mailinglistTerms: 'newsletter',
        },
    },
}

const getFirstCartProductId = (cartProducts = []) => R.prop('id', R.head(cartProducts))

const formatOrderFields = (productId, orderData, formData, cartId, prodCountryName) => ({
    prodId: productId,
    addOnProducts: orderData.selectedAddOnProducts,
    recipientName: formData.recipientName,
    recipientAddress: formData.recipientAddress,
    recipientPhone: formData.recipientPhone,
    recipientDate: formData.recipientDate,
    recipientAdvanceNotice: formData.recipientAdvanceNotice,
    recipientCompany: formData.recipientCompany,
    recipientZip: formData.recipientZip,
    recipientCity: formData.recipientCity,
    recipientCountry: prodCountryName,
    mortName: formData.mortName,
    ribbonWords: formData.ribbonWords,
    ribbonNames: formData.ribbonNames,
    condolenceWords: formData.condolenceWords,
    condolenceMortName: formData.condolenceMortName,
    condolenceSenderNames: formData.condolenceSenderNames,
    churchName: formData.churchName,
    churchTime: formData.churchTime,
    attachedMessage: formData.attachedMessage,
    attachedMessageToCourier: formData.attachedMessageToCourier,
    recipientNotContacted: formData.recipientNotContacted,
    senderPhone: formData.senderPhone,
    senderEmail: formData.senderEmail,
    senderName: formData.senderName,
    senderCompany: formData.senderCompany,
    giftcardPrice: formData.giftcardPrice,
    giftcardAmount: formData.giftcardAmount,
    cartId: cartId,
})

class FormPayment extends Component {
    constructor(props) {
        super(props)
        this.state = {
            paymentError: null,
            paymentComplete: false,
            token: null,
            paymentCardNumber: '',
            paymentValidUntilFormatted: '',
            paymentValidUntil: '',
            paymentCVC: '',
            paymentInvoiceRefNumber: '',
            discountCode: '',
            joinMailinglist: false,
            paymentDisabled: false,
        }

        this.handle_deliveryTerms_change = this.handle_deliveryTerms_change.bind(this)
        this.handle_paymentInvoiceRefNumber_change = this.handle_paymentInvoiceRefNumber_change.bind(this)
        this.handle_discountCode_change = this.handle_discountCode_change.bind(this)
        this.submitPaymentType = this.submitPaymentType.bind(this)
        this.submitPayform = this.submitPayform.bind(this)
        this.submitInvoice = this.submitInvoice.bind(this)
        this.handle_joinMailinglist_change = this.handle_joinMailinglist_change.bind(this)

        const { storeState } = this.props
        trackCheckoutProcess(storeState, 5, 'Payment')
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    showIndicator(show) {
        const { setLoadingIndicatorVisibility } = this.props
        setLoadingIndicatorVisibility(show)
    }

    submitPaymentType(event, method_type) {
        event.preventDefault()
        const { selectPaymentMethodType } = this.props
        selectPaymentMethodType(method_type)
    }

    mailinglistId = () => {
        const { loginToken } = this.props
        if (loginToken) {
            return '2'
        } else {
            return '1'
        }
    }

    submitPayform(event) {
        event.preventDefault()
        this.setState({ paymentDisabled: true })

        const {
            payformPurchase,
            payformGiftcardPurchase,
            payform_selected,
            allProducts,
            allCountries,
            cartProducts,
            orderData,
            formData,
            cartId,
        } = this.props

        // Join mailing list
        const { joinMailinglist } = this.state
        if (joinMailinglist) postJoinMailinglist(formData.senderEmail, formData.senderName, this.mailinglistId())

        const productId = getFirstCartProductId(cartProducts)
        const prod = getProductById(allProducts, productId)
        const prodCountryName = getProductsCountryName(allCountries, prod)
        const category = R.path(['category'], prod)

        const payformSelected = !R.isEmpty(payform_selected)
        const selected_value = R.path(['selected_value'], payform_selected)

        this.showIndicator(true)

        if (payformSelected) {
            category === 'lahjakortti'
                ? payformGiftcardPurchase(
                      R.mergeRight(formatOrderFields(productId, orderData, formData, cartId, prodCountryName), {
                          payform_selected: selected_value,
                      }),
                  )
                : payformPurchase(
                      R.mergeRight(formatOrderFields(productId, orderData, formData, cartId, prodCountryName), {
                          payform_selected: selected_value,
                      }),
                  )
        }
    }

    submitInvoice(event) {
        event.preventDefault()

        const {
            lang,
            cc,
            history,
            orderData,
            formData,
            cartId,
            allProducts,
            allCountries,
            cartProducts,
            invoiceProduct,
            invoiceGiftcard,
        } = this.props

        const { paymentInvoiceRefNumber, joinMailinglist } = this.state

        if (joinMailinglist) postJoinMailinglist(formData.senderEmail, formData.senderName, this.mailinglistId())

        const productId = getFirstCartProductId(cartProducts)
        const prod = getProductById(allProducts, productId)
        const prodCountryName = getProductsCountryName(allCountries, prod)
        const category = R.path(['category'], prod)

        const data = R.mergeRight(formatOrderFields(productId, orderData, formData, cartId, prodCountryName), {
            invoiceRef: paymentInvoiceRefNumber,
        })

        this.showIndicator(true)

        const invoiceAction =
            category === 'lahjakortti'
                ? invoiceGiftcard({ invoiceRef: data.invoiceRef, cartId: data.cartId })
                : invoiceProduct({ invoiceRef: data.invoiceRef, cartId: data.cartId })

        // Replace history with homepage, then redirect to 'thank you' page.
        // -> Clicking back from 'thank you' page directs to homepage.
        invoiceAction.then(() => {
            history.replace(`/${lang}/${cc}`)
            history.push(`/${lang}/${cc}/order/thank-you?redirect=true`)
        })
    }

    validateNow() {
        // Form contents changed by conditional rendering of input elements
        // -> validate form to prevent submit of unfilled forms
        this.form.validateAll()
    }

    saveDeliveryTermsAcceptedToRedux(termBoolean) {
        const { addFormPayment, saveDeliverytermsAccepted } = this.props
        addFormPayment(termBoolean)
        saveDeliverytermsAccepted()
    }

    handle_deliveryTerms_change(event) {
        this.saveDeliveryTermsAcceptedToRedux(event)
    }

    handle_paymentInvoiceRefNumber_change(event) {
        this.setState({ paymentInvoiceRefNumber: event.target.value })
    }

    handle_discountCode_change(event) {
        this.setState({ discountCode: event.target.value })
    }

    handle_joinMailinglist_change = (value) => {
        this.setState(() => ({ joinMailinglist: value }))
    }

    checkDiscountCode(event) {
        event.preventDefault()

        const { discountToCart } = this.props
        const { discountCode } = this.state

        discountToCart(discountCode).then(() => {
            this.openDiscountSuccessModal()
            this.openDiscountErrorModal()
        })
    }

    openDeliveryTermsModal(event) {
        event.preventDefault()

        const { showModal } = this.props
        showModal(true, 'Delivery modal', 'deliveryTerms', 'modal__content')
    }

    openMailinglistTermsModal = (event) => {
        event.preventDefault()

        const { showModal } = this.props
        showModal(true, 'Mailinglist modal', 'mailinglistTerms', 'modal__content')
    }

    openDiscountSuccessModal() {
        const { showModal, discountAmount, discountSuccessModal } = this.props
        const { discountCode } = this.state

        discountSuccessModal &&
            discountAmount > 0 &&
            showModal(
                true,
                'Discount modal',
                'discountCodeSuccess',
                'modal__content',
                `${discountCode}`,
                `${discountAmount}`,
            )
    }

    openDiscountErrorModal() {
        const { showModal, discountErrorCode, clearDiscountError } = this.props
        const { discountCode } = this.state

        discountErrorCode === 1 &&
            showModal(true, 'Discount modal', 'discountCodeNotFound', 'modal__content', `${discountCode}`)
        discountErrorCode === 2 &&
            showModal(true, 'Discount modal', 'discountCodeNotActive', 'modal__content', `${discountCode}`)
        discountErrorCode === 3 &&
            showModal(true, 'Discount modal', 'discountAlreadyApplied', 'modal__content', `${discountCode}`)
        discountErrorCode === 1001 &&
            showModal(true, 'Discount modal', 'discountCodeGiftCard', 'modal__content', `${discountCode}`)
        discountErrorCode && clearDiscountError()
    }

    redirectToPayform() {
        const { payform_redirect_url, clearPayformRedirectUrl } = this.props
        if (canUseDOM) {
            clearPayformRedirectUrl()
            window.location.href = payform_redirect_url
        }
    }

    render() {
        const {
            lang,
            cc,
            payform_selected,
            payform_redirect_url,
            selected_page,
            loginToken,
            loadingIndicator,
            discounts,
            deliveryTermsAccepted,
            checking_discount,
        } = this.props

        const isProd = process.env.NODE_ENV === 'production'

        const { paymentInvoiceRefNumber, discountCode, joinMailinglist } = this.state

        const selectedTrans = R.path([getLanguage()], pageTranslation)
        const trans = R.path(['page'], selectedTrans)

        if (payform_redirect_url !== '') {
            this.props.saveStateToSessionStorage()
            this.redirectToPayform()
        }

        const isInvoice = selected_page === 'invoice'
        const isBank = selected_page === 'banks'
        const isCreditcard = selected_page === 'creditcards'
        const isWallet = selected_page === 'wallets'
        const noPayformMethodSelected = R.isEmpty(payform_selected)
        const deliveryTermsOk = deliveryTermsAccepted
        const mailinglistOk = joinMailinglist

        const enableInvoiceButton = isInvoice && R.length(paymentInvoiceRefNumber) > 0 && deliveryTermsOk

        const payformOptionsStyle = classNames('bottomMargin', { notdisplayed: isInvoice })

        const invoiceOptionsStyle = classNames('bottomMargin', { notdisplayed: !isInvoice })

        const discountCodeButton = classNames('check-discount-button')

        const payformSubmitButtonStyle = classNames(
            'pure-button',
            'pure-button-primary',
            'productOrder__button',
            { 'pure-button-disabled': this.state.paymentDisabled || noPayformMethodSelected || !deliveryTermsOk },
            isInvoice ? 'hidden disabled' : '',
        )

        const invoiceSubmitButtonStyle = classNames(
            'pure-button',
            'pure-button-primary',
            'productOrder__button',
            { 'pure-button-disabled': loadingIndicator || !enableInvoiceButton },
            !isInvoice ? 'hidden disabled' : '',
        )

        return (
            <div className="productOrder__contentHolder">
                <Form
                    ref={(c) => {
                        this.form = c
                    }}
                    className="pure-form pure-form-stacked productOrder__form"
                >
                    <fieldset>
                        <div className="pure-g">
                            <div className="pure-u-20-24">
                                <h2 className="order__headline">{R.path(['choosePaymentType'], trans)}</h2>
                            </div>
                            <div className="pure-u-4-24 alignRight form__indicator">
                                <span>5/5</span>
                            </div>
                        </div>

                        <div>
                            <span>
                                <h4>{R.path(['choosePaymentOption'], trans)}</h4>
                            </span>
                        </div>

                        <div className="input-group-radio input-group-radio-wrapper bottomMargin paymentSelection pure-g">
                            <PaymentTypeIcon
                                method_type="banks"
                                submitPaymentType={this.submitPaymentType}
                                name={R.path(['EBANK'], trans)}
                                selected_page={selected_page}
                            />

                            <PaymentTypeIcon
                                method_type="creditcards"
                                submitPaymentType={this.submitPaymentType}
                                name={R.path(['CREDITCARD'], trans)}
                                selected_page={selected_page}
                            />

                            {isProd && (
                                <PaymentTypeIcon
                                    method_type="wallets"
                                    submitPaymentType={this.submitPaymentType}
                                    name={R.path(['WALLET'], trans)}
                                    selected_page={selected_page}
                                />
                            )}

                            {loginToken && (
                                <PaymentTypeIcon
                                    method_type="invoice"
                                    submitPaymentType={this.submitPaymentType}
                                    name={R.path(['INVOICE'], trans)}
                                    selected_page={selected_page}
                                />
                            )}
                        </div>

                        {!isInvoice && (
                            <div>
                                <span>
                                    {isBank && <h4>{R.path(['chooseBank'], trans)}</h4>}
                                    {isCreditcard && <h4>{R.path(['supportedCards'], trans)}</h4>}
                                    {isWallet && <h4>{R.path(['supportedWallets'], trans)}</h4>}
                                </span>
                            </div>
                        )}

                        <div className={payformOptionsStyle}>
                            <PayformIcons />
                        </div>

                        {loginToken && (
                            <div className={invoiceOptionsStyle}>
                                {isInvoice && (
                                    <div className="pure-g">
                                        <div className="pure-u-1 pure-u-sm-1-2">
                                            <div className="input-group form__field">
                                                <label htmlFor="paymentInvoiceRefNumber">
                                                    {R.path(['invoiceRefNumber'], trans)}
                                                    <sup>*</sup>
                                                </label>
                                                <input
                                                    onChange={this.handle_paymentInvoiceRefNumber_change}
                                                    name="paymentInvoiceRefNumber"
                                                    placeholder={R.path(['invoiceRefDefaultText'], trans)}
                                                    maxLength="80"
                                                    id="paymentInvoiceRefNumber"
                                                    type="text"
                                                    value={this.state.paymentInvoiceRefNumber}
                                                />
                                                <span className="input-group form__counter">
                                                    {this.state.paymentInvoiceRefNumber.length}/80
                                                </span>
                                            </div>
                                            <h4 style={{ marginTop: '0px' }}>{R.path(['invoiceNote'], trans)}</h4>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                        {discounts === null && (
                            <div className="pure-g discountcode-container">
                                <div className="pure-u-lg-1-2 pure-u-sm-1-1 pure-u-1-1 discountcode rightPaddingOnLarge">
                                    <div className="input-group form__field">
                                        <label htmlFor="discountCode">{R.path(['discountCode'], trans)}</label>
                                        <input
                                            type="text"
                                            name="discountCode"
                                            id="FormPayment-discountcode-input"
                                            placeholder={R.path(['doYouHaveCampaingCode'], trans)}
                                            onChange={this.handle_discountCode_change}
                                            value={discountCode}
                                        />
                                    </div>
                                    <button
                                        className={discountCodeButton}
                                        id="FormPayment-discountcode-button"
                                        disabled={!R.length(discountCode) > 0 || checking_discount}
                                        onClick={this.checkDiscountCode.bind(this)}
                                    >
                                        {R.path(['discountButton'], trans)}
                                    </button>
                                </div>
                            </div>
                        )}

                        <Checkbox
                            key="terms"
                            helperText={[
                                <span>
                                    {R.path(['accept'], trans)}{' '}
                                    <a href="" onClick={this.openDeliveryTermsModal.bind(this)}>
                                        {R.path(['termsOfDelivery'], trans)}
                                    </a>
                                </span>,
                            ]}
                            isSelected={deliveryTermsOk}
                            onCheckboxChange={this.handle_deliveryTerms_change}
                        />

                        <div style={{ paddingTop: '10px' }}>
                            <Checkbox
                                key="mailinglist"
                                helperText={[
                                    <span>
                                        {R.path(['mailinglistOrder'], trans)}{' '}
                                        <a href="" onClick={this.openMailinglistTermsModal.bind(this)}>
                                            {R.path(['mailinglistTerms'], trans)}
                                        </a>
                                    </span>,
                                ]}
                                isSelected={mailinglistOk}
                                onCheckboxChange={this.handle_joinMailinglist_change}
                            />
                        </div>

                        {!isInvoice && (
                            <div className="redirect-info-text">
                                <p>{R.path(['continueToPayform'], trans)}</p>
                            </div>
                        )}
                        {/* REFACTOR THIS PLZ */}
                        <div className="form__buttonAbsoluteWrapper">
                            <div className="pure-u-1">
                                <button
                                    onClick={this.submitPayform}
                                    className={payformSubmitButtonStyle}
                                    id="FormPayment-payform-submit-button"
                                >
                                    {R.path(['continueWithPaymentPayform'], trans)}
                                </button>
                                <button
                                    onClick={this.submitInvoice}
                                    className={invoiceSubmitButtonStyle}
                                    id="FormPayment-invoice-submit-button"
                                >
                                    {R.path(['continueWithPaymentInvoice'], trans)}
                                </button>
                                <LoadingIndicator />
                            </div>
                            <div className="pure-g form__buttonWrapper">
                                <div className="pure-u-1 pure-u-sm-8-24 order__second__mobile">
                                    <div className="form__returnlink__wrapper">
                                        <Link className="form__returnlink" to={`/${lang}/${cc}/order/sender-info`}>
                                            <i className="fa fa-angle-left" aria-hidden="true" />
                                            {R.path(['back'], trans)}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </Form>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    storeState: state,
    lang: R.path(['lang', 'lang'], state),
    cc: R.path(['lang', 'db'], state),
    formData: state.form,
    orderData: state.order,
    allProducts: state.products.allProducts,
    allCountries: state.products.allCountries,
    selectedProductId: state.order.selectedProductId,
    selected_page: R.path(['payform', 'selected_page'], state),
    cartId: R.path(['cart', 'items', 'cart_id'], state),
    cartZipcode: R.path(['cart', 'items', 'zipcode'], state),
    cartProducts: R.pathOr([], ['cart', 'items', 'products'], state),
    deliveryTermsAccepted: R.path(['cart', 'items', 'delivery_terms_accepted'], state),
    payform_selected: R.path(['payform', 'selected'], state),
    payform_redirect_url: R.path(['payform', 'redirect_url'], state),
    loginToken: R.path(['login', 'token'], state),
    loadingIndicator: R.path(['loadingIndicator', 'show'], state),
    checking_discount: R.path(['cart', 'checking_discount'], state),
    discountError: R.path(['cart', 'discount_error'], state),
    discountErrorCode: R.path(['cart', 'discount_error_code'], state),
    discountSuccessModal: R.path(['cart', 'discount_success_modal'], state),
    discountAmount: Number(R.pathOr(0, ['cart', 'items', 'discount'], state)),
    discounts: R.pathOr(null, ['cart', 'items', 'discounts'], state),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    dispatch: dispatch,
    saveStateToSessionStorage: () => dispatch(saveStateToSessionStorage()),
    clearPayform: () => dispatch(clearPayform()),
    clearPayformRedirectUrl: () => dispatch(clearPayformRedirectUrl()),
    selectPaymentMethodType: (method_type) => dispatch(selectPaymentMethodType(method_type)),
    addFormPayment: (deliveryTerms) => dispatch(addFormPayment(deliveryTerms)),
    payformPurchase: (data) => dispatch(payformPurchase(data)),
    payformGiftcardPurchase: (data) => dispatch(payformGiftcardPurchase(data)),
    setLoadingIndicatorVisibility: (show) => dispatch(setLoadingIndicatorVisibility(show)),
    saveDeliverytermsAccepted: () => dispatch(saveDeliverytermsAccepted()),
    showModal: (show, label, modaltype, modalclass, headline, content) =>
        dispatch(showModal(show, label, modaltype, modalclass, headline, content)),
})

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { loginToken } = stateProps
    const { dispatch } = dispatchProps
    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        invoiceProduct: (data) => dispatch(invoicePurchase(data, loginToken)),
        invoiceGiftcard: (data) => dispatch(invoiceGiftcardPurchase(data, loginToken)),
        discountToCart: (code) => dispatch(applyDiscountToCart(code)),
        clearDiscountError: () => dispatch(clearDiscountError()),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(FormPayment))
