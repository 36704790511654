import * as R from 'ramda'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { getLanguage } from '../../utils/translation'
import config from '../../config'
import { DELIVERY_COST_DOMESTIC_WITH_COMMA, DELIVERY_COST_FOREIGN_WITH_COMMA } from '../../utils/constants'

const assets = R.path(['ASSETS'], config)

const pageTranslation = {
    fi: {
        page: {
            deliveryFee: 'toimitusmaksu',
            select: 'Näytä',
        },
    },
    en: {
        page: {
            deliveryFee: 'delivery fee',
            select: 'Show',
        },
    },
}

export default class RecommendationsCard extends Component {
    constructor(props) {
        super(props)

        // If there is no need to check for loaded state, loadedProducts is undefined
        if (props.loadedProducts === undefined) {
            this.state = {
                imageStatus: 'loaded',
            }
        } else {
            if (props.loadedProducts.includes(props.index)) {
                this.state = {
                    imageStatus: 'loaded',
                }
            } else {
                this.state = {
                    imageStatus: null,
                }
            }
        }
    }

    handleImageLoaded = () => {
        if (this.props.loadedStatus) {
            this.props.loadedStatus(this.props.index)
            this.setState({ imageStatus: 'loaded' })
        }
    }

    handleImageErrored = (event) => {
        this.setState({ imageStatus: `failed to load, ${event.target.error}` })
    }

    render() {
        const { product, index, lang, cc } = this.props
        const trans = pageTranslation[getLanguage()].page

        const recommendationsCardClass = classNames(
            'recommendationsCard',
            { 'recommendationscard-wide': index < 2 },
            { transp: this.state.imageStatus !== 'loaded' },
        )

        const recommendationsCardSubClass = classNames(
            { 'pure-u-1': index < 2 },
            { 'pure-u-1': index > 1 },
            { 'pure-u-sm-1-2': index < 2 },
            { 'pure-u-sm-1-2': index > 1 },
            { 'pure-u-md-1-2': index < 2 },
            { 'pure-u-md-1': index > 1 },
        )

        const bgImageURL = cc === 'fi' ? `${assets}/thumb_` + product.image : product.image

        const deliveryCostWithComma =
            product.countrycode === 'FI'
                ? `${DELIVERY_COST_DOMESTIC_WITH_COMMA}`
                : `${DELIVERY_COST_FOREIGN_WITH_COMMA}`

        return (
            <div className={recommendationsCardClass} key={product.id}>
                <Link className="no-decoration" to={`/${lang}/${cc}/product/${product.id}`}>
                    <div className="recommendationsCard__product">
                        <div className="pure-g">
                            <div className={recommendationsCardSubClass}>
                                <div
                                    className="recommendationsCard__product--image"
                                    style={{ backgroundImage: 'url(' + bgImageURL + ')' }}
                                >
                                    <img
                                        className="pure-img"
                                        src={bgImageURL}
                                        onLoad={this.handleImageLoaded}
                                        onError={this.handleImageErrored}
                                        alt=""
                                    />
                                </div>
                            </div>
                            <div className={recommendationsCardSubClass}>
                                <div className="recommendationsCard__product--info">
                                    <div className="pure-g recommendationsCard__productinfo">
                                        <div className="pure-u-1">
                                            <div className="recommendationsCard__productinfo__headline--container">
                                                <h2 className="recommendationsCard__productinfo--headline">
                                                    {product.name}
                                                </h2>
                                            </div>
                                        </div>
                                        <div className="pure-u-1">
                                            <div className="recommendationsCard__product--divider"></div>
                                        </div>
                                        <div className="pure-u-1">
                                            <div className="recommendationsCard__productinfo__price--container">
                                                <div className="recommendationsCard__productinfo--price">
                                                    {product.price}
                                                    <span className="recommendationsCard__productinfo--price--euro">
                                                        €
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pure-u-1">
                                            <span className="recommendationsCard__priceinfo">
                                                + {deliveryCostWithComma}€ {trans.deliveryFee}
                                            </span>
                                        </div>
                                        <div className="pure-u-1">
                                            <button className="pure-button pure-button-primary recommendationsCard__orderbutton">
                                                {trans.select}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        )
    }
}
