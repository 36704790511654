import * as R from 'ramda'
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { detect } from 'detect-browser'

const pageTranslation = {
    fi: {
        page: {
            notSupported:
                'Käyttämääsi selainversiota ei enää tueta. Toiminnallisuuden varmistamiseksi asenna uusin versio käyttämästäsi selaimesta.',
        },
    },
    en: {
        page: {
            notSupported:
                'The browser you are using is not supported on this site. Please update to the newest version to keep using this site.',
        },
    },
}

class BrowserNotSupported extends Component {
    render() {
        const { lang } = this.props
        const selectedTrans = R.path([lang], pageTranslation)
        const trans = R.path(['page'], selectedTrans)

        const browser = R.or(detect(), {})

        const browserName = R.path(['name'], browser)
        const splitBrowserVersion = R.pathOr('0.0.0', ['version'], browser).split('.')
        const browserMajor = parseInt(splitBrowserVersion[0], 10)

        let notSupported = false

        if (browserName === 'safari' && browserMajor <= 9) {
            notSupported = true
        }

        return notSupported ? <div className="browser__not__supported">{R.path(['notSupported'], trans)}</div> : null
    }
}

const mapStateToProps = (state, ownProps) => ({
    lang: R.path(['lang', 'lang'], state),
})

export default withRouter(connect(mapStateToProps, undefined)(BrowserNotSupported))
