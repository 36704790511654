import { combineReducers } from 'redux'
import { SET_URI } from '../actions/uriActions'

const location = (state = {}, action) => {
    switch (action.type) {
        case SET_URI:
            return action.uri
        default:
            return state
    }
}

export default combineReducers({
    location,
})
