let HOSTNAME = 'shop.interflora.fi'
let SERVER = `https://${HOSTNAME}`

if (process.env.NODE_ENV === 'tilt') {
    HOSTNAME = 'localhost'
    SERVER = `http://${HOSTNAME}`
}

if (process.env.NODE_ENV === 'playground') {
    HOSTNAME = 'playground.interflora.fi'
    SERVER = `https://${HOSTNAME}`
}

export default {
    API_URL: `${SERVER}/api`,
    API_URL_V3: `${SERVER}/api`,
    HOSTNAME,
    SERVER,
    SSR_API_URL: 'http://backend.ifws.svc.cluster.local/api',
    SSR_API_URL_V3: 'http://backend-v3-internal.ifws.svc.cluster.local/api',
    ASSETS: 'https://shop.interflora.fi/images',
}
