import * as R from 'ramda'
import { getProductsByCountry, getExtras, getOccasions, getCountries } from '../api/backend'

import { emptyAllCartProducts } from './cartActions'

export const REQUEST_PRODUCTS = 'REQUEST_PRODUCTS'
export const REQUEST_EXTRAS = 'REQUEST_EXTRAS'
export const REQUEST_OCCASIONS = 'REQUEST_OCCASIONS'
export const REQUEST_COUNTRIES = 'REQUEST_COUNTRIES'
export const LOADING_START_PRODUCTS = 'LOADING_START_PRODUCTS'
export const LOADING_START_EXTRAS = 'LOADING_START_EXTRAS'
export const LOADING_START_OCCASIONS = 'LOADING_START_OCCASIONS'
export const LOADING_START_COUNTRIES = 'LOADING_START_COUNTRIES'
export const REQUEST_PRODUCT_CONTENT = 'REQUEST_PRODUCT_CONTENT'

const loadingStartProducts = () => {
    return {
        type: LOADING_START_PRODUCTS,
    }
}

const loadingStartExtras = () => {
    return {
        type: LOADING_START_EXTRAS,
    }
}

const loadingStartOccasions = () => {
    return {
        type: LOADING_START_OCCASIONS,
    }
}

const loadingStartCountries = () => {
    return {
        type: LOADING_START_COUNTRIES,
    }
}

export const getAllProducts = (cc) => (dispatch, getState) => {
    const { cart, lang } = getState()
    const translation = R.path(['lang'], lang)
    const firstProductCountryCode = R.pathOr('', ['countrycode'], R.head(R.pathOr([], ['items', 'products'], cart)))

    // If we have products in the cart, check their countrycode and empty the cart if the products countrycode and
    // the new product catalog do not match
    if (firstProductCountryCode && R.toLower(firstProductCountryCode) !== R.toLower(cc)) {
        dispatch(emptyAllCartProducts())
    }

    dispatch(loadingStartProducts())
    return getProductsByCountry(cc, translation)
        .then((results) =>
            dispatch({
                type: REQUEST_PRODUCTS + '_SUCCESS',
                products: results,
                lang: R.path(['lang'], lang),
            }),
        )
        .catch((error) =>
            dispatch({
                type: REQUEST_PRODUCTS + '_FAILED',
                error,
            }),
        )
}

export const getAllExtras = () => (dispatch, getState) => {
    dispatch(loadingStartExtras())
    const { lang } = getState()
    const translation = R.path(['lang'], lang)

    return getExtras(translation)
        .then((results) =>
            dispatch({
                type: REQUEST_EXTRAS + '_SUCCESS',
                extras: results,
            }),
        )
        .catch((err) =>
            dispatch({
                type: REQUEST_EXTRAS + '_FAILED',
                err,
            }),
        )
}

export const getAllOccasions = () => (dispatch, getState) => {
    dispatch(loadingStartOccasions())
    const { lang } = getState()
    const translation = R.path(['lang'], lang)

    return getOccasions(translation)
        .then((results) =>
            dispatch({
                type: REQUEST_OCCASIONS + '_SUCCESS',
                occasions: results,
            }),
        )
        .catch((error) =>
            dispatch({
                type: REQUEST_OCCASIONS + '_FAILED',
                error,
            }),
        )
}

export const getAllCountries = () => (dispatch, getState) => {
    dispatch(loadingStartCountries())
    const { lang } = getState()
    const translation = R.path(['lang'], lang)

    return getCountries(translation)
        .then((results) =>
            dispatch({
                type: REQUEST_COUNTRIES + '_SUCCESS',
                countries: results,
            }),
        )
        .catch((error) =>
            dispatch({
                type: REQUEST_COUNTRIES + '_FAILED',
                error,
            }),
        )
}

export const getAllProductContent = (
    isLoadingProducts,
    isLoadingExtras,
    isLoadingZipcodes,
    isLoadingOccasions,
    isLoadingCountries,
    allProducts,
    allExtras,
    allOccasions,
    allCountries,
    errorProducts,
    errorExtras,
    errorZipcodes,
    errorOccasions,
    errorCountries,
) => ({
    type: REQUEST_PRODUCT_CONTENT,
    isLoadingProducts: isLoadingProducts,
    isLoadingExtras: isLoadingExtras,
    isLoadingZipcodes: isLoadingZipcodes,
    isLoadingOccasions: isLoadingOccasions,
    isLoadingCountries: isLoadingCountries,
    allProducts: allProducts,
    allExtras: allExtras,
    allOccasions: allOccasions,
    allCountries: allCountries,
    errorProducts: errorProducts,
    errorExtras: errorExtras,
    errorZipcodes: errorZipcodes,
    errorOccasions: errorOccasions,
    errorCountries: errorCountries,
})
