import * as R from 'ramda'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Form from 'react-validation/build/form'
import { addRibbonContent } from '../../actions/formActions'
import { saveRibbonContent } from '../../actions/cartActions'
import { getLanguage } from '../../utils/translation'
import { isForeignProductByProduct } from '../../utils/ProductTypes'
import BottomNavigationBar from '../bottom-navigation-bar/BottomNavigationBar'

const pageTranslation = {
    fi: {
        page: {
            ribbonTextHeader: 'Nauhateksti',
            ribbonTextArea: 'Värssy tai muistosanat',
            names: 'Nimi/ nimet',
        },
    },
    en: {
        page: {
            ribbonTextHeader: 'Ribbon text',
            ribbonTextArea: 'Verse or greeting',
            names: 'Name/ names',
        },
    },
}
const FormRibbon = (props) => {
    const { formData, history, cartProducts, lang, cc, saveRibbonContentToStore, saveRibbonContentToDb, cartLoading } =
        props

    const [ribbonWords, setRibbonWords] = useState(R.path(['ribbonWords'], formData) || '')
    const [ribbonNames, setRibbonNames] = useState(R.path(['ribbonNames'], formData) || '')

    const selectedTrans = R.path([getLanguage()], pageTranslation)
    const trans = R.path(['page'], selectedTrans)

    useEffect(() => {
        // No ribbon or condolences products selection for international orders
        if (isForeignProductByProduct(R.head(cartProducts))) {
            history.push(`/${lang}/${cc}`)
        }
        window.scrollTo(0, 0)
    }, [])

  useEffect(() => {
      saveRibbonContentToStore(ribbonWords, ribbonNames)
  }, [ribbonWords, ribbonNames])

    const handleRibbonWordsChange = (event) => {
        setRibbonWords(event.target.value)
    }

    const handleRibbonNamesChange = (event) => {
        setRibbonNames(event.target.value)
    }

    const submitRibbonContent = (event) => {
        event.preventDefault()
        saveRibbonContentToStore(ribbonWords, ribbonNames)
        saveRibbonContentToDb()
    }

    return (
        <div className="productOrder__contentHolder">
            <Form className="pure-form pure-form-stacked productOrder__form">
                <fieldset>
                    <div className="pure-g">
                        <div className="pure-u-20-24">
                            <h2 className="order__headline">{R.path(['ribbonTextHeader'], trans)}</h2>
                        </div>
                        <div className="pure-u-4-24 alignRight form__indicator">
                            <span></span>
                        </div>
                    </div>

                    <div className="input-group form__field">
                        <label htmlFor="ribbonWords">{R.path(['ribbonTextArea'], trans)}</label>
                        <div className="productOrder__attachedMessage__container">
                            <textarea
                                maxLength="99"
                                id="ribbonWords"
                                className="productOrder__attachedMessage"
                                value={ribbonWords}
                                onChange={handleRibbonWordsChange}
                                placeholder=""
                            ></textarea>
                            <span className="productOrder__attachedMessage__counter">{ribbonWords.length}/99</span>
                        </div>
                    </div>

                    <div className="input-group form__field">
                        <label htmlFor="ribbonNames">{R.path(['names'], trans)}</label>
                        <div className="productOrder__attachedMessage__container">
                            <textarea
                                maxLength="99"
                                id="ribbonNames"
                                className="productOrder__attachedMessage"
                                value={ribbonNames}
                                onChange={handleRibbonNamesChange}
                                placeholder=""
                            ></textarea>
                            <span className="productOrder__attachedMessage__counter">{ribbonNames.length}/99</span>
                        </div>
                    </div>
                </fieldset>
                <BottomNavigationBar
                    backLink={`/${lang}/${cc}/order/recipient-info`}
                    handleContinue={submitRibbonContent}
                    loading={cartLoading}
                />
            </Form>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        formData: state.form,
        order: state.order,
        condolencesSelected: R.path(['order', 'condolences'], state),
        lang: R.path(['lang', 'lang'], state),
        cc: R.path(['lang', 'db'], state),
        cartProducts: R.pathOr([], ['cart', 'items', 'products'], state),
        cartLoading: R.path(['cart', 'loading'], state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        saveRibbonContentToStore: (ribbonWords, ribbonNames) => dispatch(addRibbonContent(ribbonWords, ribbonNames)),
        saveRibbonContentToDb: () => dispatch(saveRibbonContent()),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FormRibbon))
