import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as R from 'ramda'
import classNames from 'classnames'

class LoadingIndicator extends Component {
    render() {
        const { show, overwrite } = this.props

        const loadingIndicatorStyle = classNames('sk-circle', { notdisplayed: show === false && !overwrite })

        return (
            <div className={loadingIndicatorStyle}>
                <div className="sk-circle1 sk-child"></div>
                <div className="sk-circle2 sk-child"></div>
                <div className="sk-circle3 sk-child"></div>
                <div className="sk-circle4 sk-child"></div>
                <div className="sk-circle5 sk-child"></div>
                <div className="sk-circle6 sk-child"></div>
                <div className="sk-circle7 sk-child"></div>
                <div className="sk-circle8 sk-child"></div>
                <div className="sk-circle9 sk-child"></div>
                <div className="sk-circle10 sk-child"></div>
                <div className="sk-circle11 sk-child"></div>
                <div className="sk-circle12 sk-child"></div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        show: R.path(['loadingIndicator', 'show'], state),
    }
}

export default connect(mapStateToProps, null)(LoadingIndicator)
