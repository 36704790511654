import * as R from 'ramda'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { hideCartModal } from '../../actions/cartModalActions'
import { removeCartProduct, removeAllCartAdditionalProducts } from '../../actions/cartActions'
import { unselectRibbon, unselectCondolences, emptyCartAdditionalProduct } from '../../actions/orderActions'
import { getLanguage } from '../../utils/translation'

import { isGiftcardByProductId } from '../../utils/ProductTypes'
import { uniqueProductsInCart, productCountsInCart } from '../../utils/CartProducts'

import config from '../../config'

const assets = R.path(['ASSETS'], config)

const pageTranslation = {
    fi: {
        page: {
            continue: 'Jatka tilausta',
            close: 'Sulje',
            cartEmpty: 'Ostoskorisi on tyhjä',
            shoppingCart: 'Ostoskori',
            amount: 'kpl',
        },
    },
    en: {
        page: {
            continue: 'Proceed to order',
            close: 'Close',
            cartEmpty: 'Your cart is empty',
            shoppingCart: 'Your cart',
            amount: 'pcs',
        },
    },
}

const CartItem = (props) => {
    const { product, index, productAmount, giftcardAmount, giftcardPrice, onRemoveClick } = props

    const selectedTrans = R.path([getLanguage()], pageTranslation)
    const trans = R.path(['page'], selectedTrans)

    const product_id = R.path(['id'], product)
    const name = R.path(['name'], product)
    const productPrice = R.path(['price'], product)
    const countryCode = R.path(['countrycode'], product)
    const isGiftcard = isGiftcardByProductId(product_id)

    const price = isGiftcard ? giftcardPrice : productPrice

    const amount = isGiftcard ? giftcardAmount : productAmount

    const image = countryCode === 'FI' ? `${assets}/thumb_${R.path(['image'], product)}` : R.path(['image'], product)

    return (
        <div className="single-product" key={index}>
            <div className="image-area">
                <img className="pure-img" src={image} alt={name} />
            </div>
            <div className="description-area">
                <p>
                    <b>{name}</b>
                    <br />
                    {price}
                    <span className="small-font">€</span>
                    <br />
                    <span className="small-font">
                        {amount} {R.path(['amount'], trans)}
                    </span>
                </p>
            </div>
            <div className="button-area">
                <button className="close-button" onClick={(event) => onRemoveClick(event, product)}>
                    <i className="fa fa-close fa-2x" />
                </button>
            </div>
        </div>
    )
}

const getPriceAsCurr = (price) =>
    Number(price).toLocaleString('fi-Fi', {
        style: 'decimal',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
    })

const getItemsTotalPrice = (items = []) =>
    R.reduce(R.add, 0, R.map((item) => Number(R.pathOr(0, ['price'], item)))(items))

const getGiftcardTotalPrice = (cart) => {
    const amount = Number(R.pathOr(1, ['giftcard_amount'], cart))
    const price = Number(R.pathOr(10, ['giftcard_price'], cart))
    return amount * price
}

class CartModal extends Component {
    constructor(props) {
        super(props)
        this.removeProductFromCart = this.removeProductFromCart.bind(this)
    }

    removeProductFromCart(event, product) {
        event.preventDefault()
        const {
            removeCartProduct,
            removeAllCartAdditionalProducts,
            unselectRibbon,
            unselectCondolences,
            emptyCartAdditionalProduct,
        } = this.props

        const id = R.path(['id'], product)

        // Redux + backend
        removeCartProduct(id)
        removeAllCartAdditionalProducts()
        unselectRibbon()
        unselectCondolences()
        emptyCartAdditionalProduct()
    }

    render() {
        const { lang, cc, items, hideCartModal } = this.props

        const selectedTrans = R.path([getLanguage()], pageTranslation)
        const trans = R.path(['page'], selectedTrans)

        const products = R.path(['products'], items)
        const extras = R.path(['extras'], items)

        const uniqueProducts = uniqueProductsInCart(products)
        const productAmounts = productCountsInCart(products)
        const productCount = R.length(products)
        const mapIndexed = R.addIndex(R.map)

        const isGiftcard = isGiftcardByProductId(R.prop('id', R.head(products)))

        const productsTotalPrice = isGiftcard ? getGiftcardTotalPrice(items) : getItemsTotalPrice(products)
        const extrasTotalPrice = getItemsTotalPrice(extras)
        const cartTotalItemPrice = productsTotalPrice + extrasTotalPrice

        return (
            <div className="cart-modal">
                <div className="cart-content">
                    <h3>
                        {R.path(['shoppingCart'], trans)} {getPriceAsCurr(cartTotalItemPrice)}
                        <span style={{ fontSize: `.8em` }}>€</span>
                    </h3>
                    {mapIndexed((product, index) => (
                        <CartItem
                            product={product}
                            index={index}
                            key={index}
                            productAmount={R.prop(R.path(['id'], product), productAmounts)}
                            giftcardAmount={R.pathOr(0, ['giftcard_amount'], items)}
                            giftcardPrice={R.pathOr(0, ['giftcard_price'], items)}
                            onRemoveClick={this.removeProductFromCart}
                        />
                    ))(uniqueProducts)}
                    {productCount === 0 && <p>{R.path(['cartEmpty'], trans)}</p>}
                </div>

                <div className="cart-buttons">
                    <Link to={`/${lang}/${cc}/order/overview`}>
                        <button
                            className="pure-button pure-button-primary modal-button"
                            onClick={hideCartModal}
                            id="CartModal-continue-button"
                        >
                            {R.path(['continue'], trans)}
                        </button>
                    </Link>
                    <br />
                    <button
                        className="pure-button pure-button-primary modal-button modal-button-secondary"
                        onClick={hideCartModal}
                    >
                        {R.path(['close'], trans)}
                    </button>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        items: R.pathOr({}, ['cart', 'items'], state),
        lang: R.path(['lang', 'lang'], state),
        cc: R.path(['lang', 'db'], state),
    }
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        hideCartModal: () => dispatch(hideCartModal()),
        removeCartProduct: (id) => dispatch(removeCartProduct(id)),
        removeAllCartAdditionalProducts: () => dispatch(removeAllCartAdditionalProducts()),
        unselectRibbon: () => dispatch(unselectRibbon()),
        unselectCondolences: () => dispatch(unselectCondolences()),
        emptyCartAdditionalProduct: () => dispatch(emptyCartAdditionalProduct()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CartModal)
