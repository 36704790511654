import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import uriReducer from './reducers/uriReducer'

import productsReducer from './reducers/productsReducer'
import orderReducer from './reducers/orderReducer'
import formReducer from './reducers/formReducer'
import categoryReducer from './reducers/categoryReducer'
import langReducer from './reducers/langReducer'
import payformReducer from './reducers/payformReducer'
import invoiceReducer from './reducers/invoiceReducer'
import loginReducer from './reducers/loginReducer'
import loadingIndicatorReducer from './reducers/loadingIndicatorReducer'
import cartReducer from './reducers/cartReducer'
import modalReducer from './reducers/modalReducer'
import zipcodeReducer from './reducers/zipcodeReducer'
import cartModalReducer from './reducers/cartModalReducer'
import contentReducer from './reducers/contentReducer'
import deliveryCardReducer from './reducers/deliveryCardReducer'
import landingContentReducer from './reducers/landingContentReducer'

const createRootReducer = (history) =>
    combineReducers({
        router: connectRouter(history),
        content: contentReducer,
        products: productsReducer,
        cards: deliveryCardReducer,
        order: orderReducer,
        form: formReducer,
        category: categoryReducer,
        lang: langReducer,
        login: loginReducer,
        payform: payformReducer,
        invoice: invoiceReducer,
        uri: uriReducer,
        loadingIndicator: loadingIndicatorReducer,
        cart: cartReducer,
        modal: modalReducer,
        cartModal: cartModalReducer,
        zipcode: zipcodeReducer,
        landingContent: landingContentReducer,
    })

export default createRootReducer
