import React from 'react'

const ContentPage_main_headline = ({ headline }) => (
    <div className="contentPage__main__headlinewrapper pure-u-1">
        <div className="contentPage__headline__innerwrapper">
            <h1>{headline}</h1>
        </div>
    </div>
)

export default ContentPage_main_headline
