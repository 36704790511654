import { TimelineLite } from 'gsap'
import filter from 'lodash/filter'
import * as R from 'ramda'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import config from '../../config'
import { trackHomePageView } from '../../tracking/tracking'
import isMobile from '../../utils/IsMobile'
import { getLanguage } from '../../utils/translation'
import ContentPage_main_card from '../contentpages/ContentPage_main_card'
import ContentPage_main_headline from '../contentpages/ContentPage_main_headline'
import DeliveryContent from './DeliveryContent'
import FavouriteCard from './FavouriteCard'
import OccasionCard from './OccasionCard'

function revealAnim() {
    return new TimelineLite()
        .staggerFrom('.favouriteCard', 1.2, { opacity: 0, y: 10 }, 0.25)
        .from('.landing__button', 1.2, { opacity: 0 })
}

const assets = R.path(['ASSETS'], config)

const pageTranslation = {
    fi: {
        page: {
            clickToProducts: 'Tuotevalikoima',
            headerHowToSend: 'Tilauksen tekeminen',
            headerIsOrderPossible: 'Onko toimitus mahdollinen?',
            subHeaderChooseProduct: 'Valitse kukat',
            chooseProductMainText:
                'Löydä tilanteellesi sopiva kaunis kimppu sesongin kukista tai valloittava istutus suosituimmista kasveista. Valikoimamme kattaa elämän jokaiset hetket ja tilanteet.',
            subHeaderChooseDate: 'Valitse päivä & anna osoite',
            chooseDateMainText:
                'Kerro minä päivänä haluat kukkien saapuvan ja lisää saajan nimi ja osoitetiedot. Yllätä rakkaasi kesken työpäivän tai onnittele ystävääsi hänen tärkeänä päivänä. Kaikkien kukkien mukana tulee kortti, jolla välität terveisesi.',
            subHeaderPlaceOrder: 'Tee tilaus',
            placeOrderMainText:
                'Saat tilausvahvistuksen toimitustietoineen sähköpostiisi. Lähettimme vie kukat perille valitsemanasi päivänä.',
            headerOccasion: 'Kukat Jokaiseen Tilanteeseen',
            buttonViewSelection: 'Tutustu valikoimaan',
            headerNewsLetter: 'Tilaa Interfloran uutiskirje',
            paragraphNewsLetter:
                'Saat ajankohtaista kukka-aiheista tietoa, ideoita ja uutisia säännöllisesti suoraan sähköpostiisi, etkä enää koskaan unohda kukkia!',
            blogHeadline: 'Tietoa ja ideoita',
            buttonBrowseArticles: 'Selaa artikkeleita',
            inputNewsLetter: 'Syötä sähköpostiosoitteesi',
            buttonOrderNewsLetter: 'Tilaa',
            featuredCategory: 'Suosituimmat tuotteet',
            blogHeaderGlobal: 'Kukkalähetys ulkomaille',
            blogTextGlobal: 'Toimitamme kukat 140 maahan ympäri maailmaa.',
            blogHeaderAddOns: 'Lisätuotteet kukkalähetyksiin',
            blogTextAddOns:
                'Kukkien kanssa voit lähettää myös suklaata, nallen, maljakon, kortin ym. Tutustu Interfloran lisätuotevalikoimaan!',
            blogHeaderFirst: 'Kukkatietoutta',
            blogTextFirst: 'Tietoa kukista ja hoito-ohjeita eri kukkalajikkeille.',
            blogHeaderSecond: 'Usein kysyttyä',
            blogTextSecond: 'Täältä löydät vastaukset meiltä useimmiten kysyttyihin kysymyksiin.',
        },
        helmet: {
            title: ' Kukkia kaikkiin tilanteisiin - Nopeat toimitukset ympäri maailmaa',
            description: 'Interflora - kukkalähetykset ja kukkalahjakortit Suomeen ja ulkomaille',
            keywords:
                'Interflora, kukkakaupan huippuammattilaisia, kukkasitojia, floristeja, floristihortonomeja ja floristimestareita, kukkatervehdys, hautajaiskukat, hautavihot, kukat hautajaisiin, kukat juhliin, verkkokukka, kukka + yllätys',
        },
    },
    en: {
        page: {
            clickToProducts: 'Our selection',
            headerHowToSend: 'How to place an order',
            headerIsOrderPossible: 'Is delivery possible?',
            subHeaderChooseProduct: 'Choose flowers',
            chooseProductMainText:
                'Choose first one of our beautiful bouquets or a wonderful planting made of the most popular plants. Our selection covers all the moments and occasions in life.',
            subHeaderChooseDate: 'Delivery date and destination',
            chooseDateMainText:
                "Tell us when you want the flowers delivered and to who'm. Surprise your loved one during a work day or congratulate your friend on a special occasion. All deliveries include a card which conveys your message to the recipient.",
            subHeaderPlaceOrder: 'Place the order',
            placeOrderMainText:
                'You receive an order confirmation to the provided email address. A courier will deliver the flowers on chosen day.',
            headerOccasion: 'Flowers For All Occasions',
            buttonViewSelection: 'See our selection',
            headerNewsLetter: 'Why join Interflora’s mailing list?',
            paragraphNewsLetter:
                'Subscribe to our newsletter - Get up-to-date floral information, ideas, and news so you will never forget to send flowers!',
            blogHeadline: 'Floral knowledge',
            buttonBrowseArticles: 'Browse our articles',
            inputNewsLetter: 'Your email address',
            buttonOrderNewsLetter: 'Order',
            featuredCategory: 'Most popular',
            blogHeaderGlobal: 'International deliveries',
            blogTextGlobal: 'Flowers deliveries to 140 different countries around the World!',
            blogHeaderAddOns: 'Additional products',
            blogTextAddOns: 'Add chocolates, a teddy bear, a vase or a card to the flower delivery.',
            blogHeaderFirst: 'Floral Knowledge',
            blogTextFirst: 'Information and care instructions for flowers.',
            blogHeaderSecond: 'FAQ',
            blogTextSecond: 'Here you will find answers to the most frequently asked questions.',
        },
        helmet: {
            title: ' Flowers for all occasions in Finland and abroad',
            description:
                'Interflora - Flowers for all occasions in Finland and abroad. A wide selection of seasonal flowers and Interflora gift vouchers.',
            keywords: 'flower delivery, flowers online, flower delivery overseas',
        },
    },
}

const getValueOccasion = (occasion, product) =>
    R.propOr(0, 'value', R.find(R.propEq('occasion', occasion))(R.pathOr([], ['promoted'], product)))

const promoteByOccasion = (products, occasion) => R.reverse(R.sortBy(getValueOccasion.bind(this, occasion))(products))

const defaultSort = (products, occasion = 'all') => promoteByOccasion(products, occasion)

const landingBlogItems = (trans, cc) => [
    {
        headline: R.path(['blogHeaderFirst'], trans),
        content: R.path(['blogTextFirst'], trans),
        urlFi: `/fi/${cc}/tietoa/kukkatietoutta`,
        urlEn: `/en/${cc}/content/floral-knowledge`,
        img: `${assets}/floristin_valinta_1500x1000.jpeg`,
    },
    {
        headline: R.path(['blogHeaderSecond'], trans),
        content: R.path(['blogTextSecond'], trans),
        urlFi: `/fi/${cc}/tietoa/tilaus-ja-toimitus/usein-kysyttya`,
        urlEn: `/en/${cc}/content/order-and-delivery/faq`,
        img: `${assets}/usein_kysyttya_1500x1000.jpeg`,
    },
    {
        headline: R.path(['blogHeaderGlobal'], trans),
        content: R.path(['blogTextGlobal'], trans),
        urlFi: `/fi/${cc}/tietoa/tilaus-ja-toimitus/kukkalahetys-ulkomaille`,
        urlEn: `/en/${cc}/content/order-and-delivery/international-deliveries`,
        img: `${assets}/international.jpg`,
    },
    {
        headline: R.path(['blogHeaderAddOns'], trans),
        content: R.path(['blogTextAddOns'], trans),
        urlFi: `/fi/${cc}/tietoa/tilaus-ja-toimitus/lisatuotteet`,
        urlEn: `/en/${cc}/content/order-and-delivery/additional-products`,
        img: `${assets}/lisatuotteet2.2.jpg`,
    },
]

class Landing extends Component {
    constructor(props, context) {
        super(props, context)
        const { storeState } = this.props
        trackHomePageView(storeState)
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        !isMobile() && revealAnim()
    }

    render() {
        const hiddenField = {
            position: 'absolute',
            left: '-5000px',
        }
        const { lang, cc, products, occasions } = this.props

        const selectedTrans = R.path([getLanguage()], pageTranslation)
        const trans = R.path(['page'], selectedTrans)

        // DISPLAY FEATURED PRODUCTS
        let favourites = filter(products, ['featured', true])
        const sortedFavourites = defaultSort(favourites, R.path(['featuredCategory'], trans))

        return (
            <div className="landing__wrapper">
                <div className="landing__favourites">
                    <div className="landing__favourites__innercontainer">
                        <div className="pure-g">
                            {sortedFavourites.map(function (product, key) {
                                return <FavouriteCard product={product} key={key} index={key} lang={lang} cc={cc} />
                            }, this)}
                        </div>

                        <Link to={`/${lang}/${cc}/category`}>
                            <button className="pure-button pure-button-primary landing__button">
                                {R.path(['clickToProducts'], trans)}
                            </button>
                        </Link>
                    </div>
                </div>

                <div className="landing__content">
                    <div style={{ marginTop: '48px' }} />
                    <h1>{R.path(['headerIsOrderPossible'], trans)}</h1>
                    <DeliveryContent lang={lang} cc={cc} />
                    <hr className="landing__divider" />
                    <div className="landing__how_to_order">
                        <h1>{R.path(['headerHowToSend'], trans)}</h1>
                        <div className="pure-g">
                            <div className="pure-u-1 pure-u-md-8-24 how_to_orderCard">
                                <div className="how_to_orderCard__content">
                                    <img className="pure-img" src={`${assets}/icon-flowers.svg`} alt="" />
                                    <h3>{R.path(['subHeaderChooseProduct'], trans)}</h3>
                                    <p>{R.path(['chooseProductMainText'], trans)}</p>
                                </div>
                            </div>
                            <div className="pure-u-1 pure-u-md-8-24 how_to_orderCard">
                                <div className="how_to_orderCard__content">
                                    <img className="pure-img" src={`${assets}/icon-letter.svg`} alt="" />
                                    <h3>{R.path(['subHeaderChooseDate'], trans)}</h3>
                                    <p>{R.path(['chooseDateMainText'], trans)}</p>
                                </div>
                            </div>
                            <div className="pure-u-1 pure-u-md-8-24 how_to_orderCard">
                                <div className="how_to_orderCard__content">
                                    <img className="pure-img" src={`${assets}/icon-runner.svg`} alt="" />
                                    <h3>{R.path(['subHeaderPlaceOrder'], trans)}</h3>
                                    <p>{R.path(['placeOrderMainText'], trans)}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="contentPage">
                    <div className="contentPage__main__wrapper pure-g" style={{ maxWidth: '1200px', margin: '0 auto' }}>
                        <ContentPage_main_headline headline={R.path(['blogHeadline'], trans)} />
                        {landingBlogItems(trans, cc).map((item) => (
                            <ContentPage_main_card
                                key={item.urlFi}
                                headline={item.headline}
                                content={item.content}
                                url={lang === 'fi' ? item.urlFi : item.urlEn}
                                widthtype="2"
                                imgurl={item.img}
                            />
                        ))}
                    </div>
                </div>
                <div>
                    <a href={`/${lang}/${cc}/${lang === 'fi' ? 'tietoa' : 'content'}`}>
                        <button className="pure-button pure-button-primary landing__button">
                            {R.path(['buttonBrowseArticles'], trans)}
                        </button>
                    </a>
                </div>
                <div className="landing__occasions">
                    <h1>{R.path(['headerOccasion'], trans)}</h1>

                    <div className="pure-g occasionCards">
                        {occasions.map(function (occasion, key) {
                            return <OccasionCard occasion={occasion} key={key} lang={lang} cc={cc} />
                        }, this)}
                    </div>

                    <Link to={`/${lang}/${cc}/category`}>
                        <button className="pure-button pure-button-primary landing__button">
                            {R.path(['buttonViewSelection'], trans)}
                        </button>
                    </Link>
                </div>

                <div className="landing__subscription">
                    <div className="verticalAlign landing__subscription__wrapper">
                        <div className="verticalAlignChild">
                            <form
                                className="pure-form pure-form-stacked"
                                action="//interflora.us14.list-manage.com/subscribe/post?u=e76a0e19ba29e57578ee60a57&amp;id=b975f75d6e"
                                method="post"
                                id="mc-embedded-subscribe-form"
                                name="mc-embedded-subscribe-form"
                                target="_blank"
                            >
                                <fieldset>
                                    <div className="input-group form__field">
                                        <h2>{R.path(['headerNewsLetter'], trans)}</h2>
                                        <h3>{R.path(['paragraphNewsLetter'], trans)}</h3>
                                        <div>
                                            <div style={hiddenField}>
                                                <input
                                                    type="text"
                                                    name="b_e76a0e19ba29e57578ee60a57_b975f75d6e"
                                                    tabIndex="-1"
                                                    value=""
                                                    readOnly
                                                />
                                            </div>
                                            <input
                                                type="text"
                                                name="EMAIL"
                                                id="mce-EMAIL"
                                                placeholder={R.path(['inputNewsLetter'], trans)}
                                            />
                                            <button
                                                type="submit"
                                                value="Subscribe"
                                                id="mc-embedded-subscribe"
                                                className="pure-button pure-button-primary"
                                            >
                                                {R.path(['buttonOrderNewsLetter'], trans)}
                                            </button>
                                        </div>
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    storeState: state,
})

export default connect(mapStateToProps, undefined)(Landing)
