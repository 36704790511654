import { REQUEST_LANDING_CONTENT, LOADING_LANDING_CONTENT } from '../actions/landingContentActions'

const initialState = {
    content: null,
    isLoadingCampaignHeader: false,
    errorCampaignHeader: null,
}

const landingContentReducer = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_LANDING_CONTENT:
            return {
                ...state,
                content: action.content,
                isLoadingLandingContent: false,
            }
        case REQUEST_LANDING_CONTENT + '_SUCCESS':
            return {
                ...state,
                content: action.content,
                isLoadingLandingContent: false,
            }
        case REQUEST_LANDING_CONTENT + '_FAILED':
            return {
                ...state,
                isLoadingCampaignHeader: false,
                errorLandingContent: 'Failed to fetch Landing Content.',
            }
        case LOADING_LANDING_CONTENT:
            return {
                ...state,
                isLoadingCampaignHeader: true,
            }
        default:
            return state
    }
}

export default landingContentReducer
