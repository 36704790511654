import * as R from 'ramda'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { initiateSearch } from '../../actions/categoryActions'
import { trackCategoryPageView } from '../../tracking/tracking'

import flow from 'lodash/flow'
import drop from 'lodash/drop'
import indexOf from 'lodash/indexOf'
import uniq from 'lodash/uniq'
import flattenDeep from 'lodash/flattenDeep'
import filter from 'lodash/filter'

import classNames from 'classnames'
import ProductCard from './ProductCard'
import Waypoint from 'react-waypoint'
import { TimelineLite } from 'gsap'
import isMobile from '../../utils/IsMobile'
import moment from 'moment-timezone'

import { getLanguage } from '../../utils/translation'

import Helmet from 'react-helmet'

import config from '../../config'

const assets = R.path(['ASSETS'], config)
const server = R.path(['SERVER'], config)

const pageTranslation = {
    fi: {
        page: {
            headerTitle: 'Kukat jokaiseen tilanteeseen',
            country: 'Maa',
            occasion: 'Tilanne',
            allOccasions: 'Kaikki',
            additionalOptions: 'Lisävalinnat',
            price: 'Hinta',
            priceSelection: 'Hinta järjestäen',
            priceLowToHigh: 'Edullisin ensin',
            priceHighToLow: 'Kallein ensin',
            color: 'Väri',
            allColors: 'Kaikki värit',
            type: 'Tyyppi',
            allTypes: 'Kaikki Tyypit',
            variety: 'Lajike',
            allVarieties: 'Kaikki lajikkeet',
            emptyParams: 'Tyhjennä hakuehdot',
        },
        helmet: {
            title: 'Kukkia kaikkiin tilanteisiin - Nopeat toimitukset ympäri maailmaa',
            description: 'Interflora - kukkalähetykset ja kukkalahjakortit Suomeen ja ulkomaille',
            keywords:
                'Interflora, kukkakaupan huippuammattilaisia, kukkasitojia, floristeja, floristihortonomeja ja floristimestareita, kukkatervehdys, hautajaiskukat, hautavihot, kukat hautajaisiin, kukat juhliin, verkkokukka, kukka + yllätys',
        },
    },
    en: {
        page: {
            headerTitle: 'Flowers for all occassions',
            country: 'Country',
            occasion: 'Occasion',
            allOccasions: 'All',
            additionalOptions: 'Additional options',
            price: 'Price',
            priceSelection: 'Arrange by pricing',
            priceLowToHigh: 'Low to high',
            priceHighToLow: 'High to low',
            color: 'Color',
            allColors: 'All colors',
            type: 'Type',
            allTypes: 'All Types',
            variety: 'Variety',
            allVarieties: 'All Varieties',
            emptyParams: 'Clear search criteria',
        },
        helmet: {
            title: 'Flowers for all occasions in Finland and abroad',
            description:
                'Interflora - Flowers for all occasions in Finland and abroad. A wide selection of seasonal flowers and Interflora gift vouchers.',
            keywords: 'flower delivery, flowers online, flower delivery overseas',
        },
    },
}

function revealAnim() {
    return new TimelineLite().staggerFrom('.productCard', 1.2, { opacity: 0, y: 10 }, 0.25)
}

const getValueOccasion = (occasion, product) =>
    R.propOr(0, 'value', R.find(R.propEq('occasion', occasion))(R.pathOr([], ['promoted'], product)))

const promoteByOccasion = (products, occasion) => R.reverse(R.sortBy(getValueOccasion.bind(this, occasion))(products))

const defaultSort = (products, occasion = 'all') => promoteByOccasion(products, occasion)

const getVisibleProducts = (prods, occasion, color, contains, type, minPrice, sort) => {
    if (!prods || R.length(prods) === 0) return []

    // Remove giftcard from products listed here
    let products = filter(prods, function (o) {
        return o.category !== 'lahjakortti'
    })

    if (occasion === '' && color === '' && contains === '' && type === '' && minPrice === '' && sort === '') {
        return defaultSort(products)
    } else {
        function minPriceFilter(products) {
            return products.filter((product) => Number(product.price) >= Number(minPrice))
        }

        function occasionFilter(products) {
            return occasion !== ''
                ? promoteByOccasion(
                      products.filter((product) => R.contains(occasion, product.occasion)),
                      occasion,
                  )
                : products
        }

        function colorFilter(products) {
            return color !== '' ? products.filter((product) => R.contains(color, product.color)) : products
        }

        function containsFilter(products) {
            return contains !== '' ? products.filter((product) => R.contains(contains, product.contains)) : products
        }

        function typeFilter(products) {
            return type !== '' ? products.filter((product) => R.contains(type, product.type)) : products
        }

        function priceSort(products) {
            if (sort === 'Halvin') {
                return products.sort(function (a, b) {
                    return a.price - b.price
                })
            } else if (sort === 'Kallein') {
                return products.sort(function (a, b) {
                    return b.price - a.price
                })
            } else {
                return products
            }
        }

        let composeFunctions = flow([
            minPriceFilter,
            occasionFilter,
            containsFilter,
            typeFilter,
            colorFilter,
            priceSort,
        ])
        return composeFunctions(products, products)
    }
}

class Category extends Component {
    static amountOfProducts = 11

    constructor(props, context) {
        super(props, context)

        this.handle_categoryOccasion_change = this.handle_categoryOccasion_change.bind(this)
        this.handle_categoryColor_change = this.handle_categoryColor_change.bind(this)
        this.handle_categoryContains_change = this.handle_categoryContains_change.bind(this)
        this.handle_categoryType_change = this.handle_categoryType_change.bind(this)
        this.handle_minPrice_change = this.handle_minPrice_change.bind(this)
        this.handle_categoryPriceSort_change = this.handle_categoryPriceSort_change.bind(this)
        this.handle_additionalOptions_toggle = this.handle_additionalOptions_toggle.bind(this)
        this.handle_clear_filters = this.handle_clear_filters.bind(this)
        this.handle_countryCode_change = this.handle_countryCode_change.bind(this)
        this.loadMoreItems = this.loadMoreItems.bind(this)
        this.loadedStatusHandler = this.loadedStatusHandler.bind(this)

        this.state = {
            ...this.categories(),
            selectedCountryCode: this.props.cc.toUpperCase(),
            minPrice: '',
            productsLoaded: false,
            allProductsShown: false,
            loadedProducts: [],
            additionalOptionsOpen: false,
            ...this.getInitialItems(),
        }

        this.getColors()
        this.getTypes()
        this.getContains()
        trackCategoryPageView(this.props.storeState)
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        !isMobile() && revealAnim()
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.categoryOccasion !== prevProps.categoryOccasion ||
            this.props.categoryColor !== prevProps.categoryColor ||
            this.props.categoryContains !== prevProps.categoryContains ||
            this.props.categoryType !== prevProps.categoryType ||
            this.props.categoryPriceSort !== prevProps.categoryPriceSort ||
            (this.props.products !== prevProps.products && this.props.products.length)
        ) {
            this.setState({ ...this.categories(), productsLoaded: false })
            this.getNewItems()
            this.getColors()
            this.getTypes()
            this.getContains()
        }
    }

    categories() {
        return {
            categoryOccasion: this.props.categoryOccasion,
            categoryColor: this.props.categoryColor,
            categoryContains: this.props.categoryContains,
            categoryType: this.props.categoryType,
            categoryPriceSort: this.props.categoryPriceSort,
        }
    }

    getInitialItems() {
        let allFilteredItems = this.props.products
        let allVisibleItems = []

        if (allFilteredItems.length > Category.amountOfProducts) {
            for (let i = 0; i < Category.amountOfProducts; i++) {
                allVisibleItems.push(allFilteredItems[i])
            }
            allFilteredItems = drop(allFilteredItems, Category.amountOfProducts)
            return {
                allFilteredItems: allFilteredItems,
                allVisibleItems: allVisibleItems,
                productsLoaded: true,
                allProductsShown: false,
            }
        } else {
            allVisibleItems = allFilteredItems
            allFilteredItems = drop(allFilteredItems, Category.amountOfProducts)
            return {
                allFilteredItems: allFilteredItems,
                allVisibleItems: allVisibleItems,
                productsLoaded: true,
                allProductsShown: true,
            }
        }
    }

    getNewItems() {
        let allFilteredItems = this.props.products
        let allVisibleItems = []

        if (allFilteredItems.length > Category.amountOfProducts) {
            for (let i = 0; i < Category.amountOfProducts; i++) {
                allVisibleItems.push(allFilteredItems[i])
            }
            allFilteredItems = drop(allFilteredItems, Category.amountOfProducts)
            this.setState({
                allFilteredItems: allFilteredItems,
                allVisibleItems: allVisibleItems,
                productsLoaded: true,
                allProductsShown: false,
            })
        } else {
            allVisibleItems = allFilteredItems
            allFilteredItems = drop(allFilteredItems, Category.amountOfProducts)
            this.setState({
                allFilteredItems: allFilteredItems,
                allVisibleItems: allVisibleItems,
                productsLoaded: true,
                allProductsShown: true,
            })
        }
    }

    renderWaypoint() {
        if (this.state.productsLoaded) {
            return <Waypoint onEnter={this.loadMoreItems} />
        }
    }

    loadMoreItems() {
        if (this.state.allProductsShown) {
            return false
        }

        this.setState({ productsLoaded: false })

        let allFilteredItems = this.state.allFilteredItems
        let allVisibleItems = this.state.allVisibleItems

        if (allFilteredItems.length > 0) {
            if (allFilteredItems.length > Category.amountOfProducts) {
                for (let i = 0; i < Category.amountOfProducts; i++) {
                    allVisibleItems.push(allFilteredItems[i])
                }
                allFilteredItems = drop(allFilteredItems, Category.amountOfProducts)
                this.setState({
                    allFilteredItems: allFilteredItems,
                    allVisibleItems: allVisibleItems,
                    productsLoaded: true,
                    allProductsShown: false,
                })
            } else {
                for (let i = 0; i < allFilteredItems.length; i++) {
                    allVisibleItems.push(allFilteredItems[i])
                }
                allFilteredItems = drop(allFilteredItems, Category.amountOfProducts)
                this.setState({
                    allFilteredItems: allFilteredItems,
                    allVisibleItems: allVisibleItems,
                    productsLoaded: true,
                    allProductsShown: true,
                })
            }
        } else {
            this.setState({
                productsLoaded: true,
                allProductsShown: true,
            })
        }
    }

    loadedStatusHandler(index) {
        let loadedProducts = this.state.loadedProducts
        if (indexOf(loadedProducts, index) === -1) {
            loadedProducts.push(index)
            this.setState({
                loadedProducts: loadedProducts,
            })
        }
    }

    handle_categoryOccasion_change(event) {
        this.setState({ categoryOccasion: event.target.value }, function () {
            this.props.initiateSearchClick(
                this.state.categoryOccasion,
                this.state.categoryColor,
                this.state.categoryContains,
                this.state.categoryType,
                this.state.minPrice,
                this.state.categoryPriceSort,
                this.state.selectedCountryCode,
            )
        })
    }

    handle_categoryColor_change(event) {
        this.setState({ categoryColor: event.target.value }, function () {
            this.props.initiateSearchClick(
                this.state.categoryOccasion,
                this.state.categoryColor,
                this.state.categoryContains,
                this.state.categoryType,
                this.state.minPrice,
                this.state.categoryPriceSort,
                this.state.selectedCountryCode,
            )
        })
    }

    handle_categoryContains_change(event) {
        this.setState({ categoryContains: event.target.value }, function () {
            this.props.initiateSearchClick(
                this.state.categoryOccasion,
                this.state.categoryColor,
                this.state.categoryContains,
                this.state.categoryType,
                this.state.minPrice,
                this.state.categoryPriceSort,
                this.state.selectedCountryCode,
            )
        })
    }

    handle_categoryType_change(event) {
        this.setState({ categoryType: event.target.value }, function () {
            this.props.initiateSearchClick(
                this.state.categoryOccasion,
                this.state.categoryColor,
                this.state.categoryContains,
                this.state.categoryType,
                this.state.minPrice,
                this.state.categoryPriceSort,
                this.state.selectedCountryCode,
            )
        })
    }

    handle_minPrice_change(event) {
        this.setState({ minPrice: event.target.value }, function () {
            this.props.initiateSearchClick(
                this.state.categoryOccasion,
                this.state.categoryColor,
                this.state.categoryContains,
                this.state.categoryType,
                this.state.minPrice,
                this.state.categoryPriceSort,
                this.state.selectedCountryCode,
            )
        })
    }

    handle_categoryPriceSort_change(event) {
        this.setState({ categoryPriceSort: event.target.value }, function () {
            this.props.initiateSearchClick(
                this.state.categoryOccasion,
                this.state.categoryColor,
                this.state.categoryContains,
                this.state.categoryType,
                this.state.minPrice,
                this.state.categoryPriceSort,
                this.state.selectedCountryCode,
            )
        })
    }

    handle_countryCode_change(event) {
        this.setState(
            {
                categoryOccasion: '',
                categoryColor: '',
                categoryContains: '',
                categoryType: '',
                minPrice: '',
                categoryPriceSort: '',
                selectedCountryCode: event.target.value,
            },
            this.updateURL.bind(this),
        )
    }

    handle_additionalOptions_toggle(event) {
        event.preventDefault()
        this.setState({
            additionalOptionsOpen: !this.state.additionalOptionsOpen,
        })
    }

    handle_clear_filters() {
        this.setState(
            {
                categoryOccasion: '',
                categoryColor: '',
                categoryContains: '',
                categoryType: '',
                minPrice: '',
                categoryPriceSort: '',
                selectedCountryCode: 'FI',
            },
            this.updateURL.bind(this),
        )
    }

    updateURL() {
        const { history, lang, initiateSearchClick } = this.props
        initiateSearchClick(
            this.state.categoryOccasion,
            this.state.categoryColor,
            this.state.categoryContains,
            this.state.categoryType,
            this.state.minPrice,
            this.state.categoryPriceSort,
            this.state.selectedCountryCode,
        )
        const url = `/${lang}/${R.toLower(this.state.selectedCountryCode)}/category`
        history.push(url)
    }

    getTypes() {
        let types = []

        if (!R.path(['allProducts'], this.props) || R.length(R.path(['allProducts'], this.props)) === 0)
            return (this.uniqTypes = [])

        for (let i = 0; i < R.path(['allProducts'], this.props).length; i++) {
            types.push(R.path(['allProducts'], this.props)[i].type)
        }

        let flattenTypes = flattenDeep(types)
        this.uniqTypes = R.filter((item) => typeof item === 'string')(uniq(flattenTypes).sort())
    }

    getColors() {
        let colors = []

        if (!R.path(['allProducts'], this.props) || R.length(R.path(['allProducts'], this.props)) === 0)
            return (this.uniqColors = [])

        for (let i = 0; i < R.path(['allProducts'], this.props).length; i++) {
            colors.push(R.path(['allProducts'], this.props)[i].color)
        }

        let flattenColors = flattenDeep(colors)
        this.uniqColors = R.filter((item) => typeof item === 'string')(uniq(flattenColors).sort())
    }

    getContains() {
        let contains = []

        if (!R.path(['allProducts'], this.props) || R.length(R.path(['allProducts'], this.props)) === 0)
            return (this.uniqContains = [])

        for (let i = 0; i < R.path(['allProducts'], this.props).length; i++) {
            contains.push(R.path(['allProducts'], this.props)[i].contains)
        }

        let flattenContains = flattenDeep(contains)
        this.uniqContains = R.filter((item) => typeof item === 'string')(uniq(flattenContains).sort())
    }

    countrySort(countries) {
        return R.sortWith([R.ascend(R.prop('Name'))], countries)
    }

    render() {
        var categoryBannerStyle = {
            backgroundImage: `url(${assets}/banner-valikoima.jpg)`,
        }

        var additionalOptionsAngleStyle = classNames('fa', {
            'fa-angle-down': !this.state.additionalOptionsOpen,
            'fa-angle-up': this.state.additionalOptionsOpen,
        })

        var additionalOptionsWrapperStyle = classNames(
            'category__additionalOptions__wrapper',
            'pure-u-1',
            { 'category__additionalOptions__wrapper--hidden': !this.state.additionalOptionsOpen },
            'pure-always-show-desktop',
        )

        var occasionDropdown = classNames('input-group-dropdown', 'category__dropdown', {
            'category__dropdown--disabled': this.state.selectedCountryCode !== 'FI',
        })

        var categoryDropdown = classNames(
            'input-group-dropdown',
            'category__dropdown',
            'category__dropdown--additional',
            { 'category__dropdown--disabled': this.state.selectedCountryCode !== 'FI' },
        )

        const { lang, cc, occasions, allCountries, selectedCountryCode } = this.props

        const trans = R.path([getLanguage(), 'page'], pageTranslation)

        return (
            <div className="category__container">
                <Helmet
                    title="Kukkia kaikkiin tilanteisiin | Nopeat toimitukset ympäri maailmaa"
                    meta={[
                        {
                            name: 'description',
                            content:
                                'Interflora - kukkalähetykset ja kukkalahjakortit Suomeen ja ulkomaille - blomsterförmedlingar och blomstergåvokort till Finland och utlandet – Flower deliveries and Floral Cheques to Finland and other countries',
                        },
                        {
                            name: 'keywords',
                            content:
                                'Interflora, kukkakaupan huippuammattilaisia, kukkasitojia, floristeja, floristihortonomeja ja floristimestareita, kukkatervehdys, hautajaiskukat, hautavihot, kukat hautajaisiin, kukat juhliin, verkkokukka, kukka + yllätys',
                        },
                        {
                            property: 'og:title',
                            content: 'Kukkia kaikkiin tilanteisiin | Nopeat toimitukset ympäri maailmaa',
                        },
                        { property: 'og:image', content: `${assets}/interflora-logo.png` },
                        { property: 'og:url', content: `${server}/${lang}/${cc}/category` },
                        {
                            property: 'og:description',
                            content:
                                'Interflora - kukkalähetykset ja kukkalahjakortit Suomeen ja ulkomaille - blomsterförmedlingar och blomstergåvokort till Finland och utlandet – Flower deliveries and Floral Cheques to Finland and other countries',
                        },
                    ]}
                    script={[
                        {
                            type: 'application/ld+json',
                            innerHTML: `{
            "@context": "http://schema.org",
            "@type": "WebPage",
            "name": "Kukkia kaikkiin tilanteisiin | Nopeat toimitukset ympäri maailmaa",
            "description": "Interflora - kukkalähetykset ja kukkalahjakortit Suomeen ja ulkomaille - blomsterförmedlingar och blomstergåvokort till Finland och utlandet – Flower deliveries and Floral Cheques to Finland and other countries"
             }`,
                        },
                    ]}
                />

                <div className="category__banner" style={categoryBannerStyle}></div>

                <div className="category__search__wrapper pure-g">
                    <div className="category__search__innerwrapper">
                        <div className="pure-u-1">
                            <h1>{R.path(['headerTitle'], trans)}</h1>
                        </div>

                        <div className="pure-u-1 pure-u-sm-12-24 pure-u-md-12-24 pure-padded">
                            <div className="input-group-dropdown category__dropdown">
                                <label htmlFor="categoryCountry">{R.path(['country'], trans)}</label>
                                <i className="fa fa-angle-down" aria-hidden="true"></i>
                                <select
                                    id="categoryCountry"
                                    value={this.state.selectedCountryCode}
                                    onChange={this.handle_countryCode_change}
                                >
                                    {this.countrySort(allCountries).map(function (country, key) {
                                        return (
                                            <option value={country.CountryCode} key={key}>
                                                {country.Name}
                                            </option>
                                        )
                                    }, this)}
                                </select>
                            </div>
                        </div>

                        <div className="pure-u-1 pure-u-sm-12-24 pure-u-md-12-24 pure-padded">
                            <div className={occasionDropdown}>
                                <label htmlFor="categoryOccasion">{R.path(['occasion'], trans)}</label>
                                <i className="fa fa-angle-down" aria-hidden="true"></i>
                                <select
                                    id="categoryOccasion"
                                    value={this.state.categoryOccasion}
                                    onChange={this.handle_categoryOccasion_change}
                                >
                                    <option value="">{R.path(['allOccasions'], trans)}</option>
                                    {occasions.map(function (occasion, key) {
                                        // Conditional rendering of this card
                                        if (occasion) {
                                            if (occasion.time_start !== '' && occasion.time_end !== '') {
                                                if (
                                                    !moment(new Date())
                                                        .tz('Europe/Helsinki')
                                                        .isBetween(occasion.time_start, occasion.time_end, null, '[]')
                                                ) {
                                                    return null
                                                }
                                            }
                                        }

                                        return (
                                            <option value={occasion.occasion} key={key}>
                                                {occasion.occasion}
                                            </option>
                                        )
                                    }, this)}
                                </select>
                            </div>
                        </div>

                        <label htmlFor="minPrice" className="notdisplayed">
                            Hinta vähintään
                        </label>
                        <div className="input-group minprice__input notdisplayed">
                            <input
                                type="text"
                                id="minPrice"
                                className="productOrder__minPrice productOrder__input--text"
                                value={this.state.minPrice}
                                onChange={this.handle_minPrice_change}
                            />
                        </div>

                        <div className="category__additionalOptions--toggle pure-u-1">
                            <a href="#" onClick={this.handle_additionalOptions_toggle}>
                                <div className="line-title pure-hidden-desktop">
                                    {R.path(['additionalOptions'], trans)}
                                </div>
                                <div className="category__additionalOptions--toggle--icon pure-hidden-desktop">
                                    <i className={additionalOptionsAngleStyle} aria-hidden="true"></i>
                                </div>
                            </a>
                        </div>

                        <div className={additionalOptionsWrapperStyle}>
                            <div className="pure-u-1 pure-u-sm-12-24 pure-u-md-12-24 pure-u-lg-1-4 pure-padded">
                                <div className="input-group-dropdown category__dropdown category__dropdown--additional">
                                    <label htmlFor="categoryPriceSort">{R.path(['price'], trans)}</label>
                                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                                    <select
                                        id="categoryPriceSort"
                                        value={this.state.categoryPriceSort}
                                        onChange={this.handle_categoryPriceSort_change}
                                    >
                                        <option value="">{R.path(['priceSelection'], trans)}</option>
                                        <option value="Halvin">{R.path(['priceLowToHigh'], trans)}</option>
                                        <option value="Kallein">{R.path(['priceHighToLow'], trans)}</option>
                                    </select>
                                </div>
                            </div>

                            <div className="pure-u-1 pure-u-sm-12-24 pure-u-md-12-24 pure-u-lg-1-4 pure-padded">
                                <div className={categoryDropdown}>
                                    <label htmlFor="categoryColor">{R.path(['color'], trans)}</label>
                                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                                    <select
                                        id="categoryColor"
                                        value={this.state.categoryColor}
                                        onChange={this.handle_categoryColor_change}
                                    >
                                        <option value="">{R.path(['allColors'], trans)}</option>
                                        {this.uniqColors.map(function (color, key) {
                                            return (
                                                <option value={color} key={key}>
                                                    {color}
                                                </option>
                                            )
                                        }, this)}
                                    </select>
                                </div>
                            </div>
                            <div className="pure-u-1 pure-u-sm-12-24 pure-u-md-12-24 pure-u-lg-1-4 pure-padded">
                                <div className={categoryDropdown}>
                                    <label htmlFor="categoryType">{R.path(['type'], trans)}</label>
                                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                                    <select
                                        id="categoryType"
                                        value={this.state.categoryType}
                                        onChange={this.handle_categoryType_change}
                                    >
                                        <option value="">{R.path(['allTypes'], trans)}</option>
                                        {this.uniqTypes.map(function (type, key) {
                                            return (
                                                <option value={type} key={key}>
                                                    {type}
                                                </option>
                                            )
                                        }, this)}
                                    </select>
                                </div>
                            </div>
                            <div className="pure-u-1 pure-u-sm-12-24 pure-u-md-12-24 pure-u-lg-1-4 pure-padded">
                                <div className={categoryDropdown}>
                                    <label htmlFor="categoryContains">{R.path(['variety'], trans)}</label>
                                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                                    <select
                                        id="categoryContains"
                                        value={this.state.categoryContains}
                                        onChange={this.handle_categoryContains_change}
                                    >
                                        <option value="">{R.path(['allVarieties'], trans)}</option>
                                        {this.uniqContains.map(function (contains, key) {
                                            return (
                                                <option value={contains} key={key}>
                                                    {contains}
                                                </option>
                                            )
                                        }, this)}
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="pure-u-1 alignCenter bottomMargin">
                            <button
                                className="pure-button pure-button-primary clear__category__filter__button"
                                onClick={this.handle_clear_filters}
                            >
                                {R.path(['emptyParams'], trans)}
                            </button>
                        </div>
                    </div>
                </div>

                <div className="pure-g category__wrapper">
                    {this.state.productsLoaded === true &&
                        this.state.allVisibleItems.map(function (product, key) {
                            return (
                                <ProductCard
                                    loadedStatus={this.loadedStatusHandler}
                                    loadedProducts={this.state.loadedProducts}
                                    product={product}
                                    key={key}
                                    index={key}
                                    lang={lang}
                                    cc={cc}
                                    selectedCountryCode={selectedCountryCode}
                                />
                            )
                        }, this)}
                </div>
                {this.state.productsLoaded === true && <div className="waypoint">{this.renderWaypoint()}</div>}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        products: getVisibleProducts(
            state.products.allProducts,
            state.category.categoryOccasion,
            state.category.categoryColor,
            state.category.categoryContains,
            state.category.categoryType,
            state.category.minPrice,
            state.category.categoryPriceSort,
        ),
        categoryOccasion: state.category.categoryOccasion,
        categoryColor: state.category.categoryColor,
        categoryContains: state.category.categoryContains,
        categoryType: state.category.categoryType,
        categoryPriceSort: state.category.categoryPriceSort,
        allProducts: state.products.allProducts,
        allCountries: state.products.allCountries,
        selectedCountryCode: R.toUpper(state.category.selectedCountryCode),
        cc: R.toLower(R.path(['lang', 'db'], state)),
        lang: R.path(['lang', 'lang'], state),
        storeState: state,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        initiateSearchClick: (
            categoryOccasion,
            categoryColor,
            categoryContains,
            categoryType,
            minPrice,
            categoryPriceSort,
            selectedCountryCode,
        ) =>
            dispatch(
                initiateSearch(
                    categoryOccasion,
                    categoryColor,
                    categoryContains,
                    categoryType,
                    minPrice,
                    categoryPriceSort,
                    selectedCountryCode,
                ),
            ),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Category))
