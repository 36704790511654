import { SHOW_CART_MODAL, HIDE_CART_MODAL } from '../actions/cartModalActions'

const initialState = {
    show: false,
}

export default function modalReducer(state = initialState, action) {
    switch (action.type) {
        case SHOW_CART_MODAL:
            return {
                ...state,
                show: action.show,
            }
        case HIDE_CART_MODAL:
            return {
                ...state,
                show: action.show,
            }
        default:
            return state
    }
}
