import { combineReducers } from 'redux'
import {
    FETCH_PAYMENT_METHODS,
    SELECT_PAYFORM_PAYMENT_METHOD,
    SELECT_PAYFORM_PAYMENT_METHOD_TYPE,
    PAYFORM_PURCHASE,
    CLEAR_PAYFORM,
    REDIRECT_TO_PAYFORM,
    CLEAR_PAYFORM_REDIRECT_URL,
} from '../actions/payformActions'
import { saveState } from '../store/sessionStorage'

const paymentMethods = (state = [], { type, data }) => {
    switch (type) {
        case FETCH_PAYMENT_METHODS + '_SUCCESS':
            return data
        default:
            return state
    }
}

const paymentMethodTypeCreditcardState = 'creditcards'
const paymentMethodTypeBanksState = 'banks'
const paymentMethodCreditcardState = { selected_value: paymentMethodTypeCreditcardState }

const selectPaymentMethod = (state = paymentMethodTypeBanksState, { type, method, method_type }) => {
    switch (type) {
        case SELECT_PAYFORM_PAYMENT_METHOD:
            return method
        case SELECT_PAYFORM_PAYMENT_METHOD_TYPE:
            return method_type === paymentMethodTypeCreditcardState ? paymentMethodCreditcardState : state
        case CLEAR_PAYFORM:
            return {}
        case REDIRECT_TO_PAYFORM:
            saveState(state)
            return state
        default:
            return state
    }
}

const selectedPaymentMethodType = (state = paymentMethodTypeBanksState, { type, method_type }) => {
    switch (type) {
        case SELECT_PAYFORM_PAYMENT_METHOD_TYPE:
            return method_type
        default:
            return state
    }
}

const payformRedirect = (state = '', { type, redirect_url }) => {
    switch (type) {
        case PAYFORM_PURCHASE + '_SUCCESS':
            return redirect_url
        case CLEAR_PAYFORM:
            return ''
        case CLEAR_PAYFORM_REDIRECT_URL:
            return ''
        default:
            return state
    }
}

const purchaseError = (state = null, { type, error }) => {
    switch (type) {
        case PAYFORM_PURCHASE + '_FAILED':
            return error
        default:
            return state
    }
}

export default combineReducers({
    payment_methods: paymentMethods,
    selected: selectPaymentMethod,
    selected_page: selectedPaymentMethodType,
    redirect_url: payformRedirect,
    purchase_error: purchaseError,
})
