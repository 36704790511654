import * as R from 'ramda'
import { getPaymentMethods, postPayformPurchase, postPayformGiftcardPurchase } from '../api/payform'

import { setLoadingIndicatorVisibility } from './loadingIndicatorActions'

export const FETCH_PAYMENT_METHODS = 'FETCH_PAYMENT_METHODS'
export const SELECT_PAYFORM_PAYMENT_METHOD = 'SELECT_PAYFORM_PAYMENT_METHOD'
export const SELECT_PAYFORM_PAYMENT_METHOD_TYPE = 'SELECT_PAYFORM_PAYMENT_METHOD_TYPE'
export const PAYFORM_PURCHASE = 'PAYFORM_PURCHASE'
export const CLEAR_PAYFORM = 'CLEAR_PAYFORM'
export const CLEAR_PAYFORM_REDIRECT_URL = 'CLEAR_PAYFORM_REDIRECT_URL'

export const REDIRECT_TO_PAYFORM = 'REDIRECT_TO_PAYFORM'

export const fetchPaymentMethods = () => {
    return (dispatch) => {
        getPaymentMethods()
            .then((payload) =>
                dispatch({
                    type: FETCH_PAYMENT_METHODS + '_SUCCESS',
                    data: R.path(['payment_methods'], payload),
                }),
            )
            .catch((err) =>
                dispatch({
                    type: FETCH_PAYMENT_METHODS + '_FAILED',
                }),
            )
    }
}

export const selectPaymentMethod = (method) => ({
    type: SELECT_PAYFORM_PAYMENT_METHOD,
    method,
})

export const selectPaymentMethodType = (method_type) => ({
    type: SELECT_PAYFORM_PAYMENT_METHOD_TYPE,
    method_type,
})

export const payformPurchase = (body) => {
    return (dispatch) => {
        postPayformPurchase(body)
            .then((payload) => {
                dispatch(setLoadingIndicatorVisibility(false))
                dispatch({
                    type: PAYFORM_PURCHASE + '_SUCCESS',
                    redirect_url: R.path(['redirect_url'], payload),
                })
            })
            .catch((err) => {
                dispatch(setLoadingIndicatorVisibility(false))
                dispatch({
                    type: PAYFORM_PURCHASE + '_FAILED',
                    error: err,
                })
            })
    }
}

export const payformGiftcardPurchase = (body) => {
    return (dispatch) => {
        postPayformGiftcardPurchase(body)
            .then((payload) => {
                dispatch(setLoadingIndicatorVisibility(false))
                dispatch({
                    type: PAYFORM_PURCHASE + '_SUCCESS',
                    redirect_url: R.path(['redirect_url'], payload),
                })
            })
            .catch((err) => {
                dispatch(setLoadingIndicatorVisibility(false))
                dispatch({
                    type: PAYFORM_PURCHASE + '_FAILED',
                    error: err,
                })
            })
    }
}

export const clearPayform = () => ({
    type: CLEAR_PAYFORM,
})

export const clearPayformRedirectUrl = () => ({
    type: CLEAR_PAYFORM_REDIRECT_URL,
})
export const saveStateToSessionStorage = () => ({
    type: REDIRECT_TO_PAYFORM,
})
