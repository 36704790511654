import * as R from 'ramda'
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { addFormDelivery } from '../../actions/formActions'

import { required } from '../../utils/ValidationRules.jsx'
import Form from 'react-validation/build/form'
import Select from 'react-validation/build/select'

import { getLanguage } from '../../utils/translation'
import { trackCheckoutProcess } from '../../tracking/tracking'
import { isFuneralProduct } from '../../utils/ProductTypes'

import { saveCourierContent } from '../../actions/cartActions'
import BottomNavigationBar from '../bottom-navigation-bar/BottomNavigationBar'

const pageTranslation = {
    fi: {
        page: {
            deliveryRemarks: 'Toimitustiedot lähetykseen',
            specialWishes: 'Erityistoiveet toimitukseen',
            addYourWishes:
                'Voit lisätä toiveita tai lisätietoja kukkakauppiaalle ja toimitukseen, esim. Rapun ovikoodi on 1234.\n\nMikäli tilauksessasi on useita kortteja, voit kertoa tässä, minkä kortin haluat liittää kuhunkin kimppuun.',
            addYourWishesFuneral:
                'Voit lisätä toiveita tai lisätietoja kukkakauppiaalle ja toimitukseen. Mikäli tilauksessasi on useita kortteja, voit kertoa tässä, minkä kortin haluat liittää kuhunkin kimppuun.\n\nOlet tilannut hautajaiskukkia. Kerro kukkakaupalle oletko menossa hautajaisiin itse vai laskeeko joku muu nämä kukat. Kerro laskijan nimi. Ilmoita myös, jos laskijaa ei ole sovittu.\n\nJos olet tilannut hautajaiskukat kotiosoitteeseen, kerro milloin (pvm ja klo) sieltä lähdetään hautajaisiin.',
            contactBeforeDelivery: 'Vastaanottaja tulee tavoittaa ennen toimitusta',
            deliveryDetails: 'Toimitustapa',
            chooseBestOption: 'Valitse sopivin vaihtoehto',
            leaveAtDoor: 'Jätetään ovelle jos vastaanottaja ei ole paikalla',
        },
    },
    en: {
        page: {
            deliveryRemarks: 'Information for the delivery',
            specialWishes: 'Special wishes for the delivery',
            addYourWishes:
                'You can add here your wishes and special information for the flower shop - for example the house door code.\n\nIf your order contains multiple cards, you can specify here which card you want to associate with each bouquet.',
            addYourWishesFuneral:
                'You can add here your wishes and special information for the flower shop. If your order contains multiple cards, you can specify here which card you want to associate with each bouquet.\n\nYou have ordered flowers for a funeral. Please inform us if you are going to the funeral yourself or if someone else will be laying these flowers at the funeral. Tell us the name of the contact person. Also state if the person laying the flowers has not been agreed upon.\n\nIf you have ordered funeral flowers to a home address, tell us when (date and time) they will leave for the funeral.',
            contactBeforeDelivery: 'The recipient must be reached before the delivery',
            deliveryDetails: 'Delivery options',
            chooseBestOption: 'Choose preferred option',
            leaveAtDoor: 'Leave at door if recipient is not at home',
        },
    },
}

class FormDelivery extends Component {
    constructor(props) {
        super(props)
        const { formData } = this.props
        this.state = {
            attachedMessageToCourier: R.path(['attachedMessageToCourier'], formData),
            recipientNotContacted: R.path(['recipientNotContacted'], formData),
            recipientAdvanceNotice: R.path(['recipientAdvanceNotice'], formData),
        }

        this.handle_attachedMessageToCourier_change = this.handle_attachedMessageToCourier_change.bind(this)
        this.handle_recipientNotContacted_change = this.handle_recipientNotContacted_change.bind(this)

        const { storeState } = this.props
        trackCheckoutProcess(storeState, 3, 'Delivery')
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    saveToStore() {
        const { addFormDelivery } = this.props
        const { attachedMessageToCourier, recipientNotContacted, recipientAdvanceNotice } = this.state

        addFormDelivery(attachedMessageToCourier, recipientNotContacted, recipientAdvanceNotice)
    }

    handle_recipientNotContacted_change(event) {
        if (event.target.value === 'call_in_advance')
            this.setState({ recipientAdvanceNotice: true, recipientNotContacted: event.target.value }, this.saveToStore)
        else
            this.setState(
                {
                    recipientAdvanceNotice: false,
                    recipientNotContacted: event.target.value,
                },
                this.saveToStore,
            )
    }

    handle_attachedMessageToCourier_change(event) {
        this.setState({ attachedMessageToCourier: event.target.value }, this.saveToStore)
    }

    submitCourierContent(event) {
        event.preventDefault()
        const { saveCourierContent } = this.props
        saveCourierContent()
    }

    render() {
        const { lang, cc, allProducts, cartProducts, cartLoading, dispatch } = this.props

        const isFuneralOrder = R.find((product) => isFuneralProduct(allProducts, R.prop('id', product)))(cartProducts)

        const selectedTrans = R.path([getLanguage()], pageTranslation)
        const trans = R.path(['page'], selectedTrans)

        return (
            <div className="productOrder__contentHolder">
                <Form
                    ref={(c) => {
                        this.form = c
                    }}
                    className="pure-form pure-form-stacked productOrder__form"
                >
                    <fieldset>
                        <div className="pure-g">
                            <div className="pure-u-20-24">
                                <h2 className="order__headline">{R.path(['deliveryRemarks'], trans)}</h2>
                            </div>
                            <div className="pure-u-4-24 alignRight form__indicator">
                                <span>3/5</span>
                            </div>
                        </div>
                        <div className="input-group form__field">
                            <label htmlFor="attachedMessageToCourier">{R.path(['specialWishes'], trans)}</label>
                            <div className="productOrder__attachedMessage__container">
                                <textarea
                                    maxLength="215"
                                    id="attachedMessageToCourier"
                                    className="productOrder__attachedMessage"
                                    value={this.state.attachedMessageToCourier}
                                    onChange={this.handle_attachedMessageToCourier_change}
                                    placeholder={
                                        isFuneralOrder
                                            ? R.path(['addYourWishesFuneral'], trans)
                                            : R.path(['addYourWishes'], trans)
                                    }
                                ></textarea>
                                <span className="productOrder__attachedMessage__counter">
                                    {this.state.attachedMessageToCourier.length}/215
                                </span>
                            </div>
                        </div>

                        {!isFuneralOrder && (
                            <div className="input-group-dropdown form__field">
                                <label htmlFor="recipientNotContacted">
                                    {R.path(['deliveryDetails'], trans)} <sup>*</sup>
                                </label>
                                <i className="fa fa-angle-down" aria-hidden="true"></i>
                                <Select
                                    name="recipientNotContacted"
                                    placeholder={R.path(['chooseBestOption'], trans)}
                                    value={this.state.recipientNotContacted}
                                    onChange={this.handle_recipientNotContacted_change}
                                    validations={[required]}
                                    lang={lang}
                                    id="FormDelivery-delivery-option-select"
                                >
                                    <option value="">{R.path(['chooseBestOption'], trans)}</option>
                                    <option value="call_in_advance">{R.path(['contactBeforeDelivery'], trans)}</option>
                                    <option value="leave_at_door">{R.path(['leaveAtDoor'], trans)}</option>
                                </Select>
                            </div>
                        )}
                    </fieldset>
                    <BottomNavigationBar
                        backLink={`/${lang}/${cc}/order/card-text`}
                        handleContinue={this.submitCourierContent.bind(this)}
                        loading={cartLoading}
                    />
                </Form>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    storeState: state,
    lang: R.path(['lang', 'lang'], state),
    cc: R.path(['lang', 'db'], state),
    formData: state.form,
    allProducts: state.products.allProducts,
    cartProducts: R.pathOr([], ['cart', 'items', 'products'], state),
    cartLoading: R.path(['cart', 'loading'], state),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    addFormDelivery: (attachedMessageToCourier, recipientNotContacted, recipientAdvanceNotice) =>
        dispatch(addFormDelivery(attachedMessageToCourier, recipientNotContacted, recipientAdvanceNotice)),
    saveCourierContent: () => dispatch(saveCourierContent()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FormDelivery))
