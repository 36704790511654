import { backendFetch, parseJSON } from './fetchUtil'

const invoiceOptions = (body) => ({
    method: 'post',
    mode: 'cors',
    body,
})

export function postInvoicePurchase(body = {}, token) {
    return new Promise((resolve, reject) => {
        backendFetch('/v3/invoice/purchase', invoiceOptions(body), token)
            .then(parseJSON)
            .then((response) => (response.ok ? resolve(response.json) : reject(response)))
            .catch((err) => reject(err))
    })
}

export function postInvoiceGiftcardPurchase(body = {}, token) {
    return new Promise((resolve, reject) => {
        backendFetch('/v3/invoice/giftcard', invoiceOptions(body), token)
            .then(parseJSON)
            .then((response) => (response.ok ? resolve(response.json) : reject(response)))
            .catch((err) => reject(err))
    })
}
