import * as R from 'ramda'

export const loadState = () => {
    try {
        const serializedState = sessionStorage.getItem('interFlora')
        if (serializedState === null) {
            return undefined
        }
        const parsed = JSON.parse(serializedState)

        // ALWAYS REFRESH PRODUCTS
        if (R.path(['products', 'allProducts'], parsed)) parsed.products.allProducts = []

        return parsed
    } catch (err) {
        return undefined
    }
}

export const saveState = (state) => {
    try {
        const serializedState = JSON.stringify(state)
        sessionStorage.setItem('interFlora', serializedState)
    } catch (err) {}
}
