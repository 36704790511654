import { backendFetchV3, success, error } from './fetchUtil'

export function getPaymentMethods() {
    return backendFetchV3('/v3/payform/paymentmethods')
        .then((response) => success(response, 'paymentmethods'))
        .catch((err) => error(err))
}

const payformOptions = (body) => ({
    method: 'post',
    mode: 'cors',
    body,
})

export function postPayformPurchase(body = {}) {
    return backendFetchV3('/v3/payform/purchase', payformOptions(body))
        .then((response) => success(response, 'payformpurchase'))
        .catch((err) => error(err))
}

export function postPayformGiftcardPurchase(body = {}) {
    return backendFetchV3('/v3/payform/giftcard', payformOptions(body))
        .then((response) => success(response, 'payformgiftcard'))
        .catch((err) => error(err))
}
