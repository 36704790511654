import * as R from 'ramda'
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { addFormSenderInfo } from '../../actions/formActions'

import { email, name, phone, required } from '../../utils/ValidationRules.jsx'
import Form from 'react-validation/build/form'
import Input from 'react-validation/build/input'

import { getLanguage } from '../../utils/translation'
import { trackCheckoutProcess } from '../../tracking/tracking'

import { saveSenderInfo } from '../../actions/cartActions'
import { showModal } from '../../actions/modalActions'

import BottomNavigationBar from '../bottom-navigation-bar/BottomNavigationBar'
import { isGiftcardByProductId } from '../../utils/ProductTypes'

const pageTranslation = {
    fi: {
        page: {
            senderInfo: 'Lähettäjän tiedot',
            senderName: 'Tilaajan nimi',
            nameFirstLast: 'Etu- ja sukunimi',
            company: 'Yritys',
            logIn: 'Kirjaudu sisään',
            orOrderWithoutCredentials: ' tai tilaa ilman tunnuksia',
            phoneNumber: 'Puhelinnumero',
            yourPhoneNumber: 'Puhelinnumerosi',
            email: 'Sähköposti',
            yourEmail: 'Sähköpostiosoitteesi',
        },
    },
    en: {
        page: {
            senderInfo: 'Sender details',
            senderName: "Sender's name",
            nameFirstLast: 'First and surname',
            company: 'Company',
            logIn: 'Log in',
            orOrderWithoutCredentials: ' or continue without credentials',
            phoneNumber: 'Phone number',
            yourPhoneNumber: 'Your phone number',
            email: 'Email address',
            yourEmail: 'Your email address',
        },
    },
}

class FormSenderInfo extends Component {
    constructor(props) {
        super(props)
        const { storeState, formData } = this.props
        this.state = {
            senderPhone: R.path(['senderPhone'], formData),
            senderEmail: R.path(['senderEmail'], formData),
            senderName: R.path(['senderName'], formData),
            senderCompany: R.path(['senderCompany'], formData),
            createAccount: R.path(['createAccount'], formData),
        }

        this.handle_senderPhone_change = this.handle_senderPhone_change.bind(this)
        this.handle_senderEmail_change = this.handle_senderEmail_change.bind(this)
        this.handle_senderName_change = this.handle_senderName_change.bind(this)
        this.handle_senderCompany_change = this.handle_senderCompany_change.bind(this)
        this.handle_createAccount_change = this.handle_createAccount_change.bind(this)
        this.validateForm = this.validateForm.bind(this)

        trackCheckoutProcess(storeState, 4, 'Sender Info') // track checkout status
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    saveToStore(event) {
        const { addFormSenderInfo } = this.props
        const { senderPhone, senderEmail, senderName, createAccount, senderCompany } = this.state

        addFormSenderInfo(senderPhone, senderEmail, senderName, createAccount, senderCompany)
    }

    handle_senderPhone_change(event) {
        this.setState({ senderPhone: event.target.value }, this.saveToStore)
    }

    handle_senderEmail_change(event) {
        this.setState({ senderEmail: event.target.value }, this.saveToStore)
    }

    handle_senderName_change(event) {
        this.setState({ senderName: event.target.value }, this.saveToStore)
    }

    handle_senderCompany_change(event) {
        this.setState({ senderCompany: event.target.value }, this.saveToStore)
    }

    handle_createAccount_change(event) {
        const { createAccount } = this.state
        this.setState({ createAccount: !createAccount }, this.saveToStore)
    }

    validateForm(event) {
        event.preventDefault()
        const { saveSenderInfo } = this.props
        saveSenderInfo()
    }

    openModal() {
        const { showModal } = this.props
        showModal(true, 'Interflora account modal', 'account', 'modal__content')
    }

    render() {
        const { lang, cc, cartProducts, cartLoading } = this.props

        const { senderName, senderCompany, senderPhone, senderEmail, createAccount } = this.state

        const giftcard = isGiftcardByProductId(R.prop('id', R.head(cartProducts)))
        const selectedTrans = R.path([getLanguage()], pageTranslation)
        const trans = R.path(['page'], selectedTrans)

        const backLink = giftcard ? `/${lang}/${cc}/order/card-text` : `/${lang}/${cc}/order/delivery`

        return (
            <div className="productOrder__contentHolder">
                <Form className="pure-form pure-form-stacked productOrder__form">
                    <fieldset>
                        <div className="pure-g">
                            <div className="pure-u-20-24">
                                <h2 className="order__headline">{R.path(['senderInfo'], trans)}</h2>
                            </div>
                            <div className="pure-u-4-24 alignRight form__indicator">
                                <span>4/5</span>
                            </div>
                        </div>

                        <div className="line-title alignCenterWithMargin bottomMargin hidden">
                            <a href="#">{R.path(['logIn'], trans)}</a> tai tilaa ilman tunnuksia
                        </div>

                        <div className="input-group form__field">
                            <label htmlFor="senderName">
                                {R.path(['senderName'], trans)} <sup>*</sup>
                            </label>
                            <Input
                                onChange={this.handle_senderName_change}
                                placeholder={R.path(['nameFirstLast'], trans)}
                                id="senderName"
                                errorclassname="is-invalid-input"
                                type="text"
                                containerclassname=""
                                value={senderName}
                                name="senderName"
                                validations={[required, name]}
                                lang={lang}
                            />
                        </div>

                        <div className="input-group form__field">
                            <label htmlFor="senderCompany">{R.path(['company'], trans)} </label>
                            <Input
                                onChange={this.handle_senderCompany_change}
                                placeholder={R.path(['company'], trans)}
                                id="senderCompany"
                                errorclassname="is-invalid-input"
                                type="text"
                                containerclassname=""
                                value={senderCompany}
                                name="senderCompany"
                                validations={[]}
                                lang={lang}
                            />
                        </div>

                        <div className="input-group form__field">
                            <label htmlFor="senderPhone">
                                {R.path(['phoneNumber'], trans)} <sup>*</sup>
                            </label>
                            <Input
                                onChange={this.handle_senderPhone_change}
                                placeholder={R.path(['yourPhoneNumber'], trans)}
                                id="senderPhone"
                                errorclassname="is-invalid-input"
                                type="tel"
                                containerclassname=""
                                value={senderPhone}
                                name="senderPhone"
                                validations={[required, phone]}
                                lang={lang}
                            />
                        </div>

                        <div className="input-group form__field">
                            <label htmlFor="senderEmail">
                                {R.path(['email'], trans)} <sup>*</sup>
                            </label>
                            <Input
                                onChange={this.handle_senderEmail_change}
                                placeholder={R.path(['yourEmail'], trans)}
                                id="senderEmail"
                                errorclassname="is-invalid-input"
                                type="email"
                                containerclassname=""
                                value={senderEmail}
                                name="senderEmail"
                                validations={[required, email]}
                                lang={lang}
                            />
                        </div>
                    </fieldset>
                    <BottomNavigationBar backLink={backLink} handleContinue={this.validateForm} loading={cartLoading} />
                </Form>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    storeState: state,
    lang: R.path(['lang', 'lang'], state),
    cc: R.path(['lang', 'db'], state),
    formData: state.form,
    cartProducts: R.pathOr([], ['cart', 'items', 'products'], state),
    cartLoading: R.path(['cart', 'loading'], state),
})

const mapDispatchToProps = (dispatch) => ({
    dispatch: dispatch,
    addFormSenderInfo: (senderPhone, senderEmail, senderName, createAccount, senderCompany) =>
        dispatch(addFormSenderInfo(senderPhone, senderEmail, senderName, createAccount, senderCompany)),
    showModal: (show, label, modaltype, modalclass, headline, content) =>
        dispatch(showModal(show, label, modaltype, modalclass, headline, content)),
    saveSenderInfo: () => dispatch(saveSenderInfo()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FormSenderInfo))
