import * as R from 'ramda'
import config from '../config'

const canUseDOM = !!(typeof window !== 'undefined' && window.document)

export const POST = 'POST'
export const GET = 'GET'
export const PUT = 'PUT'
export const DELETE = 'DELETE'
export const PATCH = 'PATCH'

export const defaults = {
    method: GET,
    mode: 'cors',
}

const fetchApi = (origin, url, options) => {
    if (R.path(['body'], options)) {
        options.body = JSON.stringify(options.body)
        options.headers = R.assoc('Content-Type', 'application/json', R.path(['headers'], options))
    }

    options.credentials = 'include'

    return new Promise((resolve, reject) => {
        fetch(origin + url, options)
            .then((response) => (response.status >= 400 ? reject(response) : resolve(response)))
            .catch((err) => {
                reject(err)
            })
    })
}

export const backendFetch = (endpoint, options = defaults, token = '') => {
    return fetchApi(canUseDOM ? config.API_URL : config.SSR_API_URL, endpoint, options, token)
}

export const backendFetchV3 = (endpoint, options = defaults, token = '') => {
    return fetchApi(canUseDOM ? config.API_URL_V3 : config.SSR_API_URL_V3, endpoint, options, token)
}

export const success = (response, msg) => {
    if (response.ok) return response.json()
    throw new Error(`Unable to retrieve ${msg}`)
}

export const error = (err) => console.error(err)

export const parseJSON = (response) =>
    new Promise((resolve) => {
        response.json().then((json) =>
            resolve({
                status: response.status,
                ok: response.ok,
                json,
            }),
        )
    })
