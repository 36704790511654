import * as R from 'ramda'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import indexOf from 'lodash/indexOf'

import config from '../../config'
const assets = R.path(['ASSETS'], config)

export default class ProductCard extends Component {
    constructor(props) {
        super(props)

        // If there is no need to check for loaded state, loadedProducts in undefined
        if (props.loadedProducts === undefined) {
            this.state = {
                imageStatus: 'loaded',
            }
        } else {
            if (indexOf(props.loadedProducts, props.index) !== -1) {
                this.state = {
                    imageStatus: 'loaded',
                }
            } else {
                this.state = {
                    imageStatus: null,
                }
            }
        }
    }

    handleImageLoaded() {
        const { loadedStatus, index } = this.props

        if (loadedStatus) {
            loadedStatus(index)
            this.setState({ imageStatus: 'loaded' })
        }
    }

    handleImageErrored() {
        this.setState({ imageStatus: 'failed to load' })
    }

    render() {
        const { product, index, lang, cc } = this.props

        var productCardClass = classNames(
            'productCard',
            { 'pure-u-1': index < 1 },
            { 'pure-u-1-2': index >= 1 },
            { 'pure-u-sm-1': index < 1 },
            { 'pure-u-sm-12-24': index >= 1 },
            { 'pure-u-md-12-24': index < 2 },
            { 'pure-u-md-8-24': index >= 2 },
            { 'pure-u-xl-8-24': index < 3 },
            { 'pure-u-xl-6-24': index >= 3 },
            { 'card-typeone': index === 0 },
            { 'card-typetwo': index === 1 },
            { 'card-typethree': index === 2 },
            { 'card-typefour': index >= 3 },
        )

        var headlineClass = classNames('productCard__productinfo--headline', {
            'productCard__productinfo--headline--first': index === 0,
        })

        var priceClass = classNames('productCard__productinfo--price', {
            'productCard__productinfo--price--first': index === 0,
        })

        var bgImageURL =
            R.path(['countrycode'], product) === 'FI'
                ? `${assets}/thumb_` + R.path(['image'], product)
                : R.path(['image'], product) + '?width=300&height=300'

        var productImageStyle = {
            backgroundImage: 'url(' + bgImageURL + ')',
        }

        return (
            <div
                className={productCardClass}
                id={`product-id-${R.path(['id'], product)}`}
                key={R.path(['id'], product)}
            >
                <Link className="no-decoration" to={`/${lang}/${cc}/product/${R.path(['id'], product)}`}>
                    <div className="productCard__product">
                        <div className="productCard__product--image">
                            <img
                                className="pure-img"
                                src={bgImageURL}
                                onLoad={this.handleImageLoaded.bind(this)}
                                onError={this.handleImageErrored.bind(this)}
                                alt=""
                            />
                        </div>
                        <div className="productCard__product--info" style={productImageStyle}>
                            <div className="productCard__productinfo">
                                <h2 className={headlineClass}>{R.path(['name'], product)}</h2>
                                <div className={priceClass}>
                                    {R.path(['price'], product)}
                                    <span className="productCard__productinfo--price--euro">€</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        )
    }
}
