import * as R from 'ramda'
import { combineReducers } from 'redux'
import {
    CREATE_CART,
    GET_CART,
    SAVE_RECIPIENT_INFO_TO_CART,
    SAVE_RECIPIENT_ZIP_TO_CART,
    SAVE_RECIPIENT_DATE_AND_TIME_TO_CART,
    SAVE_CARD_TEXT_TO_CART,
    SAVE_RIBBON_CONTENT_TO_CART,
    SAVE_CONDOLENCES_CONTENT_TO_CART,
    SAVE_COURIER_CONTENT_TO_CART,
    SAVE_SENDER_INFO_TO_CART,
    SAVE_ADDITIONAL_PRODUCTS_TO_CART,
    REMOVE_ALL_ADDITIONAL_PRODUCTS_FROM_CART,
    SAVE_PRODUCT_TO_CART,
    REMOVE_PRODUCT_FROM_CART,
    SAVE_GIFTCARD_TO_CART,
    SAVE_DELIVERYTERMS_ACCEPTED_TO_CART,
    SAVE_DISCOUNT_CODE_TO_CART,
    SET_CART_STEP_VALUE,
    CLEAR_CART_STEP_VALUES,
    STEP,
} from '../actions/cartActions'

import { HIDE_MODAL } from '../actions/modalActions'

const cart = (state = [], { type, cart }) => {
    switch (type) {
        case CREATE_CART + '_SUCCESS':
            return cart
        case GET_CART + '_SUCCESS':
            return cart
        default:
            return state
    }
}

const creating = (state = false, { type }) => {
    switch (type) {
        case CREATE_CART:
            return true
        case CREATE_CART + '_SUCCESS':
            return false
        case CREATE_CART + '_FAILED':
            return false
        default:
            return state
    }
}

const creation_error = (state = null, { type, error }) => {
    switch (type) {
        case CREATE_CART:
            return null
        case CREATE_CART + '_SUCCESS':
            return null
        case CREATE_CART + '_FAILED':
            return error
        default:
            return state
    }
}

const loading = (state = false, { type }) => {
    switch (type) {
        case CREATE_CART:
            return true
        case CREATE_CART + '_SUCCESS':
            return false
        case CREATE_CART + '_FAILED':
            return false
        case GET_CART:
            return true
        case GET_CART + '_SUCCESS':
            return false
        case GET_CART + '_FAILED':
            return false
        case SAVE_PRODUCT_TO_CART:
            return true
        case SAVE_PRODUCT_TO_CART + '_SUCCESS':
            return false
        case SAVE_PRODUCT_TO_CART + '_FAILED':
            return false
        case REMOVE_PRODUCT_FROM_CART:
            return true
        case REMOVE_PRODUCT_FROM_CART + '_SUCCESS':
            return false
        case REMOVE_PRODUCT_FROM_CART + '_FAILED':
            return false
        case SAVE_ADDITIONAL_PRODUCTS_TO_CART:
            return true
        case SAVE_ADDITIONAL_PRODUCTS_TO_CART + '_SUCCESS':
            return false
        case SAVE_ADDITIONAL_PRODUCTS_TO_CART + '_FAILED':
            return false
        case REMOVE_ALL_ADDITIONAL_PRODUCTS_FROM_CART:
            return true
        case REMOVE_ALL_ADDITIONAL_PRODUCTS_FROM_CART + '_SUCCESS':
            return false
        case REMOVE_ALL_ADDITIONAL_PRODUCTS_FROM_CART + '_FAILED':
            return false
        case SAVE_GIFTCARD_TO_CART:
            return true
        case SAVE_GIFTCARD_TO_CART + '_SUCCESS':
            return false
        case SAVE_GIFTCARD_TO_CART + '_FAILED':
            return false
        case SAVE_RECIPIENT_INFO_TO_CART:
            return true
        case SAVE_RECIPIENT_INFO_TO_CART + '_SUCCESS':
            return false
        case SAVE_RECIPIENT_INFO_TO_CART + '_FAILED':
            return false
        case SAVE_RECIPIENT_ZIP_TO_CART:
            return true
        case SAVE_RECIPIENT_ZIP_TO_CART + '_SUCCESS':
            return false
        case SAVE_RECIPIENT_ZIP_TO_CART + '_FAILED':
            return false
        case SAVE_RECIPIENT_DATE_AND_TIME_TO_CART:
            return true
        case SAVE_RECIPIENT_DATE_AND_TIME_TO_CART + '_SUCCESS':
            return false
        case SAVE_RECIPIENT_DATE_AND_TIME_TO_CART + '_FAILED':
            return false
        case SAVE_CARD_TEXT_TO_CART:
            return true
        case SAVE_CARD_TEXT_TO_CART + '_SUCCESS':
            return false
        case SAVE_CARD_TEXT_TO_CART + '_FAILED':
            return false
        case SAVE_RIBBON_CONTENT_TO_CART:
            return true
        case SAVE_RIBBON_CONTENT_TO_CART + '_SUCCESS':
            return false
        case SAVE_RIBBON_CONTENT_TO_CART + '_FAILED':
            return false
        case SAVE_CONDOLENCES_CONTENT_TO_CART:
            return true
        case SAVE_CONDOLENCES_CONTENT_TO_CART + '_SUCCESS':
            return false
        case SAVE_CONDOLENCES_CONTENT_TO_CART + '_FAILED':
            return false
        case SAVE_COURIER_CONTENT_TO_CART:
            return true
        case SAVE_COURIER_CONTENT_TO_CART + '_SUCCESS':
            return false
        case SAVE_COURIER_CONTENT_TO_CART + '_FAILED':
            return false
        case SAVE_SENDER_INFO_TO_CART:
            return true
        case SAVE_SENDER_INFO_TO_CART + '_SUCCESS':
            return false
        case SAVE_SENDER_INFO_TO_CART + '_FAILED':
            return false
        case SAVE_DELIVERYTERMS_ACCEPTED_TO_CART:
            return true
        case SAVE_DELIVERYTERMS_ACCEPTED_TO_CART + '_SUCCESS':
            return false
        case SAVE_DELIVERYTERMS_ACCEPTED_TO_CART + '_FAILED':
            return false
        case SAVE_DISCOUNT_CODE_TO_CART:
            return true
        case SAVE_DISCOUNT_CODE_TO_CART + '_SUCCESS':
            return false
        case SAVE_DISCOUNT_CODE_TO_CART + '_FAILED':
            return false
        default:
            return state
    }
}

const error = (state = '', { type }) => {
    switch (type) {
        case CREATE_CART:
            return ''
        case CREATE_CART + '_FAILED':
            return 'Creating cart failed.'
        case SAVE_PRODUCT_TO_CART:
            return ''
        case SAVE_PRODUCT_TO_CART + '_FAILED':
            return 'Saving product to cart failed.'
        case REMOVE_PRODUCT_FROM_CART:
            return ''
        case REMOVE_PRODUCT_FROM_CART + '_FAILED':
            return 'Removing product from cart failed.'
        case SAVE_ADDITIONAL_PRODUCTS_TO_CART:
            return ''
        case SAVE_ADDITIONAL_PRODUCTS_TO_CART + '_FAILED':
            return 'Saving additional products to cart failed.'
        case REMOVE_ALL_ADDITIONAL_PRODUCTS_FROM_CART:
            return ''
        case REMOVE_ALL_ADDITIONAL_PRODUCTS_FROM_CART + '_FAILED':
            return 'Removing all additional product from cart failed.'
        case SAVE_GIFTCARD_TO_CART:
            return ''
        case SAVE_GIFTCARD_TO_CART + '_FAILED':
            return 'Saving giftcard values to cart failed.'
        case SAVE_RECIPIENT_INFO_TO_CART:
            return ''
        case SAVE_RECIPIENT_INFO_TO_CART + '_FAILED':
            return 'Saving recipient info to cart failed.'
        case SAVE_RECIPIENT_ZIP_TO_CART:
            return ''
        case SAVE_RECIPIENT_ZIP_TO_CART + '_FAILED':
            return 'Saving recipient zip to cart failed.'
        case SAVE_RECIPIENT_DATE_AND_TIME_TO_CART:
            return ''
        case SAVE_RECIPIENT_DATE_AND_TIME_TO_CART + '_FAILED':
            return 'Saving recipient date and time to cart failed.'
        case SAVE_CARD_TEXT_TO_CART:
            return ''
        case SAVE_CARD_TEXT_TO_CART + '_FAILED':
            return 'Saving card text to cart failed.'
        case SAVE_RIBBON_CONTENT_TO_CART:
            return ''
        case SAVE_RIBBON_CONTENT_TO_CART + '_FAILED':
            return 'Saving ribbon content to cart failed.'
        case SAVE_CONDOLENCES_CONTENT_TO_CART:
            return ''
        case SAVE_CONDOLENCES_CONTENT_TO_CART + '_FAILED':
            return 'Saving condolences content to cart failed.'
        case SAVE_COURIER_CONTENT_TO_CART:
            return ''
        case SAVE_COURIER_CONTENT_TO_CART + '_FAILED':
            return 'Saving courier content to cart failed.'
        case SAVE_SENDER_INFO_TO_CART:
            return ''
        case SAVE_SENDER_INFO_TO_CART + '_FAILED':
            return 'Saving sender info to cart failed.'
        case SAVE_DELIVERYTERMS_ACCEPTED_TO_CART:
            return ''
        case SAVE_DELIVERYTERMS_ACCEPTED_TO_CART + '_FAILED':
            return 'Saving delivery terms accepted to cart failed.'
        default:
            return state
    }
}

const discount_success_modal = (state = false, { type, error }) => {
    switch (type) {
        case SAVE_DISCOUNT_CODE_TO_CART + '_SUCCESS':
            return true
        case HIDE_MODAL:
            return false
        default:
            return state
    }
}

const discount_error = (state = null, { type, error }) => {
    switch (type) {
        case GET_CART + '_SUCCESS':
            return null
        case SAVE_DISCOUNT_CODE_TO_CART + '_FAILED':
            return error
        default:
            return state
    }
}

const discount_error_code = (state = null, { type, code }) => {
    switch (type) {
        case GET_CART + '_SUCCESS':
            return null
        case SAVE_DISCOUNT_CODE_TO_CART + '_FAILED':
            return code
        default:
            return state
    }
}

const checking_discount = (state = false, { type }) => {
    switch (type) {
        case SAVE_DISCOUNT_CODE_TO_CART + '_SENT':
            return true
        case SAVE_DISCOUNT_CODE_TO_CART + '_SUCCESS':
        case SAVE_DISCOUNT_CODE_TO_CART + '_FAILED':
        case GET_CART + '_SUCCESS':
            return false
        default:
            return state
    }
}

const stepsDefaultState = {
    product: STEP.NOT_READY,
    giftcard: STEP.NOT_READY,
    recipientInfo: STEP.NOT_READY,
    recipientZip: STEP.NOT_READY,
    recipientDateAndTime: STEP.NOT_READY,
    senderInfo: STEP.NOT_READY,
    deliveryTermsAccepted: STEP.NOT_READY,
}

const steps = (state = stepsDefaultState, { type, step, value }) => {
    switch (type) {
        case SET_CART_STEP_VALUE:
            return R.assoc(step, value, state)
        case CLEAR_CART_STEP_VALUES:
            return stepsDefaultState
        default:
            return state
    }
}

export default combineReducers({
    items: cart,
    creating,
    creation_error,
    loading,
    error,
    discount_error_code,
    discount_success_modal,
    discount_error,
    checking_discount,
    steps,
})
