import { fetchLandingContent } from '../api/backend'

export const REQUEST_LANDING_CONTENT = 'REQUEST_LANDING_CONTENT'
export const LOADING_LANDING_CONTENT = 'LOADING_LANDING_CONTENT'

const loadingStartLandingContent = () => {
    return {
        type: LOADING_LANDING_CONTENT,
    }
}

export const getLandingContent = () => (dispatch) => {
    dispatch(loadingStartLandingContent())
    return fetchLandingContent()
        .then((result) =>
            dispatch({
                type: REQUEST_LANDING_CONTENT + '_SUCCESS',
                content: result,
            }),
        )
        .catch((err) => dispatch({ type: LOADING_LANDING_CONTENT + '_FAILED', err }))
}
