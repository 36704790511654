import * as R from 'ramda'
import React from 'react'
import validator from 'validator'

export const required = (value, props) => {
    const { lang } = props
    const fiLang = lang === 'fi'
    if (!value.toString().trim().length) {
        // We can return string or jsx as the 'error' prop for the validated Component
        return <span className="form-error is-visible">{fiLang ? 'Pakollinen tieto.' : 'Required field.'}</span>
    }
}

export const name = (value, props) => {
    const { lang } = props
    const fiLang = lang === 'fi'
    if (!validator.isLength(value, { min: 3, max: 50 })) {
        return (
            <span className="form-error is-visible">{fiLang ? 'Enintään 50 merkkiä.' : 'Maximum of 50 letters.'}</span>
        )
    }
}

export const alpha = (value, props) => {
    const { lang } = props
    const fiLang = lang === 'fi'
    if (!validator.isAlpha(value)) {
        return (
            <span className="form-error is-visible">
                {fiLang ? 'Käytäthän vain isoja ja/tai pieniä kirjaimia.' : 'Please only use alphabetic letters.'}
            </span>
        )
    }
}

export const numeric = (value, props) => {
    const { lang } = props
    const fiLang = lang === 'fi'
    if (!validator.isNumeric(value)) {
        return (
            <span className="form-error is-visible">
                {fiLang ? 'Käytäthän vain numeroita.' : 'Please only use numbers.'}
            </span>
        )
    }
}

export const phone = (value, props) => {
    const { lang } = props
    const fiLang = lang === 'fi'
    if (!validator.matches(value, /^(\+?|0|1|2|3|4|5|6|7|8|9)[ -]?(\d[ -]?){0,50}\d$/)) {
        return (
            <span className="form-error is-visible">
                {fiLang
                    ? 'Puhelinnumeron muoto ei ole oikea. Käytäthän vain numeroita.'
                    : 'Phone number format is not correct. Please only use numbers.'}
            </span>
        )
    } else if (!validator.isLength(value, { min: 4, max: 20 })) {
        return (
            <span className="form-error is-visible">
                {fiLang
                    ? 'Puhelinnumeron muoto ei ole oikea. Tarkista puhelinumeron pituus.'
                    : 'Phone number format is not correct. Please check the length.'}
            </span>
        )
    }
}

export const emoji = (value, props) => {
    const { lang } = props
    const fiLang = lang === 'fi'
    if (
        validator.matches(
            value,
            /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g,
        )
    ) {
        return (
            <span className="form-error is-visible">
                {fiLang
                    ? 'Poista EMOJIT, niin voit jatkaa. Ne eivät näy oikein toimitettavassa kortissa.'
                    : 'Please remove EMOJIS to continue. They won’t appear correctly on the card.'}
            </span>
        )
    }
}

export const has7LineBreaks = (value, props) => {
    const { lang } = props
    const fiLang = lang === 'fi'
    if (validator.matches(value, /(.*)\n(.*)\n(.*)\n(.*)\n(.*)\n(.*)\n(.*)\n(.*)\n(.*)/)) {
        return (
            <span className="form-error is-visible">
                {fiLang
                    ? 'Huom! Maks. 8 riviä. Vähennä rivinvaihtoja, niin voit jatkaa.'
                    : 'Max. 8 lines! Reduce line breaks to continue.'}
            </span>
        )
    }
}

export const time = (value, props) => {
    const { lang } = props
    const fiLang = lang === 'fi'
    if (!validator.matches(value, /^(0[0-9]|1[0-9]|2[0-3])[:.][0-5][0-9]$/)) {
        return (
            <span className="form-error is-visible">
                {fiLang ? 'Käytä aikamuotoa HH.MM' : 'Please use format HH.MM'}
            </span>
        )
    }
}

export const zip = (value, props) => {
    const { lang } = props
    const fiLang = lang === 'fi'
    const strippedValues = R.replace(/ /g, '', value)
    if (!validator.matches(strippedValues, /^(\d){4}\d$/)) {
        return (
            <span className="form-error is-visible">
                {fiLang
                    ? 'Postinumero ei ole oikeaa muotoa. Tarkista postinumeron pituus.'
                    : 'Zip code format is not correct. Please check the length.'}
            </span>
        )
    }
}

export const intZip = (value, props) => {
    const { lang } = props
    const fiLang = lang === 'fi'
    if (!validator.isLength(value, { min: 1 })) {
        return (
            <span className="form-error is-visible">
                {fiLang ? 'Postinumero ei ole oikeaa muotoa.' : 'Zip code format is not correct.'}
            </span>
        )
    }
}

export const validate_zip = (value, props) => {
    const { lang } = props
    const fiLang = lang === 'fi'
    if (!R.isNil(R.path(['data'], props))) {
        return (
            <span className="form-error is-visible">{`${
                fiLang
                    ? 'Postinumerolle ' + value + ' toimitus ei ole mahdollinen.'
                    : ' Delivery to zip code ' + value + ' is not available.'
            }`}</span>
        )
    }
}

export const email = (value, props) => {
    const { lang } = props
    const fiLang = lang === 'fi'
    if (!validator.isEmail(value)) {
        return (
            <span className="form-error is-visible">{`${
                fiLang ? value + ' ei ole sähköpostiosoite' : value + ' is not an email address'
            }`}</span>
        )
    }
}

export const card_validator = (value, props) => validator.isLength(value, { min: 15 })
export const card = (value, props) => {
    const { lang } = props
    const fiLang = lang === 'fi'
    if (!card_validator(value)) {
        return (
            <span className="form-error is-visible">
                {fiLang ? 'Täytä luottokortin numero' : 'Please fill in credti card number'}
            </span>
        )
    }
}

export const cardvalid_validator_unformatted = (value, props) => validator.isLength(value, { min: 4, max: 4 })
const cardvalid_validator = (value, props) => validator.isLength(value, { min: 7, max: 7 })
export const cardvalid = (value, props) => {
    const { lang } = props
    const fiLang = lang === 'fi'
    if (!cardvalid_validator(value)) {
        return (
            <span className="form-error is-visible">
                {fiLang ? 'Täytä voimassaolo' : 'Please fill in expiration date'}
            </span>
        )
    }
}

export const cvc_validator = (value, props) => validator.isLength(value, { min: 3 })
export const cvc = (value, props) => {
    const { lang } = props
    const fiLang = lang === 'fi'
    if (!cvc_validator(value)) {
        return (
            <span className="form-error is-visible">
                {fiLang ? 'Täytä tarkistusnumero' : 'Please fill in cvc number'}
            </span>
        )
    }
}

export const passwordsEqual = (value, props, components) => {
    const passwordComponent = R.head(R.path(['resetPassword'], components))
    const passwordConfirmComponent = R.head(R.path(['resetPasswordConfirm'], components))

    const password = R.path(['value'], passwordComponent)
    const passwordConfirm = R.path(['value'], passwordConfirmComponent)
    const { lang } = props
    const fiLang = lang === 'fi'
    /*
  const isBothUsed = password && passwordConfirm && password.isUsed && passwordConfirm.isUsed
  const isBothChanged = isBothUsed && password.isChanged && passwordConfirm.isChanged
  */
    if (password !== passwordConfirm) {
        return (
            <span className="form-error is-visible">
                {fiLang ? 'Salasanojen tulee olla yhtäläiset' : 'Passwords must match'}
            </span>
        )
    }
}
