import * as R from 'ramda'

// First one is the default language
const supportedLanguages = ['fi', 'en']

export const getPathnameArray = (pathname = '') => R.split('/', pathname)

export const isFullPath = (pathname) => R.length(R.without([''], R.split('/', pathname))) >= 2

export const getDefaultLanguage = () => R.head(supportedLanguages)

export const isSupportedLanguage = (language = '') => supportedLanguages.indexOf(R.toLower(language)) > -1

export const getLanguageFromLocation = (location) =>
    R.or(getPathnameArray(R.path(['pathname'], location))[1], getDefaultLanguage())

export const getDBFromLocation = (location) => {
    const pickedPath = getPathnameArray(R.path(['pathname'], location))[2]
    return R.length(pickedPath) === 2 ? pickedPath : getDefaultLanguage()
}

export const getLanguage = (lang) => (isSupportedLanguage(lang) ? lang : getDefaultLanguage())
