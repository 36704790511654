import * as R from 'ramda'
import { getDeliveryCards } from '../api/backend'

export const REQUEST_DELIVERY_CARDS = 'REQUEST_DELIVERY_CARDS'
export const LOADING_START_DELIVERY_CARDS = 'LOADING_START_DELIVERY_CARDS'
export const REQUEST_DELIVERY_CARD_CONTENT = 'LOADING_START_DELIVERY_CARDS'
export const UPDATE_SELECTED_CARDS = 'UPDATE_SELECTED_CARDS'

const loadingStartDeliveryCards = () => {
    return {
        type: LOADING_START_DELIVERY_CARDS,
    }
}

export const getAllDeliveryCards = () => (dispatch, getState) => {
    dispatch(loadingStartDeliveryCards())
    const { lang } = getState()
    const translation = R.path(['lang'], lang)

    return getDeliveryCards(translation)
        .then((results) =>
            dispatch({
                type: REQUEST_DELIVERY_CARDS + '_SUCCESS',
                deliveryCards: results,
            }),
        )
        .catch((err) =>
            dispatch({
                type: REQUEST_DELIVERY_CARDS + '_FAILED',
                err,
            }),
        )
}

export const updateSelectedCards = (selectedCards) => {
    return {
        type: UPDATE_SELECTED_CARDS,
        selectedCards,
    }
}

export const getAllDeliveryCardContent = (isLoadingDeliveryCards, allDeliveryCards, errorDeliveryCards) => ({
    type: REQUEST_DELIVERY_CARDS,
    isLoadingDeliveryCards: isLoadingDeliveryCards,
    allDeliveryCards: allDeliveryCards,
    errorDeliveryCards: errorDeliveryCards,
})
