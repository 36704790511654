import * as R from 'ramda'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'

class PayformIcon extends Component {
    render() {
        const { imgSrc, onClick, group, name, selected } = this.props
        const isSelected = name === R.path(['name'], selected)
        const isBankOrWallet = group === 'banks' || group === 'wallets'

        const wrapperStyle = classNames(
            'payform__icon__wrapper',
            isBankOrWallet ? 'bankStyles' : '',
            isSelected ? 'selected' : '',
        )

        const cn = classNames('payform__icon')

        return (
            <div
                className={wrapperStyle}
                onClick={(event) => (isBankOrWallet ? onClick(event) : {})}
                id={`payform-icon-${name}`}
            >
                <div className={cn}>
                    <img className="" src={R.toLower(imgSrc)} alt={name} />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    selected: R.path(['payform', 'selected'], state),
})

export default connect(mapStateToProps, undefined)(PayformIcon)
