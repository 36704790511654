import * as R from 'ramda'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { initiateSearch } from '../../actions/categoryActions'
import { Link } from 'react-router-dom'

import config from '../../config'
const assets = R.path(['ASSETS'], config)

const pageTranslation = {
    fi: {
        page: {
            category: 'Valikoima',
            giftCard: 'Lahjakortti',
            articles: 'Tietoa',
            addOns: 'Lisätuotteet',
            faq: 'Usein kysyttyä',
            customerService: 'Asiakaspalvelu',
            aboutFlowers: 'Tietoa kukista',
            colorSymbolic: 'Värien merkitys',
            flowerSymbolic: 'Kukkien merkitys',
            careTaking: 'Hoito-ohjeet',
            seasons: 'Sesongit',
            guaranty: 'Laatutakuu',
            termsOfDelivery: 'Toimitusehdot',
            orderAndDelivery: 'Tilaus ja toimitus',
            privacyPolicy: 'Tietosuojaseloste',
            becomeMember: 'Jäseneksi Interfloraan',
            aboutInterflora: 'Tietoa Interflorasta',
            history: 'Historia',
            paymentMethods: 'Maksutavat',
            internationalDeliveries: 'Kukkalähetys ulkomaille',
            businessService: 'Yrityspalvelu',
        },
    },
    en: {
        page: {
            category: 'Selection',
            giftCard: 'Floral cheque',
            articles: 'Information',
            addOns: 'Additional products',
            faq: 'FAQ',
            customerService: 'Customer service',
            aboutFlowers: 'Floral knowledge',
            colorSymbolic: 'Color symbolics',
            flowerSymbolic: 'Flower symbolics',
            careTaking: 'Nourish for your flowers',
            seasons: 'Seasons',
            guaranty: 'Interflora guaranty',
            termsOfDelivery: 'Delivery terms',
            orderAndDelivery: 'Order and delivery',
            privacyPolicy: 'Privacy policy',
            becomeMember: 'Become a member',
            aboutInterflora: 'About Interflora',
            history: 'History of Interflora',
            paymentMethods: 'Payment methods',
            internationalDeliveries: 'International deliveries',
            businessService: 'Business service',
        },
    },
}

const logoStyle_interfloraRunner = {
    backgroundImage: `url(${assets}/interflora-logo.svg)`,
}

const iconStyle_mail = {
    backgroundImage: `url(${assets}/icon-mail.svg)`,
}

const iconStyle_phone = {
    backgroundImage: `url(${assets}/icon-phone.svg)`,
}

const socialLinks = [
    {
        name: 'facebook',
        href: 'https://www.facebook.com/interflorasuomi/',
        img: { backgroundImage: `url(${assets}/icon-facebook.svg)` },
    },
    {
        name: 'instagram',
        href: 'https://instagram.com/interflora_fi',
        img: { backgroundImage: `url(${assets}/icon-instagram.svg)` },
    },
    {
        name: 'youtube',
        href: 'https://www.youtube.com/channel/UCSwYriuMo7obC_deEV_IPMQ',
        img: { backgroundImage: `url(${assets}/icon-youtube.svg)` },
    },
]

class Footer extends Component {
    constructor(props, context) {
        super(props, context)
    }

    handle__clearFilters() {
        const { initiateSearchClick, cc } = this.props
        /* Clear filters from store before proceeding */
        initiateSearchClick('', '', '', '', '', '', cc.toUpperCase())
    }

    render() {
        const { lang, cc } = this.props
        const selectedTrans = R.path([lang], pageTranslation)
        const trans = R.path(['page'], selectedTrans)

        return (
            <div className="footer__container">
                <div className="pure-g">
                    <div className="pure-u-1 pure-u-md-1-4 pure-u-lg-1-4">
                        <div className="footer__menu pure-menu">
                            <ul className="pure-menu-list">
                                <li className="pure-menu-item">
                                    <Link to={`/${lang}/${cc}/category`} className="footer__menu--link pure-menu-link">
                                        {R.path(['category'], trans)}
                                    </Link>
                                </li>
                                <li className="pure-menu-item">
                                    <Link
                                        to={`/${lang}/fi/product/1001`}
                                        onClick={this.handle__clearFilters.bind(this)}
                                        className="footer__menu--link pure-menu-link"
                                    >
                                        {R.path(['giftCard'], trans)}
                                    </Link>
                                </li>
                                <li className="pure-menu-item">
                                    <a
                                        href={`/${lang}/${cc}/${lang === 'fi' ? 'tietoa' : 'content'}`}
                                        className="footer__menu--link pure-menu-link"
                                    >
                                        {R.path(['articles'], trans)}
                                    </a>
                                </li>
                                <li className="pure-menu-item">
                                    <a
                                        href={
                                            lang === 'fi'
                                                ? `/fi/${cc}/tietoa/tilaus-ja-toimitus/asiakaspalvelu`
                                                : `/en/${cc}/content/order-and-delivery/customer-service`
                                        }
                                        className="footer__menu--link pure-menu-link"
                                    >
                                        {R.path(['customerService'], trans)}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="pure-u-1 pure-u-md-1-4 pure-u-lg-1-4">
                        <div className="footer__menu pure-menu">
                            <ul className="pure-menu-list">
                                <li className="pure-menu-item">
                                    <a
                                        href={
                                            lang === 'fi'
                                                ? `/fi/${cc}/tietoa/tilaus-ja-toimitus/usein-kysyttya`
                                                : `/en/${cc}/content/order-and-delivery/faq`
                                        }
                                        className="footer__menu--link pure-menu-link"
                                    >
                                        {R.path(['faq'], trans)}
                                    </a>
                                </li>
                                {lang === 'fi' && (
                                    <React.Fragment>
                                        <li className="pure-menu-item">
                                            <a
                                                href={`/fi/${cc}/tietoa/tilaus-ja-toimitus/tilaus-ja-toimitus`}
                                                className="footer__menu--link pure-menu-link"
                                            >
                                                {R.path(['orderAndDelivery'], trans)}
                                            </a>
                                        </li>
                                        <li className="pure-menu-item">
                                            <a
                                                href={`/fi/${cc}/tietoa/tilaus-ja-toimitus/maksutavat`}
                                                className="footer__menu--link pure-menu-link"
                                            >
                                                {R.path(['paymentMethods'], trans)}
                                            </a>
                                        </li>
                                        <li className="pure-menu-item">
                                            <a
                                                href={`/fi/${cc}/tietoa/tilaus-ja-toimitus/laatutakuu`}
                                                className="footer__menu--link pure-menu-link"
                                            >
                                                {R.path(['guaranty'], trans)}
                                            </a>
                                        </li>
                                    </React.Fragment>
                                )}
                            </ul>
                        </div>
                    </div>
                    <div className="pure-u-1 pure-u-md-1-4 pure-u-lg-1-4">
                        <div className="footer__menu pure-menu">
                            <ul className="pure-menu-list">
                                <li className="pure-menu-item">
                                    <a
                                        href={
                                            lang === 'fi'
                                                ? `/fi/${cc}/tietoa/tilaus-ja-toimitus/toimitusehdot`
                                                : `/en/${cc}/content/order-and-delivery/terms-of-delivery`
                                        }
                                        className="footer__menu--link pure-menu-link"
                                    >
                                        {R.path(['termsOfDelivery'], trans)}
                                    </a>
                                </li>
                                <li className="pure-menu-item">
                                    <a
                                        href={
                                            lang === 'fi'
                                                ? `/fi/${cc}/tietoa/tilaus-ja-toimitus/kukkalahetys-ulkomaille`
                                                : `/en/${cc}/content/order-and-delivery/international-deliveries`
                                        }
                                        className="footer__menu--link pure-menu-link"
                                    >
                                        {R.path(['internationalDeliveries'], trans)}
                                    </a>
                                </li>
                                {lang === 'fi' && (
                                    <React.Fragment>
                                        <li className="pure-menu-item">
                                            <a
                                                href={`/fi/${cc}/tietoa/tietoa-interflorasta/historia`}
                                                className="footer__menu--link pure-menu-link"
                                            >
                                                {R.path(['history'], trans)}
                                            </a>
                                        </li>
                                        <li className="pure-menu-item">
                                            <a
                                                href={`/fi/${cc}/tietoa/tilaus-ja-toimitus/tietosuojaseloste`}
                                                className="footer__menu--link pure-menu-link"
                                            >
                                                {R.path(['privacyPolicy'], trans)}
                                            </a>
                                        </li>
                                    </React.Fragment>
                                )}
                            </ul>
                        </div>
                    </div>

                    <div className="pure-u-1 pure-u-md-1-4 pure-u-lg-1-4">
                        <div className="footer__menu pure-menu">
                            <ul className="pure-menu-list">
                                {lang === 'fi' && (
                                    <React.Fragment>
                                        <li className="pure-menu-item">
                                            <a
                                                href={`/fi/${cc}/tietoa/tilaus-ja-toimitus/yrityspalvelu`}
                                                className="footer__menu--link pure-menu-link"
                                            >
                                                {R.path(['businessService'], trans)}
                                            </a>
                                        </li>
                                        <li className="pure-menu-item">
                                            <a
                                                href={`/fi/${cc}/tietoa/tietoa-interflorasta/jaseneksi-interfloraan`}
                                                className="footer__menu--link pure-menu-link"
                                            >
                                                {R.path(['becomeMember'], trans)}
                                            </a>
                                        </li>
                                    </React.Fragment>
                                )}
                                <li className="pure-menu-item">
                                    <a
                                        href={
                                            lang === 'fi'
                                                ? `/fi/${cc}/tietoa/tilaus-ja-toimitus/lisatuotteet`
                                                : `/en/${cc}/content/order-and-delivery/additional-products`
                                        }
                                        className="footer__menu--link pure-menu-link"
                                    >
                                        {R.path(['addOns'], trans)}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="pure-g footer__bottom-logo-area">
                    <div className="pure-u-1 pure-hidden-tablet pure-hidden-desktop">
                        <a href="#" className="footer__interfloraLogo">
                            <div className="footer__interfloraLogoContainer">
                                <div
                                    className="footer__icon--interfloraRunner"
                                    style={logoStyle_interfloraRunner}
                                ></div>
                            </div>
                        </a>
                    </div>
                    <div className="pure-u-1 pure-u-md-1-3 footer__icons">
                        <div className="footer__icons--container">
                            <div className="footer__menu--icons pure-menu pure-menu-horizontal">
                                <ul className="pure-menu-list">
                                    <li className="pure-menu-item">
                                        <a
                                            href="tel://0102176320"
                                            className="footer__menu--link footer__link--centered footer__link--phone pure-menu-link"
                                        >
                                            <div className="footer__iconimagecontainer--phone">
                                                <div
                                                    className="footer__icon footer__icon--phone"
                                                    style={iconStyle_phone}
                                                ></div>
                                            </div>
                                            <span className="footer__phone__link pure-hidden-tablet pure-hidden-phone pure-hidden-smallphone">
                                                0102176320
                                            </span>
                                        </a>
                                    </li>
                                    <li className="pure-menu-item">
                                        <a
                                            href="mailto:info@interflora.fi"
                                            className="footer__link--centered pure-menu-link"
                                        >
                                            <div className="footer__iconimagecontainer--mail">
                                                <div
                                                    className="footer__icon footer__icon--mail"
                                                    style={iconStyle_mail}
                                                ></div>
                                            </div>
                                        </a>
                                    </li>
                                    {socialLinks.map(({ href, img, name }) => (
                                        <li className="pure-menu-item" key={href}>
                                            <a
                                                href={href}
                                                target="_blank"
                                                className="footer__link--centered pure-menu-link"
                                            >
                                                <div className={`footer__iconimagecontainer--${name}`}>
                                                    <div
                                                        className={`footer__icon footer__icon--${name}`}
                                                        style={img}
                                                    ></div>
                                                </div>
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="pure-u-1-3 pure-hidden-phone">{/* EMPTY GRID HELPER AREA, CENTER */}</div>
                    <div className="pure-u-1 pure-u-md-1-3 pure-hidden-smallphone pure-hidden-phone">
                        <Link to={`/${lang}/${cc}`} className="footer__interfloraLogo">
                            <div className="footer__interfloraLogoContainer">
                                <div
                                    className="footer__icon--interfloraRunner"
                                    style={logoStyle_interfloraRunner}
                                ></div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    initiateSearchClick: (
        categoryOccasion,
        categoryColor,
        categoryContains,
        categoryType,
        minPrice,
        categoryPriceSort,
        selectedCountryCode,
    ) =>
        dispatch(
            initiateSearch(
                categoryOccasion,
                categoryColor,
                categoryContains,
                categoryType,
                minPrice,
                categoryPriceSort,
                selectedCountryCode,
            ),
        ),
})

export default connect(null, mapDispatchToProps)(Footer)
