import { SET_VISIBILITY } from '../actions/loadingIndicatorActions'

const initialState = {
    show: false,
}

export default function loadingIndicatorReducer(state = initialState, action) {
    switch (action.type) {
        case SET_VISIBILITY:
            return {
                ...state,
                show: action.show,
            }
        default:
            return state
    }
}
