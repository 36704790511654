import * as R from 'ramda'
import React, { useState, useEffect, useRef } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import {
    addFormRecipientInfo,
    clearFuneralData,
    clearCompanyAndRecipientNotContactedData,
} from '../../actions/formActions'

import { addProductToGTMcart } from '../../actions/orderActions'
import { getZipcode, clearZipcode, clearZipcodeError } from '../../actions/zipcodeActions'
import moment from 'moment-timezone'
import Datepicker from '../../components/datepicker/datepicker'
import { required, name, phone, time, zip, intZip, validate_zip, numeric } from '../../utils/ValidationRules.jsx'
import Form from 'react-validation/build/form'
import Input from 'react-validation/build/input'
import BottomNavigationBar from '../bottom-navigation-bar/BottomNavigationBar'

import { isFuneralProduct, isGiftcardByProductId } from '../../utils/ProductTypes'
import { getLanguage, getLocale } from '../../utils/translation'
import { saveRecipientData } from '../../actions/cartActions'
import { getZipcodeCalendarForDatePicker } from '../../utils/zipcodes.js'

import {
    trackAddOnProductsAddToCart,
    trackProductAddToCart,
    trackProductRemoveAndAddToCart,
    trackCheckoutProcess,
} from '../../tracking/tracking'

import { showModal } from '../../actions/modalActions'

const pageTranslation = {
    fi: {
        page: {
            recipientInfo: 'Vastaanottajan tiedot',
            recipientName: 'Vastaanottajan nimi',
            nameFirstLast: 'Etu- ja sukunimi',
            deceasedName: 'Vainajan nimi',
            recipientCompany: 'Yritys tai c/o',
            recipientCompanyInfo: 'Huom! Yritystilauksen takaraja klo 9.00',
            churchChapel: 'Kirkko tai kappeli',
            churchChapelInfo: 'Kirkko tai kappeli (tai c/o, jos ei mene suoraan kappeliin)',
            address: 'Osoite',
            streetAndHouse: 'Katuosoite ja talonnumero',
            recipientZip: 'Postinumero',
            city: 'Kaupunki',
            country: 'Maa',
            deliveryDay: 'Toimituspäivä',
            chooseDate: 'Valitse päivä',
            phoneNumber: 'Puhelinnumero',
            recipientPhoneNumber: 'Vastaanottajan numero',
            contactPhoneNumber: 'Yhteyshenkilön numero',
            timeOfBlessing: 'Siunausaika',
            timeOfBlessingExample: 'Esim. 12.00',
            close: 'Sulje',
            errorNoDeliveryToZip:
                'Valitsemaasi postinumeroon ei toimiteta kukkalähetyksiä. Tarkistathan että kirjoitit osoitteen oikein',
            errorNoDeliverySameDay:
                'Valitettavasti et voi enää tilata tälle päivälle toimitusta valitsemaasi osoitteeseen. Tilausaika saman päivän tilauksille on loppunut klo',
            errorDeliveryEndFourteen:
                'Valitettavasti et voi enää tilata tälle päivälle toimitusta valitsemaasi osoitteeseen. Tilausaika saman päivän tilauksille on loppunut klo 14.00. Lauantaisin tilausaika loppuu klo 10.00.',
            errorNoSaturdayDelivery: 'Valitsemaasi osoitteeseen ei ole tarjolla lauantaitoimitusta',
            errorNoSameDayDelivery: 'Valitsemaasi osoitteeseen ei ole tarjolla saman päivän toimitusta',
            errorInvalidDate: 'Valitsemasi päivä ei kelpaa',
            samedayDelivery: 'saman päivän tilaus mahdollinen klo',
            samedayContinue: 'asti',
            checkYourInformation: 'Tarkista täyttämäsi tiedot',
            checkRecipientInfo: 'Tarkistathan vielä, että tiedot ovat oikein.',
            funeralInfoText:
                '*Ilmoita mihin aikaan siunaus alkaa, jotta kukkakauppa tietää milloin toimittaa. Älä ilmoita aikaisempaa kellonaikaa.',
            funeralInfoText2:
                '**Jos kukat eivät mene suoraan kappeliin, kerro Erityistoiveet toimitukseen -kentässä (korttitekstin jälkeen) mihin mennessä kukat pitää toimittaa.',
        },
    },
    en: {
        page: {
            recipientInfo: 'Recipient details',
            recipientName: 'Name of recipient',
            nameFirstLast: 'Full name',
            deceasedName: 'Deceased',
            recipientCompany: 'Company or c/o',
            recipientCompanyInfo: 'Orders to companies by 9:00 a.m.',
            churchChapel: 'Church or chapel',
            churchChapelInfo: 'Church or chapel (or c/o if not delivering to the chapel)',
            address: 'Address',
            streetAndHouse: 'Street and house number',
            recipientZip: 'Postal code',
            city: 'City',
            country: 'Country',
            deliveryDay: 'Delivery day',
            chooseDate: 'Choose day of delivery',
            phoneNumber: 'Phone number',
            recipientPhoneNumber: "Recipient's number",
            contactPhoneNumber: 'Number for contact person',
            timeOfBlessing: 'Time of blessing',
            timeOfBlessingExample: 'E.g. 12.00',
            close: 'Close',
            errorNoDeliveryToZip:
                'Delivery not possible to this zip code. Please check that you filled in the right zip code.',
            errorNoDeliverySameDay:
                'Unfortunately, you can no longer order a same day delivery to the chosen address. The cut-off time for same day deliveries is at',
            errorDeliveryEndFourteen:
                'Unfortunately, you can no longer order a same day delivery to the chosen address. The cut-off time for same day deliveries is at 14:00 o’clock. On Saturdays, the cut-off time is at 10:00 o’clock.',
            errorNoSaturdayDelivery: 'Delivery on Saturdays not possible to the chosen location',
            errorNoSameDayDelivery: 'No same day delivery possible to the chosen location',
            errorInvalidDate: 'Chosen day is not valid',
            samedayDelivery: 'same-day delivery possible until',
            samedayContinue: '',
            checkYourInformation: 'Please check the given information',
            checkRecipientInfo: 'Please ensure that the information is correct.',
            funeralInfoText:
                '*Please inform at what time the blessing starts so the florist knows when to deliver. Do not write an earlier time.',
            funeralInfoText2:
                '**If the flowers are not going to the chapel, please write at what time the flowers should be delivered by in the Special Wishes for the Delivery -field (after writing the card text).',
        },
    },
}

const TIMEZONE = 'Europe/Helsinki'

const isValidZipcode = (calendar) => R.length(calendar) > 0

const FormRecipientInfo = (props) => {
    const [recipientName, setRecipientName] = useState('')
    const [recipientAddress, setRecipientAddress] = useState('')
    const [recipientPhone, setRecipientPhone] = useState('')
    const [recipientDate, setRecipientDate] = useState(null)
    const [recipientCompany, setRecipientCompany] = useState('')
    const [recipientZip, setRecipientZip] = useState('')
    const [recipientCity, setRecipientCity] = useState('')
    const [mortName, setMortName] = useState('')
    const [churchName, setChurchName] = useState('')
    const [churchTime, setChurchTime] = useState('')

    const zipcodeInputRef = useRef(null)
    const formRef = useRef(null)

    const { zipcode, zipcode_error, cartProducts } = props

    const prod = R.head(cartProducts)
    const prodId = R.prop('id', prod)
    const giftcard = isGiftcardByProductId(prodId)

    useEffect(() => {
        initZip()
        window.scrollTo(0, 0) // Scroll to top
        populateState()
    }, [])

    useEffect(() => {
        saveToStore()
    }, [
        recipientName,
        recipientAddress,
        recipientPhone,
        recipientDate,
        recipientCompany,
        recipientZip,
        recipientDate,
        recipientCity,
        mortName,
        churchName,
        churchTime,
    ])

    const initZip = () => {
        const { cc, getZipcode, clearZipcode, formData } = props

        const formZip = R.path(['recipientZip'], formData)
        const parsedZip = R.replace(/ /g, '', formZip)
        const productId = Number(R.prop('id', R.head(cartProducts)))
        const ccLower = R.toLower(cc)

        if (ccLower === 'fi' && productId !== 1001) {
            R.length(parsedZip) === 5 ? getZipcode(parsedZip) : clearZipcode()
        } else if (ccLower === 'fi' && productId === 1001) {
            clearZipcode()
        } else {
            getZipcode(ccLower)
        }
    }

    const trackCart = () => {
        const { orderData, allProducts, selectedProductId, addProductToGTMcart, storeState } = props

        // Add add-on-products to cart
        trackAddOnProductsAddToCart(storeState)

        // Add item to cart if first addition of product to cart
        // If different product in cart, remove first and add
        // If same product in cart, do not add again

        const GTMProductId = R.path(['GTMProductId'], orderData)
        const selectedProduct = R.find(R.propEq('id', Number(selectedProductId)))(allProducts)
        const selectedProductName = R.path(['name'], selectedProduct)
        const selectedProductPrice = R.path(['price'], selectedProduct)

        if (!GTMProductId) {
            trackProductAddToCart(storeState)
            addProductToGTMcart(selectedProductId, selectedProductName, selectedProductPrice)
        } else if (GTMProductId !== selectedProductId) {
            trackProductRemoveAndAddToCart(storeState)
            addProductToGTMcart(selectedProductId, selectedProductName, selectedProductPrice)
        }

        trackCheckoutProcess(storeState, 1, 'Recipient Info')
    }

    const clearFuneralData = () => {
        const { clearFuneralData } = props

        clearFuneralData(mortName, churchName, churchTime, '', '', '', '', '')
    }

    const clearCompanyAndRecipientNotContactedData = () => {
        const { clearCompanyAndRecipientNotContactedData } = props
        clearCompanyAndRecipientNotContactedData('', false, '')
    }

    const cartHasFuneralProduct = () => {
        const { allProducts } = props
        return R.find((product) => isFuneralProduct(allProducts, R.prop('id', product)))(cartProducts)
    }

    const checkFuneralState = () => {
        if (!cartHasFuneralProduct()) {
            setMortName('')
            setChurchName('')
            setChurchTime('')
            clearFuneralData()
        } else {
            setRecipientCompany('')
            clearCompanyAndRecipientNotContactedData()
        }
    }

    const populateState = () => {
        const { formData, history, lang, cc } = props

        setRecipientName(R.path(['recipientName'], formData))
        setRecipientAddress(R.path(['recipientAddress'], formData))
        setRecipientPhone(R.path(['recipientPhone'], formData))
        setRecipientCompany(R.path(['recipientCompany'], formData))
        setRecipientZip(R.path(['recipientZip'], formData))
        setRecipientCity(R.path(['recipientCity'], formData))
        setRecipientDate(null)
        setMortName(R.path(['mortName'], formData))
        setChurchName(R.path(['churchName'], formData))
        setChurchTime(R.path(['churchTime'], formData))

        checkFuneralState()
        R.isEmpty(cartProducts) ? history.push('/' + lang + '/' + cc) : trackCart()
    }

    const validateZip = () => {
        const { saveRecipientData } = props

        // Check for zipcode and zipcode_error + that a date is selected
        if (R.or(!zipcode, zipcode_error)) {
            openModal(true, false)
        } else if (R.or(!recipientDate, isHistory())) {
            openModal(false, true)
        } else {
            saveRecipientData()
        }
    }

    const validateGiftCard = () => {
        const { saveRecipientData } = props

        if (R.or(!recipientDate, isHistory())) {
            // Selected delivery date is in past
            openModal(false, true)
        } else {
            saveRecipientData()
        }
    }

    const openModal = (modalInvalid, modalPast) => {
        const { showModal } = props
        const selectedTrans = R.path([getLanguage()], pageTranslation)
        const trans = R.path(['page'], selectedTrans)

        let modalContent = ''
        if (modalInvalid) modalContent += `<li>${R.path(['errorNoDeliveryToZip'], trans)}</li>`
        if (modalPast) modalContent += `<li>${R.path(['errorInvalidDate'], trans)}</li>`

        showModal(
            true,
            'Zip error modal',
            'deliveryError',
            'modal__content alignLeft',
            R.path(['checkYourInformation'], trans),
            modalContent,
        )
    }

    const saveToStore = () => {
        const { addFormRecipientInfo } = props

        addFormRecipientInfo(
            recipientName,
            recipientAddress,
            recipientPhone,
            recipientDate,
            recipientCompany,
            recipientZip,
            recipientCity,
            mortName,
            churchName,
            churchTime,
        )
    }

    const handleRecipientNameChange = (event) => setRecipientName(event.target.value)
    const handleRecipientAddressChange = (event) => setRecipientAddress(event.target.value)
    const handleRecipientPhoneChange = (event) => setRecipientPhone(event.target.value)
    const handleRecipientDateChange = (date) => setRecipientDate(date)
    const handleRecipientCompanyChange = (event) => setRecipientCompany(event.target.value)
    const handleRecipientCityChange = (event) => setRecipientCity(event.target.value)
    const handleMortNameChange = (event) => setMortName(event.target.value)
    const handleChurchNameChange = (event) => setChurchName(event.target.value)
    const handleChurchTimeChange = (event) => setChurchTime(event.target.value)

    const handleRecipientZipChange = (event) => {
        const { cc, getZipcode, clearZipcode } = props
        const value = R.path(['target', 'value'], event)
        const stripValue = R.replace(/ /g, '', value)
        const productId = Number(R.prop('id', R.head(cartProducts)))
        const ccLower = R.toLower(cc)

        if (ccLower === 'fi' && productId !== 1001) {
            // If Finnish flower product, get zipcode calendar from backend
            R.length(stripValue) === 5 ? getZipcode(stripValue) : clearZipcode()
            setRecipientZip(stripValue)
            setRecipientDate(null)
        } else if (ccLower === 'fi' && productId === 1001) {
            // If Finnish giftcard product, use default calendar
            R.length(stripValue) !== 5 && clearZipcode()
            setRecipientZip(stripValue)
            setRecipientDate(null)
        } else {
            // If foreign order, use calendar set in initZip()
            setRecipientZip(value)
        }
    }

    const isHistory = () => {
        return moment(recipientDate).tz(TIMEZONE).isBefore(moment(new Date()).tz(TIMEZONE), 'day')
    }

    const { lang, cc, allCountries, zipcode_calendar, zipcode_cutoff, cartLoading } = props

    const selectedCountryName = R.pathOr('-', ['Name'], R.find(R.propEq('CountryCode', prod.countrycode))(allCountries))

    const isFuneralOrder = cartHasFuneralProduct()

    const selectedTrans = R.path([getLanguage()], pageTranslation)
    const trans = R.path(['page'], selectedTrans)
    const locale = getLocale(lang)

    const calendar = getZipcodeCalendarForDatePicker(zipcode_calendar, zipcode_cutoff)
    const cutoff = R.or(zipcode_cutoff, '').slice(0, 5)
    const cutoffVisibility = !!zipcode_cutoff ? 'visible' : 'hidden'

    const handleContinue = prod.category !== 'lahjakortti' ? validateZip : validateGiftCard
    const backLink = giftcard ? `/${lang}/fi/order/overview` : `/${lang}/${cc}/order/add-ons`

    return (
        <div className="productOrder__contentHolder">
            <Form ref={formRef} className="pure-form pure-form-stacked productOrder__form">
                <fieldset>
                    <div className="pure-g">
                        <div className="pure-u-20-24">
                            <h2 className="order__headline">{R.path(['recipientInfo'], trans)}</h2>
                        </div>
                        <div className="pure-u-4-24 alignRight form__indicator">
                            <span>1/5</span>
                        </div>
                    </div>
                    {!isFuneralOrder && (
                        <div className="input-group form__field">
                            <label htmlFor="recipientName">
                                {R.path(['recipientName'], trans)} <sup>*</sup>
                            </label>
                            <Input
                                onChange={handleRecipientNameChange}
                                placeholder={R.path(['nameFirstLast'], trans)}
                                id="recipientName"
                                errorclassname="is-invalid-input"
                                type="text"
                                containerclassname=""
                                autoComplete="new-password"
                                value={recipientName}
                                name="recipientName"
                                validations={[required, name]}
                                lang={lang}
                            />
                        </div>
                    )}
                    {isFuneralOrder && (
                        <div className="input-group form__field">
                            <label htmlFor="mortName">
                                {R.path(['deceasedName'], trans)} <sup>*</sup>
                            </label>
                            <Input
                                onChange={handleMortNameChange}
                                placeholder={R.path(['nameFirstLast'], trans)}
                                id="mortName"
                                errorclassname="is-invalid-input"
                                type="text"
                                containerclassname=""
                                autoComplete="new-password"
                                value={mortName}
                                name="mortName"
                                validations={[required, name]}
                                lang={lang}
                            />
                        </div>
                    )}
                    {!isFuneralOrder && (
                        <div className="input-group form__field">
                            <label htmlFor="recipientCompany">{R.path(['recipientCompany'], trans)}</label>
                            <Input
                                onChange={handleRecipientCompanyChange}
                                placeholder={R.path(['recipientCompanyInfo'], trans)}
                                id="recipientCompany"
                                type="text"
                                containerclassname=""
                                autoComplete="new-password"
                                value={recipientCompany}
                                name="recipientCompany"
                                validations={[]}
                                lang={lang}
                            />
                        </div>
                    )}
                    {isFuneralOrder && (
                        <div className="input-group form__field">
                            <label htmlFor="churchName">{R.path(['churchChapel'], trans)}</label>
                            <Input
                                onChange={handleChurchNameChange}
                                placeholder={R.path(['churchChapelInfo'], trans)}
                                id="churchName"
                                errorclassname=""
                                type="text"
                                containerclassname=""
                                autoComplete="new-password"
                                name="churchName"
                                value={churchName}
                                validations={[]}
                                lang={lang}
                            />
                        </div>
                    )}
                    <div className="input-group form__field">
                        <label htmlFor="deliveryAddress">
                            {R.path(['address'], trans)} <sup>*</sup>
                        </label>
                        <Input
                            onChange={handleRecipientAddressChange}
                            placeholder={R.path(['streetAndHouse'], trans)}
                            id="recipientAddress"
                            errorclassname="is-invalid-input"
                            type="text"
                            containerclassname=""
                            autoComplete="new-password"
                            value={recipientAddress}
                            name="recipientAddress"
                            validations={[required]}
                            lang={lang}
                        />
                    </div>
                    <div className="pure-g">
                        <div className="pure-u-1 pure-u-sm-8-24 rightPadding">
                            <div className="input-group form__field">
                                <label htmlFor="deliveryZip">
                                    {R.path(['recipientZip'], trans)} <sup>*</sup>
                                </label>
                                {prod.countrycode === 'FI' && (
                                    <Input
                                        ref={zipcodeInputRef}
                                        onChange={handleRecipientZipChange}
                                        placeholder={R.path(['recipientZip'], trans)}
                                        id="recipientZip"
                                        data={zipcode_error}
                                        errorclassname="is-invalid-input"
                                        type="text"
                                        containerclassname=""
                                        autoComplete="new-password"
                                        value={recipientZip}
                                        name="recipientZip"
                                        validations={[required, numeric, zip, validate_zip]}
                                        lang={lang}
                                    />
                                )}
                                {prod.countrycode !== 'FI' && (
                                    <Input
                                        onChange={handleRecipientZipChange}
                                        placeholder={R.path(['recipientZip'], trans)}
                                        id="recipientZip"
                                        errorclassname="is-invalid-input"
                                        type="text"
                                        containerclassname=""
                                        autoComplete="new-password"
                                        value={recipientZip}
                                        name="recipientZip"
                                        validations={[required, intZip]}
                                        lang={lang}
                                    />
                                )}
                            </div>
                        </div>
                        <div className="pure-u-1 pure-u-sm-8-24 rightPadding">
                            <div className="input-group form__field">
                                <label htmlFor="deliveryCity">
                                    {R.path(['city'], trans)} <sup>*</sup>
                                </label>
                                <Input
                                    onChange={handleRecipientCityChange}
                                    placeholder={R.path(['city'], trans)}
                                    id="recipientCity"
                                    errorclassname="is-invalid-input"
                                    type="text"
                                    containerclassname=""
                                    autoComplete="new-password"
                                    value={recipientCity}
                                    name="recipientCity"
                                    validations={[required]}
                                    lang={lang}
                                />
                            </div>
                        </div>
                        <div className="pure-u-1 pure-u-sm-8-24">
                            <div className="input-group form__field">
                                <label htmlFor="deliveryCountry">{R.path(['country'], trans)}</label>
                                <input
                                    className="force-black"
                                    disabled
                                    id="recipientCountry"
                                    type="text"
                                    value={selectedCountryName}
                                    name="recipientCountry"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="pure-g">
                        <div className="pure-u-1 pure-u-sm-12-24 rightPadding">
                            <div className="input-group form__field">
                                <label htmlFor="deliveryDate">
                                    {R.path(['deliveryDay'], trans)} <sup>*</sup>
                                </label>
                                <Datepicker
                                    dateFormat="dd/MM/yyyy"
                                    minDate={giftcard ? moment() : undefined}
                                    selected={recipientDate}
                                    includeDates={!giftcard ? calendar : undefined}
                                    disabled={!giftcard && !isValidZipcode(zipcode_calendar)}
                                    onChange={handleRecipientDateChange}
                                    required={true}
                                    locale={locale}
                                    placeholderText={R.path(['chooseDate'], trans)}
                                />
                                <div className="pure-u-1-1" style={{ visibility: cutoffVisibility }}>
                                    <span className="form__field__infotext">
                                        *{R.path(['samedayDelivery'], trans)} {cutoff}{' '}
                                        {R.path(['samedayContinue'], trans)}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="pure-u-1 pure-u-sm-12-24">
                            <div className="input-group form__field">
                                <label htmlFor="recipientPhone">
                                    {R.path(['phoneNumber'], trans)} <sup>*</sup>
                                </label>
                                <Input
                                    onChange={handleRecipientPhoneChange}
                                    placeholder={
                                        isFuneralOrder
                                            ? R.path(['contactPhoneNumber'], trans)
                                            : R.path(['recipientPhoneNumber'], trans)
                                    }
                                    id="recipientPhone"
                                    errorclassname="is-invalid-input"
                                    type="tel"
                                    containerclassname=""
                                    autoComplete="new-password"
                                    value={recipientPhone}
                                    name="recipientPhone"
                                    validations={[required, phone]}
                                    lang={lang}
                                />
                            </div>
                        </div>
                    </div>
                    {isFuneralOrder && (
                        <div className="pure-g">
                            <div className="pure-u-1 pure-u-sm-12-24 rightPadding">
                                <div className="input-group form__field">
                                    <label htmlFor="churchTime">
                                        {R.path(['timeOfBlessing'], trans)} <sup>*</sup>
                                    </label>
                                    <Input
                                        onChange={handleChurchTimeChange}
                                        placeholder={R.path(['timeOfBlessingExample'], trans)}
                                        id="churchTime"
                                        errorclassname="is-invalid-input"
                                        type="text"
                                        containerclassname=""
                                        autoComplete="new-password"
                                        value={churchTime}
                                        name="churchTime"
                                        validations={[required, time]}
                                        lang={lang}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="pure-g">
                        <div className="pure-u-1-1">
                            <p style={{ margin: 0 }}>{R.path(['checkRecipientInfo'], trans)}</p>
                            {isFuneralOrder && (
                                <div className="funeralInfo__container">
                                    <p>{R.path(['funeralInfoText'], trans)}</p>
                                    <p>{R.path(['funeralInfoText2'], trans)}</p>
                                </div>
                            )}
                        </div>
                    </div>
                </fieldset>
                <BottomNavigationBar backLink={backLink} handleContinue={handleContinue} loading={cartLoading} />
            </Form>
        </div>
    )
}

const mapStateToProps = (state) => ({
    storeState: state,
    formData: state.form,
    orderData: state.order,
    selectedProductId: state.order.selectedProductId,
    productsData: state.products,
    allProducts: state.products.allProducts,
    allCountries: state.products.allCountries,
    allExtras: state.products.allExtras,
    ribbonSelected: state.order.ribbon,
    condolencesSelected: state.order.condolences,
    zipcode: R.path(['zipcode', 'delivery_zipcode'], state),
    zipcode_cutoff: R.path(['zipcode', 'cutoff'], state),
    zipcode_calendar: R.path(['zipcode', 'calendar'], state),
    zipcode_error: R.path(['zipcode', 'error'], state),
    lang: R.path(['lang', 'lang'], state),
    cc: R.path(['lang', 'db'], state),
    cartProducts: R.pathOr([], ['cart', 'items', 'products'], state),
    cartLoading: R.path(['cart', 'loading'], state),
})

const mapDispatchToProps = (dispatch) => ({
    dispatch: dispatch,
    addFormRecipientInfo: (
        recipientName,
        recipientAddress,
        recipientPhone,
        recipientDate,
        recipientCompany,
        recipientZip,
        recipientCity,
        mortName,
        churchName,
        churchTime,
    ) =>
        dispatch(
            addFormRecipientInfo(
                recipientName,
                recipientAddress,
                recipientPhone,
                recipientDate,
                recipientCompany,
                recipientZip,
                recipientCity,
                mortName,
                churchName,
                churchTime,
            ),
        ),
    clearFuneralData: (
        mortName,
        churchName,
        churchTime,
        ribbonWords,
        ribbonNames,
        condolenceWords,
        condolenceMortName,
        condolenceSenderNames,
    ) =>
        dispatch(
            clearFuneralData(
                mortName,
                churchName,
                churchTime,
                ribbonWords,
                ribbonNames,
                condolenceWords,
                condolenceMortName,
                condolenceSenderNames,
            ),
        ),
    clearCompanyAndRecipientNotContactedData: (recipientCompany, recipientAdvanceNotice, recipientNotContacted) =>
        dispatch(
            clearCompanyAndRecipientNotContactedData(recipientCompany, recipientAdvanceNotice, recipientNotContacted),
        ),
    addProductToGTMcart: (selectedProductId, selectedProductName, selectedProductPrice) =>
        dispatch(addProductToGTMcart(selectedProductId, selectedProductName, selectedProductPrice)),
    saveRecipientData: () => dispatch(saveRecipientData()),
    getZipcode: (zipcode) => dispatch(getZipcode(zipcode)),
    clearZipcode: () => dispatch(clearZipcode()),
    clearZipcodeError: () => dispatch(clearZipcodeError()),
    showModal: (show, label, modaltype, modalclass, headline, content) =>
        dispatch(showModal(show, label, modaltype, modalclass, headline, content)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FormRecipientInfo))
