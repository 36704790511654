import * as R from 'ramda'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import { OrderWrapper } from '../components/productorder/ProductOrderContainer'

const Loading = ({ isLoadingProducts, isLoadingExtras, isLoadingZipcodes }) => {
    return isLoadingProducts && isLoadingExtras && isLoadingZipcodes ? <div>Loading</div> : null
}

const ProductError = ({ errorProducts, errorExtras, errorZipcodes }) => {
    return errorProducts || errorExtras || errorZipcodes ? (
        <div>
            Error {errorProducts} {errorExtras} {errorZipcodes}
        </div>
    ) : null
}

const OrderPhases = ({ products, extras, productId, match, lang, cc, location, history, cart }) => {
    return !isEmpty(products) && !isEmpty(extras) ? (
        <OrderWrapper
            match={match}
            location={location}
            history={history}
            products={products}
            extras={extras}
            productId={productId}
            lang={lang}
            cc={cc}
            cart={cart}
        />
    ) : null
}

class OrderContainer extends Component {
    render() {
        const { products, extras, productId, lang, cc, selectedCountryCode, match, location, history, cart } =
            this.props

        return (
            <div>
                <Loading {...this.props} />
                <ProductError {...this.props} />
                <OrderPhases
                    match={match}
                    location={location}
                    history={history}
                    products={products}
                    extras={extras}
                    productId={productId}
                    lang={lang}
                    cc={cc}
                    selectedCountryCode={selectedCountryCode}
                    cart={cart}
                />
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    products: state.products.allProducts,
    isLoadingProducts: state.products.isLoadingProducts,
    errorProducts: state.products.errorProducts,
    isLoadingExtras: state.products.isLoadingExtras,
    errorExtras: state.products.errorExtras,
    isLoadingZipcodes: state.products.isLoadingZipcodes,
    errorZipcodes: state.products.errorZipcodes,
    extras: state.products.allExtras,
    productId: state.order.selectedProductId,
    cart: R.path(['cart', 'items'], state),
    lang: R.path(['lang', 'lang'], state),
    cc: R.path(['lang', 'db'], state),
    selectedCountryCode: state.category.selectedCountryCode,
})

const mapDispatchToProps = (dispatch, ownProps) => ({})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderContainer))
