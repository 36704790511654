import * as R from 'ramda'
import { applyMiddleware, compose, createStore } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import thunkMiddleware from 'redux-thunk'
import createRootReducer from './rootReducer'

import { createCookieMiddleware } from 'redux-cookie'

import { loadState, saveState } from './store/sessionStorage'

export default function configureStore(initialState = {}, history = {}, cookies = {}) {
    let store

    if (typeof window === 'undefined') {
        // We are the server
        store = createStore(
            createRootReducer(history),
            initialState,
            compose(applyMiddleware(thunkMiddleware, routerMiddleware(history))),
        )
    } else {
        // We are the browser
        // Merge SSR data to sessionStorage redux state
        // Set the data for the wanted reducers
        const state = R.compose(
            R.assocPath(['products', 'allCountries'], R.path(['products', 'allCountries'], initialState)),
            R.assocPath(['products', 'allOccasions'], R.path(['products', 'allOccasions'], initialState)),
            R.assocPath(['products', 'allExtras'], R.path(['products', 'allExtras'], initialState)),
            R.assocPath(['cards'], R.path(['cards'], initialState)),
            R.assocPath(['products', 'allProducts'], R.path(['products', 'allProducts'], initialState)),
            R.assocPath(['payform', 'payment_methods'], R.path(['payform', 'payment_methods'], initialState)),
            R.assocPath(['login', 'loginMessage'], R.path(['login', 'loginMessage'], initialState)),
            R.assocPath(['login', 'prevPage'], R.path(['login', 'prevPage'], initialState)),
            R.assocPath(['login', 'resetMessage'], R.path(['login', 'resetMessage'], initialState)),
            R.assocPath(['category'], R.path(['category'], initialState)),
            R.assocPath(['lang'], R.path(['lang'], initialState)),
            R.assoc('uri', R.path(['uri'], initialState)),
        )(loadState())

        const enhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

        store = createStore(
            createRootReducer(history),
            state,
            enhancer(applyMiddleware(thunkMiddleware, routerMiddleware(history), createCookieMiddleware(cookies))),
        )
    }

    store.subscribe(() => {
        saveState(store.getState())
    })

    return store
}
