import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as R from 'ramda'

import ProductTags from '../product/ProductTags'

import { getLanguage } from '../../utils/translation'

import config from '../../config'
import { isGiftcardByProductId } from '../../utils/ProductTypes'
import { DELIVERY_COST_DOMESTIC_WITH_COMMA, DELIVERY_COST_FOREIGN_WITH_COMMA } from '../../utils/constants'

const assets = R.path(['ASSETS'], config)

const pageTranslation = {
    fi: {
        page: {
            foreignDeliveryPrice: 'Ulkomaan toimitusmaksu',
            domesticDeliveryPrice: 'Kotimaan toimitusmaksu',
            giftcardDeliveryPrice: 'Lahjakortin toimitusmaksu',
            destinationCountry: 'Kohdemaa: ',
            infoOnDeliveryGiftcard:
                'Lahjakortti ja tervehdyskortti postitetaan vastaanottajalle antamaasi osoitteeseen. Huom! Lahjakorttia ei voi yhdistää kukkalähetykseen.',
            infoOnDeliveryInternational:
                'Tilaamasi kukat ja kortti toimitetaan vastaanottajalle antamaasi osoitteeseen kohdemaan paikallisesta kukkakaupasta.',
            infoOnDeliveryDomestic:
                'Tilaamasi kukat ja kortti toimitetaan vastaanottajalle antamaasi osoitteeseen kohdemaan paikallisesta kukkakaupasta.',
        },
    },
    en: {
        page: {
            foreignDeliveryPrice: 'International delivery',
            domesticDeliveryPrice: 'Domestic delivery fee',
            giftcardDeliveryPrice: 'Giftcard delivery fee',
            destinationCountry: 'Country: ',
            infoOnDeliveryGiftcard:
                'Floral cheque(s) and the greeting card will be sent by mail to the recipient to the address you provide. Please note that floral checks can’t be included in a flower order delivery.',
            infoOnDeliveryInternational:
                'Flowers and the greeting card will be delivered to the recipient in the country of destination from a local flower shop to the address you provide.',
            infoOnDeliveryDomestic:
                'Flowers, greeting card and additional items will be delivered to the recipient from a local flower shop to the address you provide. Please note that additional items are only delivered together with flowers.',
        },
    },
}

class ShippingPrice extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const { allCountries, lang, cc, items } = this.props

        const product = R.head(R.pathOr([], ['products'], items))
        const productCountryCode = R.toUpper(R.pathOr('fi', ['countrycode'], product))

        const country = R.find(R.propEq('CountryCode', R.path(['countrycode'], product)))(allCountries)
        const productCountryName = R.path(['Name'], country)

        const isForeign = productCountryCode !== 'FI'
        const isGiftcard = isGiftcardByProductId(R.prop('id', product))

        const giftcardDeliveryCost = '3,80'
        const productDeliveryCost = isForeign ? DELIVERY_COST_FOREIGN_WITH_COMMA : DELIVERY_COST_DOMESTIC_WITH_COMMA

        const deliveryCost = isGiftcard ? giftcardDeliveryCost : productDeliveryCost

        const selectedTrans = R.path([getLanguage()], pageTranslation)
        const trans = R.path(['page'], selectedTrans)

        const image = `${assets}/icon-runner.svg`
        const transparentTag = { backgroundColor: 'transparent' }

        return (
            <div className="pure-g flex-nowrap">
                <div className="pure-u-1 pure-u-sm-1-24 pure-hidden-smallphone"></div>
                <div className="pure-u-1 flex-center">
                    <div className="overview__itemWrapper">
                        <div className="pure-u-2-5 pure-u-sm-1-5">
                            <img className="pure-img hermes" src={image} alt="" />
                        </div>
                        {!isGiftcard && isForeign && (
                            <div className="pure-u-2-5 pure-u-sm-3-5 price__name">
                                <h2>{R.path(['foreignDeliveryPrice'], trans)}</h2>
                                <h3>
                                    {deliveryCost}
                                    <span style={{ fontSize: `.8em` }}>€</span>
                                </h3>
                                <h3>
                                    <span style={{ fontSize: `.8em` }}>
                                        {R.path(['destinationCountry'], trans)}
                                        {productCountryName}
                                    </span>
                                </h3>
                                <p className="product-description">{R.path(['infoOnDeliveryInternational'], trans)}</p>
                            </div>
                        )}
                        {!isGiftcard && !isForeign && (
                            <div className="pure-u-2-5 pure-u-sm-3-5 price__name">
                                <h2>{R.path(['domesticDeliveryPrice'], trans)}</h2>
                                <h3>
                                    {deliveryCost}
                                    <span style={{ fontSize: `.8em` }}>€</span>
                                </h3>
                                <h3>
                                    <span style={{ fontSize: `.8em` }}>
                                        {R.path(['destinationCountry'], trans)}
                                        {productCountryName}
                                    </span>
                                </h3>
                                <p className="product-description">{R.path(['infoOnDeliveryDomestic'], trans)}</p>
                            </div>
                        )}
                        {isGiftcard && (
                            <div className="pure-u-2-5 pure-u-sm-3-5 price__name">
                                <h2>{R.path(['giftcardDeliveryPrice'], trans)}</h2>
                                <h3>
                                    {deliveryCost}
                                    <span style={{ fontSize: `.8em` }}>€</span>
                                </h3>
                                <p className="product-description">{R.path(['infoOnDeliveryGiftcard'], trans)}</p>
                            </div>
                        )}
                        <div className="pure-u-1-5 pure-u-sm-1-5 remove__item product__description--tag">
                            <ProductTags
                                tagColor="cyan"
                                tagsCountry={R.path(['countrycode'], product)}
                                tagsCountryFullName={null}
                                lang={lang}
                                cc={cc}
                            />
                        </div>
                    </div>
                </div>
                <div className="pure-u-1 pure-u-sm-1-24 pure-hidden-smallphone"></div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    allCountries: state.products.allCountries,
    items: R.pathOr([], ['cart', 'items'], state),
    lang: R.path(['lang', 'lang'], state),
    cc: R.path(['lang', 'db'], state),
})

const mapDispatchToProps = (dispatch, ownProps) => ({})

export default connect(mapStateToProps, undefined)(ShippingPrice)
