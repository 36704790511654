import * as R from 'ramda'
import {
    REQUEST_PRODUCTS,
    REQUEST_EXTRAS,
    REQUEST_OCCASIONS,
    REQUEST_COUNTRIES,
    LOADING_START_PRODUCTS,
    LOADING_START_EXTRAS,
    LOADING_START_OCCASIONS,
    LOADING_START_COUNTRIES,
    REQUEST_PRODUCT_CONTENT,
} from '../actions/productsActions'

import { REQUEST_DELIVERY_CARDS, LOADING_START_DELIVERY_CARDS } from '../actions/deliveryCardActions'

const initialState = {
    isLoadingProducts: false,
    isLoadingExtras: false,
    isLoadingZipcodes: false,
    isLoadingOccasions: false,
    isLoadingCountries: false,
    allProducts: [],
    allExtras: null,
    allOccasions: [],
    allCountries: [],
    errorProducts: null,
    errorExtras: null,
    errorZipcodes: null,
    errorOccasions: null,
    errorCountries: null,
}

export default function productsReducer(state = initialState, action) {
    switch (action.type) {
        case LOADING_START_PRODUCTS:
            return {
                ...state,
                isLoadingProducts: true,
                allProducts: null,
            }
        case REQUEST_PRODUCTS:
            return {
                ...state,
                allProducts: action.products,
            }
        case REQUEST_PRODUCTS + '_SUCCESS':
            return {
                ...state,
                allProducts: R.path(['products'], action),
                isLoadingProducts: false,
            }
        case REQUEST_PRODUCTS + '_FAILED':
            return {
                ...state,
                isLoadingProducts: false,
                errorProducts: 'WE FAILED.',
            }
        case LOADING_START_EXTRAS:
            return {
                ...state,
                isLoadingExtras: true,
            }
        case REQUEST_EXTRAS:
            return {
                ...state,
                allExtras: action.extras,
            }
        case REQUEST_EXTRAS + '_SUCCESS':
            return {
                ...state,
                allExtras: action.extras,
                isLoadingExtras: false,
            }
        case REQUEST_EXTRAS + '_FAILED':
            return {
                ...state,
                isLoadingExtras: false,
                errorExtras: 'WE FAILED.',
            }
        case LOADING_START_OCCASIONS:
            return {
                ...state,
                isLoadingOccasions: true,
            }
        case REQUEST_OCCASIONS:
            return {
                ...state,
                allOccasions: action.occasions,
            }
        case REQUEST_OCCASIONS + '_SUCCESS':
            return {
                ...state,
                allOccasions: action.occasions,
                isLoadingOccasions: false,
            }
        case REQUEST_OCCASIONS + '_FAILED':
            return {
                ...state,
                isLoadingOccasions: false,
                errorOccasions: 'WE FAILED.',
            }
        case LOADING_START_COUNTRIES:
            return {
                ...state,
                isLoadingCountries: true,
            }
        case REQUEST_COUNTRIES:
            return {
                ...state,
                allCountries: action.countries,
            }
        case REQUEST_COUNTRIES + '_SUCCESS':
            return {
                ...state,
                allCountries: R.path(['countries'], action),
                isLoadingCountries: false,
            }
        case REQUEST_COUNTRIES + '_FAILED':
            return {
                ...state,
                isLoadingCountries: false,
                errorCountries: 'WE FAILED.',
            }
        case REQUEST_PRODUCT_CONTENT:
            return {
                ...state,
                isLoadingProducts: action.isLoadingProducts,
                isLoadingExtras: action.isLoadingExtras,
                isLoadingZipcodes: action.isLoadingZipcodes,
                allProducts: action.allProducts,
                allExtras: action.allExtras,
                errorProducts: action.errorProducts,
                errorExtras: action.errorExtras,
                errorZipcodes: action.errorZipcodes,
                errorOccasions: action.errorOccasions,
                errorCountries: action.errorCountries,
                isLoadingOccasions: action.isLoadingOccasions,
                allOccasions: action.allOccasions,
                isLoadingCountries: action.isLoadingCountries,
                allCountries: action.allCountries,
            }
        default:
            return state
    }
}
