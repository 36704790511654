import React from 'react'
import * as R from 'ramda'
import classNames from 'classnames'

import config from '../../config'
const assets = R.path(['ASSETS'], config)

const PaymentTypeIcon = ({ method_type, selected_page, submitPaymentType, name }) => {
    const isSelected = selected_page === method_type

    const styles = classNames('pure-u-8-24 paymentTypeSelector', isSelected ? 'selected' : '')

    return (
        <div
            className={styles}
            onClick={(event) => submitPaymentType(event, method_type)}
            id={`payment-method-${method_type}`}
        >
            <img className="pure-img" src={`${assets}/payment-type-${method_type}.svg`} alt="" />
            <span className="input-group-radio-label">{name}</span>
        </div>
    )
}

export default PaymentTypeIcon
