import * as R from 'ramda'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import config from '../../config'
const assets = R.path(['ASSETS'], config)

export default class FavouriteCard extends Component {
    render() {
        const { product, index, lang, cc } = this.props

        var favouriteCardClass = classNames(
            'favouriteCard',
            { 'pure-u-1': index < 1 },
            { 'pure-u-1': index >= 1 },
            { 'pure-u-sm-1': index < 1 },
            { 'pure-u-sm-12-24': index >= 1 },
            { 'pure-u-md-12-24': index < 2 },
            { 'pure-u-md-8-24': index >= 2 },
        )

        return (
            <div className={favouriteCardClass} key={product.id} id={`featured-product-${product.id}`}>
                <Link className="no-decoration" to={`/${lang}/${cc}/product/${product.id}`}>
                    <div className="card-content">
                        <img className="product-image" src={`${assets}/feature_${product.image}`} alt="" />
                        <div className="product-text">
                            <p className="name">{product.name}</p>
                            <p className="price">
                                {product.price}
                                <span className="euro">€</span>
                            </p>
                        </div>
                    </div>
                </Link>
            </div>
        )
    }
}
