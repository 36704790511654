import * as R from 'ramda'
import React, { Component } from 'react'
import Summary from './ProductOrderSummary'
import { Switch, Route, Redirect } from 'react-router-dom'
import FormRecipientInfo from './FormRecipientInfo'
import DeliveryCardContainer from './DeliveryCardContainer'
import FormDelivery from './FormDelivery'
import FormSenderInfo from './FormSenderInfo'
import FormPayment from './FormPayment'
import FormThankYou from './FormThankYou'
import FormPaymentFailed from './FormPaymentFailed'
import FormCondolences from './FormCondolences'
import FormRibbon from './FormRibbon'
import { parse } from 'query-string'
import classNames from 'classnames'
import { CSSTransitionGroup } from 'react-transition-group'

class ProductOrderSubContainer extends Component {
    render() {
        const { match, lang, cc, location, checkCart } = this.props

        const url = R.path(['url'], match)
        const path = R.path(['pathname'], location)
        const query = parse(R.path(['search'], location))
        const redir = R.path(['redirect'], query)
        const isCardPage = location.pathname === `${url}/card-text`
        const SETTLED = R.path(['SETTLED'], query)

        const summaryStyle = classNames(
            'product__leftContainer pure-u-1 pure-u-md-10-24 pure-u-lg-10-24',
            { notdisplayed: redir === 'true' },
            { product__leftContainer__height__fullscreen: isCardPage },
        )

        const orderStyle = classNames('productOrder pure-u-1', {
            'pure-u-md-14-24': redir !== 'true',
            'pure-u-lg-14-24': redir !== 'true',
        })

        return (
            <div className="productOrderSubContainer pure-g">
                <div className={summaryStyle}>{!redir && <Summary />}</div>
                <div className={orderStyle}>
                    <CSSTransitionGroup
                        transitionName="fade"
                        transitionEnterTimeout={1000}
                        transitionLeaveTimeout={1000}
                    >
                        <Switch key={location.pathname} location={location}>
                            <Route
                                exact
                                path={`${url}/ribbon`}
                                render={() => (checkCart() ? <FormRibbon lang={lang} cc={cc} /> : null)}
                            />
                            <Route
                                exact
                                path={`${url}/condolences`}
                                render={() => (checkCart() ? <FormCondolences lang={lang} cc={cc} /> : null)}
                            />
                            <Route
                                exact
                                path={`${url}/recipient-info`}
                                render={() => (checkCart() ? <FormRecipientInfo lang={lang} cc={cc} /> : null)}
                            />
                            <Route
                                exact
                                path={`${url}/card-text`}
                                render={() => (checkCart() ? <DeliveryCardContainer /> : null)}
                            />
                            <Route
                                exact
                                path={`${url}/delivery`}
                                render={() => (checkCart() ? <FormDelivery lang={lang} cc={cc} /> : null)}
                            />
                            <Route
                                exact
                                path={`${url}/sender-info`}
                                render={() => (checkCart() ? <FormSenderInfo lang={lang} cc={cc} /> : null)}
                            />
                            <Route
                                exact
                                path={`${url}/payment`}
                                render={() => (checkCart() ? <FormPayment lang={lang} cc={cc} /> : null)}
                            />
                            <Route
                                exact
                                path={`${url}/thank-you`}
                                render={() => <FormThankYou lang={lang} cc={cc} settled={SETTLED} />}
                            />
                            <Route
                                exact
                                path={`${url}/payment-failed`}
                                render={() => <FormPaymentFailed lang={lang} cc={cc} />}
                            />
                            <Redirect to={`/${lang}/${cc}`} />
                        </Switch>
                    </CSSTransitionGroup>
                </div>
            </div>
        )
    }
}

export default ProductOrderSubContainer
