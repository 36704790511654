import * as R from 'ramda'
import { backendFetch, backendFetchV3, parseJSON, defaults, POST, PUT, PATCH, DELETE } from './fetchUtil'

export function getProductsByCountry(cc = 'fi', translation = 'fi') {
    return new Promise((resolve, reject) => {
        backendFetchV3(`/v3/product/${R.toLower(cc)}?translation=${translation}`)
            .then(parseJSON)
            .then((response) => (response.ok ? resolve(response.json) : reject(response)))
            .catch((err) => reject(err))
    })
}

export function getDeliveryCards(translation = 'fi') {
    return new Promise((resolve, reject) => {
        backendFetchV3(`/v3/delivery-cards/lang/${translation}`)
            .then(parseJSON)
            .then((response) => (response.ok ? resolve(response.json) : reject(response)))
            .catch((err) => reject(err))
    })
}

export function getExtras(translation = 'fi') {
    return new Promise((resolve, reject) => {
        backendFetchV3(`/v3/extras/lang/${translation}`)
            .then(parseJSON)
            .then((response) => (response.ok ? resolve(response.json) : reject(response)))
            .catch((err) => reject(err))
    })
}

export function fetchZipcode(zipcode = '') {
    return new Promise((resolve, reject) => {
        backendFetchV3(`/v3/zipcode/${zipcode}`)
            .then(parseJSON)
            .then((response) => (response.ok ? resolve(response.json) : reject(response)))
            .catch((err) => reject(err))
    })
}

export function getOccasions(translation = 'fi') {
    return new Promise((resolve, reject) => {
        backendFetchV3(`/v3/occasion?translation=${translation}`)
            .then(parseJSON)
            .then((response) => (response.ok ? resolve(response.json) : reject(response)))
            .catch((err) => reject(err))
    })
}

export function getCountries(translation = 'fi') {
    return new Promise((resolve, reject) => {
        backendFetchV3(`/v3/country?translation=${translation}`)
            .then(parseJSON)
            .then((response) => (response.ok ? resolve(response.json) : reject(response)))
            .catch((err) => reject(err))
    })
}

export function createCart() {
    return new Promise((resolve, reject) => {
        backendFetch('/v1/cart', { method: POST, mode: 'cors' })
            .then(parseJSON)
            .then((response) => (response.ok ? resolve(response.json) : reject(response)))
            .catch((err) => reject(err))
    })
}

export function fetchCart(cart_id, translation = 'fi') {
    return new Promise((resolve, reject) => {
        backendFetch(`/v3/cart/${cart_id}?translation=${translation}`)
            .then(parseJSON)
            .then((response) => (response.ok ? resolve(response.json) : reject(response)))
            .catch((err) => reject(err))
    })
}

export function saveGiftcardToCart(cart_id, price, amount) {
    const body = { price, amount }
    return new Promise((resolve, reject) => {
        backendFetch(`/v1/cart/${cart_id}/giftcard`, { method: PUT, mode: 'cors', body })
            .then(parseJSON)
            .then((response) => (response.ok ? resolve(response.json) : reject(response)))
            .catch((err) => reject(err))
    })
}

export function saveProductToCart(cart_id, product_id, translation) {
    const body = { translation }
    return new Promise((resolve, reject) => {
        backendFetch(`/v1/cart/${cart_id}/product/${product_id}`, { method: PUT, mode: 'cors', body })
            .then(parseJSON)
            .then((response) => (response.ok ? resolve(response.json) : reject(response)))
            .catch((err) => reject(err))
    })
}

export function removeProductFromCart(cart_id, product_id, translation) {
    const body = { translation }
    return new Promise((resolve, reject) => {
        backendFetch(`/v1/cart/${cart_id}/product/${product_id}`, { method: DELETE, mode: 'cors', body })
            .then(parseJSON)
            .then((response) => (response.ok ? resolve(response.json) : reject(response)))
            .catch((err) => reject(err))
    })
}

export function removeAllProductsFromCart(cart_id, translation) {
    const body = { translation }
    return new Promise((resolve, reject) => {
        backendFetch(`/v1/cart/${cart_id}/products`, { method: DELETE, mode: 'cors', body })
            .then(parseJSON)
            .then((response) => (response.ok ? resolve(response.json) : reject(response)))
            .catch((err) => reject(err))
    })
}

export function removeAllAdditionalProductsFromCart(cart_id) {
    return new Promise((resolve, reject) => {
        backendFetch(`/v3/cart/${cart_id}/extraproducts`, { method: DELETE, mode: 'cors' })
            .then(parseJSON)
            .then((response) => (response.ok ? resolve(response.json) : reject(response)))
            .catch((err) => reject(err))
    })
}

export function saveAdditionalProductsToCart(cart_id, body) {
    return new Promise((resolve, reject) => {
        backendFetch(`/v3/cart/${cart_id}/extraproducts`, { method: PUT, mode: 'cors', body })
            .then(parseJSON)
            .then((response) => (response.ok ? resolve(response.json) : reject(response)))
            .catch((err) => reject(err))
    })
}

export function saveRecipientInfoToCart(cart_id, body) {
    return new Promise((resolve, reject) => {
        backendFetch(`/v3/cart/${cart_id}/recipient`, { method: PATCH, mode: 'cors', body })
            .then(parseJSON)
            .then((response) => (response.ok ? resolve(response.json) : reject(response)))
            .catch((err) => reject(err))
    })
}

export function saveCardsToCart(cart_id, body) {
    return new Promise((resolve, reject) => {
        backendFetch(`/v3/cart/${cart_id}/cardtexts`, { method: PUT, mode: 'cors', body })
            .then(parseJSON)
            .then((response) => (response.ok ? resolve(response.json) : reject(response)))
            .catch((err) => reject(err))
    })
}

export function saveRibbonContentToCart(cart_id, body) {
    return new Promise((resolve, reject) => {
        backendFetch(`/v1/cart/${cart_id}/ribbon`, { method: PUT, mode: 'cors', body })
            .then(parseJSON)
            .then((response) => (response.ok ? resolve(response.json) : reject(response)))
            .catch((err) => reject(err))
    })
}

export function saveCondolencesContentToCart(cart_id, body) {
    return new Promise((resolve, reject) => {
        backendFetch(`/v1/cart/${cart_id}/condolences`, { method: PUT, mode: 'cors', body })
            .then(parseJSON)
            .then((response) => (response.ok ? resolve(response.json) : reject(response)))
            .catch((err) => reject(err))
    })
}

export function saveCourierContentToCart(cart_id, body) {
    return new Promise((resolve, reject) => {
        backendFetch(`/v1/cart/${cart_id}/courier`, { method: PUT, mode: 'cors', body })
            .then(parseJSON)
            .then((response) => (response.ok ? resolve(response.json) : reject(response)))
            .catch((err) => reject(err))
    })
}

export function saveSenderInfoToCart(cart_id, body) {
    return new Promise((resolve, reject) => {
        backendFetch(`/v3/cart/${cart_id}/senderinfo`, { method: PATCH, mode: 'cors', body })
            .then(parseJSON)
            .then((response) => (response.ok ? resolve(response.json) : reject(response)))
            .catch((err) => reject(err))
    })
}

export function saveDeliveryTermsAcceptedToCart(cart_id, body) {
    return new Promise((resolve, reject) => {
        backendFetch(`/v1/cart/${cart_id}/deliveryterms`, { method: PUT, mode: 'cors', body })
            .then(parseJSON)
            .then((response) => (response.ok ? resolve(response.json) : reject(response)))
            .catch((err) => reject(err))
    })
}

export function applyDiscountCodeToCart(cart_id, code) {
    return new Promise((resolve, reject) => {
        backendFetch(`/v1/cart/${cart_id}/discount/${code}`, defaults)
            .then(parseJSON)
            .then((response) => (response.ok ? resolve(response.json) : reject(response)))
            .catch((err) => reject(err))
    })
}

export function fetchToken(email, password) {
    const body = { email, password }
    return new Promise((resolve, reject) => {
        backendFetch('/v1/auth/login', { method: POST, mode: 'cors', body })
            .then(parseJSON)
            .then((response) => (response.ok ? resolve(response.json) : reject(response)))
            .catch((err) => reject(err))
    })
}

export function deleteToken() {
    return new Promise((resolve, reject) => {
        backendFetch('/v1/auth/logout', defaults)
            .then(parseJSON)
            .then((response) => (response.ok ? resolve(response.json) : reject(response)))
            .catch((err) => reject(err))
    })
}

export function fetchUserInfo(token) {
    return new Promise((resolve, reject) => {
        backendFetch('/v1/auth/user', defaults, token)
            .then(parseJSON)
            .then((response) => (response.ok ? resolve(response.json) : reject(response)))
            .catch((err) => reject(err))
    })
}

export function postPasswordResetToken(reset_token, password, confirm_password) {
    return new Promise((resolve, reject) => {
        const body = { reset_token, password, confirm_password }
        backendFetch(`/v1/user/reset/token`, { method: POST, mode: 'cors', body: body })
            .then(parseJSON)
            .then((response) => (response.ok ? resolve(response.json) : reject(response)))
            .catch((err) => reject(err))
    })
}

export function postPasswordReset(password, confirm_password, token) {
    return new Promise((resolve, reject) => {
        const body = { password, confirm_password }
        backendFetch(`/v1/user/reset/password`, { method: POST, mode: 'cors', body: body }, token)
            .then(parseJSON)
            .then((response) => (response.ok ? resolve(response.json) : reject(response)))
            .catch((err) => reject(err))
    })
}

export function getPasswordResetEmail(email = '') {
    return new Promise((resolve, reject) => {
        backendFetch(`/v1/user/reset/token?email=${email}`)
            .then(parseJSON)
            .then((response) => (response.ok ? resolve(response.json) : reject(response)))
            .catch((err) => reject(err))
    })
}

export const postJoinMailinglist = (email, name, id) => {
    const splitName = R.split(' ', name)
    const firstName = R.head(R.take(1, splitName))
    const lastName = R.head(R.takeLast(1, splitName))
    return new Promise((resolve, reject) => {
        const body = {
            email_address: email,
            status: 'subscribed',
            merge_fields: {
                FNAME: firstName,
                LNAME: lastName,
            },
        }
        backendFetch(`/v1/mailing_list/${id}`, { method: POST, mode: 'cors', body: body })
            .then(parseJSON)
            .then((response) => (response.ok ? resolve(response.json) : reject(response)))
            .catch((err) => reject(err))
    })
}

export const fetchLandingContent = () => {
    return new Promise((resolve, reject) => {
        backendFetchV3(`/v3/landing-content/public`)
            .then(parseJSON)
            .then((response) => (response.ok ? resolve(response.json) : reject(response)))
            .catch((err) => reject(err))
    })
}
