import * as R from 'ramda'
import React, { Component } from 'react'
import { Link, NavLink, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { initiateSearch } from '../../actions/categoryActions'
import { resetToken, userLogOut } from '../../actions/loginActions'
import { hideCartModal } from '../../actions/cartModalActions'

import CartModal from '../modal/CartModal'

const canUseDOM = !!(typeof window !== 'undefined' && window.document)
import config from '../../config'
const assets = R.path(['ASSETS'], config)

const pageTranslation = {
    fi: {
        page: {
            frontPage: 'Etusivu',
            category: 'Lähetä kukkia',
            giftCard: 'Lahjakortti',
            articles: 'Tietoa',
            login: 'B2B',
            logout: 'Kirjaudu ulos',
        },
    },
    en: {
        page: {
            frontPage: 'Front page',
            category: 'Send flowers',
            giftCard: 'Floral cheque',
            articles: 'Information',
            login: 'B2B',
            logout: 'Logout',
        },
    },
}

const logoStyle = {
    backgroundImage: `url(${assets}/interflora-logo.svg)`,
}

const ProfileButton = ({ cc = 'fi', lang = 'fi', userEmail = '', token, logout }) => {
    const selectedTrans = R.path([lang], pageTranslation)
    const trans = R.path(['page'], selectedTrans)

    if (!canUseDOM) return null

    return token ? (
        <Link
            to={`/${lang}/${cc}`}
            onClick={logout}
            className="profile__link pure-menu-link link-white border-turquoise bg-turquoise"
        >
            <div className="profile__link__column">
                <span className="profile__link__textcontent smaller">{R.path(['logout'], trans)}</span>
                <span className="profile__link__email">{userEmail}</span>
            </div>
            <div className="iconprofile__container">
                <i className="fa fa-user fa-2x"></i>
            </div>
        </Link>
    ) : (
        <Link to={`/${lang}/${cc}/login`} className="profile__link pure-menu-link link-white border-turquoise">
            <span className="profile__link__textcontent">{R.path(['login'], trans)}</span>
            <div className="iconprofile__container">
                <i className="fa fa-user fa-2x"></i>
            </div>
        </Link>
    )
}

class MobileHeader extends Component {
    constructor(props, context) {
        super(props, context)
    }

    render() {
        const { lang, cc, token, user, logout, closeMobileHeader, handle__categoryMobileClick, handle__giftcardClick } =
            this.props

        const selectedTrans = R.path([lang], pageTranslation)
        const trans = R.path(['page'], selectedTrans)

        const userEmail = R.path(['email'], user)

        return (
            <div className="header__phone hide__mobile__header">
                <div className="pure-g header__grid--phone">
                    <div className="pure-u-3-24"></div>
                    <div className="header__grid--area--phone pure-u-9-24">
                        <div className="header__contents pure-menu pure-menu-horizontal">
                            {!token && (
                                <div className="header__menu">
                                    <Link
                                        to={`/${lang}/${cc}/login`}
                                        className="profile__link pure-menu-link"
                                        onClick={closeMobileHeader}
                                    >
                                        <span className="profile__link__textcontent">{R.path(['login'], trans)}</span>
                                        <div className="iconprofile__container">
                                            <i className="fa fa-user fa-2x"></i>
                                        </div>
                                    </Link>
                                </div>
                            )}
                            {token && (
                                <div className="header__menu">
                                    <Link
                                        to={`/${lang}/${cc}`}
                                        onClick={logout}
                                        className="profile__link pure-menu-link"
                                    >
                                        <div className="profile__link__column">
                                            <span className="profile__link__textcontent smaller">
                                                {R.path(['logout'], trans)}
                                            </span>
                                            <span className="profile__link__email">{userEmail}</span>
                                        </div>
                                        <div className="iconprofile__container">
                                            <i className="fa fa-user fa-2x"></i>
                                        </div>
                                    </Link>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="header__grid--area--phone header__closeMobileHeader pure-u-9-24">
                        <div className="header__close">
                            <div className="header__closeimage--container" onClick={closeMobileHeader}>
                                <i className="fa fa-close fa-2x"></i>
                            </div>
                        </div>
                    </div>
                    <div className="pure-u-3-24"></div>
                </div>
                <div className="pure-g header__grid--phone--verticalmenu">
                    <div className="pure-u-3-24"></div>
                    <div className="header__grid--phone--verticalmenu--content pure-u-18-24">
                        <div className="header__grid--phone--verticalmenu--content--menu pure-menu">
                            <div className="header__menu--vertical">
                                <ul className="pure-menu-list">
                                    <li className="header__phone--vertical--menu--item pure-menu-item">
                                        <NavLink
                                            exact
                                            activeClassName="header__link__active"
                                            className="header__menu--link pure-menu-link"
                                            to={`/${lang}/${cc}`}
                                            onClick={closeMobileHeader}
                                        >
                                            {R.path(['frontPage'], trans)}
                                        </NavLink>
                                    </li>
                                    <li className="header__phone--vertical--menu--item pure-menu-item">
                                        <NavLink
                                            activeClassName="header__link__active"
                                            className="header__menu--link pure-menu-link"
                                            to={`/${lang}/${cc}/category`}
                                            onClick={handle__categoryMobileClick}
                                        >
                                            {R.path(['category'], trans)}
                                        </NavLink>
                                    </li>

                                    <li className="header__phone--vertical--menu--item pure-menu-item">
                                        <NavLink
                                            activeClassName="header__link__active"
                                            className="header__menu--link pure-menu-link"
                                            to={`/${lang}/fi/product/1001`}
                                            onClick={handle__giftcardClick}
                                        >
                                            {R.path(['giftCard'], trans)}
                                        </NavLink>
                                    </li>
                                    <li className="header__phone--vertical--menu--item pure-menu-item">
                                        <a
                                            className="header__menu--link pure-menu-link"
                                            href={`${config.SERVER}/${lang}/${cc}/${
                                                lang === 'fi' ? 'tietoa' : 'content'
                                            }`}
                                            onClick={handle__categoryMobileClick}
                                        >
                                            {R.path(['articles'], trans)}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="pure-u-3-24"></div>
                </div>
                <div className="pure-g header__grid--phone--langselect">
                    <div className="pure-u-3-24"></div>
                    <div className="header__grid--phone--verticalmenu--content pure-u-18-24">
                        <div className="header__grid--phone--verticalmenu--content--menu pure-menu pure-menu-horizontal">
                            <div className="header__menu--vertical">
                                <ul className="pure-menu-list">
                                    <li className="pure-menu-item pure-menu-has-children pure-menu-allow-hover">
                                        <span id="menuLink1" className="pure-menu-link">
                                            {lang}
                                        </span>
                                        <ul className="pure-menu-children">
                                            {lang === 'fi' && (
                                                <li className="pure-menu-item">
                                                    <Link
                                                        to=""
                                                        onClick={(evt) => this.props.changeLang('en', evt)}
                                                        className="pure-menu-link"
                                                    >
                                                        EN
                                                    </Link>
                                                </li>
                                            )}
                                            {lang === 'en' && (
                                                <li className="pure-menu-item">
                                                    <Link
                                                        to=""
                                                        onClick={(evt) => this.props.changeLang('fi', evt)}
                                                        className="pure-menu-link"
                                                    >
                                                        FI
                                                    </Link>
                                                </li>
                                            )}
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="pure-u-3-24"></div>
                </div>
            </div>
        )
    }
}

class Header extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showMobileHeader: false,
        }
        this.openMobileHeader = this.openMobileHeader.bind(this)
        this.closeMobileHeader = this.closeMobileHeader.bind(this)
        this.handle__clearFilters = this.handle__clearFilters.bind(this)
        this.handle__giftcardClick = this.handle__giftcardClick.bind(this)
        this.handle__categoryMobileClick = this.handle__categoryMobileClick.bind(this)
        this.logout = this.logout.bind(this)
    }

    openMobileHeader(event) {
        event.preventDefault()
        this.setState({ showMobileHeader: true })
        document.body.style.overflow = 'hidden'
    }

    closeMobileHeader(event) {
        this.setState({ showMobileHeader: false })
        document.body.style.overflow = 'initial'
    }

    handle__clearFilters() {
        const { initiateSearchClick, cc } = this.props
        /* Clear filters from store before proceeding */
        initiateSearchClick('', '', '', '', '', '', R.toUpper(cc))
    }

    handle__giftcardClick(event) {
        const { initiateSearchClick, lang, cc } = this.props
        this.closeMobileHeader()
        cc === 'fi'
            ? initiateSearchClick('', '', '', '', '', '', 'FI')
            : (window.location.href = `/${lang}/fi/product/1001`)
    }

    handle__categoryMobileClick() {
        const { initiateSearchClick, cc } = this.props
        this.closeMobileHeader()
        /* Clear filters from store before proceeding to category page */
        initiateSearchClick('', '', '', '', '', '', R.toUpper(cc))
    }

    changeLang(newLang, evt) {
        evt.preventDefault()
        const arr = R.split('/', R.path(['location', 'pathname'], window))
        arr[1] = newLang
        const newPath = R.concat(R.path(['location', 'origin'], window), R.join('/', arr))
        window.location.href = newPath
    }

    logout() {
        const { logout } = this.props
        logout()
    }

    getDriftLink() {
        const { lang, cc, location } = this.props

        const isAddOns = location.pathname === `/${lang}/${cc}/order/add-ons`
        const isFinnishGiftCardPage = location.pathname === `/${lang}/${cc}/product/1001`
        const isEnglishSite = lang == 'en'

        if (isEnglishSite) return '#lead-bot-en'
        if (isAddOns) return '#add-ons-bot'
        if (isFinnishGiftCardPage) return '#giftcard-bot'
        return '#lead-bot-fi'
    }

    render() {
        const { lang, cc, token, user, products, cartModal, hideCartModal, location } = this.props
        const selectedTrans = R.path([lang], pageTranslation)
        const trans = R.path(['page'], selectedTrans)

        const userEmail = R.path(['email'], user)
        const productCount = R.length(products)

        const driftLink = this.getDriftLink()

        return (
            <div className="header__container">
                <div className="header__grid pure-g">
                    <div className="header__black pure-u-2-24 pure-u-md-1-24"></div>
                    <div className="header__grid--area pure-u-10-24 header__logo__area__desktop__width">
                        <Link className="header__logo" to={`/${lang}/fi`}>
                            <div className="header__logoimage--container">
                                <div className="header__logo--image" style={logoStyle}></div>
                            </div>
                        </Link>
                    </div>
                    <div className="header__grid--area">
                        <div className="header__cart">
                            <Link to={`/${lang}/${cc}/category`} />
                        </div>
                    </div>
                    <div className="header__grid--area pure-u-11-24 hide__mobile__header">
                        <div className="header__burger">
                            <div className="header__burgerimage--container">
                                <Link to="" onClick={this.openMobileHeader}>
                                    <i className="fa fa-bars fa-2x link-gold"></i>
                                </Link>
                            </div>
                            <div className="header__burgerimage--container chat-area-mobile">
                                <a href={driftLink}>
                                    <i className="fa fa-comment fa-lg icon-chat-mobile"></i>
                                </a>
                            </div>
                            <div className="header__burgerimage--container cart-area-mobile">
                                <Link to={`/${lang}/${cc}/order/overview`}>
                                    <i className="fa fa-shopping-cart fa-lg icon-cart-mobile"></i>
                                </Link>
                                {productCount > 0 && <div className="product-indicator">{productCount}</div>}
                            </div>
                        </div>
                    </div>
                    <div className="header__grid--area pure-u-md-11-24 pure-u-lg-10-24 hide__desktop__header">
                        <div className="header__contents pure-menu pure-menu-horizontal">
                            <div className="header__menu">
                                <ul className="pure-menu-list">
                                    <li className="pure-menu-item" id="Header-send-flowers-button">
                                        <NavLink
                                            activeClassName="header__link__active"
                                            className="header__menu--link pure-menu-link"
                                            to={`/${lang}/${cc}/category`}
                                            onClick={this.handle__clearFilters}
                                        >
                                            {R.path(['category'], trans)}
                                        </NavLink>
                                    </li>
                                    <li className="pure-menu-item" id="Header-giftcard-button">
                                        <NavLink
                                            activeClassName="header__link__active"
                                            className="header__menu--link pure-menu-link"
                                            to={`/${lang}/fi/product/1001`}
                                            onClick={this.handle__giftcardClick}
                                        >
                                            {R.path(['giftCard'], trans)}
                                        </NavLink>
                                    </li>
                                    <li className="pure-menu-item">
                                        <a
                                            className="header__menu--link pure-menu-link"
                                            style={{ cursor: 'pointer' }}
                                            href={`${config.SERVER}/${lang}/${cc}/${
                                                lang === 'fi' ? 'tietoa' : 'content'
                                            }`}
                                            onClick={this.handle__clearFilters}
                                        >
                                            {R.path(['articles'], trans)}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="header__grid--area pure-u-lg-6-24 hide__desktop__header">
                        <div className="header__contents pure-menu pure-menu-horizontal">
                            <div className="header__menu">
                                <ul className="header__menu--login pure-menu-list">
                                    <li className="pure-menu-item pure-menu-has-children pure-menu-allow-hover">
                                        <span id="menuLink1" className="pure-menu-link link-white">
                                            {lang}
                                        </span>
                                        <ul className="pure-menu-children">
                                            {lang === 'fi' && (
                                                <li className="pure-menu-item">
                                                    <Link
                                                        to=""
                                                        onClick={(evt) => this.changeLang('en', evt)}
                                                        className="pure-menu-link"
                                                    >
                                                        EN
                                                    </Link>
                                                </li>
                                            )}
                                            {lang === 'en' && (
                                                <li className="pure-menu-item">
                                                    <Link
                                                        to=""
                                                        onClick={(evt) => this.changeLang('fi', evt)}
                                                        className="pure-menu-link"
                                                    >
                                                        FI
                                                    </Link>
                                                </li>
                                            )}
                                        </ul>
                                    </li>
                                    <li className="pure-menu-item">
                                        <a href={driftLink}>
                                            <i className="fa fa-comment fa-lg icon-chat-desktop"></i>
                                        </a>
                                    </li>
                                    <li className="pure-menu-item" id={'shopping-cart-link'}>
                                        <Link to={`/${lang}/${cc}/order/overview`} onClick={hideCartModal}>
                                            <i className="fa fa-shopping-cart fa-lg icon-cart-desktop"></i>
                                        </Link>
                                        {productCount > 0 && <div className="product-indicator">{productCount}</div>}
                                    </li>
                                    <li className="pure-menu-item">
                                        <ProfileButton
                                            cc={cc}
                                            lang={lang}
                                            userEmail={userEmail}
                                            token={token}
                                            logout={this.logout.bind(this)}
                                        />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="pure-u-3-24 pure-u-md-1-24"></div>
                </div>
                {this.state.showMobileHeader ? (
                    <MobileHeader
                        lang={lang}
                        cc={cc}
                        token={token}
                        user={user}
                        changeLang={this.changeLang}
                        closeMobileHeader={this.closeMobileHeader}
                        logout={this.logout}
                        handle__categoryMobileClick={this.handle__categoryMobileClick}
                        handle__giftcardClick={this.handle__giftcardClick}
                    />
                ) : null}
                {cartModal ? <CartModal /> : null}
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    token: R.path(['login', 'token'], state),
    user: R.path(['login', 'user'], state),
    cc: R.path(['lang', 'db'], state),
    lang: R.path(['lang', 'lang'], state),
    products: R.pathOr([], ['cart', 'items', 'products'], state),
    cartModal: R.path(['cartModal', 'show'], state),
    location: R.path(['router', 'location'], state),
})

const mapDispatchToProps = (dispatch) => ({
    initiateSearchClick: (
        categoryOccasion,
        categoryColor,
        categoryContains,
        categoryType,
        minPrice,
        categoryPriceSort,
        selectedCountryCode,
    ) =>
        dispatch(
            initiateSearch(
                categoryOccasion,
                categoryColor,
                categoryContains,
                categoryType,
                minPrice,
                categoryPriceSort,
                selectedCountryCode,
            ),
        ),
    resetToken: () => dispatch(resetToken()),
    logout: () => dispatch(userLogOut()),
    hideCartModal: () => dispatch(hideCartModal()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header))
