import React from 'react'

const CrossmarkIcon = () => (
    <svg width="25" height="25" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1_69)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11 0C13.1756 0 15.3023 0.645139 17.1113 1.85383C18.9202 3.06253 20.3301 4.7805 21.1627 6.79048C21.9952 8.80047 22.2131 11.0122 21.7886 13.146C21.3642 15.2798 20.3166 17.2398 18.7782 18.7782C17.2398 20.3166 15.2798 21.3642 13.146 21.7886C11.0122 22.2131 8.80047 21.9952 6.79048 21.1627C4.7805 20.3301 3.06253 18.9202 1.85383 17.1113C0.645139 15.3023 0 13.1756 0 11C0 8.08262 1.15893 5.28473 3.22183 3.22183C5.28473 1.15893 8.08262 0 11 0ZM13.3525 6.58854C13.6641 6.27165 13.8592 6.01921 14.2477 6.40951L15.501 7.68604C15.911 8.09066 15.8895 8.32699 15.501 8.70475L13.1287 11.0698L15.4115 13.3525C15.7284 13.6641 15.9808 13.8592 15.5905 14.2477L14.314 15.501C13.9093 15.911 13.673 15.8895 13.297 15.501L11 13.1986L8.70654 15.492C8.33057 15.877 8.09424 15.8984 7.68962 15.492L6.41309 14.2388C6.01921 13.8538 6.27165 13.6587 6.59212 13.3436L8.87126 11.0698L6.50798 8.70833C6.12305 8.33057 6.10156 8.09424 6.50798 7.68962L7.76123 6.41309C8.14616 6.01921 8.34131 6.27165 8.65641 6.59212L11 8.94108L13.3525 6.58854Z"
                fill="#FF6C6C"
            />
        </g>
        <defs>
            <clipPath id="clip0_1_69">
                <rect width="25" height="25" fill="white" />
            </clipPath>
        </defs>
    </svg>
)

export default CrossmarkIcon
