import * as R from 'ramda'
import React, { useEffect } from 'react'
import { Link, withRouter } from 'react-router-dom'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { trackPurchase } from '../../tracking/tracking'
import { getLanguage } from '../../utils/translation'
import { createNewCart } from '../../actions/cartActions'
import { clearPayform } from '../../actions/payformActions'

const pageTranslation = {
    fi: {
        page: {
            headerThankYou: 'Kiitos tilauksesta!',
            headerFailed: 'Maksu epäonnistui',
            paymentOkParagraph_1:
                'Maksusi on mennyt onnistuneesti läpi ja olemme lähettäneet tilausvahvistuksen sähköpostiisi. Mikäli et saa tilausvahvistusta noin 10 minuutin kuluessa, suosittelemme tarkistamaan roskapostilaatikon. Automaattiset viestit saattavat toisinaan mennä sinne.',
            paymentOkParagraph_2:
                'Jos haluat vielä tehdä muutoksia tilaukseesi, voit ilmoittaa ne meille vastaamalla sähköpostitse tilausvahvistusviestiin tai soittaa asiakaspalvelunumeroomme 010 217 6320. Asiakaspalvelumme sähköpostiosoite on info@interflora.fi.',
            paymentUnverifiedParagraph_1:
                'Maksusi on mennyt onnistuneesti läpi, mutta maksupalvelun tarjoaja ei ole vielä vahvistanut sitä. Lähetämme sinulle tilausvahvistuksen ja kuitin sähköpostiisi heti, kun maksusi on vahvistettu. Mikäli et saa tilausvahvistusta noin 10 minuutin kuluessa, suosittelemme tarkistamaan roskapostilaatikon. Automaattiset viestit saattavat toisinaan mennä sinne.',
            paymentUnverifiedParagraph_2:
                'Jos haluat vielä tehdä muutoksia tilaukseesi, voit ilmoittaa ne meille vastaamalla sähköpostitse tilausvahvistusviestiin tai soittaa asiakaspalvelunumeroomme 010 217 6320. Asiakaspalvelumme sähköpostiosoite on info@interflora.fi.',
            continueFrontPage: 'Jatka etusivulle',
            backToPayment: 'Palaa maksuun',
            paymentFailedParagraph_1:
                'Valitettavasti maksua ei voitu veloittaa. Voit palata maksusivulle ja valita toisen maksutavan.',
            paymentFailedParagraph_2:
                'Tilauksesi tiedot ovat jo tallentuneet järjestelmäämme. Mikäli huomaamme, että maksutapahtuma ei vahvistu, otamme sinuun yhteyttä asiakaspalvelumme aukioloaikana mahdollisimman pikaisesti. Halutessasi voit ottaa yhteyttä asiakaspalveluumme soittamalla numeroon 010 217 6320 tai sähköpostitse info@interflora.fi.',
        },
    },
    en: {
        page: {
            headerThankYou: 'Thank you for ordering!',
            headerFailed: 'Back',
            paymentOkParagraph_1:
                'Your payment has been successfully processed and we have sent an order confirmation to the provided email address.',
            paymentOkParagraph_2:
                'If you want to make changes to your order, please reply in the order confirmation we sent you by email or call our customer service 010 217 6320. Customer service email address is info@interflora.fi.',
            paymentUnverifiedParagraph_1:
                "Your payment has been successfully processed, but the payment provider hasn't verified it yet. We will send an order confirmation to the provided email address as soon as the payment is verified.",
            paymentUnverifiedParagraph_2:
                'If you want to make changes to your order, please reply in the order confirmation we sent you by email or call our customer service 010 217 6320. Customer service email address is info@interflora.fi.',
            continueFrontPage: 'Continue to front page',
            backToPayment: 'Back to payment options',
            paymentFailedParagraph_1:
                'Unfortunately we could not process your payment. Please return to the previous page and select another payment method.',
            paymentFailedParagraph_2:
                'Your order information has been registered in our system. In case we notice that the charge is unsuccessful, our customer service will contact you as soon as possible. You may contact our customer service by calling 010 217 6320 or by email info@interflora.fi.',
        },
    },
}

const FormThankYou = ({ storeState, lang, cc, invoiceRedirect, createNewCart, settled, clearPayform }) => {
    useEffect(() => {
        window.scrollTo(0, 0)
        clearPayform()

        // For invoice purchases tracking is set here. For other purchases
        // tracking is set in App.jsx before cart creation (as redirect
        // from 3rd party site causes App.jsx to run again).
        if (invoiceRedirect === true) {
            const products = R.path(['cart', 'items', 'products'], storeState)
            if (products.length) trackPurchase(storeState)

            createNewCart()
        }
    }, [])

    const selectedTrans = R.path([getLanguage()], pageTranslation)
    const trans = R.path(['page'], selectedTrans)
    const payment_containerStyle = classNames('form__thankyou__wrapper', 'form__payment--success', 'verticalAlign')

    return (
        <div className="productOrder__contentHolder">
            <div className={payment_containerStyle}>
                <div className="verticalAlignChild">
                    <form className="pure-form pure-form-stacked productOrder__form">
                        <fieldset>
                            <div>
                                <h2 className="order__headline">{R.path(['headerThankYou'], trans)}</h2>
                            </div>
                            {settled === '1' && (
                                <div>
                                    <p>{R.path(['paymentOkParagraph_1'], trans)}</p>
                                    <p>{R.path(['paymentOkParagraph_2'], trans)}</p>
                                </div>
                            )}
                            {settled === '0' && (
                                <div>
                                    <p>{R.path(['paymentUnverifiedParagraph_1'], trans)}</p>
                                    <p>{R.path(['paymentUnverifiedParagraph_2'], trans)}</p>
                                </div>
                            )}
                            <div className="productOrder__buttonContainerCentered">
                                <Link to={`/${lang}/${cc}`}>
                                    <button
                                        type="submit"
                                        className="pure-button pure-button-primary productOrder__button productOrder__button--inverse"
                                    >
                                        {R.path(['continueFrontPage'], trans)}
                                    </button>
                                </Link>
                            </div>
                        </fieldset>
                    </form>
                </div>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        storeState: state,
        lang: R.path(['lang', 'lang'], state),
        cc: R.path(['lang', 'db'], state),
        invoiceRedirect: R.path(['invoice', 'redirect'], state),
    }
}

const mapDispatchToProps = (dispatch) => ({
    createNewCart: () => dispatch(createNewCart()),
    clearPayform: () => dispatch(clearPayform()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FormThankYou))
