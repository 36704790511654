import * as R from 'ramda'

export const getProductById = (products = [], productId) => R.find(R.propEq('id', Number(productId)))(products)

export const isGiftcardByProductId = (id) => R.or(id === '1001', id === 1001)

export const isForeignProduct = (lang) => R.toLower(R.pathOr('', ['db'], lang)) !== 'fi'

export const isForeignProductByProduct = (product = {}) => R.toLower(R.pathOr('', ['countrycode'], product)) !== 'fi'

export const isFuneralProduct = (products = [], productId) => {
    const occasion = R.pathOr([], ['occasion'], getProductById(products, productId))
    return R.or(R.contains('hautajaiset')(occasion), R.contains('funeral')(occasion))
}

// Cart has only funeral products
export const isFuneralOnlyOrder = (cartProducts) => {
    const nonFuneralProducts = cartProducts.filter((product) => !isFuneralProduct(cartProducts, product.id))
    return !nonFuneralProducts.length
}

export const getProductsCountryName = (countries = [], product) => {
    const productCountryCode = R.path(['countrycode'], product)
    const productCountry = R.find(R.propEq('CountryCode', productCountryCode))(countries)
    return R.path(['Name'], productCountry)
}
