import React, { Component } from 'react'
import classNames from 'classnames'

class Checkbox extends Component {
    constructor(props) {
        super(props)
        const { isSelected, helperText } = props
        this.state = {
            selected: isSelected,
            helperText: helperText,
        }
    }

    onChange(event) {
        event.preventDefault()
        const { onCheckboxChange } = this.props
        const newState = !this.state.selected
        this.setState({ selected: newState })
        onCheckboxChange && onCheckboxChange(newState)
    }

    render() {
        const isSelected = this.state.selected
        const styles = classNames('checkbox-container', isSelected ? 'selected' : '')

        return (
            <div className="checkbox-wrapper">
                <label className={styles}>
                    <input selected={this.state.selected} type="checkbox" onChange={this.onChange.bind(this)} />
                    <span className="checkbox_custom"></span>
                </label>
                <p>{this.props.helperText}</p>
            </div>
        )
    }
}

export default Checkbox
