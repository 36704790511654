import * as R from 'ramda'
import { combineReducers } from 'redux'
import { SET_LANG, SET_DB } from '../actions/langActions'

const langReducer = (state = 'fi', action) => {
    switch (action.type) {
        case SET_LANG:
            return action.lang
        default:
            return state
    }
}

const db = (state = 'fi', action) => {
    switch (action.type) {
        case SET_DB:
            return R.toLower(R.path(['db'], action))
        default:
            return state
    }
}

export default combineReducers({
    lang: langReducer,
    db: db,
})
