import pull from 'lodash/pull'

import {
    SET_LAST_VIEWED_PRODUCTID,
    SAVE_PRODUCTID,
    SAVE_GTM_PRODUCTID,
    ADD_TO_CART_ADDITIONAL_PRODUCT,
    REMOVE_FROM_CART_ADDITIONAL_PRODUCT,
    REMOVE_FROM_CART_ADDITIONAL_PRODUCT_INSTANCES,
    EMPTY_CART_ADDITIONAL_PRODUCT,
    SELECT_RIBBON,
    UNSELECT_RIBBON,
    SELECT_CONDOLENCES,
    UNSELECT_CONDOLENCES,
    CLEAR_ORDER,
} from '../actions/orderActions'

const initialState = {
    lastViewedProductId: null,
    selectedProductId: null,
    GTMProductId: null,
    GTMProductName: null,
    GTMProductPrice: null,
    selectedAddOnProducts: [],
    ribbon: false,
    condolences: false,
}

export default function orderReducer(state = initialState, action) {
    switch (action.type) {
        case SET_LAST_VIEWED_PRODUCTID:
            return {
                ...state,
                lastViewedProductId: action.productId,
            }
        case SAVE_PRODUCTID:
            return {
                ...state,
                selectedProductId: action.selectedProductId,
            }
        case SAVE_GTM_PRODUCTID:
            return {
                ...state,
                GTMProductId: action.GTMProductId,
                GTMProductName: action.GTMProductName,
                GTMProductPrice: action.GTMProductPrice,
            }
        case ADD_TO_CART_ADDITIONAL_PRODUCT:
            const currentArray = state.selectedAddOnProducts
            const newArray = [action.selectedAdditionalProductId]

            return {
                ...state,
                selectedAddOnProducts: currentArray.concat(newArray),
            }
        case REMOVE_FROM_CART_ADDITIONAL_PRODUCT:
            const index = state.selectedAddOnProducts.findIndex((item) => item === action.selectedAdditionalProductId)
            const arrayCopy = [...state.selectedAddOnProducts]

            if (index !== -1) arrayCopy.splice(index, 1)

            return {
                ...state,
                selectedAddOnProducts: arrayCopy,
            }
        case REMOVE_FROM_CART_ADDITIONAL_PRODUCT_INSTANCES:
            const filteredArray = pull(state.selectedAddOnProducts, action.selectedAdditionalProductId)

            return {
                ...state,
                selectedAddOnProducts: filteredArray,
            }
        case EMPTY_CART_ADDITIONAL_PRODUCT:
            return {
                ...state,
                selectedAddOnProducts: [],
            }
        case SELECT_RIBBON:
            return {
                ...state,
                ribbon: true,
            }
        case UNSELECT_RIBBON:
            return {
                ...state,
                ribbon: false,
            }
        case SELECT_CONDOLENCES:
            return {
                ...state,
                condolences: true,
            }
        case UNSELECT_CONDOLENCES:
            return {
                ...state,
                condolences: false,
            }
        case CLEAR_ORDER:
            return initialState
        default:
            return state
    }
}
