import * as R from 'ramda'
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import OverviewContainer from '../overview/OverviewContainer'
import BottomNavigationBar from '../bottom-navigation-bar/BottomNavigationBar'
import { getLanguage } from '../../utils/translation'
import { isGiftcardByProductId } from '../../utils/ProductTypes'

const pageTranslation = {
    fi: {
        page: {
            overView: 'Valitsemasi kukat',
            send: 'Jatka tilausta',
            emptyCart: 'Ostoskorisi on tyhjä',
        },
    },
    en: {
        page: {
            overView: 'Chosen flowers',
            send: 'Continue',
            emptyCart: 'No Products in cart',
        },
    },
}

class OverviewSubContainer extends Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        const { lang, cc, items } = this.props

        const selectedTrans = R.path([getLanguage()], pageTranslation)
        const trans = R.path(['page'], selectedTrans)

        const products = R.pathOr([], ['products'], items)
        const isEmpty = R.isEmpty(products)

        const firstProduct = R.head(products)
        const productCountryCode = R.toUpper(R.pathOr('fi', ['countrycode'], firstProduct))

        const isForeign = productCountryCode !== 'FI'
        const isGiftcard = isGiftcardByProductId(R.prop('id', firstProduct))

        const redirectURL =
            !isForeign && !isGiftcard ? `/${lang}/${cc}/order/add-ons` : `/${lang}/${cc}/order/recipient-info`

        return (
            <div className="productOrderSubContainer">
                {!isEmpty && (
                    <div>
                        <div className="overview__container">
                            <h1>{R.path(['overView'], trans)}</h1>
                            <OverviewContainer />
                        </div>
                        <BottomNavigationBar
                            backLink={`/${lang}/${cc}/`}
                            continueLink={redirectURL}
                            hasForm={false}
                            fullScreen={true}
                        />
                    </div>
                )}
                {isEmpty && (
                    <div className="overview__container">
                        <h1>{R.path(['emptyCart'], trans)}</h1>
                    </div>
                )}
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    items: R.pathOr([], ['cart', 'items'], state),
})

const mapDispatchToProps = (dispatch, ownProps) => ({})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OverviewSubContainer))
