import * as R from 'ramda'
import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { getLanguage } from '../../utils/translation'
import { parse } from 'query-string'
import { getCart } from '../../actions/cartActions'
import LoadingIndicator from '../productorder/LoadingIndicator'

const pageTranslation = {
    fi: {
        page: {
            paymentFailed: 'Maksu epäonnistui',
            failedText:
                'Valitettavasti maksua ei voitu veloittaa. Voit palata tuotevalintaan ja tehdä tilauksen uudelleen.',
            contactText:
                'Jos ongelma toistuu, voit ottaa yhteyttä asiakaspalveluumme soittamalla numeroon 010 217 6320 tai sähköpostitse info@interflora.fi.',
            buttonText: 'Palaa maksuun',
        },
    },
    en: {
        page: {
            paymentFailed: 'The payment could not be charged ',
            failedText:
                'Sorry, the payment could not be charged. You may return to the product selection and try again.',
            contactText:
                'If the problem recurs you can contact our customer service by email info@interflora.fi or call 0102176320.',
            buttonText: 'Back to payment',
        },
    },
}

class FormPaymentFailed extends Component {
    constructor(props, context) {
        super(props, context)

        const { lang, cc, history, location } = this.props

        const query = parse(R.path(['search'], location))
        if (R.path(['redirect'], query) !== 'true') {
            history.push('/' + lang + '/' + cc)
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    componentWillUnmount() {
        const { getCart } = this.props
        getCart()
    }

    render() {
        const payment_containerStyle = classNames('form__thankyou__wrapper', 'verticalAlign', 'form__payment--error')

        const { lang, cc, selectedProductId, cart } = this.props
        const selectedTrans = R.path([getLanguage()], pageTranslation)
        const trans = R.path(['page'], selectedTrans)

        const linkUrl = selectedProductId ? `/${lang}/${cc}/order/payment` : `/${lang}/${cc}/`
        return (
            <div className="productOrder__contentHolder">
                <div className={payment_containerStyle}>
                    <div className="verticalAlignChild">
                        <form className="pure-form pure-form-stacked productOrder__form">
                            <fieldset>
                                <div>
                                    <h2 className="order__headline">{R.path(['paymentFailed'], trans)}</h2>
                                </div>

                                <div>
                                    <p>{R.path(['failedText'], trans)}</p>
                                    <p>{R.path(['contactText'], trans)}</p>
                                </div>
                                {cart.loading ? (
                                    <LoadingIndicator />
                                ) : (
                                    <div className="productOrder__buttonContainerCentered">
                                        <Link to={linkUrl}>
                                            <button
                                                type="submit"
                                                className="pure-button pure-button-primary productOrder__button productOrder__button--inverse"
                                            >
                                                {R.path(['buttonText'], trans)}
                                            </button>
                                        </Link>
                                    </div>
                                )}
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    selectedProductId: R.path(['order', 'selectedProductId'], state),
    lang: R.path(['lang', 'lang'], state),
    cc: R.path(['lang', 'db'], state),
    cart: R.path(['cart'], state),
})

function mapDispatchToProps(dispatch) {
    return {
        getCart: () => dispatch(getCart()),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FormPaymentFailed))
