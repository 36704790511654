import * as R from 'ramda'
import React, { useState } from 'react'
import { connect } from 'react-redux'

import {
    addAdditionalProduct,
    removeAdditionalProduct,
    removeAllAdditionalProductInstances,
    selectRibbon,
    unselectRibbon,
    selectCondolences,
    unselectCondolences,
} from '../../actions/orderActions'

import { showModal } from '../../actions/modalActions'

import { getLanguage } from '../../utils/translation'

import config from '../../config'
const assets = R.path(['ASSETS'], config)

const pageTranslation = {
    fi: {
        page: {
            moreInfo: 'Lisätietoa',
            addToCart: 'Lisää koriin',
            selected: 'Lisätty',
            total: 'Yhteensä: ',
            removeAll: 'Poista kaikki',
        },
    },
    en: {
        page: {
            moreInfo: 'Description',
            addToCart: 'Add to cart',
            selected: 'Selected',
            total: 'Total: ',
            removeAll: 'Remove all',
        },
    },
}

const AddOnProductCard = ({
    product,
    cartExtras,
    addAdditionalProductRedux,
    removeAdditionalProductRedux,
    removeAdditionalProductInstancesRedux,
    selectedAddOnProducts,
    showModal,
    selectRibbonRedux,
    selectCondolencesRedux,
    unselectRibbonRedux,
    unselectCondolencesRedux,
}) => {
    const productId = R.path(['id'], product)
    const productGooseId = R.path(['goose_id'], product)
    const selectedExtras = R.map(R.prop('goose_id'))(cartExtras)
    const isInCart = R.any(R.equals(productGooseId))(selectedExtras)

    const [inCart, setInCart] = useState(isInCart)

    const isRibbon = () => {
        if (R.path(['goose_id'], product) === 'fi_701') return true
        return false
    }

    const isCondolences = () => {
        if (R.path(['goose_id'], product) === 'fi_703') return true
        return false
    }

    const handleAddToCart = (event) => {
        event.preventDefault()

        if (!inCart) setInCart(true)
        addAdditionalProductRedux()

        if (isRibbon()) {
            selectRibbonRedux()
        }
        if (isCondolences()) {
            selectCondolencesRedux()
        }
    }

    const handleDecrement = (event) => {
        event.preventDefault()

        const amount = getReduxExtraAmount()

        if (amount === 1) setInCart(false)
        removeAdditionalProductRedux()
    }

    const handleRemoveAll = (event) => {
        event.preventDefault()

        setInCart(false)
        removeAdditionalProductInstancesRedux()
    }

    const handleUnselect = (event) => {
        event.preventDefault()

        setInCart(false)
        removeAdditionalProductRedux()

        if (isRibbon()) unselectRibbonRedux()
        if (isCondolences()) unselectCondolencesRedux()
    }

    const getReduxExtraAmount = () => {
        const count = selectedAddOnProducts.reduce((total, value) => {
            return value === productId ? total + 1 : total
        }, 0)

        if (inCart && count === 0) setInCart(false)
        if (!inCart && count > 0) setInCart(true)

        return count
    }

    const openModal = () => {
        showModal(
            true,
            'Add on product more information modal',
            'addon',
            'modal__content alignLeft',
            R.path(['name'], product),
            R.path(['description'], product),
        )
    }

    const amount = getReduxExtraAmount()

    const selectedTrans = R.path([getLanguage()], pageTranslation)
    const trans = R.path(['page'], selectedTrans)

    const image = `${assets}/${product.image}`

    return (
        <div className="product__additionalproducts__card">
            <div className="product__additionalproducts__card__left product__additionalproducts__card__top">
                <img className="addon__img" src={image} />
            </div>

            <div className="product__additionalproducts__card__right product__additionalproducts__card__bottom">
                <div className="product__additionalproducts__card__right__top">
                    <div className="addon__info__container">
                        <span className="addon__name">{R.path(['name'], product)}</span>
                        <button className="addon__info__button" onClick={openModal}>
                            <i className="fa fa-info-circle fa-lg addon__info__icon" aria-hidden="true"></i>
                        </button>
                    </div>

                    <div className="addon__price">
                        <label className="addon__price__label" id={`AddOnProductCard-price-${productId}`}>
                            {R.path(['price'], product)}€
                        </label>
                    </div>

                    {amount > 0 && !isRibbon() && !isCondolences() && (
                        <div className="addon__total">
                            <label className="addon__total__label" id={`AddOnProductCard-total-${productId}`}>
                                {R.path(['total'], trans)}
                                <span className="text__style__2">{R.path(['price'], product) * amount}€</span>
                            </label>
                        </div>
                    )}
                </div>

                {!inCart && (
                    <button
                        className="addon__addtocart"
                        id={`AddOnProductCard-addtocart-${productId}`}
                        onClick={handleAddToCart}
                    >
                        <span className="addon__addtocart__label">{R.path(['addToCart'], trans)}</span>
                        <span className="addon__addtocart__label addon__addtocart__label__plus">+</span>
                    </button>
                )}

                {inCart && !isRibbon() && !isCondolences() && (
                    <div className="addon__counter">
                        <div className="addon__amount">
                            <button
                                className="addon__minus__button"
                                onClick={handleDecrement}
                                id={`AddOnProductCard-decrement-${productId}`}
                            >
                                -
                            </button>
                            <span className="addon__amount__label">{amount}</span>
                            <button
                                className="addon__plus__button"
                                onClick={handleAddToCart}
                                id={`AddOnProductCard-increment-${productId}`}
                            >
                                +
                            </button>
                        </div>

                        <button
                            className="addon__removeall"
                            onClick={handleRemoveAll}
                            id={`AddOnProductCard-removeall-${productId}`}
                        >
                            <i className="fa fa-trash-o addon__removeall__icon"></i>
                        </button>

                        <button
                            className="addon__removeall__1024"
                            onClick={handleRemoveAll}
                            id={`AddOnProductCard-removeall-${productId}`}
                        >
                            <i className="fa fa-trash-o addon__removeall__1024__icon"></i>
                            <span className="addon__removeall__1024__label">{R.path(['removeAll'], trans)}</span>
                        </button>
                    </div>
                )}

                {inCart && (isRibbon() || isCondolences()) && (
                    <button
                        className="addon__selected"
                        onClick={handleUnselect}
                        id={`AddOnProductCard-selected-${productId}`}
                    >
                        <span className="addon__selected__label">{R.path(['selected'], trans)}</span>
                        <i className="fa fa-check addon__selected__icon" aria-hidden="true"></i>
                    </button>
                )}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    items: R.pathOr({}, ['cart', 'items'], state),
    selectedAddOnProducts: state.order.selectedAddOnProducts,
})

const mapDispatchToProps = (dispatch, ownProps) => {
    const additionalProductId = R.path(['product', 'id'], ownProps)
    return {
        addAdditionalProductRedux: () => dispatch(addAdditionalProduct(additionalProductId)),
        removeAdditionalProductRedux: () => dispatch(removeAdditionalProduct(additionalProductId)),
        removeAdditionalProductInstancesRedux: () => dispatch(removeAllAdditionalProductInstances(additionalProductId)),
        selectRibbonRedux: () => dispatch(selectRibbon()),
        unselectRibbonRedux: () => dispatch(unselectRibbon()),
        selectCondolencesRedux: () => dispatch(selectCondolences()),
        unselectCondolencesRedux: () => dispatch(unselectCondolences()),
        showModal: (show, label, modaltype, modalclass, headline, content) =>
            dispatch(showModal(show, label, modaltype, modalclass, headline, content)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddOnProductCard)
