import * as R from 'ramda'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { initiateSearch } from '../../actions/categoryActions'
import { connect } from 'react-redux'
import moment from 'moment-timezone'

import deburr from 'lodash/deburr'
import kebabCase from 'lodash/kebabCase'

import config from '../../config'
const assets = R.path(['ASSETS'], config)

class OccasionCard extends Component {
    handle_categoryOccasion_change(event) {
        this.props.selectOccasion(this.props.occasion.occasion, '', '', '', '', '', 'FI')
    }

    render() {
        const { occasion, lang, cc } = this.props

        let backgroundStyle = {}

        // Set bg pic if available
        if (occasion.pic) {
            const occasionPicName = deburr(kebabCase(occasion.occasion))
            backgroundStyle = {
                backgroundImage: `url(${assets}/${occasionPicName}.jpg)`,
            }
        }

        // Conditional rendering of this card
        if (occasion.featured) {
            if (occasion.time_start !== '' && occasion.time_end !== '') {
                if (
                    !moment(new Date())
                        .tz('Europe/Helsinki')
                        .isBetween(occasion.time_start, occasion.time_end, null, '[]')
                ) {
                    return null
                }
            }
        } else {
            return null
        }

        return (
            <div className="occasionCard__margin pure-u-1 pure-u-sm-1-2 pure-u-md-8-24 pure-u-lg-6-24">
                <Link
                    className="no-decoration"
                    to={`/${lang}/${cc}/category/`}
                    onClick={this.handle_categoryOccasion_change.bind(this)}
                >
                    <div style={backgroundStyle} className="pure-button pure-button-primary occasionCard__wrapper">
                        <div className="occasionCard">
                            <div className="occasionCard__textcontent">
                                <div>
                                    <div className="occasionCard__textcontent__colourbox">
                                        <p className="name">{occasion.occasion}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        selectOccasion: (
            categoryOccasion,
            categoryColor,
            categoryContains,
            categoryType,
            minPrice,
            categoryPriceSort,
            selectedCountryCode,
        ) =>
            dispatch(
                initiateSearch(
                    categoryOccasion,
                    categoryColor,
                    categoryContains,
                    categoryType,
                    minPrice,
                    categoryPriceSort,
                    selectedCountryCode,
                ),
            ),
    }
}

export default connect(null, mapDispatchToProps)(OccasionCard)
