import React, { Component } from 'react'

import { required, email } from '../../utils/ValidationRules.jsx'
import Form from 'react-validation/build/form'
import Input from 'react-validation/build/input'
import Button from 'react-validation/build/button'

import { getLanguage } from '../../utils/translation'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import * as R from 'ramda'

import { getPasswordResetEmail } from '../../api/backend'

const pageTranslation = {
    fi: {
        page: {
            email: 'Sähköposti',
            yourEmail: 'Sähköpostiosoitteesi',
            password: 'Salasana',
            yourPassword: 'Salasanasi',
            forgotPasswordInfo: 'Pyydä uusi salasana',
            forgotPasswordButton: 'Lähetä',
            forgotPasswordBacklink: 'Palaa kirjautumissivulle',
            resetEmailSent: 'Sähköposti lähetetty',
            noUserFound: 'Käyttäjää ei löytynyt',
        },
    },
    en: {
        page: {
            email: 'Email address',
            yourEmail: 'Your email address',
            password: 'Password',
            yourPassword: 'Your password',
            forgotPasswordInfo: 'Request password reset',
            forgotPasswordButton: 'Reset',
            forgotPasswordBacklink: 'Back to login page',
            resetEmailSent: 'Reset email sent',
            noUserFound: 'User not found',
        },
    },
}

class ForgotPassword extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loginEmail: '',
            message: '',
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    handle_loginEmail_change(event) {
        this.setState({ loginEmail: event.target.value })
    }

    submitForm(event) {
        event.preventDefault()
        const { loginEmail } = this.state
        getPasswordResetEmail(loginEmail)
            .then((response) => {
                this.setState({ message: R.path(['message'], response), loginEmail: '' })
                this.form.hideError('loginEmail')
            })
            .catch((response) => {
                this.setState({ message: R.path(['statusText'], response) })
            })
    }

    render() {
        const { lang, cc } = this.props
        const { message } = this.state

        const selectedTrans = R.path([getLanguage()], pageTranslation)
        const trans = R.path(['page'], selectedTrans)

        return (
            <div className="productOrder">
                <div className="productOrder__contentHolder">
                    <Form
                        ref={(c) => {
                            this.form = c
                        }}
                        className="pure-form pure-form-stacked productOrder__form login__form"
                    >
                        <fieldset>
                            <h2 className="login__headline">{R.path(['forgotPasswordInfo'], trans)}</h2>
                            <div className="input-group form__field">
                                <label htmlFor="loginEmail">
                                    {R.path(['email'], trans)} <sup>*</sup>
                                </label>
                                <Input
                                    onChange={this.handle_loginEmail_change.bind(this)}
                                    placeholder={R.path(['yourEmail'], trans)}
                                    id="loginEmail"
                                    errorclassname="is-invalid-input"
                                    type="email"
                                    containerclassname=""
                                    value={this.state.loginEmail}
                                    name="loginEmail"
                                    validations={[required, email]}
                                    lang={lang}
                                />
                            </div>

                            {message && message === 'reset email sent' && (
                                <p className="login__message--success">{R.path(['resetEmailSent'], trans)}</p>
                            )}
                            {message && message === 'no user found' && (
                                <p className="login__message">{R.path(['noUserFound'], trans)}</p>
                            )}
                            <div className="productOrder__buttonContainer">
                                <Button
                                    onClick={this.submitForm.bind(this)}
                                    className="pure-button pure-button-primary productOrder__button"
                                >
                                    {R.path(['forgotPasswordButton'], trans)}
                                </Button>
                            </div>
                            <br />
                            <Link className="login__link" to={`/${lang}/${cc}/login`}>
                                {R.path(['forgotPasswordBacklink'], trans)}
                            </Link>
                        </fieldset>
                    </Form>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    lang: R.path(['lang', 'lang'], state),
    cc: R.path(['lang', 'db'], state),
})

export default withRouter(connect(mapStateToProps, undefined)(ForgotPassword))
