import * as R from 'ramda'
import React from 'react'

import Header from './components/header/Header'
import BrowserNotSupported from './components/header/BrowserNotSupported'
import CampaignHeader from './components/header/CampaignHeader'
import Footer from './components/footer/Footer'
import ModalWindow from './components/modal/Modal'

import Pages from './Pages.jsx'

const PagesContainer = (props) => {
    const url = R.path(['match', 'url'], props)
    const lang = R.split('/', url)[1]
    const cc = R.or(R.split('/', url)[2], 'fi')
    const path = R.path(['location', 'pathname'], props)
    const isOrderGateway = path.includes(`${url}/order/`)
    const headerStyle = `header${isOrderGateway ? ' addons__header' : ''}`

    return (
        <div>
            <div className={headerStyle}>
                <Header lang={lang} cc={cc} />
            </div>
            <div className="content">
                <BrowserNotSupported />
                <CampaignHeader path={path} />
                <Pages {...props} />
            </div>

            {!isOrderGateway && (
                <div className="footer">
                    <Footer lang={lang} cc={cc} />
                </div>
            )}
            <ModalWindow />
        </div>
    )
}

export default PagesContainer
