/*
    USAGE:
    import IsMobile from 'location/IsMobile'
    if(IsMobile()){
      Do something here
    }
*/

export default function isMobile() {
    if (typeof window !== 'undefined' && typeof navigator !== 'undefined') {
        return Android() || BlackBerry() || iOS() || Opera() || WindowsMobile()
    }
    return false
}

function Android() {
    return navigator.userAgent.match(/Android/i)
}

function BlackBerry() {
    return navigator.userAgent.match(/BlackBerry/i)
}

function iOS() {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i)
}

function Opera() {
    return navigator.userAgent.match(/Opera Mini/i)
}

function WindowsMobile() {
    return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i)
}
