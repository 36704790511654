import React from 'react'
import * as R from 'ramda'
import config from '../../config'
const assets = R.path(['ASSETS'], config)

const ContentPage_main_card = ({ headline, content, url, widthtype, imgurl }) => (
    <div className={`contentPage__card pure-u-1 pure-u-md-1-${widthtype}`}>
        <a href={url} className="no-decoration">
            <div
                className="contentPage__card__innerwrapper"
                style={{
                    backgroundImage: `url(${imgurl.includes('https') ? imgurl : `${assets}/${imgurl}`})`,
                }}
            >
                <h2>{headline}</h2>
                <div className="contentPage__card__divider"></div>
                <p>{content}</p>
                <div className="contentPage__card__fill" style={{ display: `${imgurl ? 'block' : 'none'}` }}></div>
            </div>
        </a>
    </div>
)

export default ContentPage_main_card
