import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { intervalToDuration, formatDuration, isBefore } from 'date-fns'
import { enGB, fi } from 'date-fns/locale'
import { utcToZonedTime } from 'date-fns-tz'
import { getLanguage } from '../../utils/translation'

const CampaignHeader = (props) => {
    const [countDownTimer, setCountdownTimer] = useState('')
    const [showCampaignHeader, setShowCampaignHeader] = useState(false)
    const [showCountdownTimer, setShowCountdownTimer] = useState(false)

    const { path, landingContent } = props
    const activeCampaign = landingContent && landingContent.find((campaign) => campaign.campaign_header_is_active)

    const updateCountdownTimer = () => {
        const now = utcToZonedTime(new Date(), 'Europe/Helsinki')
        const cutoff = new Date(`${activeCampaign.campaign_header_end_date}T${activeCampaign.campaign_header_end_time}`)
        const locale = getLanguage() === 'fi' ? fi : enGB
        const timer = formatDuration(intervalToDuration({ start: now, end: cutoff }), { locale: locale })

        if (isBefore(now, cutoff) && !path.includes('order')) {
            setCountdownTimer(timer)
            setShowCampaignHeader(true)
            activeCampaign.campaign_header_has_counter && setShowCountdownTimer(true)
        } else {
            setShowCampaignHeader(false)
        }
    }

    useEffect(() => {
        if (activeCampaign) {
            updateCountdownTimer()
            const interval = setInterval(updateCountdownTimer, 1000)
            return () => clearInterval(interval)
        }
    }, [activeCampaign])

    const lang = getLanguage()
    const primaryText = activeCampaign ? activeCampaign[`campaign_header_${lang}_primary_text`] : ''
    const secondaryText = activeCampaign ? activeCampaign[`campaign_header_${lang}_secondary_text`] : ''
    const color = activeCampaign ? activeCampaign[`campaign_header_color`] : ''

    return !showCampaignHeader ? null : (
        <div className="campaignheader" style={{ backgroundColor: color }}>
            <div className="campaignheader__center">
                <h3 className="campaignheader__header" style={{ backgroundColor: color }}>
                    <div> {primaryText}</div>
                    {showCountdownTimer && <div>{countDownTimer}</div>}
                    {secondaryText && <div> {secondaryText}</div>}
                </h3>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    landingContent: state.landingContent.content,
})

export default connect(mapStateToProps)(CampaignHeader)
