import * as R from 'ramda'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import moment from 'moment-timezone'
import { uniqueProductsInCart, productCountsInCart } from '../../utils/CartProducts'
import { isFuneralProduct } from '../../utils/ProductTypes'
import { updateSelectedCards } from '../../actions/deliveryCardActions'
import { populateCardData } from '../../utils/deliveryCards'

import { getLanguage } from '../../utils/translation'

import config from '../../config'
import { Link } from 'react-router-dom'

const assets = R.path(['ASSETS'], config)

const pageTranslation = {
    fi: {
        page: {
            orderSummary: 'Tilauskooste',
            recipient: 'Vastaanottaja',
            deliveryDay: 'Toimituspäivä',
            timeOfBlessing: 'Siunausaika',
            ribbonText: 'Nauhateksti',
            cardOfCondolence: 'Adressi',
            cardText: 'Korttiteksti',
            deliveryRemarks: 'Erityistoiveet toimitukseen',
            senderInfo: 'Lähettäjän yhteystiedot',
            products: 'Valitut tuotteet',
            deliveryFee: 'Toimitusmaksu',
            discount: 'Alennus',
            totalSum: 'Kokonaissumma',
            value: 'arvoltaan',
            piece: 'kpl',
            cardTextLink: 'Muokkaa korttitekstejä',
        },
    },
    en: {
        page: {
            orderSummary: 'Order summary',
            recipient: 'Recipient',
            deliveryDay: 'Delivery day',
            timeOfBlessing: 'Time of blessing',
            ribbonText: 'Ribbon text',
            cardOfCondolence: 'Card of condolence',
            cardText: 'Card text',
            deliveryRemarks: 'Special wishes for the delivery',
            senderInfo: 'Sender details',
            products: 'Products',
            deliveryFee: 'Delivery fee',
            discount: 'Discount',
            totalSum: 'Total sum',
            value: 'value',
            piece: 'pcs',
            cardTextLink: 'Edit card texts',
        },
    },
}

const getPriceAsCurr = (price) =>
    Number(price).toLocaleString('fi-Fi', {
        style: 'decimal',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
    })

const mapIndexed = R.addIndex(R.map)

const Summary = (props) => {
    const {
        recipientName,
        recipientAddress,
        recipientPhone,
        recipientDate,
        recipientCompany,
        recipientZip,
        recipientCity,
        mortName,
        churchName,
        churchTime,
        attachedMessageToCourier,
        senderName,
        senderCompany,
        senderPhone,
        senderEmail,
        cartProducts,
        cartExtras,
        giftcardPrice,
        giftcardAmount,
        ribbonWords,
        ribbonNames,
        condolenceWords,
        condolenceSenderNames,
        condolenceMortName,
        ribbonSelected,
        condolencesSelected,
        discount,
        cards,
        lang,
        cc,
        cartCards,
        updateSelectedCards,
        path,
    } = props

    useEffect(() => {
        populateCardData(cartCards, cartProducts, updateSelectedCards)
    }, [])

    const cartHasFuneralProduct = () => {
        const { cartProducts, allProducts } = props
        return R.find((product) => isFuneralProduct(allProducts, R.prop('id', product)))(cartProducts)
    }

    const firstProduct = R.head(cartProducts)
    const firstProductName = R.path(['name'])(firstProduct)
    const firstProductCategory = R.path(['category'])(firstProduct)
    const firstProductCountryCode = R.path(['countrycode'])(firstProduct)

    const productsPrice = R.reduce(R.add, 0, R.map(R.prop('price'))(cartProducts))
    const extrasPrice = R.reduce(R.add, 0, R.map(R.prop('price'))(cartExtras))

    const deliveryCost = firstProductCountryCode === 'FI' ? 12.8 : 18.0
    const deliveryCostWithComma = firstProductCountryCode === 'FI' ? '12,80' : '18,00'

    const calcPrice = deliveryCost + productsPrice + extrasPrice

    const productAmounts = productCountsInCart(cartProducts)
    const extraAmounts = productCountsInCart(cartExtras)

    const parsedDate = recipientDate !== null ? moment(recipientDate).tz('Europe/Helsinki').format('DD-MM-YYYY') : ''

    const isAFuneralProduct = cartHasFuneralProduct()

    const calcTotalPriceAsCurr = getPriceAsCurr(calcPrice - discount)
    const giftcardTotal = giftcardPrice * giftcardAmount
    const giftcardTotalAsCurr = getPriceAsCurr(giftcardTotal)
    const calcGiftCardTotalPriceAsCurr = getPriceAsCurr(3.8 + extrasPrice + giftcardTotal - discount)
    const selectedTrans = R.path([getLanguage()], pageTranslation)
    const trans = R.path(['page'], selectedTrans)

    const discountField =
        discount > 0 ? (
            <li>
                {R.path(['discount'], trans)}{' '}
                <span className="summary__calculation--price">{getPriceAsCurr(-Math.abs(discount))} €</span>
            </li>
        ) : null

    const linkToCards = `/${lang.lang}/${cc}/order/card-text`
    const firstCardText = R.path(['cardText'], R.head(cards.selectedCards))
    const multipleCardsInCart = cards.selectedCards.length > 1
    const stepName = path.split('/')[4]
    const showLinkToCardTexts = stepName !== 'card-text' && stepName !== 'recipient-info'

    return (
        <div className="productOrder__contentHolder_left">
            <div className="summary__veil">
                <div className="summary__wrapper">
                    <h2 className="summary__headline">{R.path(['orderSummary'], trans)}</h2>
                    <div className="pure-g">
                        <div className="pure-u-1 pure-u-sm-1-2 summary__section rightPadding">
                            <h3 className="summary__sub__headline">{R.path(['recipient'], trans)}</h3>
                            {!isAFuneralProduct && <p className="summary__paragraph">{recipientName}</p>}
                            {isAFuneralProduct && <p className="summary__paragraph">{mortName}</p>}
                            {!isAFuneralProduct && <p className="summary__paragraph">{recipientCompany}</p>}
                            {isAFuneralProduct && <p className="summary__paragraph">{churchName}</p>}
                            <p className="summary__paragraph">{recipientAddress}</p>
                            <p className="summary__paragraph">
                                {recipientZip} {recipientCity}
                            </p>
                            <p className="summary__paragraph">{recipientPhone}</p>
                        </div>
                        <div className="pure-u-1 pure-u-sm-1-2 summary__section">
                            <h3 className="summary__sub__headline">{R.path(['deliveryDay'], trans)}</h3>
                            <p className="summary__paragraph">{parsedDate}</p>
                            {isAFuneralProduct && (
                                <div>
                                    <p></p>
                                    <h3 className="summary__sub__headline">{R.path(['timeOfBlessing'], trans)}</h3>
                                    <p className="summary__paragraph">{churchTime}</p>
                                </div>
                            )}
                        </div>
                    </div>
                    {ribbonSelected && (
                        <div className="summary__section">
                            <h3 className="summary__sub__headline">{R.path(['ribbonText'], trans)}</h3>
                            <pre>
                                <p className="summary__paragraph">{ribbonWords}</p>
                            </pre>
                            <p className="summary__paragraph">{ribbonNames}</p>
                        </div>
                    )}
                    {condolencesSelected && (
                        <div className="summary__section">
                            <h3 className="summary__sub__headline">{R.path(['cardOfCondolence'], trans)}</h3>
                            <pre>
                                <p className="summary__paragraph">{condolenceWords}</p>
                            </pre>
                            <p className="summary__paragraph">{condolenceMortName}</p>
                            <p className="summary__paragraph">{condolenceSenderNames}</p>
                        </div>
                    )}
                    <div className="summary__section">
                        <h3 className="summary__sub__headline">{R.path(['cardText'], trans)}</h3>
                        <pre className="summary__cardmessage">
                            <p className="summary__paragraph">{firstCardText}</p>
                        </pre>
                        {showLinkToCardTexts && multipleCardsInCart ? (
                            <Link to={linkToCards} className="summary__cardtext__link">
                                {'\n'}
                                {R.path(['cardTextLink'], trans)}
                            </Link>
                        ) : null}
                    </div>
                    {firstProductCategory !== 'lahjakortti' && (
                        <div className="summary__section">
                            <h3 className="summary__sub__headline">{R.path(['deliveryRemarks'], trans)}</h3>
                            <pre>
                                <p className="summary__paragraph">{attachedMessageToCourier}</p>
                            </pre>
                        </div>
                    )}
                    <div className="summary__section">
                        <h3 className="summary__sub__headline">{R.path(['senderInfo'], trans)}</h3>
                        <p className="summary__paragraph">{senderName}</p>
                        <p className="summary__paragraph">{senderCompany}</p>
                        <p className="summary__paragraph">{senderPhone}</p>
                        <p className="summary__paragraph">{senderEmail}</p>
                    </div>

                    <h3 className="summary__sub__headline">{R.path(['products'], trans)}</h3>
                    <div className="summary__calculation">
                        {firstProductCategory !== 'lahjakortti' && (
                            <ul>
                                {mapIndexed((item, key) => {
                                    const amount = Number(R.prop(item.id, productAmounts))
                                    const totalPrice = item.price * amount
                                    const displayAmount = amount > 1 ? `* ${amount}` : ''
                                    return (
                                        <li key={key}>
                                            {item.name} {displayAmount}
                                            <span className="summary__calculation--price">
                                                {getPriceAsCurr(totalPrice)} €
                                            </span>
                                        </li>
                                    )
                                })(uniqueProductsInCart(cartProducts))}
                                {mapIndexed((item, key) => {
                                    const extraAmount = Number(R.prop(item.id, extraAmounts))
                                    const totalExtraPrice = item.price * extraAmount
                                    const displayExtraAmount = extraAmount > 1 ? `* ${extraAmount}` : ''

                                    return (
                                        <li key={key}>
                                            {item.name} {displayExtraAmount}
                                            <span className="summary__calculation--price">
                                                {getPriceAsCurr(totalExtraPrice)} €
                                            </span>
                                        </li>
                                    )
                                })(uniqueProductsInCart(cartExtras))}
                                <li>
                                    {R.path(['deliveryFee'], trans)}{' '}
                                    <span className="summary__calculation--price">{deliveryCostWithComma} €</span>
                                </li>
                                {discountField}
                                <hr />
                                <li>
                                    {R.path(['totalSum'], trans)}{' '}
                                    <span className="summary__calculation--price">{calcTotalPriceAsCurr} €</span>
                                </li>
                            </ul>
                        )}
                        {firstProductCategory === 'lahjakortti' && (
                            <ul>
                                {mapIndexed((item, key) => {
                                    const extraAmount = Number(R.prop(item.id, extraAmounts))
                                    const totalExtraPrice = item.price * extraAmount
                                    const displayExtraAmount = extraAmount > 1 ? `* ${extraAmount}` : ''

                                    return (
                                        <li key={key}>
                                            {item.name} {displayExtraAmount}
                                            <span className="summary__calculation--price">
                                                {getPriceAsCurr(totalExtraPrice)} €
                                            </span>
                                        </li>
                                    )
                                })(uniqueProductsInCart(cartExtras))}
                                <li>
                                    {firstProductName} {giftcardAmount}
                                    {R.path(['piece'], trans)} {R.path(['value'], trans)} {giftcardPrice}€
                                    <span className="summary__calculation--price">{giftcardTotalAsCurr} €</span>
                                </li>
                                <li>
                                    {R.path(['deliveryFee'], trans)}{' '}
                                    <span className="summary__calculation--price">3,80 €</span>
                                </li>
                                {discountField}
                                <hr />
                                <li>
                                    {R.path(['totalSum'], trans)}{' '}
                                    <span className="summary__calculation--price">
                                        {calcGiftCardTotalPriceAsCurr} €
                                    </span>
                                </li>
                            </ul>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        recipientName: state.form.recipientName,
        recipientAddress: state.form.recipientAddress,
        recipientPhone: state.form.recipientPhone,
        recipientDate: state.form.recipientDate,
        recipientCompany: state.form.recipientCompany,
        recipientZip: state.form.recipientZip,
        recipientCity: state.form.recipientCity,
        mortName: state.form.mortName,
        churchName: state.form.churchName,
        churchTime: state.form.churchTime,
        attachedMessageToCourier: state.form.attachedMessageToCourier,
        senderName: state.form.senderName,
        senderCompany: state.form.senderCompany,
        senderPhone: state.form.senderPhone,
        senderEmail: state.form.senderEmail,
        giftcardPrice: state.form.giftcardPrice,
        giftcardAmount: state.form.giftcardAmount,
        ribbonNames: state.form.ribbonNames,
        ribbonWords: state.form.ribbonWords,
        condolenceWords: state.form.condolenceWords,
        condolenceMortName: state.form.condolenceMortName,
        condolenceSenderNames: state.form.condolenceSenderNames,
        ribbonSelected: state.order.ribbon,
        condolencesSelected: state.order.condolences,
        allProducts: state.products.allProducts,
        cartProducts: R.path(['cart', 'items', 'products'], state),
        cartExtras: R.path(['cart', 'items', 'extras'], state),
        discount: Number(R.pathOr(0, ['cart', 'items', 'discount'], state)),
        cards: state.cards,
        lang: state.lang,
        cc: R.path(['lang', 'db'], state),
        path: R.path(['router', 'location', 'pathname'], state),
        cartCards: R.pathOr([], ['cart', 'items', 'card_texts'], state),
    }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    updateSelectedCards: (selectedCards) => dispatch(updateSelectedCards(selectedCards)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Summary)
