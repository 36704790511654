import * as R from 'ramda'

const defaultLocale = 'fi-fi'
const supportedLanguages = ['fi', 'en']

export const getLanguage = (defaultLang = 'fi') => {
    if (typeof window !== 'undefined') {
        const state = window.store.getState()
        const storedLang = R.pathOr(defaultLang, ['lang', 'lang'], state)
        return storedLang
    }
    return defaultLang
}

export const getLangFromPathname = (defaultLang = 'fi') => {
    if (typeof window !== 'undefined') {
        const pathname = window.location.pathname
        const split = R.split('/', pathname)
        const lang = split[1]
        return lang && R.contains(lang, supportedLanguages) ? lang : defaultLang
    }
    return defaultLang
}

export const getLocale = (defaultLang = 'fi') => {
    if (typeof window !== 'undefined') {
        const state = window.store.getState()
        const storedLang = R.pathOr(defaultLang, ['lang', 'lang'], state)
        return R.toLower(storedLang) === 'fi' ? defaultLocale : 'en-gb'
    } else return defaultLocale
}
