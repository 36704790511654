import { isFuneralOnlyOrder } from './ProductTypes'

export const deliveryCardIds = { noCardId: 2099, basicCard: 2001, foldedCard: 2002 }

export const getDefaultCard = (cartProducts) => {
    return isFuneralOnlyOrder(cartProducts) ? deliveryCardIds.foldedCard : deliveryCardIds.basicCard
}

export const isCardsSelected = (selectedCards) => {
    if (selectedCards.length === 1 && selectedCards[0].extraId === 2099) return false
    return true
}

export const modifyObjectAtIndex = (arr, index, newValues) =>
    arr.map((obj, i) => (i === index ? { ...obj, ...newValues } : obj))

export const populateCardData = (cartCards, cartProducts, updateSelectedCards) => {
    const parsedCards = cartCards.map((card) => {
        return { extraId: card.extra, cardText: card.text }
    })
    const defaultCard = getDefaultCard(cartProducts)

    if (parsedCards.length) {
        updateSelectedCards(parsedCards)
    } else {
        updateSelectedCards([{ extraId: defaultCard, cardText: '' }])
    }
}
