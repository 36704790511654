import * as R from 'ramda'
import moment from 'moment-timezone'

const TIMEZONE = 'Europe/Helsinki'

const validateDay = (day, cutoff) => {
    const now = moment(new Date()).tz(TIMEZONE)
    const date = moment(R.path(['date'], day)).tz(TIMEZONE)

    if (now.isSame(date, 'd')) {
        const arr = R.split(':', R.or(cutoff, '00:00:00'))
        const cutoffMoment = now.clone().set({
            hour: R.or(arr[0], '00'),
            minute: R.or(arr[1], '00'),
            second: R.or(arr[2], '00'),
        })

        return now < cutoffMoment ? date.toDate() : null
    } else return date.toDate()
}

export const getZipcodeCalendarForDatePicker = (calendar = [], cutoff = null) =>
    R.compose(
        R.reject(R.isNil),
        R.map((day) => validateDay(day, cutoff)),
        R.filter(R.propEq('open', true)),
    )(calendar)
