import {
    REQUEST_DELIVERY_CARDS,
    LOADING_START_DELIVERY_CARDS,
    REQUEST_DELIVERY_CARD_CONTENT,
    UPDATE_SELECTED_CARDS,
} from '../actions/deliveryCardActions'

const initialState = {
    isLoadingDeliveryCards: false,
    allDeliveryCards: [],
    errorDeliveryCards: null,
    selectedCards: [],
}

export default function deliveryCardReducer(state = initialState, action) {
    switch (action.type) {
        case LOADING_START_DELIVERY_CARDS:
            return {
                ...state,
                isLoadingDeliveryCards: true,
            }
        case REQUEST_DELIVERY_CARDS:
            return {
                ...state,
                allDeliveryCards: action.deliveryCards,
            }
        case REQUEST_DELIVERY_CARDS + '_SUCCESS':
            return {
                ...state,
                allDeliveryCards: action.deliveryCards,
                isLoadingDeliveryCards: false,
            }
        case REQUEST_DELIVERY_CARDS + '_FAILED':
            return {
                ...state,
                isLoadingDeliveryCards: false,
                errorDeliveryCards: 'WE FAILED.',
            }
        case REQUEST_DELIVERY_CARD_CONTENT:
            return {
                ...state,
                isLoadingDeliveryCards: action.isLoadingDeliveryCards,
                allDeliveryCards: action.allDeliveryCards,
                errorDeliveryCards: action.errorDeliveryCards,
            }
        case UPDATE_SELECTED_CARDS:
            return {
                ...state,
                selectedCards: action.selectedCards,
            }
        default:
            return state
    }
}
