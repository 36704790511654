import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as R from 'ramda'
import { productCountsInCart, uniqueProductsInCart } from '../../utils/CartProducts'

import { getLanguage } from '../../utils/translation'
const assets = R.path(['ASSETS'], config)
import config from '../../config'
import { isGiftcardByProductId } from '../../utils/ProductTypes'

const pageTranslation = {
    fi: {
        page: {
            shoppingCart: 'Tuotteet ja kuljetus yhteensä',
            amount: 'kpl',
        },
    },
    en: {
        page: {
            shoppingCart: 'Your items total',
            amount: 'pcs',
        },
    },
}

class TotalItem extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const { lang, cc, items } = this.props

        const getPriceAsCurr = (price) =>
            Number(price).toLocaleString('fi-Fi', {
                style: 'decimal',
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
            })

        const getItemsTotalPrice = (items = []) =>
            R.reduce(R.add, 0, R.map((item) => Number(R.pathOr(0, ['price'], item)))(items))

        const selectedTrans = R.path([getLanguage()], pageTranslation)
        const trans = R.path(['page'], selectedTrans)

        const products = R.path(['products'], items)
        const extras = R.path(['extras'], items)

        const product = R.head(R.pathOr([], ['products'], items))
        const productCountryCode = R.toUpper(R.pathOr('fi', ['countrycode'], product))

        const isForeign = productCountryCode !== 'FI'
        const isGiftcard = isGiftcardByProductId(R.prop('id', product))

        const giftcardDeliveryCost = 3.8
        const productDeliveryCost = isForeign ? 18.0 : 12.8

        const deliveryCost = isGiftcard ? giftcardDeliveryCost : productDeliveryCost

        const getGiftcardTotalPrice = (cart) => {
            const amount = Number(R.pathOr(1, ['giftcard_amount'], cart))
            const price = Number(R.pathOr(10, ['giftcard_price'], cart))
            return amount * price
        }

        const productsTotalPrice = isGiftcard ? getGiftcardTotalPrice(items) : getItemsTotalPrice(products)
        const extrasTotalPrice = getItemsTotalPrice(extras)
        const cartTotalItemPrice = productsTotalPrice + extrasTotalPrice + deliveryCost

        return (
            <div className="pure-g flex-nowrap">
                <div className="pure-u-1 pure-u-sm-1-24 pure-hidden-smallphone"></div>
                <div className="pure-u-1 flex-center">
                    <div className="overview__itemWrapper">
                        <div className="price__name">
                            <h3>
                                {R.path(['shoppingCart'], trans)} {getPriceAsCurr(cartTotalItemPrice)}
                                <span style={{ fontSize: `.8em` }}>€</span>
                            </h3>
                        </div>
                    </div>
                </div>
                <div className="pure-u-1 pure-u-sm-1-24 pure-hidden-smallphone"></div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        productCountryCode: R.or(R.pathOr([], ['cart', 'items', 'products'], state)[0], 'FI'),
        items: R.pathOr({}, ['cart', 'items'], state),
        lang: R.path(['lang', 'lang'], state),
        cc: R.path(['lang', 'db'], state),
    }
}

export default connect(mapStateToProps, undefined)(TotalItem)
