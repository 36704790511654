export const SET_LANG = 'SET_LANG'
export const SET_DB = 'SET_DB'

export const setLang = (lang) => ({
    type: SET_LANG,
    lang: lang,
})

export const setDB = (db) => ({
    type: SET_DB,
    db,
})
