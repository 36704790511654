import * as R from 'ramda'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'

import Helmet from 'react-helmet'

import Landing from '../components/landing/Landing'
import { initiateSearch } from '../actions/categoryActions'
import { fetchPaymentMethods } from '../actions/payformActions'
import { getAllCountries, getAllExtras, getAllOccasions, getAllProducts } from '../actions/productsActions'
import { getLandingContent } from '../actions/landingContentActions'

import { getDBFromLocation } from '../language'

import isEmpty from 'lodash/isEmpty'

import config from '../config'

const assets = R.path(['ASSETS'], config)
const server = R.path(['SERVER'], config)

const Loading = ({ isLoadingProducts, isLoadingOccasions, isLoadingCountries }) => {
    return isLoadingProducts && isLoadingOccasions && isLoadingCountries ? <div>Loading</div> : null
}

const LandingError = ({ errorProducts, errorOccasions, errorCountries }) => {
    return errorProducts || errorOccasions || errorCountries ? (
        <div>
            Error {errorProducts} {errorOccasions} {errorCountries}
        </div>
    ) : null
}

const LandingComplete = ({ products, occasions, lang, cc, countries }) => {
    return !isEmpty(products) && !isEmpty(occasions) && !isEmpty(countries) ? (
        <Landing products={products} occasions={occasions} lang={lang} cc={cc} countries={countries} />
    ) : null
}

class LandingContainer extends Component {
    // This is the "root" route for this app --> These calls are always executed by SSR
    static fetchData(dispatch, params, location, token) {
        const db = R.toUpper(getDBFromLocation({ pathname: location }))
        return Promise.all([
            dispatch(fetchPaymentMethods()),
            dispatch(getAllProducts(db)),
            dispatch(getAllOccasions()),
            dispatch(getAllCountries()),
            dispatch(getAllExtras()),
            dispatch(getLandingContent()),
        ])
    }

    constructor(props, context) {
        super(props, context)
        const { initiateSearch, cc } = this.props
        initiateSearch('', '', '', '', '', '', R.toUpper(cc))
    }

    render() {
        const { products, occasions, countries, lang, cc } = this.props

        const shouldRedirect = cc !== 'fi'
        const redirectUrl = `/${lang}/${cc}/category`

        return shouldRedirect ? (
            <Redirect to={redirectUrl} />
        ) : (
            <div>
                <Helmet
                    title="Kukkia kaikkiin tilanteisiin | Nopeat toimitukset ympäri maailmaa"
                    meta={[
                        {
                            name: 'description',
                            content:
                                'Interflora - kukkalähetykset ja kukkalahjakortit Suomeen ja ulkomaille - blomsterförmedlingar och blomstergåvokort till Finland och utlandet – Flower deliveries and Floral Cheques to Finland and other countries',
                        },
                        {
                            name: 'keywords',
                            content:
                                'Interflora, kukkakaupan huippuammattilaisia, kukkasitojia, floristeja, floristihortonomeja ja floristimestareita, kukkatervehdys, hautajaiskukat, hautavihot, kukat hautajaisiin, kukat juhliin, verkkokukka, kukka + yllätys',
                        },
                        {
                            property: 'og:title',
                            content: 'Kukkia kaikkiin tilanteisiin - Nopeat toimitukset ympäri maailmaa',
                        },
                        { property: 'og:image', content: `${assets}/interflora-logo.png` },
                        { property: 'og:url', content: `${server}/${lang}/${R.toLower(cc)}` },
                        {
                            property: 'og:description',
                            content:
                                'Interflora - kukkalähetykset ja kukkalahjakortit Suomeen ja ulkomaille - blomsterförmedlingar och blomstergåvokort till Finland och utlandet – Flower deliveries and Floral Cheques to Finland and other countries',
                        },
                    ]}
                />
                <Loading {...this.props} />
                <LandingError {...this.props} />
                <LandingComplete products={products} occasions={occasions} lang={lang} cc={cc} countries={countries} />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        products: R.path(['products', 'allProducts'], state),
        extras: R.path(['products', 'allExtras'], state),
        countries: R.path(['products', 'allCountries'], state),
        occasions: R.path(['products', 'allOccasions'], state),
        isLoadingProducts: state.products.isLoadingProducts,
        errorProducts: state.products.errorProducts,
        isLoadingOccasions: state.products.isLoadingOccasions,
        errorOccasions: state.products.errorOccasions,
        lang: R.path(['lang', 'lang'], state),
        cc: R.path(['lang', 'db'], state),
        isLoadingCountries: state.products.isLoadingCountries,
        errorCountries: state.products.errorCountries,
    }
}

const mapDispatchToProps = (dispatch) => ({
    initiateSearch: (
        categoryOccasion,
        categoryColor,
        categoryContains,
        categoryType,
        minPrice,
        categoryPriceSort,
        selectedCountryCode,
    ) =>
        dispatch(
            initiateSearch(
                categoryOccasion,
                categoryColor,
                categoryContains,
                categoryType,
                minPrice,
                categoryPriceSort,
                selectedCountryCode,
            ),
        ),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LandingContainer))
