import { SHOW_MODAL, HIDE_MODAL } from '../actions/modalActions'

const initialState = {
    show: false,
    label: '',
    modaltype: 'delivery',
    modalclass: 'modal__content',
    headline: '',
    content: '',
}

export default function modalReducer(state = initialState, action) {
    switch (action.type) {
        case SHOW_MODAL:
            return {
                ...state,
                show: action.show,
                label: action.label,
                modaltype: action.modaltype,
                modalclass: action.modalclass,
                headline: action.headline,
                content: action.content,
            }
        case HIDE_MODAL:
            return {
                ...state,
                show: action.show,
            }
        default:
            return state
    }
}
