import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import * as R from 'ramda'
import DeliveryCard from './DeliveryCard'
import { updateSelectedCards } from '../../actions/deliveryCardActions'
import { getLanguage } from '../../utils/translation'
import { isCardsSelected, populateCardData, getDefaultCard } from '../../utils/deliveryCards'
import { trackCheckoutProcess } from '../../tracking/tracking'
import BottomNavigationBar from '../bottom-navigation-bar/BottomNavigationBar'
import { saveCards } from '../../actions/cartActions'
import { ordinalSuffixOf } from '../../utils/ordinalSuffix'
import { CSSTransitionGroup } from 'react-transition-group'
import { showModal } from '../../actions/modalActions'
import validator from 'validator'

const pageTranslation = {
    fi: {
        page: {
            header: 'Korttitervehdys',
            add: 'Lisää ',
            2: 'toinen ',
            3: 'kolmas ',
            4: 'neljäs ',
            card: 'kortti',
            cardTextRequired: 'Yhdeltä tai useammalta kortilta puuttuu korttiteksti. Täytä korttiteksti jatkaaksesi.',
            noEmojisAllowed: 'Poista EMOJIT, niin voit jatkaa. Ne eivät näy oikein toimitettavassa kortissa.',
            only8RowsAllowed: 'Huom! Maks. 8 riviä. Vähennä rivinvaihtoja, niin voit jatkaa.',
            multipleCardsDomesticOrdersOnly: 'Useampi kortti sallittu vain kotimaan tilauksissa.',
            modalTitle: 'TARKISTA TÄYTTÄMÄSI TIEDOT',
        },
    },
    en: {
        page: {
            header: 'Delivery Card',
            add: 'Add a ',
            2: 'second ',
            3: 'third ',
            4: 'fourth ',
            card: 'card',
            cardTextRequired: 'One or more cards are missing the card text. Please fill in the card text to continue.',
            noEmojisAllowed: `Please remove EMOJIS to continue. They won't appear correctly on the card.`,
            only8RowsAllowed: 'Max. 8 lines! Reduce line breaks to continue.',
            multipleCardsDomesticOrdersOnly: 'Multiple cards allowed only for domestic orders.',
            modalTitle: 'PLEASE CHECK THE GIVEN INFORMATION',
        },
    },
}

const DeliveryCardContainer = (props) => {
    const {
        storeState,
        selectedCards,
        updateSelectedCards,
        cartCards,
        cartProducts,
        lang,
        cc,
        saveCards,
        condolencesSelected,
        ribbonSelected,
        cartLoading,
        showModal,
    } = props

    useEffect(() => {
        window.scrollTo(0, 0)
        trackCheckoutProcess(storeState, 2, 'Card Text')
        populateCardData(cartCards, cartProducts, updateSelectedCards)
    }, [])

    const selectedTrans = R.path([getLanguage()], pageTranslation)
    const trans = R.path(['page'], selectedTrans)
    const defaultCard = getDefaultCard(cartProducts)
    const cardsSelected = isCardsSelected(selectedCards)
    const isDomesticOrder = cc === 'fi'

    const handleAddCard = () => {
        updateSelectedCards([...selectedCards, { extraId: defaultCard, cardText: '' }])
    }

    const handleContinue = () => {
        const isEmptyCardText = selectedCards.some((card) => card.cardText === '')
        const hasEmojis = selectedCards.some((card) => hasEmoji(card.cardText))
        const has7LineBreaks = selectedCards.some((card) =>
            validator.matches(card.cardText, /(.*)\n(.*)\n(.*)\n(.*)\n(.*)\n(.*)\n(.*)\n(.*)\n(.*)/),
        )

        if (isEmptyCardText && isCardsSelected(selectedCards)) openModal(R.path(['cardTextRequired'], trans))
        else if (has7LineBreaks && isCardsSelected(selectedCards)) openModal(R.path(['only8RowsAllowed'], trans))
        else if (hasEmojis && isCardsSelected(selectedCards)) openModal(R.path(['noEmojisAllowed'], trans))
        else if (isCardsSelected(selectedCards) && selectedCards.length > 1 && !isDomesticOrder)
            openModal(R.path(['multipleCardsDomesticOrdersOnly'], trans))
        else saveCards()
    }

    const hasEmoji = (str) => {
        return /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gi.test(
            str,
        )
    }

    const openModal = (content) => {
        showModal(
            true,
            'Card error modal',
            'cardTextError',
            'modal__content alignLeft',
            R.path(['modalTitle'], trans),
            content,
        )
    }

    let backLink
    if (condolencesSelected) backLink = `/${lang}/${cc}/order/condolences`
    else if (ribbonSelected) backLink = `/${lang}/${cc}/order/ribbon`
    else backLink = `/${lang}/${cc}/order/recipient-info`

    const showNextCardButton = cardsSelected && isDomesticOrder
    const nextCardNumber = selectedCards.length + 1
    const ordinalSuffix = getLanguage() === 'fi' ? '.' : ordinalSuffixOf(nextCardNumber)
    const nextCardNumberAsWord =
        nextCardNumber < 5 ? R.path([nextCardNumber], trans) : `${nextCardNumber}${ordinalSuffix} `

    return (
        <div className="productOrder__contentHolder">
            <span className="DeliveryCardContainer-header">{R.path(['header'], trans)}</span>
            <CSSTransitionGroup transitionName="DeliveryCard" transitionEnterTimeout={400} transitionLeaveTimeout={400}>
                {selectedCards.map((card, index) => (
                    <DeliveryCard key={index} cardIndex={index} card={card} />
                ))}
                {showNextCardButton && (
                    <div>
                        <div className="DeliveryCardContainer-horizontal-divider"></div>
                        <button className="add-delivery-card-button" onClick={handleAddCard}>
                            + {R.path(['add'], trans)}
                            {nextCardNumberAsWord}
                            {R.path(['card'], trans)}
                        </button>
                    </div>
                )}
            </CSSTransitionGroup>
            <BottomNavigationBar
                backLink={backLink}
                handleContinue={handleContinue}
                hasForm={false}
                loading={cartLoading}
            />
        </div>
    )
}

const mapStateToProps = (state, ownProps) => ({
    storeState: state,
    lang: R.path(['lang', 'lang'], state),
    cc: R.path(['lang', 'db'], state),
    cartProducts: R.pathOr([], ['cart', 'items', 'products'], state),
    selectedCards: R.path(['cards', 'selectedCards'], state),
    cartCards: R.pathOr([], ['cart', 'items', 'card_texts'], state),
    ribbonSelected: R.path(['order', 'ribbon'], state),
    condolencesSelected: R.path(['order', 'condolences'], state),
    cartLoading: R.path(['cart', 'loading'], state),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    updateSelectedCards: (selectedCards) => dispatch(updateSelectedCards(selectedCards)),
    saveCards: () => dispatch(saveCards()),
    showModal: (show, label, modaltype, modalclass, headline, content) =>
        dispatch(showModal(show, label, modaltype, modalclass, headline, content)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryCardContainer)
