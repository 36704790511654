import React, { Component } from 'react'
import { connect } from 'react-redux'
import Category from '../components/category/Category'
import { withRouter } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'

const Loading = ({ isLoadingProducts, isLoadingOccasions, isLoadingCountries }) => {
    return isLoadingProducts && isLoadingOccasions && isLoadingCountries ? <div>Loading</div> : null
}

const ProductError = ({ errorProducts, errorOccasions, errorCountries }) => {
    return errorProducts || errorOccasions || errorCountries ? (
        <div>
            Error {errorProducts} {errorOccasions} {errorCountries}
        </div>
    ) : null
}

const AllProducts = ({ products, occasions, countries }) => {
    return !isEmpty(occasions) && !isEmpty(countries) ? (
        <Category products={products} occasions={occasions} countries={countries} />
    ) : null
}

class CategoryContainer extends Component {
    render() {
        const { products, occasions, countries } = this.props

        return (
            <div>
                <Loading {...this.props} />
                <ProductError {...this.props} />
                <AllProducts products={products} occasions={occasions} countries={countries} />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    products: state.products.allProducts,
    isLoadingProducts: state.products.isLoadingProducts,
    errorProducts: state.products.errorProducts,
    occasions: state.products.allOccasions,
    isLoadingOccasions: state.products.isLoadingOccasions,
    errorOccasions: state.products.errorOccasions,
    countries: state.products.allCountries,
    isLoadingCountries: state.products.isLoadingCountries,
    errorCountries: state.products.errorCountries,
    selectedCountryCode: state.category.selectedCountryCode,
})

const mapDispatchToProps = (dispatch, ownProps) => ({})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CategoryContainer))
