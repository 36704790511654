import * as R from 'ramda'
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { getLanguage } from '../../utils/translation'
import AddOnContainer from '../add-on-products/AddOnContainer'
import LoadingIndicator from './LoadingIndicator'
import BottomNavigationBar from '../bottom-navigation-bar/BottomNavigationBar'
import { saveAddOns } from '../../actions/cartActions'

const CartLoadingIndicator = () => {
    return (
        <div className="loading-container">
            <LoadingIndicator overwrite={true} />
        </div>
    )
}

const pageTranslation = {
    fi: {
        page: {
            titleChooseAddOn: 'Valitse kukkien mukaan',
            subtitle: 'HUOM! Kortin voit lisätä toimitusosoitteen jälkeen.',
        },
    },
    en: {
        page: {
            titleChooseAddOn: 'Purchase with your flowers',
            subtitle: 'NOTE! You can add a greeting card once you have given the delivery address.',
        },
    },
}

class AddOnsSubContainer extends Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    handleContinue = () => {
        const { saveAddOns } = this.props
        saveAddOns()
    }

    render() {
        const { productId, extras, cart, lang, cc, cartLoading } = this.props
        const selectedTrans = R.path([getLanguage()], pageTranslation)
        const trans = R.path(['page'], selectedTrans)

        // TODO replace this with generic cart_operation_in_progress_indicator
        const cart_items = R.path(['products'], cart)
        if (R.isEmpty(cart_items)) return <CartLoadingIndicator />
        return (
            <div>
                <div className="productOrderSubContainer">
                    <div className="addons__container pure-g">
                        <div className="pure-u-1 addon__content">
                            <div className="addons__title__container">
                                <h1 className="addons__title">{R.path(['titleChooseAddOn'], trans)}</h1>
                                <h4 className="addons__subtitle">{R.path(['subtitle'], trans)}</h4>
                            </div>
                            <AddOnContainer productId={productId} extras={extras} />
                        </div>
                    </div>
                </div>
                <BottomNavigationBar
                    backLink={`/${lang}/${cc}/order/overview`}
                    hasForm={false}
                    fullScreen={true}
                    handleContinue={this.handleContinue}
                    loading={cartLoading}
                />
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    lang: R.path(['lang', 'lang'], state),
    cc: R.path(['lang', 'db'], state),
    cartLoading: R.path(['cart', 'loading'], state),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    saveAddOns: () => dispatch(saveAddOns()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddOnsSubContainer))
