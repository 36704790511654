import * as R from 'ramda'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, withRouter } from 'react-router-dom'
import { setDB, setLang } from './actions/langActions'
import PagesContainer from './PagesContainer.jsx'
import {
    getDBFromLocation,
    getLanguage,
    getLanguageFromLocation,
    getPathnameArray,
    isFullPath,
    isSupportedLanguage,
} from './language'

class Routes extends Component {
    constructor(props, context) {
        super(props, context)
    }

    componentDidMount() {
        const { location } = this.props
        this.setLanguage(location)
        this.setCountryCode(location)
        this.updateURL(location)
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            const { location } = this.props
            this.setLanguage(location)
            this.setCountryCode(location)
        }
    }

    setLanguage(location) {
        const { setLanguage } = this.props
        setLanguage(getLanguageFromLocation(location))
    }

    setCountryCode(location) {
        const { setCountryCode } = this.props
        setCountryCode(getDBFromLocation(location))
    }

    updateURL(location) {
        const { history } = this.props

        const pathArray = getPathnameArray(R.path(['location', 'pathname'], history))
        const ccInternal = getDBFromLocation(location)
        const ccHistory = pathArray[2]

        // Check if URL matches redux and update URL if necessary
        if (ccInternal !== ccHistory) {
            pathArray[2] = ccInternal
            history.push(R.join('/', pathArray))
        }
    }

    render() {
        const { language, location } = this.props
        const lang = getLanguage(language)
        const cc = getDBFromLocation(location)

        return isSupportedLanguage(language) && isFullPath(R.path(['pathname'], location)) ? (
            <Route path="/:lang/:cc" render={(props) => <PagesContainer {...props} />} />
        ) : (
            <Redirect to={`/${lang}/${cc}`} />
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    language: R.path(['lang', 'lang'], state),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    setLanguage: (language) => dispatch(setLang(language)),
    setCountryCode: (cc) => dispatch(setDB(R.toUpper(cc))),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Routes))
