import * as R from 'ramda'
import { fetchToken, deleteToken, fetchUserInfo, postPasswordResetToken, postPasswordReset } from '../api/backend'

export const GET_TOKEN = 'GET_TOKEN'
export const SET_TOKEN = 'SET_TOKEN'
export const RESET_TOKEN = 'RESET_TOKEN'
export const SET_PREV_PAGE = 'SET_PREV_PAGE'
export const USER_LOGOUT = 'USER_LOGOUT'

export const GET_USER_INFO = 'GET_USER_INFO'

export const RESET_PASSWORD = 'RESET_PASSWORD'
export const RESET_PASSWORD_MESSAGE = 'RESET_PASSWORD_MESSAGE'

export const setToken = (token) => (dispatch) => {
    dispatch({ type: SET_TOKEN, token })
}

export const getToken =
    (email = '', password = '') =>
    (dispatch) =>
        fetchToken(email, password)
            .then(() => {
                dispatch({
                    type: GET_TOKEN + '_SUCCESS',
                    token: true,
                })
                dispatch(resetResetMessage())
                dispatch(getUserInfo())
            })
            .catch((err) => {
                dispatch({
                    type: GET_TOKEN + '_FAILED',
                    message: R.or(R.path(['message'], err), 'Login error'),
                })
                dispatch(resetToken())
                dispatch(resetResetMessage())
            })

export const resetToken = () => (dispatch) =>
    deleteToken().then(() => {
        dispatch({ type: RESET_TOKEN })
    })

export const resetResetMessage = () => (dispatch) => {
    dispatch({ type: RESET_PASSWORD_MESSAGE })
}

export const userLogOut = () => (dispatch) => {
    dispatch(resetToken())
    dispatch({ type: USER_LOGOUT })
}

export const getUserInfo = () => (dispatch) =>
    fetchUserInfo()
        .then((user) =>
            dispatch({
                type: GET_USER_INFO + '_SUCCESS',
                user,
            }),
        )
        .then(() => dispatch(setToken(true)))
        .catch((err) =>
            dispatch({
                type: GET_USER_INFO + '_FAILURE',
                err,
            }),
        )

export const resetPasswordWithToken = (reset_token, password, confirm_password) => (dispatch) =>
    postPasswordResetToken(reset_token, password, confirm_password)
        .then((response) => {
            dispatch({
                type: RESET_PASSWORD + '_SUCCESS',
                message: R.path(['message'], response),
            })
        })
        .catch((err) => {
            dispatch({
                type: RESET_PASSWORD + '_FAILED',
                message: R.or(R.path(['message'], err), 'Reset error'),
            })
        })

export const resetPassword = (password, confirm_password, token) => (dispatch) =>
    postPasswordReset(password, confirm_password, token)
        .then((response) => {
            dispatch({
                type: RESET_PASSWORD + '_SUCCESS',
                message: R.path(['message'], response),
            })
        })
        .catch((err) => {
            dispatch({
                type: RESET_PASSWORD + '_FAILED',
                message: R.or(R.path(['message'], err), 'Reset error'),
            })
        })
