import * as R from 'ramda'
import React from 'react'
import { CardTypeIcon } from './CardIcon'
import { connect } from 'react-redux'
import { updateSelectedCards } from '../../actions/deliveryCardActions'
import { getFirstProductCountryCode, getFirstProductId } from '../../utils/CartProducts'
import { isFuneralOnlyOrder } from '../../utils/ProductTypes'
import { modifyObjectAtIndex, deliveryCardIds } from '../../utils/deliveryCards'

const CardTypeSelector = (props) => {
    const { deliveryCards, selectedCards, cardObject, cardIndex, cart, updateSelectedCards, cartProducts } = props

    const getCardsToRender = () => {
        // Only show `basic card` & `no card` in international and giftcard orders.
        const cardsForInternationalAndGiftCardOrders = deliveryCards.filter(
            (card) => card.id === deliveryCardIds.basicCard || card.id === deliveryCardIds.noCardId,
        )

        // Hide `basic card` if there's only funeral products in cart.
        const cardsForNormalOrFuneralOrders = isFuneralOnlyOrder(cartProducts)
            ? deliveryCards.filter((card) => card.id !== deliveryCardIds.basicCard)
            : deliveryCards

        const cardsToRender =
            getFirstProductCountryCode(cart) === 'FI' && getFirstProductId(cart) !== 1001
                ? cardsForNormalOrFuneralOrders
                : cardsForInternationalAndGiftCardOrders

        return cardsToRender
    }

    const cardsToRender = getCardsToRender()
    const selectedCardId = cardObject.extraId
    const selectedCardType = deliveryCards.filter((card) => card.id === selectedCardId)[0]

    const handleSelectCard = (cardId) => {
        let newSelectedCards

        if (cardId === deliveryCardIds.noCardId) {
            if (selectedCards.length > 1) {
                newSelectedCards = selectedCards.filter((_, i) => i !== cardIndex)
            } else {
                newSelectedCards = modifyObjectAtIndex(selectedCards, cardIndex, { extraId: cardId, cardText: '' })
            }
        } else {
            newSelectedCards = modifyObjectAtIndex(selectedCards, cardIndex, { extraId: cardId })
        }

        updateSelectedCards(newSelectedCards)
    }

    const cards = cardsToRender.map((card) => (
        <CardTypeIcon
            key={card.id}
            id={String(card.id)}
            card_type={card.category}
            img_url={card.image}
            isSelected={selectedCardId === card.id}
            name={card.name}
            price={card.price.toFixed(0)}
            onSelectCard={() => handleSelectCard(card.id)}
        />
    ))

    return (
        <React.Fragment>
            <div className="delivery__card__icons">{cards}</div>
            <div className="form__infotext delivery__card__info_text">
                <i className="fa fa-info-circle" aria-hidden="true"></i>

                {selectedCardType ? selectedCardType.description : null}
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = (state, ownProps) => ({
    cart: R.path(['cart'], state),
    deliveryCards: R.path(['cards', 'allDeliveryCards'], state),
    selected_card: R.path(['cards', 'selectedCard'], state),
    selectedCards: R.path(['cards', 'selectedCards'], state),
    cartProducts: R.pathOr([], ['cart', 'items', 'products'], state),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    updateSelectedCards: (selectedCards) => dispatch(updateSelectedCards(selectedCards)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CardTypeSelector)
