import * as R from 'ramda'

export const uniqueProductsInCart = (products) => R.uniqBy(R.prop('id'), products)

export const productCountsInCart = (products) =>
    R.reduce((acc, product) => {
        const currentId = R.path(['id'], product)
        const currentCount = R.pathOr(0, [currentId], acc)
        return R.assoc(currentId, currentCount + 1, acc)
    }, {})(products)

export const getFirstProductCountryCode = (cart) =>
    R.pathOr('', ['countrycode'], R.head(R.pathOr([], ['items', 'products'], cart)))

export const getFirstProductId = (cart) => R.pathOr('', ['id'], R.head(R.pathOr([], ['items', 'products'], cart)))

export const getTaxAmount = (price, tax) => Number(price) * (Number(tax) / 100)
