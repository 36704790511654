import React from 'react'
import * as R from 'ramda'
import classNames from 'classnames'
import config from '../../config'
const assets = R.path(['ASSETS'], config)

export const CardTypeIcon = ({ name, img_url, price, isSelected, onSelectCard, id }) => {
    const styles = classNames('pure-u-8-24 paymentTypeSelector', isSelected ? 'selected' : '')
    return (
        <div id={id} className={styles} onClick={onSelectCard}>
            <img className="pure-img" src={`${assets}/${img_url}`} alt={name} />
            <span className="input-group-radio-label paymentTypeSelector-text">
                {name} {String(price)}€
            </span>
        </div>
    )
}
