import * as R from 'ramda'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ProductContent from '../components/product/Product'
import isEmpty from 'lodash/isEmpty'

const Loading = ({ isLoadingProducts, isLoadingExtras, isLoadingOccasions, isLoadingCountries }) => {
    return isLoadingProducts && isLoadingExtras && isLoadingOccasions && isLoadingCountries ? <div>Loading</div> : null
}

const ProductError = ({ errorProducts, errorExtras, errorOccasions, errorCountries }) => {
    return errorProducts || errorExtras || errorOccasions || errorCountries ? (
        <div>
            Error {errorProducts} {errorExtras} {errorOccasions} {errorCountries}
        </div>
    ) : null
}

const AllProducts = ({ products, occasions, productId, lang, cc, selectedCountryCode, countries }) => {
    return !isEmpty(products) && !isEmpty(occasions) && !isEmpty(countries) ? (
        <ProductContent
            products={products}
            occasions={occasions}
            productId={productId}
            lang={lang}
            cc={cc}
            selectedCountryCode={selectedCountryCode}
            countries={countries}
        />
    ) : null
}

class ProductsContainer extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const { products, occasions, countries, lang, cc, selectedCountryCode, match } = this.props

        const productId = R.path(['params', 'productId'], match)

        return (
            <div>
                <Loading {...this.props} />
                <ProductError {...this.props} />
                <AllProducts
                    products={products}
                    occasions={occasions}
                    productId={productId}
                    lang={lang}
                    cc={cc}
                    selectedCountryCode={selectedCountryCode}
                    countries={countries}
                />
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    products: state.products.allProducts,
    isLoadingProducts: state.products.isLoadingProducts,
    errorProducts: state.products.errorProducts,
    isLoadingExtras: state.products.isLoadingExtras,
    errorExtras: state.products.errorExtras,
    isLoadingOccasions: state.products.isLoadingOccasions,
    errorOccasions: state.products.errorOccasions,
    occasions: state.products.allOccasions,
    lang: R.path(['lang', 'lang'], state),
    cc: R.path(['lang', 'db'], state),
    selectedCountryCode: state.category.selectedCountryCode,
    countries: state.products.allCountries,
    isLoadingCountries: state.products.isLoadingCountries,
    errorCountries: state.products.errorCountries,
})

export default withRouter(connect(mapStateToProps, undefined)(ProductsContainer))
