export const categoryRoute = {
    path: '/category',
    exact: true,
    strict: false,
}

export const productRoute = {
    path: '/product/:productId',
    exact: true,
    strict: false,
}

export const contentRoute = {
    path: '/content',
    exact: false,
    strict: false,
}

export const orderRoute = {
    path: '/order',
    exact: false,
    strict: false,
}

export const loginRoute = {
    path: '/login',
    exact: false,
    strict: false,
}

export const forgotPasswordRoute = {
    path: '/forgot_password',
    exact: false,
    strict: false,
}

export const resetPasswordRoute = {
    path: '/reset_password/:reset_token',
    exact: false,
    strict: false,
}

export const landingRoute = {
    path: '',
    exact: true,
    strict: false,
}
