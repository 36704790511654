import * as R from 'ramda'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import ProductTags from './ProductTags'
import Recommendations from '../recommendations/Recommendations'
import {
    emptyCartAdditionalProduct,
    saveProductId,
    setLastViewedProductId,
    unselectCondolences,
    unselectRibbon,
} from '../../actions/orderActions'
import { saveGiftCardAmount, saveGiftCardPrice } from '../../actions/formActions'
import {
    addGiftcardToCart,
    addProductToCart,
    emptyAllCartProducts,
    saveProductAndGiftcardToCart,
} from '../../actions/cartActions'
import { showCartModal } from '../../actions/cartModalActions'
import { Link, Redirect, withRouter } from 'react-router-dom'
import Helmet from 'react-helmet'
import Slider from 'react-rangeslider'
import { trackProductDetailView, trackProductRemoveFromCart } from '../../tracking/tracking'
import { getLanguage } from '../../utils/translation'

import config from '../../config'
import { DELIVERY_COST_DOMESTIC_WITH_COMMA, DELIVERY_COST_FOREIGN_WITH_COMMA } from '../../utils/constants'

const canUseDOM = !!(typeof window !== 'undefined' && window.document)

const assets = R.path(['ASSETS'], config)
const server = R.path(['SERVER'], config)

const pageTranslation = {
    fi: {
        page: {
            giftCardInfo_1:
                'Interfloralta voit tilata myös kukkalahjakortteja! Niillä voi maksaa kukkaostoksia kaikissa Suomen Interflora-kukkakaupoissa.',
            giftCardInfo_2:
                'Toimituskulut ovat 3,80 euroa lahjakorttia kohden. Mikäli tilaat useamman lahjakortin samaan toimitusosoitteeseen, ovat toimituskulut 3,80 euroa osoitetta kohden. Lahjakortit ovat voimassa kaksi (2) vuotta.',
            giftCardInfo_3:
                'Lahjakortit postitetaan vastaanottajille, tilaajan antamaan osoitteeseen. Arkipäivisin lahjakortti tulee olla tilattu klo 11 mennessä ehtiäkseen saman päivän postituksiin. Lahjakortit jotka on tilattu perjantaina klo 11 jälkeen, tai viikonloppuna, postitetaan seuraavana arkipäivänä, ellet ole määrittänyt myöhäisempää postituspäivää. Jos lisäät tilaukseesi myös tervehdyskortin, voit kirjoittaa siihen haluamasi korttitekstin.',
            giftcardInfo_4:
                'Kukkalahjakorttia ei voi vaihtaa rahaksi eikä sillä voi maksaa Interflora-kukkalähetystä. Niitä voi käyttää ainoastaan Suomessa.',
            giftCardAmount: 'Määrä',
            giftCardInfo_header: 'Tietoa lahjakorteista',
            buttonAddToCart: 'Lisää ostoskoriin',
            buttonSend: 'Jatka tilausta',
            description: 'Kuvaus',
            deliveryFee: 'toimitusmaksu',
            price: 'Hinta',
            contains: 'Kuvan tuotteessa on',
            countryOfDestination: 'Kohdemaa',
        },
    },
    en: {
        page: {
            giftCardInfo_1:
                'You can send floral cheques to be redeemed in any Interflora flower shop in Finland. The service charge is 3,80 euros per delivery address. All floral cheques are sent by mail to the recipient and they are valid for two (2) years from the date of purchase.',
            giftCardInfo_2:
                'For same day shipment, we must receive your floral cheque order before 11 A.M. on weekdays. Floral cheques ordered after 11 A.M. on Fridays or during the weekend will be mailed on the following weekday at the earliest unless you have chosen a posterior mailing date. If you add a greeting card to your order, you may write a personal message to be delivered with your floral cheque.',
            giftCardInfo_3:
                'The floral cheques cannot be paid out in cash nor be used to pay for an Interflora flower delivery. They are valid only in Finland.',
            giftCardInfo_4: '',
            giftCardAmount: 'Amount',
            giftCardInfo_header: 'About floral cheques',
            buttonAddToCart: 'Add to cart',
            buttonSend: 'Continue',
            description: 'Description',
            deliveryFee: 'delivery fee',
            price: 'Price',
            contains: 'Displayed item includes',
            countryOfDestination: 'Country of destination',
        },
    },
}

class ProductContent extends Component {
    constructor(props) {
        super(props)
        this.state = { prod: this.getProduct(this.props) }
    }

    componentDidUpdate(prevProps) {
        if (this.props.productId !== prevProps.productId) {
            this.setState({ prod: this.getProduct(this.props) })
        }
    }

    getProduct(props) {
        const { productId, products, storeState, setLastViewedProductId } = props

        if (canUseDOM) {
            window.scrollTo(0, 0)
            this.sendTracking()
            trackProductDetailView(storeState, productId)
        }

        // Set this product as last viewed
        setLastViewedProductId(productId)

        // Get Product data for state
        return R.find(R.propEq('id', Number(productId)))(products)
    }

    handleOnSliderPriceChange(value) {
        const { saveGiftCardPrice } = this.props
        saveGiftCardPrice(value)
    }

    handleOnSliderAmountChange(value) {
        const { saveGiftCardAmount } = this.props
        saveGiftCardAmount(value)
    }

    sendTracking() {
        const { addOnProducts, storeState } = this.props

        // Empty dataLayer cart of additional products if any
        if (R.length(addOnProducts) !== 0) {
            trackProductRemoveFromCart(storeState)
        }
    }

    removeAllCartProductsThenAddGiftcardToCart(event) {
        event.preventDefault()

        const {
            productId,
            saveProductId,
            unselectRibbon,
            unselectCondolences,
            emptyCartAdditionalProduct,
            addGiftcardToCart,
            emptyAllCartProducts,
            showCartModal,
        } = this.props

        // When selecting a new product, reset all product/extras related stuff

        // Redux only
        saveProductId(productId)
        unselectRibbon()
        unselectCondolences()
        emptyCartAdditionalProduct()

        // Redux + backend
        // First remove all other products from cart
        emptyAllCartProducts()

        // Then add product to cart
        addGiftcardToCart(productId)

        // Lastly display modal
        showCartModal()
    }

    addProductToCart(event) {
        event.preventDefault()

        const { productId, saveProductId, addProductToCart, showCartModal } = this.props

        // Show Cart Modal
        showCartModal()

        // Redux only
        saveProductId(productId)

        // Redux + backend
        addProductToCart(productId)
    }

    render() {
        const { lang, cc, allCountries, productId, giftcardPrice, giftcardAmount, occasions } = this.props

        const { prod } = this.state

        const selectedTrans = R.path([getLanguage()], pageTranslation)
        const trans = R.path(['page'], selectedTrans)

        const bgImageURL =
            cc === 'fi' ? `${assets}/big_` + R.path(['image'], prod) : R.path(['image'], prod) + '?width=900&height=900'

        const bgImageURL_thumb =
            cc === 'fi' ? `${assets}/big_` + R.path(['image'], prod) : R.path(['image'], prod) + '?width=900&height=900'

        const country = R.find(R.propEq('CountryCode', R.path(['countrycode'], prod)))(allCountries)
        const productCountryName = R.path(['Name'], country)
        const deliveryCost =
            R.path(['countrycode'], prod) === 'FI'
                ? `${DELIVERY_COST_DOMESTIC_WITH_COMMA}`
                : `${DELIVERY_COST_FOREIGN_WITH_COMMA}`

        return prod === undefined ? (
            <Redirect to={`/${lang}/${cc}`} />
        ) : (
            <div>
                <Helmet
                    title={R.path(['name'], prod)}
                    meta={[
                        {
                            name: 'keywords',
                            content:
                                'Interflora, kukkakaupan huippuammattilaisia, kukkasitojia, floristeja, floristihortonomeja ja floristimestareita, kukkatervehdys, hautajaiskukat, hautavihot, kukat hautajaisiin, kukat juhliin, verkkokukka, kukka + yllätys',
                        },
                        { name: 'description', content: R.path(['description'], prod) },
                        { property: 'og:title', content: R.path(['name'], prod) },
                        { property: 'og:description', content: R.path(['description'], prod) },
                        { property: 'og:image', content: bgImageURL_thumb },
                        { property: 'og:url', content: `${server}/${lang}/${cc}/product/${productId}` },
                    ]}
                    script={[
                        {
                            type: 'application/ld+json',
                            innerHTML: `{
              "@context": "http://schema.org",
              "@type": "Product",
              "name": "${R.path(['name'], prod)}",
              "image": "${bgImageURL_thumb}",
              "description": "${R.path(['description'], prod)}",
              "url": "${server}/${lang}/${cc}/product/${productId}"
             }`,
                        },
                    ]}
                />
                <div className={'product__grid pure-hidden-smallphone'}>
                    <div key={`${productId}`} className="product__wrapper pure-g">
                        <div className="pure-u-1 pure-u-sm-15-24 product__image__wrapper">
                            <img className="pure-img product__image" src={bgImageURL} alt="" />
                        </div>
                        <div className="pure-u-1 pure-u-sm-9-24 product__sidebar">
                            <div className="product__sidebar__wrapper">
                                <div className="product__sidebar__headline">
                                    <h1 className="product__sidebar__heading">{R.path(['name'], prod)}</h1>
                                    <hr />
                                    {R.path(['category'], prod) !== 'lahjakortti' && (
                                        <h1 className="product__sidebar__price">
                                            {R.path(['price'], prod)}
                                            <span>€</span>
                                        </h1>
                                    )}
                                    {R.path(['category'], prod) !== 'lahjakortti' && (
                                        <span className="product__sidebar__priceinfo hidden">
                                            + {deliveryCost}€ {R.path(['deliveryFee'], trans)}
                                        </span>
                                    )}
                                </div>
                                {R.path(['category'], prod) === 'lahjakortti' && (
                                    <div className="giftcard__slider">
                                        <h3 className="product__tags--headline alignLeft">
                                            {R.path(['price'], trans)}
                                        </h3>
                                        <div className="giftcard__value">{giftcardPrice}€</div>
                                        <Slider
                                            min={10}
                                            max={100}
                                            step={5}
                                            value={giftcardPrice}
                                            orientation="horizontal"
                                            onChange={this.handleOnSliderPriceChange.bind(this)}
                                        />
                                    </div>
                                )}
                                {R.path(['category'], prod) === 'lahjakortti' && (
                                    <div className="giftcard__slider">
                                        <h3 className="product__tags--headline alignLeft">
                                            {R.path(['giftCardAmount'], trans)}
                                        </h3>
                                        <div className="giftcard__value">{giftcardAmount}</div>
                                        <Slider
                                            min={1}
                                            max={100}
                                            step={1}
                                            value={giftcardAmount}
                                            orientation="horizontal"
                                            onChange={this.handleOnSliderAmountChange.bind(this)}
                                        />
                                    </div>
                                )}
                                {R.path(['description'], prod) && (
                                    <div className="product__sidebar__info pure-hidden-tablet pure-hidden-phone">
                                        <h3 className="product__sidebar__info__headline">
                                            {R.path(['description'], trans)}
                                        </h3>
                                        <p className="product__sidebar__info__textcontent">
                                            {R.path(['description'], prod)}
                                        </p>
                                    </div>
                                )}
                                {R.path(['category'], prod) !== 'lahjakortti' &&
                                    R.path(['countrycode'], prod) === 'FI' && (
                                        <div className="product__sidebar__tags pure-hidden-tablet pure-hidden-phone">
                                            <div className="product__description--tags--gold">
                                                <ProductTags
                                                    tagColor="gold"
                                                    occasions={occasions}
                                                    tagsOccasion={R.path(['occasion'], prod)}
                                                    tagsType={R.path(['type'], prod)}
                                                    tagsColor={R.path(['color'], prod)}
                                                    lang={lang}
                                                    cc={cc}
                                                />
                                            </div>
                                            <h3 className="product__tags--headline">{R.path(['contains'], trans)}</h3>
                                            <div className="product__description--tags--cyan">
                                                <ProductTags
                                                    tagColor="cyan"
                                                    tagsContains={R.path(['contains'], prod)}
                                                    lang={lang}
                                                    cc={cc}
                                                />
                                            </div>
                                        </div>
                                    )}
                                <div className="product__sidebar__tags pure-hidden-tablet pure-hidden-phone">
                                    <h3 className="product__tags--headline">
                                        {R.path(['countryOfDestination'], trans)}
                                    </h3>
                                    <div className="product__description--tags--cyan">
                                        <ProductTags
                                            tagColor="cyan"
                                            tagsCountry={R.path(['countrycode'], prod)}
                                            tagsCountryFullName={productCountryName}
                                            lang={lang}
                                            cc={cc}
                                        />
                                    </div>
                                </div>
                                <div className="product__sidebar__buttoncontainer" id={`add-product-to-cart-button`}>
                                    {R.path(['category'], prod) === 'kukat' && (
                                        <button
                                            className="pure-button pure-button-primary product__sidebar__orderbutton"
                                            onClick={this.addProductToCart.bind(this)}
                                        >
                                            <i className="fa fa-shopping-cart fa-lg icon-padding"></i>
                                            {R.path(['buttonAddToCart'], trans)}
                                        </button>
                                    )}
                                    {R.path(['category'], prod) === 'lahjakortti' &&
                                        R.path(['countrycode'], prod) === 'FI' && (
                                            <button
                                                className="pure-button pure-button-primary product__sidebar__orderbutton"
                                                id="Product-add-giftcard-to-cart-button"
                                                onClick={this.removeAllCartProductsThenAddGiftcardToCart.bind(this)}
                                            >
                                                <i className="fa fa-shopping-cart fa-lg icon-padding"></i>
                                                {R.path(['buttonAddToCart'], trans)}
                                            </button>
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="product__grid pure-hidden-desktop pure-hidden-tablet pure-hidden-phone">
                    <div key={`${productId}`} className="product__wrapper pure-g">
                        <div className="product__grid__colorbox"></div>
                        <div className="pure-u-1 pure-u-sm-15-24 product__image__wrapper">
                            <img className="pure-img product__image" src={bgImageURL_thumb} alt="" />
                        </div>
                    </div>
                </div>
                <div className="product__sidebar__tablet pure-g pure-hidden-desktop">
                    <div className="pure-u-1 product__sidebar__headline pure-hidden-tablet pure-hidden-phone">
                        <h1 className="product__sidebar__heading">{R.path(['name'], prod)}</h1>
                        <hr />
                        {R.path(['category'], prod) !== 'lahjakortti' && R.path(['countrycode'], prod) === 'FI' && (
                            <div className="pure-g">
                                <div className="pure-u-1 alignLeft">
                                    <h1 className="product__sidebar__price">
                                        {R.path(['price'], prod)}
                                        <span>€</span>
                                    </h1>
                                    <span className="product__sidebar__priceinfo hidden">
                                        + {deliveryCost}€ {R.path(['deliveryFee'], trans)}
                                    </span>
                                </div>
                                <div className="add__to__cart__buttons__container">
                                    <div className="pure-u-1">
                                        <button
                                            className="pure-button pure-button-primary product__sidebar__orderbutton wide"
                                            onClick={this.addProductToCart.bind(this)}
                                        >
                                            <i className="fa fa-shopping-cart fa-lg icon-padding"></i>
                                            {R.path(['buttonAddToCart'], trans)}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                        {R.path(['category'], prod) !== 'lahjakortti' && R.path(['countrycode'], prod) !== 'FI' && (
                            <div className="pure-g">
                                <div className="pure-u-10-24 alignLeft">
                                    <h1 className="product__sidebar__price">
                                        {R.path(['price'], prod)}
                                        <span>€</span>
                                    </h1>
                                    <span className="product__sidebar__priceinfo hidden">
                                        + {deliveryCost}€ {R.path(['deliveryFee'], trans)}
                                    </span>
                                </div>
                                <div className="pure-u-1">
                                    <button
                                        className="pure-button pure-button-primary product__sidebar__orderbutton wide"
                                        onClick={this.addProductToCart.bind(this)}
                                    >
                                        <i className="fa fa-shopping-cart fa-lg icon-padding"></i>
                                        {R.path(['buttonAddToCart'], trans)}
                                    </button>
                                </div>
                            </div>
                        )}
                        {R.path(['category'], prod) === 'lahjakortti' && (
                            <Link
                                to={`/${lang}/${cc}/order/recipient-info`}
                                onClick={this.removeAllCartProductsThenAddGiftcardToCart.bind(this)}
                            >
                                <button className="pure-button pure-button-primary product__sidebar__orderbutton wide">
                                    {R.path(['buttonSend'], trans)}
                                </button>
                            </Link>
                        )}
                        {R.path(['category'], prod) === 'lahjakortti' && (
                            <div className="giftcard__slider">
                                <h3 className="product__tags--headline alignLeft">{R.path(['price'], trans)}</h3>
                                <div className="giftcard__value">{giftcardPrice}€</div>
                                <Slider
                                    min={10}
                                    max={100}
                                    step={5}
                                    value={giftcardPrice}
                                    orientation="horizontal"
                                    onChange={this.handleOnSliderPriceChange.bind(this)}
                                />
                            </div>
                        )}
                        {R.path(['category'], prod) === 'lahjakortti' && (
                            <div className="giftcard__slider">
                                <h3 className="product__tags--headline alignLeft">
                                    {R.path(['giftCardAmount'], trans)}
                                </h3>
                                <div className="giftcard__value">{giftcardAmount}</div>
                                <Slider
                                    min={1}
                                    max={100}
                                    step={1}
                                    value={giftcardAmount}
                                    orientation="horizontal"
                                    onChange={this.handleOnSliderAmountChange.bind(this)}
                                />
                            </div>
                        )}
                    </div>
                    {(R.path(['category'], prod) === 'lahjakortti' || R.path(['countrycode'], prod) !== 'FI') &&
                        R.path(['description'], prod) && (
                            <div className="product__sidebar__info pure-u-1">
                                <h3 className="product__sidebar__info__headline">{R.path(['description'], trans)}</h3>
                                <p className="product__sidebar__info__textcontent">{R.path(['description'], prod)}</p>
                            </div>
                        )}
                    {R.path(['category'], prod) !== 'lahjakortti' && R.path(['countrycode'], prod) === 'FI' && (
                        <div className="product__sidebar__info pure-u-1 pure-u-sm-16-24">
                            <h3 className="product__sidebar__info__headline">{R.path(['description'], trans)}</h3>
                            <p className="product__sidebar__info__textcontent">{R.path(['description'], prod)}</p>
                            <div className="product__sidebar__tags">
                                <div className="product__description--tags--gold">
                                    <ProductTags
                                        tagColor="gold"
                                        occasions={occasions}
                                        tagsOccasion={R.path(['occasion'], prod)}
                                        tagsType={R.path(['type'], prod)}
                                        tagsColor={R.path(['color'], prod)}
                                        lang={lang}
                                        cc={cc}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    {R.path(['category'], prod) !== 'lahjakortti' && R.path(['countrycode'], prod) === 'FI' && (
                        <div className="product__sidebar__tags pure-u-1 pure-u-sm-8-24">
                            <h3 className="product__tags--headline">{R.path(['contains'], trans)}</h3>
                            <div className="product__description--tags--cyan">
                                <ProductTags
                                    tagColor="cyan"
                                    tagsContains={R.path(['contains'], prod)}
                                    lang={lang}
                                    cc={cc}
                                />
                            </div>
                        </div>
                    )}
                    <div className="product__sidebar__tags pure-u-1">
                        <h3 className="product__tags--headline">{R.path(['countryOfDestination'], trans)}</h3>
                        <div className="product__description--tags--cyan">
                            <ProductTags
                                tagColor="cyan"
                                tagsCountry={R.path(['countrycode'], prod)}
                                tagsCountryFullName={productCountryName}
                                lang={lang}
                                cc={cc}
                            />
                        </div>
                    </div>
                </div>
                {R.path(['category'], prod) === 'lahjakortti' && (
                    <div className="product__giftcard__info">
                        <h3 className="product__giftcard__info__headline">{R.path(['giftCardInfo_header'], trans)}</h3>
                        <p className="product__giftcard__info__textcontent">
                            {R.path(['giftCardInfo_1'], trans)}
                            <br />
                            <br />
                            {R.path(['giftCardInfo_2'], trans)}
                            <br />
                            <br />
                            {R.path(['giftCardInfo_3'], trans)}
                            <br />
                            <br />
                            {R.path(['giftCardInfo_4'], trans)}
                        </p>
                    </div>
                )}
                {R.path(['category'], prod) !== 'lahjakortti' && (
                    <div className="recommendations">
                        <Recommendations />
                    </div>
                )}
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    storeState: state,
    addOnProducts: state.order.selectedAddOnProducts,
    allCountries: state.products.allCountries,
    giftcardAmount: R.path(['form', 'giftcardAmount'], state),
    giftcardPrice: R.path(['form', 'giftcardPrice'], state),
    lang: R.path(['lang', 'lang'], state),
    cc: R.path(['lang', 'db'], state),
})

const mapDispatchToProps = (dispatch) => ({
    setLastViewedProductId: (productId) => dispatch(setLastViewedProductId(productId)),
    saveProductAndGiftcardToCart: (product_id) => dispatch(saveProductAndGiftcardToCart(product_id)),
    addProductToCart: (product_id) => dispatch(addProductToCart(product_id)),
    addGiftcardToCart: (product_id) => dispatch(addGiftcardToCart(product_id)),
    saveProductId: (selectedProductId) => dispatch(saveProductId(selectedProductId)),
    saveGiftCardPrice: (giftcardPrice) => dispatch(saveGiftCardPrice(giftcardPrice)),
    saveGiftCardAmount: (giftcardAmount) => dispatch(saveGiftCardAmount(giftcardAmount)),
    unselectRibbon: () => dispatch(unselectRibbon()),
    unselectCondolences: () => dispatch(unselectCondolences()),
    emptyCartAdditionalProduct: () => dispatch(emptyCartAdditionalProduct()),
    showCartModal: () => dispatch(showCartModal()),
    emptyAllCartProducts: () => dispatch(emptyAllCartProducts()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductContent))
