import * as R from 'ramda'
import React, { Component } from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { initiateSearch } from '../../actions/categoryActions'
import { connect } from 'react-redux'
import moment from 'moment-timezone'
import find from 'lodash/find'

class ProductTags extends Component {
    handle_click_occasion(tag) {
        const { cc } = this.props
        this.props.initiateSearchClick(tag.tag, '', '', '', '', '', R.toUpper(cc))
    }
    handle_click_type(tag) {
        const { cc } = this.props
        this.props.initiateSearchClick('', '', '', tag.tag, '', '', R.toUpper(cc))
    }
    handle_click_color(tag) {
        const { cc } = this.props
        this.props.initiateSearchClick('', tag.tag, '', '', '', '', R.toUpper(cc))
    }
    handle_click_contains(tag) {
        const { cc } = this.props
        this.props.initiateSearchClick('', '', tag.tag, '', '', '', R.toUpper(cc))
    }
    handle_click_country(tagsCountry) {
        this.props.initiateSearchClick('', '', '', '', '', '', R.path(['tagsCountry'], tagsCountry))
    }

    render() {
        const {
            tagsOccasion,
            tagsType,
            tagsColor,
            tagsContains,
            tagsCountry,
            tagsCountryFullName,
            occasions,
            lang,
            cc,
        } = this.props

        var tagClass = classNames(
            'product__description--tag',
            'pure-button',
            'pure-button-primary',
            { 'tag--cyan': this.props.tagColor === 'cyan' },
            { 'tag--gold': this.props.tagColor === 'gold' },
        )

        return (
            <div>
                {tagsOccasion &&
                    tagsOccasion.map(function (tag, key) {
                        const occasion = find(occasions, { occasion: tag })

                        // Conditional rendering of this tag
                        if (occasion) {
                            if (occasion.time_start !== '' && occasion.time_end !== '') {
                                if (
                                    !moment(new Date())
                                        .tz('Europe/Helsinki')
                                        .isBetween(occasion.time_start, occasion.time_end, null, '[]')
                                ) {
                                    return null
                                }
                            }
                        }
                        return (
                            <Link key={key} className="no-decoration" to={`/${lang}/${cc}/category`}>
                                <div className={tagClass}>
                                    <div onClick={this.handle_click_occasion.bind(this, { tag })}>
                                        <span>{tag}</span>
                                    </div>
                                </div>
                            </Link>
                        )
                    }, this)}
                {tagsType &&
                    tagsType.map(function (tag, key) {
                        return (
                            <Link key={key} className="no-decoration" to={`/${lang}/${cc}/category`}>
                                <div className={tagClass}>
                                    <div onClick={this.handle_click_type.bind(this, { tag })}>
                                        <span>{tag}</span>
                                    </div>
                                </div>
                            </Link>
                        )
                    }, this)}
                {tagsColor &&
                    tagsColor.map(function (tag, key) {
                        return (
                            <Link key={key} className="no-decoration" to={`/${lang}/${cc}/category`}>
                                <div className={tagClass}>
                                    <div onClick={this.handle_click_color.bind(this, { tag })}>
                                        <span>{tag}</span>
                                    </div>
                                </div>
                            </Link>
                        )
                    }, this)}
                {tagsContains &&
                    tagsContains.map(function (tag, key) {
                        return (
                            <Link key={key} className="no-decoration" to={`/${lang}/${cc}/category`}>
                                <div className={tagClass}>
                                    <div onClick={this.handle_click_contains.bind(this, { tag })}>
                                        <span>{tag}</span>
                                    </div>
                                </div>
                            </Link>
                        )
                    }, this)}
                {tagsCountry && (
                    <Link key={tagsCountry} className="no-decoration" to={`/${lang}/${cc}/category`}>
                        <div className={tagClass}>
                            <div onClick={this.handle_click_country.bind(this, { tagsCountry })}>
                                <span className={'flag-icon flag-icon-' + tagsCountry.toLowerCase()}></span>
                                {tagsCountryFullName}
                            </div>
                        </div>
                    </Link>
                )}
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    lang: R.path(['lang', 'lang'], state),
    cc: R.path(['lang', 'db'], state),
})

function mapDispatchToProps(dispatch) {
    return {
        initiateSearchClick: (
            categoryOccasion,
            categoryColor,
            categoryContains,
            categoryType,
            minPrice,
            categoryPriceSort,
            selectedCountryCode,
        ) =>
            dispatch(
                initiateSearch(
                    categoryOccasion,
                    categoryColor,
                    categoryContains,
                    categoryType,
                    minPrice,
                    categoryPriceSort,
                    selectedCountryCode,
                ),
            ),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductTags)
