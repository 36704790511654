import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import AddOnProductCard from './AddOnProductCard'

import * as R from 'ramda'

const pageTranslation = {
    fi: {
        page: {
            send: 'Jatka',
            back: 'Takaisin',
        },
    },
    en: {
        page: {
            send: 'Continue',
            back: 'Back',
        },
    },
}

class AddOnContainer extends Component {
    constructor(props, context) {
        super(props, context)

        const { history, lang, cc, cartProducts } = this.props

        // User tried to skip straight to add-ons without having any products in cart, redirect
        if (R.isEmpty(cartProducts)) {
            replace('/' + lang + '/' + cc)
            history.push('/' + lang + '/' + cc)
        } else {
            const firstCartProduct = R.head(cartProducts)
            const firstCartProductCountryCode = R.path(['countrycode'], firstCartProduct)

            // No such product, redirect to front page
            if (!firstCartProductCountryCode) {
                history.push('/' + lang + '/' + cc)
            }

            // No additional products selection for international orders
            if (firstCartProductCountryCode !== 'FI') {
                history.push('/' + lang + '/' + cc)
            }
        }
    }

    render() {
        const { extras, cartExtras } = this.props

        return (
            <div className="pure-g addons__product__grid">
                {extras.map(function (product, key) {
                    return (
                        <AddOnProductCard product={product} cartExtras={R.or(cartExtras, [])} key={key} index={key} />
                    )
                }, this)}
            </div>
        )
    }
}

const getVisibleExtras = (cartProducts, allExtras) => {
    const cartOccasions = R.compose(
        R.uniq,
        R.flatten,
        R.map((p) => R.pathOr([], ['occasion'], p)),
    )(cartProducts)

    return R.filter((extra) => !R.isEmpty(R.intersection(cartOccasions, R.prop('occasion', extra))))(allExtras)
}

const mapStateToProps = (state, ownProps) => ({
    allProducts: R.path(['products', 'allProducts'], state),
    allExtras: R.path(['products', 'allExtras'], state),
    cartProducts: R.pathOr([], ['cart', 'items', 'products'], state),
    cartExtras: R.pathOr([], ['cart', 'items', 'extras'], state),
    lang: R.path(['lang', 'lang'], state),
    cc: R.path(['lang', 'db'], state),
})

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { allExtras, cartProducts } = stateProps
    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        extras: getVisibleExtras(cartProducts, allExtras),
    }
}

export default withRouter(connect(mapStateToProps, undefined, mergeProps)(AddOnContainer))
