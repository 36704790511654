import React, { Component } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import isMobile from '../../utils/IsMobile'

import fi from 'date-fns/locale/fi'
import en from 'date-fns/locale/en-GB'

class CustomInput extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <input
                {...this.props}
                ref={(c) => (this.input = c)}
                onFocus={() => {
                    this.input.blur()
                }}
                type="text"
                readOnly={true}
                style={{ color: 'transparent', textShadow: '0 0 0 black' }}
            />
        )
    }
}

class Datepicker extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        if (this.props.locale === 'fi-fi') {
            registerLocale('fi', fi)
        } else {
            registerLocale('en', en)
        }
        return isMobile() ? (
            <DatePicker
                {...this.props}
                customInput={<CustomInput />}
                locale={this.props.locale === 'fi-fi' ? 'fi' : 'en'}
                withPortal
            />
        ) : (
            <DatePicker
                {...this.props}
                customInput={<CustomInput />}
                locale={this.props.locale === 'fi-fi' ? 'fi' : 'en'}
            />
        )
    }
}

export default Datepicker
