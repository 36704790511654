import React, { Component } from 'react'

import { required, email } from '../../utils/ValidationRules.jsx'
import Form from 'react-validation/build/form'
import Input from 'react-validation/build/input'
import Button from 'react-validation/build/button'

import { getLanguage } from '../../utils/translation'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import * as R from 'ramda'

import { getToken } from '../../actions/loginActions'

const pageTranslation = {
    fi: {
        page: {
            email: 'Sähköposti',
            yourEmail: 'Sähköpostiosoitteesi',
            password: 'Salasana',
            yourPassword: 'Salasanasi',
            loginInfo: 'Kirjaudu',
            loginAdditionalInfo:
                'Kirjatuminen koskee vain laskulle tilaavia yritysasiakkaita. Yksityishenkilöt voivat tilata ilman kirjautumista.',
            loginButton: 'Kirjaudu',
            forgotPassword: 'Unohditko salasanasi?',
        },
    },
    en: {
        page: {
            email: 'Email address',
            yourEmail: 'Your email address',
            password: 'Password',
            yourPassword: 'Your password',
            loginInfo: 'Login',
            loginAdditionalInfo:
                'Accounts are only for business that order through invoicing. Individuals can order without logging in.',
            loginButton: 'Login',
            forgotPassword: 'Forgot your password?',
        },
    },
}

class Login extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loginEmail: '',
            loginPassword: '',
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        this.checkToken()
    }

    componentDidUpdate() {
        this.checkToken()
    }

    checkToken() {
        const { token, history, lang, cc } = this.props
        token && history.push(`/${lang}/${cc}`)
    }

    submitForm(event) {
        event.preventDefault()
        const { login } = this.props
        const { loginEmail, loginPassword } = this.state
        login(loginEmail, loginPassword)
    }

    handle_loginEmail_change(event) {
        this.setState({ loginEmail: event.target.value })
    }

    handle_loginPassword_change(event) {
        this.setState({ loginPassword: event.target.value })
    }

    render() {
        const { lang, cc } = this.props

        const selectedTrans = R.path([getLanguage()], pageTranslation)
        const trans = R.path(['page'], selectedTrans)

        const { loginMessage } = this.props
        const message = R.or(loginMessage, '')

        return (
            <div className="productOrder">
                <div className="productOrder__contentHolder">
                    <Form className="pure-form pure-form-stacked productOrder__form login__form">
                        <fieldset>
                            <h2 className="login__headline">{R.path(['loginInfo'], trans)}</h2>
                            <h5 className="login__headline">{R.path(['loginAdditionalInfo'], trans)}</h5>
                            <div className="input-group form__field">
                                <label htmlFor="loginEmail">
                                    {R.path(['email'], trans)} <sup>*</sup>
                                </label>
                                <Input
                                    onChange={this.handle_loginEmail_change.bind(this)}
                                    placeholder={R.path(['yourEmail'], trans)}
                                    id="loginEmail"
                                    errorclassname="is-invalid-input"
                                    type="email"
                                    containerclassname=""
                                    value={this.state.loginEmail}
                                    name="loginEmail"
                                    validations={[required, email]}
                                    lang={lang}
                                />
                            </div>

                            <div className="input-group form__field">
                                <label htmlFor="loginPassword">
                                    {R.path(['password'], trans)} <sup>*</sup>
                                </label>
                                <Input
                                    onChange={this.handle_loginPassword_change.bind(this)}
                                    placeholder={R.path(['yourPassword'], trans)}
                                    id="loginPassword"
                                    errorclassname="is-invalid-input"
                                    type="password"
                                    containerclassname=""
                                    value={this.state.loginPassword}
                                    name="loginPassword"
                                    validations={[required]}
                                    lang={lang}
                                />
                            </div>

                            <p className="login__message">{message}</p>

                            <div className="productOrder__buttonContainer">
                                <Button
                                    onClick={this.submitForm.bind(this)}
                                    className="pure-button pure-button-primary productOrder__button"
                                    id="Login-Page-login-button"
                                >
                                    {R.path(['loginButton'], trans)}
                                </Button>
                            </div>

                            <br />

                            <Link className="login__link" to={`/${lang}/${cc}/forgot_password`}>
                                {R.path(['forgotPassword'], trans)}
                            </Link>
                        </fieldset>
                    </Form>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    lang: R.path(['lang', 'lang'], state),
    cc: R.path(['lang', 'db'], state),
    token: R.path(['login', 'token'], state),
    loginMessage: R.path(['login', 'loginMessage'], state),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    login: (email, password) => dispatch(getToken(email, password)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login))
