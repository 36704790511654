import * as R from 'ramda'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Modal from 'react-modal/lib/components/Modal'
import Motion from 'react-motion/lib/Motion'
import spring from 'react-motion/lib/spring'
import { hideModal } from '../../actions/modalActions'
import { getLanguage } from '../../utils/translation'
import { DELIVERY_COST_DOMESTIC_WITH_COMMA, DELIVERY_COST_FOREIGN_WITH_COMMA } from '../../utils/constants'

const pageTranslation = {
    fi: {
        page: {
            close: 'Sulje',
            errorOccurredWhileProcessing: 'Tilauksesi käsittelyssä tapahtui virhe',
            errorCantProcessRightNow:
                'Maksua ei voi tällä hetkellä suorittaa. Tarkista verkkoyhteytesi ja kokeile myöhemmin uudelleen.',
            errorFillOrderAgain: 'Tee tilaus uudestaan.',
            errorCheckCard: 'Maksua ei voida suorittaa. Tarkista korttitiedot.',
            errorCanNotProcess: 'Maksua ei voida suorittaa. Kokeile myöhemmin uudelleen.',
            deliveryTermsHeader: 'Toimitusehdot',
            deliveryTermsFlowerOrder: 'Kukkatilaukset',
            discount: 'Alennus',
            discountCodeNotFound: 'Kampanjakoodia ei löytynyt. ',
            discountCodeNotActive: 'Kampanjakoodi ei ole aktiivinen.',
            discountAlreadyApplied: 'Tilaukseen on jo liitetty kampanjakoodi',
            discountCodeGiftCard: 'Kampanjakoodi ei koske lahjakortteja.',
            mainText_1:
                'Arkisin ennen klo 13.45 tehdyt tilaukset voidaan toimittaa kotiosoitteeseen samana päivänä lähes kaikkialla Suomessa. Suurimmilla paikkakunnilla saman päivän tilauksia otetaan vastaan klo 15.45 asti. Joillakin pienemmillä paikkakunnilla ja esimerkiksi saaristopaikkakunnilla tilaus tulee tehdä vuorokautta aikaisemmin kuljetuksen haasteellisuuden vuoksi. Näillä paikkakunnilla kuljetus saatetaan jakaa esim. linja-auton tai Postin kautta. Linja-autokuljetusten paikkakunnista löydät listauksen toimitusehtojen lopusta. \nLauantaisin saman päivän toimituksia otetaan vastaan klo 10.45 asti. Tähän poikkeuksena ovat paikkakunnat, joihin toimituksia on vain arkena.',
            mainText_2:
                'Emme voi taata toimituksia ulkomaille sunnuntaisin tai pyhäpäivinä. Otamme sinuun yhteyttä, mikäli toimitus ei ole mahdollinen valittuna päivänä. Kansainvälisten toimitusten tilaukset tulee tehdä arkisin toimituspäivänä viimeistään klo 12.00. Huomioi myös maiden välinen aikaero, sillä se voi vaikuttaa toimituspäivään. Joissakin maissa saattaa olla myös kohteita, joihin toimitus ei ole mahdollinen lainkaan liian pitkän välimatkan vuoksi. Näissä tapauksissa Interfloran toimisto on Sinuun yhteydessä.',
            mainText_3:
                'Toimitus yritysosoitteisiin on mahdollista arkisin virka-aikana, ellei tilauksessa mainita muuta toimitusaikaa. Tilaukset yritysosoitteisiin tulee tehdä toimitusta edeltävänä päivänä tai viimeistään toimituspäivän aamuna ennen klo 9.00.',
            headerPublicHolidayDeliveries: 'Toimitukset pyhäpäivinä',
            mainText_4:
                'Sunnuntaisin tai arkipyhinä ei ole toimituksia. Poikkeuksia ovat osa juhlapäivistä: äitienpäivä, isänpäivä, ystävänpäivä, naistenpäivä sekä joulunaluspäivät, niiden osuessa viikonlopulle – paikkakuntakohtaisin rajoituksin.',
            headerDeliveryFee: 'Toimitusmaksu',
            mainText_5: `Kotimaan tilauksen kukkatuotteen hinta sisältää palvelumaksun 5,20 euroa, joka jakautuu toimittavan kukkakaupan ja Interfloran kesken. Kotimaan toimitusmaksu on ${DELIVERY_COST_DOMESTIC_WITH_COMMA} euroa. Kukat toimitetaan tilaajan antamaan osoitteeseen joko kukkakaupan oman henkilökunnan tai lähettipalvelun toimesta. Jos valitset yritysasiakkaana maksutavaksi laskun, lisätään tilauksen loppusummaan 4 euron laskutuslisä. Yksityisasiakkaille laskutusmahdollisuutta ei ole.`,
            mainText_6: `Ulkomaan toimitusmaksu on ${DELIVERY_COST_FOREIGN_WITH_COMMA} euroa. Lisäksi kukkien hinta sisältää paikallisen toimitusmaksun, joka vaihtelee maittain. Joihinkin ulkomaan kohteisiin voi tulla alueellinen lisätoimitusmaksu mikäli paikkakunnalla ei ole Interflora -liikettä. Tässä tapauksessa Interfloran toimisto on Sinuun yhteydessä.`,
            headerDeliveryTime: 'Toimitusaika',
            mainText_8:
                'Kotiosoitteisiin toimitukset tapahtuvat klo 10-20 välillä. Toimitukset yritysosoitteisiin tapahtuvat arkisin virka-aikana. Emme voi taata tarkkoja toimitusaikoja, mutta toimittava kukkakauppa tekee parhaansa toimituksen järjestämiseksi toivomanasi ajankohtana. Voit kirjoittaa toiveesi toimitusajankohdasta “Erityistoiveet toimitukseen” -kohtaan valittuasi kukat ja mahdolliset lisätuotteet.',
            mainText_9:
                'Poikkeuksen muodostavat hautajaistilaukset, jotka toimitetaan siunausajankohdan mukaan. Hautajaistilaukset on tehtävä viimeistään kaksi arkipäivää ennen siunausta, jotta toimitus tiettynä ajankohtana ehtii ajoissa perille.',
            headerDeliveryInfo: 'Toimitustapa ja toimitustiedot',
            mainText_10:
                'Tilaaja on vastuussa, että toimitustiedot (nimi, osoite, postinumero, kaupunki, puhelinnumero), jotka syötetään tilauksen yhteydessä, ovat oikein.',
            mainText_11:
                'Tehtyäsi tilauksen verkkokaupassa ja maksun mentyä onnistuneesti läpi, saat sähköpostiisi tilausvahvistuksen ja kuitin, josta voit tarkistaa tilauksen tiedot.',
            mainText_12:
                'Valitessasi toimitustapavaihtoehdoista ”Jätetään ovelle, jos vastaanottaja ei ole paikalla” antaa tilaaja luvan jättää kukat oven eteen, mikäli vastaanottaja ei ole paikalla. Tästä ei ilmoiteta erikseen tilaajalle tai vastaanottajalle. Kun tilaaja antaa luvan jättää kukat oven eteen, on tilaaja vastuussa, jos kukat katoavat ovelta tai menevät pilalle esimerkiksi sääolosuhteiden vuoksi. Emme takaa, että kukkalähetys voidaan jättää ovelle esimerkiksi sääolosuhteiden tai ulkomaantilausten kohdalla eri maiden toimintatapojen vuoksi (esimerkiksi lämpimät maat). Ellei kukkalähetystä voida jättää ovelle, palautuvat kukat kukkakauppaan. Mikäli kukat halutaan jakaa uudelleen, tulee tilaajan maksaa uusi toimitusmaksu. Vaihtoehtoisesti vastaanottaja voi noutaa kukat liikkeestä.',
            mainText_13:
                'Jos valitset vaihtoehdon “Vastaanottaja tulee tavoittaa ennen toimitusta”, lähetys toimitetaan vasta kun vastaanottaja on tavoitettu puhelimitse/tekstiviestillä. Tällöin toimituspäivä saattaa siirtyä, mikäli vastaanottajaa ei toimituspäivänä tavoiteta puhelimitse.\n',
            mainText_14: `Tilaaja voi halutessaan kieltää vastaanottajalle soittamisen ennen kukkien toimittamista. On huomioitava, että tällaisessa tilanteessa kukkien toimitus saattaa viivästyä tai olla jopa mahdotonta olosuhteista johtuen. Ellei vastaanottaja ole paikalla ja kukkia ei voida olosuhteiden vuoksi jättää ovelle, palautuvat kukat takaisin kukkakauppaan. Tällöin toimituksen osuus on jo käytetty ja vastaanottaja voi noutaa kukat  toimittavasta kukkakaupasta. Vaihtoehtoisesti tilaaja voi maksaa uuden toimitusmaksun ${DELIVERY_COST_DOMESTIC_WITH_COMMA}€, jolloin kukkakauppa toimittaa kukat vastaanottajalle. Mikäli kukkalähetyksen toimitus ei onnistu, ilmoitamme asiasta tilaajalle.`,
            headerFloralCheques: 'Kukkalahjakortit',
            mainText_19: `Kukkalahjakortteja voi hankkia 10 euron arvosta ylöspäin. Toimituskulut ovat 3,80 euroa lahjakorttia tai toimitusosoitetta kohden. Kukkalahjakortteja voi lähettää vain Suomeen ja ne ovat voimassa Suomen Interflora -kukkakaupoissa. Kukkalahjakortit postitetaan vastaanottajalle. Lahjakortteja ei voi tilata sähköpostiin eikä niitä voi käyttää verkkokaupassa.`,
            mainText_20:
                'Lahjakorttitilaus on tehtävä arkipäivisin klo 11.00 mennessä mikäli lahjakortti halutaan postitettavan samana päivänä. Lahjakortit postitetaan tilaajan toivomana postituspäivänä, ellei postituspäiväksi ole valittu kuluvaa päivää klo 11 jälkeen, viikonloppua tai arkipyhää. Tällöin lahjakortti voidaan postittaa aikaisintaan seuraavana arkipäivänä.',
            headerAdditionalProducts: 'Lisätuotteet',
            mainText_21:
                'Kukkien lisäksi voit ostaa lähetykseesi lisätuotteita eli suklaata, nallen, tervehdyskortin, maljakon, ruukun, adressin tai nauhat hautajaiskukkiin. Kaupoilla on valikoimissaan myös adresseja sekä kaksiosaisia kuvallisia kortteja, mutta näiden valikoima vaihtelee kaupoittain. Tämän vuoksi korttien mallikuvia ei ole nähtävissä verkkokaupassa, vaan toimittava kauppa valitsee teemaan parhaiten sopivan kortin valikoimistaan. Voit esittää toiveen kortista “Erityistoiveet toimitukseen” –kohdassa (esimerkiksi eläinkortti, kukkakortti tms.).',
            mainText_22:
                'Mikäli tilaamasi lisätuotteen toimittaminen ei ole mahdollista haluamallesi paikkakunnalle esim. tilapäisen saatavuuskatkoksen vuoksi, ottaa Interfloran toimisto tai toimittava kukkakauppa Sinuun yhteyttä sähköpostitse tai puhelimitse. Tällöin tilaamasi lisätuote voidaan joko vaihtaa toiseen samanarvoiseen lisätuotteeseen, lisätuotteen hinta voidaan palauttaa Sinulle tai sen arvo voidaan lisätä kukkien osuuteen.',
            mainText_23: `Lue lisää lisätuotevalikoimastamme `,
            mainText_23_link: `täältä.`,
            headerInterfloraGuaranty: 'Laatutakuu',
            mainText_24: `Interflora takaa, että kukkien laatu on toimitettaessa hyvä, ja että kukat toimitetaan maksamasi summan mukaisesti. `,
            mainText_24_b:
                'HUOM! Hintojen vaihtelun vuoksi kukkien kappalemäärät sekakimpuissa saattavat hieman vaihdella',
            mainText_24_2:
                '. Vastaanottajalle toimitetaan ensisijaisesti tilattua kukkalajiketta. Ellei tämä ole mahdollista, toimitetaan vaihtoehdoksi ilmoitettua tai sitä mahdollisimman lähellä olevaa lajiketta. Istutusten ruukut ja korit saattavat vaihdella toimittavan liikkeen valikoiman mukaan. Jos ostamasi tuote ei ole saatavilla, on Interfloran toimisto Sinuun yhteydessä. Kukat toimitetaan toivottuna päivänä.',
            mainText_25:
                'Mikäli toimitettujen kukkien laatu ei vastaa odotuksia, toimittava kukkakauppa on velvollinen toimittamaan vastaanottajalle uudet kukat. Reklamaatio huonolaatuisista kukista on osoitettava Interfloran toimistolle tai toimittavaan kukkakauppaan viimeistään toimitusta seuraavana arkipäivänä. Muu kukkalähetyksen toimitukseen tai toimitettuihin kukkiin liittyvä huomautus on osoitettava Interfloran toimistolle kuukauden sisällä valitusta toimituspäivästä.',
            headerCustomerInfo: 'Tietojen käyttötarkoitus',
            mainText_27:
                'Kaikki tilauksen yhteydessä antamasi tiedot (nimet, osoitteet, puhelinnumerot, sähköpostiosoite, korttiteksti ja toivotut kukat) sekä sen laitteen IP-osoite, jolta tilaus on tehty, rekisteröityvät tietokantaamme. Antamiasi tietoja käytetään  vain tilauksen toimitukseen. IP-osoitetta käytetään vain mahdollisten väärinkäyttötapausten selvittämiseksi.  ',
            mainText_28:
                'Emme anna tilaustietoja ulkopuolisille, emmekä käytä sähköpostiosoitetta muuhun tarkoitukseen. Ainoastaan Interfloran työntekijöillä on pääsy tilaustiedostoihin, joita säilytämme viiden vuoden ajan.',
            mainText_29: 'Tilauksen maksuun liittyvät tiedot eivät rekisteröidy tietokantaamme.',
            headerBusDelivery: 'Linja-auto toimitukset',
            mainText_30:
                'Seuraaville paikkakunnille kukkatoimitukset hoituvat linja-autokuljetuksena, jolloin vastaanottajan on noudettava kukat Matkahuollosta tai linja-autolta. Näille paikkakunnille toimitukset onnistuvat vain arkisin, pois lukien arkipyhät. Vastaanottajan kanssa on sovittava kukkien noudosta, minkä vuoksi vastaanottajan numero tarvitaan tilaukseen.',
            postNumber_99950: '99950 KARIGASNIEMI',
            postNumber_98600: '98600 KURSU',
            postNumber_99990: '99990 NUORGAM',
            postNumber_93400: '93400 TAIVALKOSKI',
            postNumber_99980: '99980 UTSJOKI',
            postNumber_99870: '99870 INARI',
            postNumber_99940: '99940 NÄÄTÄMÖ',
            postNumber_99930: '99930 SEVETTIJÄRVI',
            postNumber_97645: '97645 JUUJÄRVI',
            postNumber_98360: '98360 VUOSTIMO',
            mailinglistTitle: 'Tilaa Interfloran uutiskirje',
            mailinglistBody:
                'Saat ajankohtaista kukka-aiheista tietoa, ideoita ja uutisia säännöllisesti suoraan sähköpostiisi, etkä enää koskaan unohda kukkia!',
        },
    },
    en: {
        page: {
            close: 'Close',
            errorOccurredWhileProcessing: 'An error occurred while processing your order',
            errorCantProcessRightNow:
                'Your payment can not be processed right now. Please check your connection and try again',
            errorFillOrderAgain: 'Please fill in your order again',
            errorCheckCard: 'We can not process the payment. Please check your card information.',
            errorCanNotProcess: 'We can not process your payment. Please try again later.',
            deliveryTermsHeader: 'Terms of Delivery',
            deliveryTermsFlowerOrder: 'Flower Orders',
            discount: 'Discount',
            discountCodeNotFound: 'Discount code not found.',
            discountCodeNotActive: 'Discount code is not active.',
            discountAlreadyApplied: 'This order already has a discount code applied.',
            discountCodeGiftCard: 'Discount code does not apply to giftcards.',
            mainText_1:
                'An order placed before 1.45 PM on weekdays can be delivered to a home address on the same day almost everywhere in Finland. To bigger cities it is possible to place an order until 15.45 PM. On Saturdays orders can be placed until 10.45 AM for same day deliveries, except to locations where delivery is possible only between Monday to Friday. To some of these locations orders must be made a day in advance because of the transportation of deliveries. In these locations delivery is possible only by bus or via the post office. You can find a list of bus delivery locations at the end of the Terms of Delivery.',
            mainText_2:
                'We cannot guarantee deliveries abroad on Sundays or public holidays. We will contact you if the delivery is not possible on the chosen day. Orders for international deliveries need to be done on the delivery day before 12 noon at the latest on weekdays. Please also note the time difference between countries, since this may affect the delivery date. In some countries, there may also be locations where delivery is not possible at all due to excessive distances. In these cases, the Interflora office will contact you.',
            mainText_3:
                'Delivery to a company address is possible on weekdays during office hours unless another delivery time is presented in the order. Orders to a company address must be placed the day before delivery or at the latest on the morning of the delivery day before 9 AM.',
            headerPublicHolidayDeliveries: 'Deliveries on Public Holidays',
            mainText_4:
                "There are no deliveries on Sundays or on public holidays, except on certain days such as Mother's Day and Father's Day or if Valentine's Day, International Women's Day or days before Christmas are on Sunday (with local limitations).",
            headerDeliveryFee: 'Delivery Fee',
            mainText_5: `The flower item includes a service fee 5,20€ which will be divided between the delivering flower shop and Interflora. The delivery fee for deliveries in Finland is ${DELIVERY_COST_DOMESTIC_WITH_COMMA}€. The flowers are delivered to the given address, provided by the sender. The delivery is done by the staff of the flower shop or by a courier service.`,
            mainText_6:
                'Note that if the selected payment method is invoice, an additional 4€ invoice fee will be added to the total price.',
            mainText_7: `The delivery fee for deliveries abroad is ${DELIVERY_COST_FOREIGN_WITH_COMMA}€. In addition, the price of flowers includes a local delivery charge, which varies by country. To some international destinations an extra delivery fee can be added if there is no Interflora -shop in the region. In this case we will contact You.`,
            headerDeliveryTime: 'Time of Delivery',
            mainText_8:
                'We cannot guarantee the delivery at a precise time, but the flower shop will do their best to arrange the delivery at the desired time. If you have a wish about the delivery time, you can write it in the section Special wishes for the delivery. Deliveries to home addresses usually take place between 10 AM and 8 PM. Orders to company addresses are delivered within office hours.',
            mainText_9:
                'Funeral orders are exceptions; they will be delivered according to the time of the funeral. Funeral orders must be placed two days before the funeral service at the latest.',
            headerDeliveryInfo: 'Delivery Options and Recipient Details',
            mainText_10:
                'The sender is responsible for the correctness of the information (name, address, zip code, city, phone number) that they enter when an order is placed.',
            mainText_11:
                'When you have made your order in the web shop and your payment has been processed successfully, you will receive an order confirmation to your email address. From the order confirmation you can check that all the details are correct.',
            mainText_12:
                'Once choosing the delivery option ‘Leave at door if recipient is not at home’, the sender gives their permission to leave the flowers at the door if the recipient is not at home. We do not inform the sender nor the recipient separately about the situation. If you give permission to leave flowers at the door, you are responsible if the flowers disappear or get ruined. We cannot guarantee that the flowers can be left at the door, because of weather conditions or practices in different countries (for example warm countries).',
            mainText_13:
                'If you choose the option ‘The recipient must be reached before the delivery’, your order won’t be delivered before the shop has reached the recipient by phone or text message.',
            mainText_14: `You may forbid the delivering shop to call the recipient. In this case delivery might be delayed or cancelled. If the recipient is not able to receive the flowers and there is no possibility to leave the flowers on the door, the delivery is returned to the flower shop and the delivery fee is used. The recipient can then pick up the flowers at the delivering flower shop or the sender can pay another delivery fee of ${DELIVERY_COST_DOMESTIC_WITH_COMMA}€. In this case the flower shop will arrange a new delivery. If the delivery is not possible, Interflora will contact you.`,
            mainText_15:
                'If you choose the option “The recipient must be reached before the delivery”, your order won’t be delivered before the shop has reached the recipient by phone and scheduled the time of delivery.',
            headerFloralCheques: 'Floral Cheques',
            mainText_19:
                'The delivery charge is 3,80€ per delivery address. Floral cheques can only be delivered within Finland and can be used in Interflora flower shops in Finland. Flora cheques are mailed to the recipient. For shipment on the same day, we must receive your order before 11 AM on weekdays. Cheques will be mailed on the requested date unless the order is placed for the same day after 11 AM, on weekends or midweek holidays. In these cases, the cheque will be mailed at the earliest on the next weekday.',
            mainText_20: '',
            headerAdditionalProducts: 'Additional Products',
            mainText_21:
                "In addition to flowers, you can order products such as chocolate, a teddy bear, a vase, a pot, a greeting card, card of condolence or a ribbon (for funeral orders). Every flower shop has cards of condolences and folded greeting cards, but the selection varies depending on the delivering shop. That's why there are no model pictures of the cards in the web shop. The flower shop chooses the most suitable card for the occasion.",
            mainText_22:
                "If the chosen additional product is not available at the given location, Interflora's office or the delivering shop will contact You via phone or email. The chosen additional product can be changed to another additional product with the same value, the value of the product can be refunded or the value can be included in the flower amount.",
            headerInterfloraGuaranty: 'Interflora Guarantee',
            mainText_24:
                'Interflora guarantees the freshness and quality of every Interflora order at the time of delivery, and that the order is filled to full value. ',
            mainText_24_b: 'NOTE: Due to price fluctuations, the number of flowers in mixed bouquets may vary slightly',
            mainText_24_2:
                '. Primarily the chosen flowers will be delivered to the recipient. If the ordered flowers are not available, the alternative choice or a similar substitute will be delivered. If this is not possible, we will contact You. Plant pots and baskets may vary depending on the delivering flower shop. The delivery will take place on the requested date.',
            mainText_25:
                "If the quality of the flowers does not meet your expectations, the flower shop is obligated to deliver new flowers to the recipient. Complaints of poor quality must be made immediately, no later than the next weekday, to Interflora's office (0102176320, info@interflora.fi) or to the Interflora member who delivered the flowers. Other complaints or notifications about the delivery or delivered flowers must be sent to Interflora’s office within 30 days from the selected delivery date.",
            headerCustomerInfo: 'Usage of Customer Information',
            mainText_27:
                'All order information (names, addresses, telephone numbers, e-mail address, card message, chosen item) and IP-address of the used device will be saved in our database. The given information is only used for delivering your flower order. The IP-address is only used to solve a possible situation of malpractice.',
            mainText_28:
                "We do not pass on order information to third parties, except to complete the delivery. Your e-mail address will not be used for any other purpose either. Only Interflora's staff has access to order data, which we store for five years.",
            mainText_29: 'Information related to the payment of your order will not be saved in our database.',
            headerBusDelivery: 'Bus Deliveries',
            mainText_30:
                "Deliveries to the following locations are done by bus. In these cases the recipient picks up the flowers at the bus or at Matkahuolto-terminal. Deliveries to these locations are done only on weekdays, excluding public holidays. The recipient's phone number is mandatory to complete these deliveries, as the flower shop must call the recipient in advance to agree the delivery with them.",
            postNumber_99950: '99950 KARIGASNIEMI',
            postNumber_98600: '98600 KURSU',
            postNumber_99990: '99990 NUORGAM',
            postNumber_93400: '93400 TAIVALKOSKI',
            postNumber_99980: '99980 UTSJOKI',
            postNumber_99870: '99870 INARI',
            postNumber_99940: '99940 NÄÄTÄMÖ',
            postNumber_99930: '99930 SEVETTIJÄRVI',
            postNumber_97645: '97645 JUUJÄRVI',
            postNumber_98360: '98360 VUOSTIMO',
            mailinglistTitle: 'Subscribe to our newsletter',
            mailinglistBody:
                'Get up-to-date floral information, ideas, and news so you will never forget to send flowers!',
        },
    },
}

Modal.setAppElement('#root')

class ModalWindow extends Component {
    render() {
        const { show, label, headline, content, modalclass, modaltype, hideModal } = this.props

        const selectedTrans = R.path([getLanguage()], pageTranslation)
        const trans = R.path(['page'], selectedTrans)

        return (
            <div>
                <Motion
                    style={{
                        y: spring(show ? -50 : -90, { stiffness: 90, damping: 15 }),
                        o: spring(show ? 1 : 0, { stiffness: 90, damping: 15 }),
                    }}
                >
                    {({ y, o }) => (
                        <Modal
                            isOpen={show}
                            style={{
                                content: {
                                    WebkitTransform: 'translate(-50%,${y}%)',
                                    transform: 'translate(-50%,${y}%)',
                                    opacity: '${o}',
                                },
                            }}
                            className={modalclass ? modalclass : ''}
                            overlayClassName="modal__wrapper"
                            contentLabel={label ? label : ''}
                            onRequestClose={hideModal}
                            closeTimeoutMS={300}
                        >
                            {modaltype === 'discountCodeSuccess' && (
                                <div>
                                    <h3>{headline}</h3>
                                    <p>
                                        {R.path(['discount'], trans)}: {content} €
                                    </p>
                                </div>
                            )}
                            {modaltype === 'discountCodeNotFound' && (
                                <div>
                                    <h3>{headline}</h3>
                                    <p>{R.path(['discountCodeNotFound'], trans)}</p>
                                </div>
                            )}
                            {modaltype === 'discountCodeNotActive' && (
                                <div>
                                    <h3>{headline}</h3>
                                    <p>{R.path(['discountCodeNotActive'], trans)}</p>
                                </div>
                            )}
                            {modaltype === 'discountAlreadyApplied' && (
                                <div>
                                    <h3>{headline}</h3>
                                    <p>{R.path(['discountAlreadyApplied'], trans)}</p>
                                </div>
                            )}
                            {modaltype === 'discountCodeGiftCard' && (
                                <div>
                                    <h3>{headline}</h3>
                                    <p>{R.path(['discountCodeGiftCard'], trans)}</p>
                                </div>
                            )}
                            {modaltype === 'addon' && (
                                <div>
                                    <h3>{headline}</h3>
                                    <span className="addon__description">{content}</span>
                                </div>
                            )}
                            {modaltype === 'noDeliveryTermsAccepted' && (
                                <div>
                                    <h3>Tilausta ei voitu lähettää</h3>
                                    <p>Sinun täytyy hyväksyä toimitusehdot.</p>
                                </div>
                            )}
                            {modaltype === 'noBackendConnection' && (
                                <div>
                                    <h3>{R.path(['errorOccurredWhileProcessing'], trans)}</h3>
                                    <p>{R.path(['errorCantProcessRightNow'], trans)}</p>
                                </div>
                            )}
                            {modaltype === 'invalidProduct' && (
                                <div>
                                    <h3>{R.path(['errorOccurredWhileProcessing'], trans)}</h3>
                                    <p>{R.path(['errorFillOrderAgain'], trans)}</p>
                                </div>
                            )}
                            {modaltype === 'deliveryTerms' && (
                                <div>
                                    <h3>{R.path(['deliveryTermsHeader'], trans)}</h3>
                                    <h4>{R.path(['deliveryTermsFlowerOrder'], trans)}</h4>
                                    <p>
                                        {R.path(['mainText_1'], trans)}
                                        <br />
                                        <br />
                                        {R.path(['mainText_2'], trans)}
                                        <br />
                                        <br />
                                        {R.path(['mainText_3'], trans)}
                                        <br />
                                        <br />
                                    </p>
                                    <h3>{R.path(['headerPublicHolidayDeliveries'], trans)}</h3>
                                    <p className="contentPage__paragraph">
                                        {R.path(['mainText_4'], trans)}
                                        <br />
                                        <br />
                                    </p>
                                    <h3>{R.path(['headerDeliveryFee'], trans)}</h3>
                                    <p className="contentPage__paragraph">
                                        {R.path(['mainText_5'], trans)}
                                        <br />
                                        <br />
                                        {R.path(['mainText_6'], trans)}
                                        <br />
                                        <br />
                                        {R.path(['mainText_7'], trans)}
                                        <br />
                                        <br />
                                    </p>
                                    <h3>{R.path(['headerDeliveryTime'], trans)}</h3>
                                    <p className="contentPage__paragraph">
                                        {R.path(['mainText_8'], trans)}
                                        <br />
                                        <br />
                                        {R.path(['mainText_9'], trans)}
                                        <br />
                                        <br />
                                    </p>
                                    <h3>{R.path(['headerDeliveryInfo'], trans)}</h3>
                                    <p className="contentPage__paragraph">
                                        {R.path(['mainText_10'], trans)}
                                        <br />
                                        <br />
                                        {R.path(['mainText_11'], trans)}
                                        <br />
                                        <br />
                                        {R.path(['mainText_12'], trans)}
                                        <br />
                                        <br />
                                        {R.path(['mainText_13'], trans)}
                                        <br />
                                        <br />
                                        {R.path(['mainText_14'], trans)}
                                        <br />
                                        <br />
                                    </p>
                                    <h3>{R.path(['headerFloralCheques'], trans)}</h3>
                                    <p className="contentPage__paragraph">
                                        {R.path(['mainText_19'], trans)}
                                        <br />
                                        <br />
                                        {R.path(['mainText_20'], trans)}
                                        <br />
                                        <br />
                                    </p>
                                    <h3>{R.path(['headerAdditionalProducts'], trans)}</h3>
                                    <p className="contentPage__paragraph">
                                        {R.path(['mainText_21'], trans)}
                                        <br />
                                        <br />
                                        {R.path(['mainText_22'], trans)}
                                        <br />
                                        <br />
                                    </p>
                                    <h3>{R.path(['headerInterfloraGuaranty'], trans)}</h3>
                                    <p className="contentPage__paragraph">
                                        {R.path(['mainText_24'], trans)}
                                        <b>{R.path(['mainText_24_b'], trans)}</b>
                                        {R.path(['mainText_24_2'], trans)}
                                        <br />
                                        <br />
                                        {R.path(['mainText_25'], trans)}
                                        <br />
                                        <br />
                                    </p>
                                    <h3>{R.path(['headerCustomerInfo'], trans)}</h3>
                                    <p className="contentPage__paragraph">
                                        {R.path(['mainText_27'], trans)}
                                        <br />
                                        <br />
                                        {R.path(['mainText_28'], trans)}
                                        <br />
                                        <br />
                                        {R.path(['mainText_29'], trans)}
                                        <br />
                                        <br />
                                    </p>
                                    <h3>{R.path(['headerBusDelivery'], trans)}</h3>
                                    <p className="contentPage__paragraph">
                                        {R.path(['mainText_30'], trans)}
                                        <br />
                                        <br />
                                        <ul>
                                            <li>{R.path(['postNumber_99950'], trans)}</li>
                                            <br />
                                            <li>{R.path(['postNumber_98600'], trans)}</li>
                                            <br />
                                            <li>{R.path(['postNumber_99990'], trans)}</li>
                                            <br />
                                            <li>{R.path(['postNumber_93400'], trans)}</li>
                                            <br />
                                            <li>{R.path(['postNumber_99980'], trans)}</li>
                                            <br />
                                            <li>{R.path(['postNumber_99870'], trans)}</li>
                                            <br />
                                            <li>{R.path(['postNumber_99940'], trans)}</li>
                                            <br />
                                            <li>{R.path(['postNumber_99930'], trans)}</li>
                                            <br />
                                            <li>{R.path(['postNumber_97645'], trans)}</li>
                                            <br />
                                            <li>{R.path(['postNumber_98360'], trans)}</li>
                                            <br />
                                        </ul>
                                    </p>
                                </div>
                            )}
                            {modaltype === 'deliveryError' && (
                                <div>
                                    <h3>{headline}</h3>
                                    <ul dangerouslySetInnerHTML={{ __html: content }} />
                                </div>
                            )}
                            {modaltype === 'cardTextError' && (
                                <div>
                                    <h3>{headline}</h3>
                                    <ul dangerouslySetInnerHTML={{ __html: content }} />
                                </div>
                            )}
                            {modaltype === 'account' && (
                                <div>
                                    <h3>Interflora-tili</h3>
                                    <p>
                                        Luo oma Interflora-asiakastili tilauksen yhteydessä. Rekisteröityneenä
                                        asiakkaana saat etuja ja ajankohtaista tietoa Interfloran palveluista. Voit myös
                                        tarkastella tilaushistoriaasi ja tallentaa ystäviesi yhteystiedot osoitekirjaan.
                                    </p>
                                </div>
                            )}
                            {modaltype === 'mailinglistTerms' && (
                                <div>
                                    <h3>{R.path(['mailinglistTitle'], trans)}</h3>
                                    <p>{R.path(['mailinglistBody'], trans)}</p>
                                </div>
                            )}

                            <br />
                            <br />
                            <button
                                className="pure-button pure-button-primary product__sidebar__orderbutton"
                                onClick={hideModal}
                            >
                                {R.path(['close'], trans)}
                            </button>
                        </Modal>
                    )}
                </Motion>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        show: R.path(['modal', 'show'], state),
        label: R.path(['modal', 'label'], state),
        modalclass: R.path(['modal', 'modalclass'], state),
        modaltype: R.path(['modal', 'modaltype'], state),
        headline: R.path(['modal', 'headline'], state),
        content: R.path(['modal', 'content'], state),
    }
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        hideModal: () => dispatch(hideModal()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalWindow)
