import * as R from 'ramda'
import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import LandingContainer from './containers/LandingContainer'
import CategoryContainer from './containers/CategoryContainer'
import ProductsContainer from './containers/ProductsContainer'
import OrderContainer from './containers/OrderContainer'

import {
    categoryRoute,
    forgotPasswordRoute,
    landingRoute,
    loginRoute,
    orderRoute,
    productRoute,
    resetPasswordRoute,
} from './_routes'

import Login from './components/login/Login'
import ForgotPassword from './components/login/ForgotPassword'
import ResetPassword from './components/login/ResetPassword'

const parseProps = (route, component, url = '') =>
    R.mergeRight(route, { path: R.concat(`${url}`, R.pathOr('', ['path'], route)), component })

export default (props) => {
    const url = R.path(['match', 'url'], props)
    const lang = R.split('/', url)[1]
    const cc = R.or(R.split('/', url)[2], 'fi')
    return (
        <Switch>
            <Route {...parseProps(categoryRoute, CategoryContainer, url)} />
            <Route {...parseProps(productRoute, ProductsContainer, url)} />
            <Route {...parseProps(orderRoute, OrderContainer, url)} />
            <Route {...parseProps(loginRoute, Login, url)} />
            <Route {...parseProps(forgotPasswordRoute, ForgotPassword, url)} />
            <Route {...parseProps(resetPasswordRoute, ResetPassword, url)} />
            <Route {...parseProps(landingRoute, LandingContainer, url)} />
            <Redirect path="*" to={`/${lang}/${cc}`} />
        </Switch>
    )
}
