import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { createBrowserHistory } from 'history'
import Cookies from 'js-cookie'
import { ConnectedRouter } from 'connected-react-router'
import configureStore from './configureStore'
import './style.scss'
import App from './App.jsx'

try {
    window.process = JSON.parse(window.process)
} catch {
    window.process = { env: { NODE_ENV: window.NODE_ENV || '' } }
}

const history = createBrowserHistory({ basename: '/' })

const store = configureStore(window.__PRELOADED_STATE__, history, Cookies)

window.store = store

const Root = () => (
    <Provider store={window.store}>
        <ConnectedRouter history={history}>
            <App />
        </ConnectedRouter>
    </Provider>
)

ReactDOM.render(<Root />, document.getElementById('root'))
