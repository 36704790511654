export const INITIATE_SEARCH = 'INITIATE_SEARCH'

export const initiateSearch = (
    categoryOccasion,
    categoryColor,
    categoryContains,
    categoryType,
    minPrice,
    categoryPriceSort,
    selectedCountryCode,
) => ({
    type: INITIATE_SEARCH,
    categoryOccasion: categoryOccasion,
    categoryColor: categoryColor,
    categoryContains: categoryContains,
    categoryType: categoryType,
    minPrice: minPrice,
    categoryPriceSort: categoryPriceSort,
    selectedCountryCode: selectedCountryCode,
})
