import { setLoadingIndicatorVisibility } from './loadingIndicatorActions'
import { postInvoicePurchase, postInvoiceGiftcardPurchase } from '../api/invoice'

export const INVOICE_PURCHASE = 'INVOICE_PURCHASE'
export const SET_INVOICE_REDIRECT = 'SET_INVOICE_REDIRECT'

export const invoicePurchase = (body, token) => async (dispatch) => {
    try {
        const response = await postInvoicePurchase(body, token)
        console.log(response)
        if (response.status === 200) {
            dispatch(setInvoiceRedirect(true))
            dispatch(setLoadingIndicatorVisibility(false))
            return response
        } else {
            dispatch({
                type: INVOICE_PURCHASE + '_FAILED',
                error: response.error,
            })
            return response
        }
    } catch (err) {
        dispatch({
            type: INVOICE_PURCHASE + '_FAILED',
            error: err,
        })
    }
}

export const invoiceGiftcardPurchase = (body, token) => async (dispatch) => {
    try {
        const response = await postInvoiceGiftcardPurchase(body, token)
        console.log(response)
        if (response.status === 200) {
            dispatch(setInvoiceRedirect(true))
            dispatch(setLoadingIndicatorVisibility(false))
            return response
        } else {
            dispatch({
                type: INVOICE_PURCHASE + '_FAILED',
                error: response.error,
            })
            return response
        }
    } catch (err) {
        dispatch({
            type: INVOICE_PURCHASE + '_FAILED',
            error: err,
        })
    }
}

export const setInvoiceRedirect =
    (value = false) =>
    (dispatch) =>
        dispatch({
            type: SET_INVOICE_REDIRECT,
            invoice_redirect: value,
        })
