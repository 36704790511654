import * as R from 'ramda'
import { fetchZipcode } from '../api/backend'

export const REQUEST_ZIPCODE = 'REQUEST_ZIPCODE'
export const CLEAR_ZIPCODE = 'CLEAR_ZIPCODE'
export const CLEAR_ZIPCODE_ERROR = 'CLEAR_ZIPCODE_ERROR'

export const SET_ZIPCODE = 'SET_ZIPCODE'
export const SET_ZIPCODE_CALENDAR = 'SET_ZIPCODE_CALENDAR'
export const SET_ZIPCODE_CUTOFF = 'SET_ZIPCODE_CUTOFF'

export const getZipcode =
    (zipcode = '') =>
    (dispatch) =>
        fetchZipcode(zipcode)
            .then((response) => {
                dispatch({
                    type: REQUEST_ZIPCODE + '_SUCCESS',
                    zipcode: zipcode,
                    calendar: R.path(['zipcode', zipcode], response),
                    cutoff: R.path(['sameday', 'cutoff'], response),
                })
            })
            .catch((err) => {
                console.log(R.path(['statusText'], err))
                dispatch({
                    type: REQUEST_ZIPCODE + '_FAILED',
                    zipcode: zipcode,
                    error: R.path(['statusText'], err),
                })
            })

export const clearZipcode = () => (dispatch) => dispatch({ type: CLEAR_ZIPCODE })

export const clearZipcodeError = () => (dispatch) => dispatch({ type: CLEAR_ZIPCODE_ERROR })
