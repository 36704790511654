import * as R from 'ramda'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Routes from './Routes'
import Helmet from 'react-helmet'
import classNames from 'classnames'

import moment from 'moment'
import 'moment/locale/fi'
import 'moment/locale/en-gb'

import { parse } from 'query-string'
import { trackPurchase } from './tracking/tracking'

import { fetchPaymentMethods } from './actions/payformActions'
import { getUserInfo } from './actions/loginActions'
import { getAllCountries, getAllExtras, getAllOccasions, getAllProducts } from './actions/productsActions'
import { getLandingContent } from './actions/landingContentActions'
import { getAllDeliveryCards } from './actions/deliveryCardActions'
import { createNewCart, getCart } from './actions/cartActions'
import { getLanguage, getLocale } from './utils/translation'
import config from './config'

const canUseDOM = !!(typeof window !== 'undefined' && window.document)
const assets = R.path(['ASSETS'], config)

const isEmpty = (value) => R.or(R.isEmpty(value), R.isNil(value))

const pageTranslation = {
    fi: {
        page: {
            cart_error: 'Sivuston lataus epäonnistui verkkovirheen takia, kokeile hetken kuluttua uudelleen.',
        },
    },
    en: {
        page: {
            cart_error: 'Connection interrupted: site loading failed, please try again momentarily.',
        },
    },
}

class App extends Component {
    static drift = canUseDOM && window && window.drift ? drift : undefined

    constructor(props, context) {
        super(props, context)
        const { getUserInfo } = this.props
        canUseDOM && getUserInfo()
    }

    componentDidMount() {
        this.setMomentLocale()
        this.checkRedux()
        this.driftPage(this.props.location.pathname)
    }

    componentDidUpdate(prevProps) {
        const { cc, getProducts, cart_creation_error } = this.props

        // Update product listing when changing the selected country
        R.path(['cc'], prevProps) !== cc && getProducts(cc)

        const selectedTrans = R.path([getLanguage()], pageTranslation)
        const trans = R.path(['page'], selectedTrans)

        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.driftPage(this.props.location.pathname)
        }

        // Alert if cart creation has failed
        if (canUseDOM && cart_creation_error) {
            alert(R.path(['cart_error'], trans))
        }
    }

    driftPage(pathname) {
        if (App.drift) {
            App.drift.page(pathname)
        }
    }

    setMomentLocale() {
        const { lang } = this.props
        const locale = getLocale(lang)
        moment.locale(locale)
    }

    setTracking() {
        const { storeState, location } = this.props
        const query = parse(R.path(['search'], location))
        const payformRedirect = R.path(['redirect'], query)

        // Only for payform purchases. For invoice purchases
        // tracking is set in FormThankYou.jsx
        if (payformRedirect === 'true') {
            const products = R.path(['cart', 'items', 'products'], storeState)
            if (products.length) trackPurchase(storeState)
        }
    }

    checkRedux() {
        const {
            products,
            extras,
            deliveryCards,
            countries,
            occasions,
            paymentMethods,
            lang,
            cc,
            location,
            getProducts,
            getExtras,
            getCountries,
            getDeliveryCards,
            getOccasions,
            fetchPaymentMethods,
            createNewCart,
            getCart,
            cartid,
            landingContent,
            getLandingContent,
        } = this.props

        // onMount if SSR has failed, get the required data here
        isEmpty(products) && getProducts(cc)
        isEmpty(extras) && getExtras()
        isEmpty(deliveryCards) && getDeliveryCards()
        isEmpty(countries) && getCountries()
        isEmpty(occasions) && getOccasions()
        isEmpty(paymentMethods) && fetchPaymentMethods()
        isEmpty(landingContent) && getLandingContent()

        // Set tracking for confirmed payform orders before creating a new cart
        const path = R.path(['pathname'], location)
        const confirmedOrderPath = `/${lang}/${cc}/order/thank-you`
        if (path === confirmedOrderPath) this.setTracking()

        !cartid ? createNewCart() : getCart()
    }

    render() {
        const { uri, location, lang, cart_creating, cart_creation_error } = this.props
        const url = R.path(['location', 'href'], uri)
        const locale = getLocale(lang)

        const appClass = classNames('App', { loading: cart_creating })

        // Do not render contents if shopping cart not available
        const pageRoutes = !cart_creation_error ? <Routes location={location} /> : null

        return (
            <div className={appClass}>
                <Helmet
                    titleTemplate="Interflora | %s"
                    meta={[
                        {
                            name: 'keywords',
                            content:
                                'Interflora, kukkakaupan huippuammattilaisia, kukkasitojia, floristeja, floristihortonomeja ja floristimestareita, kukkatervehdys, hautajaiskukat, hautavihot, kukat hautajaisiin, kukat juhliin, verkkokukka, kukka + yllätys',
                        },
                        {
                            name: 'description',
                            content:
                                'Interflora - kukkalähetykset ja kukkalahjakortit Suomeen ja ulkomaille - blomsterförmedlingar och blomstergåvokort till Finland och utlandet – Flower deliveries and Floral Cheques to Finland and other countries',
                        },
                        {
                            property: 'og:description',
                            name: 'description',
                            content: 'Interflora - kukkalähetykset ja kukkalahjakortit Suomeen ja ulkomaille',
                        },
                        {
                            property: 'og:title',
                            id: 'title',
                            content: 'Kukkia kaikkiin tilanteisiin - Nopeat toimitukset ympäri maailmaa',
                        },
                        { property: 'og:image', content: `${assets}/interflora-logo.png` },
                        { property: 'og:url', content: url },
                        { property: 'og:type', content: 'website' },
                        { property: 'og:locale', content: locale },
                        { property: 'og:site_name', content: 'Interflora' },
                    ]}
                    script={[
                        {
                            type: 'application/ld+json',
                            innerHTML: `{
            "@context": "http://schema.org",
            "@type": "Organization",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Helsinki, Finland",
              "postalCode": "00440",
              "streetAddress": "Tinatie 2 D"
            },
            "name": "Interflora-Myynti Oy",
            "legalName": "Interflora-Myynti Oy",
            "telephone": "010 2176320",
            "url": "https://shop.interflora.fi",
            "contactPoint" : [
            { "@type" : "ContactPoint",
              "telephone" : "+358-10-217-6230",
              "contactType" : "customer service"
            } ]
             }`,
                        },
                    ]}
                />
                {pageRoutes}
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    storeState: state,
    uri: R.path(['uri'], state),
    token: R.path(['login', 'token'], state),
    products: R.path(['products', 'allProducts'], state),
    extras: R.path(['products', 'allExtras'], state),
    cards: R.path(['cards', 'allDeliveryCards'], state),
    countries: R.path(['products', 'allCountries'], state),
    occasions: R.path(['products', 'allOccasions'], state),
    paymentMethods: R.path(['payform', 'payment_methods'], state),
    lang: R.path(['lang', 'lang'], state),
    cc: R.path(['lang', 'db'], state),
    cartid: R.path(['cart', 'items', 'cart_id'], state),
    cart_creating: R.path(['cart', 'creating'], state),
    cart_creation_error: R.path(['cart', 'creation_error'], state),
    landingContent: R.path(['landingContent', 'content'], state),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    fetchPaymentMethods: () => dispatch(fetchPaymentMethods()),
    getUserInfo: () => dispatch(getUserInfo()),
    getProducts: (cc) => dispatch(getAllProducts(cc)),
    getOccasions: () => dispatch(getAllOccasions()),
    getCountries: () => dispatch(getAllCountries()),
    getExtras: () => dispatch(getAllExtras()),
    getDeliveryCards: () => dispatch(getAllDeliveryCards()),
    createNewCart: () => dispatch(createNewCart()),
    getCart: () => dispatch(getCart()),
    getLandingContent: () => dispatch(getLandingContent()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
