import React, { Component } from 'react'
import * as R from 'ramda'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import OverviewItem from './OverviewItem'
import TotalItem from './TotalItem'
import ShippingPrice from './ShippingPrice'

import { removeAllCartAdditionalProducts } from '../../actions/cartActions'
import { uniqueProductsInCart, productCountsInCart } from '../../utils/CartProducts'
import { getLanguage } from '../../utils/translation'

const pageTranslation = {
    fi: {
        page: {
            description: 'Valitsemasi mahdolliset lisätuotteet näet ennen maksua',
            send: 'Jatka tilausta',
            back: 'Takaisin',
        },
    },
    en: {
        page: {
            description: 'You will see your selected optional add ons before the payment step',
            send: 'Continue',
            back: 'Back',
        },
    },
}

class OverviewContainer extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const { items, lang, cc } = this.props

        const selectedTrans = R.path([getLanguage()], pageTranslation)
        const trans = R.path(['page'], selectedTrans)

        const products = R.pathOr([], ['products'], items)
        const uniqueProducts = uniqueProductsInCart(products)
        const productAmounts = productCountsInCart(products)

        const mapIndexed = R.addIndex(R.map)

        return (
            <div>
                <div className="overview__itemContainer">
                    {mapIndexed((product, index) => (
                        <OverviewItem
                            key={index}
                            index={index}
                            cart={items}
                            product={product}
                            productAmount={R.prop(R.path(['id'], product), productAmounts)}
                            giftcardAmount={R.path(['giftcard_amount'], items)}
                            giftcardPrice={R.path(['giftcard_price'], items)}
                            lang={lang}
                            cc={cc}
                        />
                    ))(uniqueProducts)}
                    <ShippingPrice />
                </div>

                <TotalItem />

                <div className="pure-g flex-nowrap">
                    <div className="pure-u-1 pure-u-sm-1-24 pure-hidden-smallphone"></div>
                    <div className="pure-u-1 flex-center">
                        <div className="overview__itemWrapper__button">
                            <div className="button__additionalText__container">
                                <div className="textContainer">
                                    <p className="additionalText">{R.path(['description'], trans)}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pure-u-1 pure-u-sm-1-24 pure-hidden-smallphone"></div>
                </div>
            </div>
        )
    }
}

const mapStatetoProps = (state, ownProps) => ({
    items: R.pathOr([], ['cart', 'items'], state),
    lang: R.path(['lang', 'lang'], state),
    cc: R.path(['lang', 'db'], state),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    removeAllCartAdditionalProducts: () => dispatch(removeAllCartAdditionalProducts()),
})

export default withRouter(connect(mapStatetoProps, mapDispatchToProps)(OverviewContainer))
