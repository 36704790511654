import React from 'react'
import { Link } from 'react-router-dom'
import Button from 'react-validation/build/button'
import { getLanguage } from '../../utils/translation'
import classNames from 'classnames'
import LoadingIndicator from '../productorder/LoadingIndicator'
import * as R from 'ramda'

const pageTranslation = {
    fi: {
        page: {
            send: 'Jatka tilausta',
            back: 'Takaisin',
        },
    },
    en: {
        page: {
            send: 'Continue order',
            back: 'Back',
        },
    },
}

const BottomNavigationBar = (props) => {
    const {
        backLink,
        continueLink = '#', // Disabled by default; in some cases the redirecting is handled (by handleContinue) only after a successful API call
        handleContinue = undefined,
        loading = false,
        hasForm = true,
        fullScreen = false,
    } = props

    const selectedTrans = R.path([getLanguage()], pageTranslation)
    const trans = R.path(['page'], selectedTrans)

    const bottomNavigationBarStyle = classNames('bottomNavigation__bar', {
        bottomNavigation__bar__half_screen: !fullScreen,
    })

    return (
        <div className={bottomNavigationBarStyle}>
            <Link to={backLink} className="bottomNavigation__link">
                <button type="button" className="bottomNavigation__back__button">
                    <p className="bottomNavigation__arrow__left">{'<'}</p>
                    <p className="bottomNavigation__back__text">{R.path(['back'], trans)}</p>
                </button>
            </Link>

            <Link to={continueLink} className="bottomNavigation__link" onClick={handleContinue}>
                {hasForm && !loading && (
                    <Button className="bottomNavigation__continue__button" id="BottomNavigationBar-continue-button">
                        <p className="bottomNavigation__continue__text">{R.path(['send'], trans)}</p>
                        <p className="bottomNavigation__arrow__right">{'>'}</p>
                    </Button>
                )}
                {!hasForm && !loading && (
                    <button className="bottomNavigation__continue__button" id="BottomNavigationBar-continue-button">
                        <p className="bottomNavigation__continue__text">{R.path(['send'], trans)}</p>
                        <p className="bottomNavigation__arrow__right">{'>'}</p>
                    </button>
                )}
                {loading && <LoadingIndicator overwrite={loading} />}
            </Link>
        </div>
    )
}

export default BottomNavigationBar
