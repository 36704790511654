import * as R from 'ramda'
import React, { Component } from 'react'
import RecommendationsCard from './RecommendationsCard'
import { connect } from 'react-redux'

import interSection from 'lodash/intersection'
import forEach from 'lodash/forEach'
import flow from 'lodash/flow'
import find from 'lodash/find'

import { getLanguage } from '../../utils/translation'

const pageTranslation = {
    fi: {
        page: {
            recommendation: 'Voisit olla kiinnostunut myös näistä',
        },
    },
    en: {
        page: {
            recommendation: 'You might also be interested in these',
        },
    },
}

const getVisibleProducts = (products, id, countryCode) => {
    if (!products || R.length(products) === 0) return

    const prod = find(products, { id: Number(id) })
    const occasion = R.path(['occasion'], prod)

    function idFilter(products) {
        return products.filter((product) => Number(R.path(['id'], product)) !== Number(id))
    }

    function occasionFilter(products) {
        const filteredProducts = []

        forEach(products, function (product) {
            if (interSection(R.path(['occasion'], product), occasion).length) {
                filteredProducts.push(product)
            }
        })

        return filteredProducts
    }

    // If product is international, show any other products as recommendations
    if (R.toUpper(countryCode) !== 'FI') {
        return idFilter(products)
    } else {
        const composeFunctions = flow([occasionFilter, idFilter])
        return composeFunctions(products, products)
    }
}

class Recommendations extends Component {
    render() {
        const { lang, cc, products, lastViewedProduct } = this.props

        const selectedTrans = R.path([getLanguage()], pageTranslation)
        const trans = R.path(['page'], selectedTrans)
        const displayProducts = R.or(getVisibleProducts(products, lastViewedProduct, R.toUpper(cc)), [])

        return (
            <div className="pure-g">
                <div className="pure-u-1 pure-u-sm-1-24 pure-hidden-smallphone" />
                <div className="pure-u-1 pure-u-sm-22-24">
                    <div className="recommendations__wrapper">
                        <h3 className="recommendations__headline alignCenter">{R.path(['recommendation'], trans)}</h3>

                        <div className="pure-g">
                            <div className="pure-u-1 pure-u-md-14-24">
                                {displayProducts[0] && (
                                    <RecommendationsCard product={displayProducts[0]} index={0} lang={lang} cc={cc} />
                                )}
                                {displayProducts[1] && (
                                    <RecommendationsCard product={displayProducts[1]} index={1} lang={lang} cc={cc} />
                                )}
                            </div>
                            <div className="pure-u-1 pure-u-md-10-24">
                                {displayProducts[2] && (
                                    <RecommendationsCard product={displayProducts[2]} index={2} lang={lang} cc={cc} />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pure-u-1 pure-u-sm-1-24 pure-hidden-smallphone" />
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    products: R.path(['products', 'allProducts'], state),
    lastViewedProduct: R.path(['order', 'lastViewedProductId'], state),
    lang: R.path(['lang', 'lang'], state),
    cc: R.path(['lang', 'db'], state),
})

export default connect(mapStateToProps, undefined)(Recommendations)
