export const SHOW_CART_MODAL = 'SHOW_CART_MODAL'
export const HIDE_CART_MODAL = 'HIDE_CART_MODAL'

export const showCartModal = () => ({
    type: SHOW_CART_MODAL,
    show: true,
})

export const hideCartModal = () => ({
    type: HIDE_CART_MODAL,
    show: false,
})
