import * as R from 'ramda'
import { INITIATE_SEARCH } from '../actions/categoryActions'

const initialState = {
    categoryOccasion: '',
    categoryColor: '',
    categoryContains: '',
    categoryType: '',
    minPrice: '',
    categoryPriceSort: '',
    selectedCountryCode: '',
}

export default function categoryReducer(state = initialState, action) {
    switch (action.type) {
        case INITIATE_SEARCH:
            return {
                ...state,
                categoryOccasion: action.categoryOccasion,
                categoryColor: action.categoryColor,
                categoryContains: action.categoryContains,
                categoryType: action.categoryType,
                minPrice: action.minPrice,
                categoryPriceSort: action.categoryPriceSort,
                allProducts: action.products,
                selectedCountryCode: R.toUpper(R.pathOr('FI', ['selectedCountryCode'], action)),
            }
        default:
            return state
    }
}
