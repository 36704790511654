import { combineReducers } from 'redux'
import { SET_INVOICE_REDIRECT } from '../actions/invoiceActions'

const invoiceRedirect = (state = false, { type, invoice_redirect }) => {
    switch (type) {
        case SET_INVOICE_REDIRECT:
            return invoice_redirect
        default:
            return state
    }
}

export default combineReducers({
    redirect: invoiceRedirect,
})
