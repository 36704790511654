import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import * as R from 'ramda'
import { intervalToDuration, getDay, formatDuration, isBefore, set } from 'date-fns'
import { enGB, fi } from 'date-fns/locale'
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'
import { getLanguage } from '../../utils/translation'
import PostalCodeSearch from './PostalCodeSearch'
import config from '../../config'

const assets = config.ASSETS

const pageTranslation = {
    fi: {
        page: {
            postalCodeInputInfo:
                'Syötä alle päivämäärä sekä postinumero tarkistaaksesi, onko toimitus mahdollinen kyseisenä päivänä.',
            send: 'Lähetä nyt',
            deliveryPossible: 'Tilaus tälle päivälle mahdollinen vielä',
            counterEndText:
                'Kukat kulkevat taas seuraavana arkipäivänä tai lauantaina. Joillekin paikkakunnille on mahdollista tilata arkisin 15.45 asti.',
            searchOnlyFinnishPostalCodes: '*Haku koskee vain kotimaan postinumeroita.',
        },
    },
    en: {
        page: {
            postalCodeInputInfo:
                'Enter a date and postal code below to check if delivery is possible on the selected date.',
            send: 'Send now',
            deliveryPossible: 'Same day order still possible for',
            counterEndText:
                'Delivery is possible on the next working day or Saturday. Orders to some regions are possible until 15:45 on weekdays.',
            searchOnlyFinnishPostalCodes: '*The search is only for Finnish postal codes.',
        },
    },
}

const getCutoffTimeUtc = (isSaturday, nowInHelsinki) => {
    const [hours, minutes] = isSaturday ? [10, 45] : [13, 45]
    return zonedTimeToUtc(set(nowInHelsinki, { hours, minutes, seconds: 0 }), 'Europe/Helsinki')
}

const getLocale = () => (getLanguage() === 'fi' ? fi : enGB)

const DeliveryContent = (props) => {
    const [countDownText, setCountDownText] = useState('')
    const [showDeliveryPossibleText, setShowDeliveryPossibleText] = useState(false)

    const { lang, landingContent } = props

    const selectedTrans = R.path([getLanguage()], pageTranslation)
    const trans = R.path(['page'], selectedTrans)

    const updateCountdown = () => {
        const countdownActive = R.pathOr(true, [0, 'delivery_counter_is_active'], landingContent)

        const now = new Date()
        const nowInHelsinki = utcToZonedTime(now, 'Europe/Helsinki')
        const isSaturday = getDay(nowInHelsinki) === 6
        const isSunday = getDay(nowInHelsinki) === 0
        const cutoffTime = getCutoffTimeUtc(isSaturday, nowInHelsinki)
        const timer = formatDuration(intervalToDuration({ start: now, end: cutoffTime }), { locale: getLocale() })

        if (!countdownActive) {
            setCountDownText(countdownDisabledText)
            setShowDeliveryPossibleText(false)
        } else if (isSunday) {
            setCountDownText(R.path(['counterEndText'], trans))
            setShowDeliveryPossibleText(false)
        } else if (isBefore(now, cutoffTime)) {
            setCountDownText(`${timer}.`)
            setShowDeliveryPossibleText(true)
        } else {
            setCountDownText(R.path(['counterEndText'], trans))
            setShowDeliveryPossibleText(false)
        }
    }

    useEffect(() => {
        updateCountdown()
        const interval = setInterval(updateCountdown, 1000)
        return () => clearInterval(interval)
    }, [landingContent])

    const countdownDisabledText = R.pathOr('', [0, `delivery_counter_${lang}_disabled_text`], landingContent)

    return (
        <div className="delivery_info_and_image__container">
            <img src={`${assets}/icon-time.png`} alt="Time Icon" />
            <div className="delivery_info__container">
                <div>
                    {showDeliveryPossibleText && <p>{R.path(['deliveryPossible'], trans)}</p>}
                    <p className="delivery__counter__time">{countDownText}</p>
                </div>
                <p>{R.path(['postalCodeInputInfo'], trans)}</p>
                <p className="postal_code_search__info">{R.path(['searchOnlyFinnishPostalCodes'], trans)}</p>
                <PostalCodeSearch />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    landingContent: state.landingContent.content,
})

export default connect(mapStateToProps)(DeliveryContent)
