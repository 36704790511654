import * as R from 'ramda'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { addCondolencesContent } from '../../actions/formActions'

import { name, required } from '../../utils/ValidationRules.jsx'
import Form from 'react-validation/build/form'
import Input from 'react-validation/build/input'

import { getLanguage } from '../../utils/translation'

import { saveCondolencesContent } from '../../actions/cartActions'
import { isForeignProductByProduct } from '../../utils/ProductTypes'
import BottomNavigationBar from '../bottom-navigation-bar/BottomNavigationBar'

const pageTranslation = {
    fi: {
        page: {
            condolenceCardHeader: 'Adressi',
            greeting: 'Muistosanat',
            greetingTextArea:
                'Adresseihin on mahdollista lisätä muistosanat eli värssy. Adresseissa on myös valmiita värssyjä, joten tämä kenttä on mahdollista jättää myös tyhjäksi',
            nameDeceasedHeader: 'Vainajan nimi',
            deceasedTextArea: 'Adressiin tekstataan aina vainajan nimi',
            senderName: 'Lähettäjien nimet',
            senderNameTextArea: 'Adressin lähettäjien, henkilöiden, yrityksen tai yhteisön nimi/nimet',
        },
    },
    en: {
        page: {
            condolenceCardHeader: 'Card of condolence',
            greeting: 'Greeting',
            greetingTextArea:
                'Add your own verse or greeting to the card of condolence. You can also leave this field empty and the delivering flower shop will add a pre-filled card of condolence to the delivery.',
            nameDeceasedHeader: 'Name of the deceased',
            deceasedTextArea: 'The name of the deceased will be added to the card of condolence',
            senderName: 'Name of sender',
            senderNameTextArea: 'The name(s) of the sender(s), company’s or community’s name',
        },
    },
}

const FormCondolences = (props) => {
    const {
        formData,
        history,
        cartProducts,
        lang,
        cc,
        saveCondolencesContentToStore,
        saveCondolencesContentToDb,
        cartLoading,
        ribbonSelected,
    } = props

    const [condolenceWords, setCondolenceWords] = useState(R.path(['condolenceWords'], formData) || '')
    const [condolenceMortName, setCondolenceMortName] = useState(R.path(['condolenceMortName'], formData) || '')
    const [condolenceSenderNames, setCondolenceSenderNames] = useState(
        R.path(['condolenceSenderNames'], formData) || '',
    )

    useEffect(() => {
        window.scrollTo(0, 0)
        if (isForeignProductByProduct(R.head(cartProducts))) {
            history.push(`/${lang}/${cc}`)
        }
    }, [])

    useEffect(() => {
        saveCondolencesContentToStore(condolenceWords, condolenceMortName, condolenceSenderNames)
    }, [condolenceWords, condolenceMortName, condolenceSenderNames])

    const selectedTrans = R.path([getLanguage()], pageTranslation)
    const trans = R.path(['page'], selectedTrans)
    const backLink = ribbonSelected ? `/${lang}/${cc}/order/ribbon` : `/${lang}/${cc}/order/recipient-info`

    const handleCondolenceWordsChange = (event) => {
        setCondolenceWords(event.target.value)
    }

    const handleCondolenceMortNameChange = (event) => {
        setCondolenceMortName(event.target.value)
    }

    const handleCondolenceSenderNamesChange = (event) => {
        setCondolenceSenderNames(event.target.value)
    }

    const submitCondolencesContent = (event) => {
        event.preventDefault()
        saveCondolencesContentToStore(condolenceWords, condolenceMortName, condolenceSenderNames)
        saveCondolencesContentToDb()
    }

    return (
        <div className="productOrder__contentHolder">
            <Form className="pure-form pure-form-stacked productOrder__form">
                <fieldset>
                    <div className="pure-g">
                        <div className="pure-u-20-24">
                            <h2 className="order__headline">{R.path(['condolenceCardHeader'], trans)}</h2>
                        </div>
                        <div className="pure-u-4-24 alignRight form__indicator">
                            <span></span>
                        </div>
                    </div>

                    <div className="input-group form__field">
                        <label htmlFor="condolenceWords">{R.path(['greeting'], trans)}</label>
                        <div className="productOrder__attachedMessage__container">
                            <textarea
                                maxLength="255"
                                id="condolenceWords"
                                className="productOrder__attachedMessage"
                                value={condolenceWords}
                                onChange={handleCondolenceWordsChange}
                                placeholder={R.path(['greetingTextArea'], trans)}
                            />
                            <span className="productOrder__attachedMessage__counter">{condolenceWords.length}/255</span>
                        </div>
                    </div>

                    <div className="input-group form__field">
                        <label htmlFor="condolenceMortName">
                            {R.path(['nameDeceasedHeader'], trans)}
                            <sup>*</sup>
                        </label>
                        <Input
                            onChange={handleCondolenceMortNameChange}
                            placeholder={R.path(['deceasedTextArea'], trans)}
                            id="condolenceMortName"
                            errorclassname="is-invalid-input"
                            type="text"
                            containerclassname=""
                            value={condolenceMortName}
                            name="condolenceMortName"
                            validations={[required, name]}
                            lang={lang}
                        />
                    </div>

                    <div className="input-group form__field">
                        <label htmlFor="condolenceSenderNames">
                            {R.path(['senderName'], trans)}
                            <sup>*</sup>
                        </label>
                        <Input
                            onChange={handleCondolenceSenderNamesChange}
                            placeholder={R.path(['senderNameTextArea'], trans)}
                            id="condolenceSenderNames"
                            errorclassname="is-invalid-input"
                            type="text"
                            containerclassname=""
                            value={condolenceSenderNames}
                            name="condolenceSenderNames"
                            validations={[required, name]}
                            lang={lang}
                        />
                    </div>
                </fieldset>
                <BottomNavigationBar
                    backLink={backLink}
                    handleContinue={submitCondolencesContent}
                    loading={cartLoading}
                />
            </Form>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        formData: state.form,
        order: state.order,
        lang: R.path(['lang', 'lang'], state),
        cc: R.path(['lang', 'db'], state),
        cartProducts: R.pathOr([], ['cart', 'items', 'products'], state),
        cartLoading: R.path(['cart', 'loading'], state),
        ribbonSelected: R.path(['order', 'ribbon'], state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        saveCondolencesContentToStore: (condolenceWords, condolenceMortName, condolenceSenderNames) =>
            dispatch(addCondolencesContent(condolenceWords, condolenceMortName, condolenceSenderNames)),
        saveCondolencesContentToDb: () => dispatch(saveCondolencesContent()),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FormCondolences))
